import { gql } from '@apollo/client/core';

export const CREATE_QUIZ = gql`
  mutation CreateQuiz($model: QuizInput!) {
    Activity {
      CreateQuiz(model: $model) {
        Result {
          Id
        }
      }
    }
  }
`;
