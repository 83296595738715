import { gql } from '@apollo/client/core';

export const REMOVE_STUDENT = gql`
  mutation RemoveStudents($model: ManageStudentInput) {
    Teacher {
      DeleteStudent(model: $model) {
        Result
      }
    }
  }
`;
