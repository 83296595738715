import { gql } from '@apollo/client/core';

export const JOIN_COURSE_GROUP = gql`
  mutation JoinCourseGroup($model: JoinCourseGroupInput!) {
    Request {
      StudentRequests {
        JoinCourseGroup(model: $model) {
          Result {
            Id
          }
        }
      }
    }
  }
`;
