import { gql } from '@apollo/client/core';

export const ABSENT_REQUEST = gql`
  mutation RequestForAbsent($model: AbsentInput!) {
    Request {
      StudentRequests {
        Absent(model: $model) {
          Result {
            Id
          }
        }
      }
    }
  }
`;
