import { gql } from '@apollo/client/core';

export const CREATE_TEACHER_QUESTION_BANK = gql`
  mutation CreateTeacherQuestionBank($model: TeacherQuestionInput) {
    Teacher {
      CreateTeacherQuestion(model: $model) {
        Result {
          Id
          TeacherId
          SubjectId
        }
      }
    }
  }
`;
