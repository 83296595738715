import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class ToastrService {
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly translate: TranslateService
  ) {}

  success(message: string, duration = 2000): void {
    this.snackBar.open(message, '', {
      duration,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      direction: this.translate.currentLang === 'en' ? 'ltr' : 'rtl',
      panelClass: ['toastr-success'],
    });
  }

  error(message: string, duration = 2000): void {
    this.snackBar.open(message, '', {
      duration,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      direction: this.translate.currentLang === 'en' ? 'ltr' : 'rtl',
      panelClass: ['toastr-error'],
    });
  }
}
