import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastrService } from '../services/toastr.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class DefaultInterceptor implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    private readonly injector: Injector,
    private authService: AuthService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
            this.notifyOnError(event);
          }
        },
        (err) => {
          if (err instanceof HttpErrorResponse) {
            this.notifyOnError(err);
          } else {
            this.displayErrorNotification(err.message);
          }
        }
      )
    );
  }

  private notifyOnError(response: HttpResponse<any> | HttpErrorResponse): void {
    if (response instanceof HttpErrorResponse) {
      if (response.status === 401) {
        console.log('UNOTHURIZED ERROR OCCURS ! ');
        this.authService
          .logout()
          .subscribe(() => console.log('HANDEL AUTHOURIZED ERROR IN SERVICE '));
        this.router.navigate(['/login']);
      }
    } else {
      const graphqlErrors = response?.body?.errors;
      if (graphqlErrors && Array.isArray(graphqlErrors)) {
        if (
          graphqlErrors.filter(
            (error) => error.extensions.code === 'Emailalreadytaken'
          )?.length
        ) {
          return;
        }
        const message = graphqlErrors.map((err) => err.message).join('\n');
        this.displayErrorNotification(message);
      }
    }
  }

  private displayErrorNotification(message: string): void {
    const toastr = this.injector.get<ToastrService>(ToastrService);
    toastr.error(message);
  }
}
