import { Subject } from 'rxjs';

export class BaseComponent {
  protected destroy$: Subject<void>;
  public constructor() {
    this.destroy$ = new Subject();
  }

  protected onDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
