<mat-form-field appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    [(value)]="defaultSelectedOption"
    (selectionChange)="onSelectOption($event)"
  >
    <mat-option *ngFor="let option of options" [value]="option">{{
      option | titlecase
    }}</mat-option>
  </mat-select>
</mat-form-field>
