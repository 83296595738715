import { gql } from '@apollo/client/core';

export const GET_ASSISTANTS = gql`
  query Assistants($criteria: AssistantCriteria) {
    AssistantQuery {
      Assistants(criteria: $criteria) {
        Result {
          Id
          Name
          ImagePath
          MobileNumber
          Email
          About
          CourseGroups {
            Id
            Name
            SchoolYearName
            ScientificSubjectName
          }
        }
        TotalCount
      }
    }
  }
`;

export const GET_ASSISTANT_ROLES = gql`
query AssistantRoles($criteria: AssistantRoleCriteria) {
  AssistantQuery {
    AssistantRoles(criteria: $criteria) {
      Result {
        RoleId
        RoleName
        CourseGroupId
        CourseGroupName
        Permission
      }
      TotalCount
    }
  }
}

`;


export const ASSIGN_ASSISTANT_COURSEGROUP_ROLES = gql`mutation AssignAssistantCourseGroupRoles($model: AssignAssistantRolesInput!) {
  Teacher {
    AssignAssistantCourseGroupRoles(model: $model) {
      Result {
       AssistantId
        CourseGroupId
        TeacherId
      }
    }
  }
}

`;

export const ASSIGN_ASSISTANT_GENERAL_ROLES = gql`mutation AssignAssistantGeneralRoles($model: AssignAssistantRolesInput!) {
  Teacher {
    AssignAssistantPublicRoles(model: $model) {
      Result{
        AssistantId
      }
    }
  }
}


`;

export const DELETE_ASSISTANT = gql`mutation DeleteAssistant($model: ManageAssistantInput!) {
  Teacher {
    DeleteAssistant(model: $model) {
      Result
    }
  }
}

`;







