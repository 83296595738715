import { gql } from '@apollo/client/core';

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($model: ChangePasswordInput!) {
    Account {
      ChangePassword(model: $model) {
        Result {
          AssistantId
          ImagePath
          Name
          ParentId
          RefreshToken
          StudentId
          TeacherId
          Token
          TokenExpirationDate
          UserId
          UserName
        }
        TotalCount
      }
    }
  }
`;
