import { gql } from '@apollo/client/core';

export const GET_USER_ACTIVITIES = gql`
  query UserActivities($criteria: UserActivityCriteria) {
    ActivityQuery {
      UserActivities(criteria: $criteria) {
        Result {
          ActivityAddedDate
          ActivityDeadline
          ActivityDescription
          ActivityGrad
          ActivityId
          ActivityIsFinished
          ActivityIsOnline
          ActivityName
          ActivityTypeName
          AssistantName
          AttachmentPath
          AttachmentPathFileType
          Attachments {
            AttachmentName
            AttachmentPath
            FileType
          }
          Comment
          CourseGroupName
          DeliveredDate
          Grade
          Id
          ImagePath
          IsMarked
          IsView
          IsWinner
          ParentUserActivityId
          SchoolYearName
          ScientificSubjectName
          ScoreOrder
          SessionName
          StudentId
          StudentName
          TeacherName
          UserName
        }
        TotalCount
      }
    }
  }
`;
