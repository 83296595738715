import { gql } from '@apollo/client/core';

export const CREATE_POST = gql`
  mutation CreatePost($model: PostInput!) {
    Activity {
      CreatePost(model: $model) {
        Result {
          Id
        }
      }
    }
  }
`;
