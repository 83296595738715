import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { RoleBasedDirective } from './directives/role-based.directive';
import { FlexLayoutModule } from '@angular/flex-layout';

import {
  MtxButtonModule,
  MtxGridModule,
  MtxLoaderModule,
} from '@ng-matero/extensions';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { SelectStringsInputComponent } from './components/select-strings-input/select-strings-input.component';
import { NgPipesModule } from 'ngx-pipes';
import { VideoModalComponent } from './components/video-modal/video-modal.component';
import { CoursesCarouselComponent } from './components/courses-carousel/courses-carousel.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { RatingComponent } from './components/rating/rating.component';
import { ChangeGroupComponent } from './components/change-group/change-group.component';
import { AbsentRequestComponent } from './components/absent-request/absent-request.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

import { NoResultsComponent } from './components/no-results/no-results.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TableImageComponent } from './components/table-image/table-image.component';
import { AttachmentsGroupComponent } from './components/attachments-group/attachments-group.component';
import { LightboxModule } from 'ngx-lightbox';
import { AttachmentComponent } from './components/attachment/attachment.component';
import { NgxTimerModule } from 'ngx-timer';
import { TimerComponent } from './components/timer/timer.component';
import { TimeIsUpModalComponent } from './components/time-is-up-modal/time-is-up-modal.component';
import { SearchComponent } from './components/search/search.component';
import { SelectComponent } from './components/select/select.component';
import { PreviewAttachmentsComponent } from './components/preview-attachments/preview-attachments.component';
import { RemoveDialogComponent } from './dialogs/remove-dialog/remove-dialog.component';
import { AngularFileUploaderModule } from 'angular-file-uploader';
import { FloorPipe } from './directives/floor.pipe';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

const ANGULAR_MATERIAL_MODULES = [
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSliderModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTabsModule,
  MatTableModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,
  MatNativeDateModule,
  MatMomentDateModule,
  NgxMaterialTimepickerModule,
];

const COMPONENTS = [
  SearchInputComponent,
  SelectStringsInputComponent,
  CoursesCarouselComponent,
  VideoModalComponent,
  RatingComponent,
  ChangeGroupComponent,
  AbsentRequestComponent,
  NoResultsComponent,
  TableImageComponent,
  AttachmentsGroupComponent,
  AttachmentComponent,
  TimerComponent,
  TimeIsUpModalComponent,
  SearchComponent,
  SelectComponent,
  PreviewAttachmentsComponent,
  RemoveDialogComponent,
];

const ANGULAR_MATERIAL_EXTENSIONS = [
  MtxGridModule,
  MtxLoaderModule,
  MtxButtonModule,
  NgxSpinnerModule,
];

@NgModule({
  declarations: [RoleBasedDirective, ...COMPONENTS, FloorPipe],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FontAwesomeModule,
    TranslateModule,
    NgPipesModule,
    FlexLayoutModule,
    RoleBasedDirective,
    AngularFileUploaderModule,
    ...ANGULAR_MATERIAL_MODULES,
    ...ANGULAR_MATERIAL_EXTENSIONS,
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    TranslateModule,
    FlexLayoutModule,
    NgPipesModule,
    CarouselModule,
    LightboxModule,
    NgxTimerModule,
    AngularFileUploaderModule,
    ...ANGULAR_MATERIAL_EXTENSIONS,
    ...ANGULAR_MATERIAL_MODULES,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
