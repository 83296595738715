import { gql } from '@apollo/client/core';

export const GET_SESSION_ATTENDANCE_CODE = gql`
  query GetSessionAttendanceCode($criteria: SessionAttendanceCriteria) {
    SessionQuery {
      SessionAttendanceCode(criteria: $criteria) {
        Result {
          StudentId
          StudentName
          ScientificSubjectName
          SchoolYearName
          CourseGroupName
          IsAttended
          StudentAttendanceCode
        }
        TotalCount
      }
    }
  }
`;
