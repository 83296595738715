import { gql } from '@apollo/client/core';

export const GET_USER_PROFILE = gql`
  query UserProfile {
    AccountQuery {
      UserProfile {
        Result {
          Bio
          Email
          Gender
          GenderName
          MobileNumber
          EducationTypeId
          SchoolYearId
          ImagePath
          IsConfirmed
          VideoPath
          VideoPathId
          VideoPicturePath
          Name
          Slogan
          Status
          StudentEducationTypeName
          StudentSchoolYearName
          UserId
          UserType
          StudentId
          TeacherId
          ParentId
          AssistantId
          VideoPath
        }
      }
    }
  }
`;
