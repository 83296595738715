import { gql } from '@apollo/client/core';

export const GET_REVISIONS = gql`
  query Revisions($criteria: ActivityCriteria, $withComment: Boolean!) {
    ActivityQuery {
      Revisions(criteria: $criteria) {
        Result {
          Id
          Name
          Description
          IsOnline
          CreatorImage
          Creator
          ScientificSubjectName
          SchoolYearName
          CourseGroupName
          Date
          StartDate
          StartTime
          Content
          EndTime
          IsDraft
          CourseGroupId
          ServerDateTime
          CurrentTime
          IsScheduled
          DeadLine
          UserActivityCount
          Attachments {
            AttachmentPath
            AttachmentName
            FileType
          }
          Comments @include(if: $withComment) {
            Id
            Comment
            UserName
            ImagePath
            DeliveredDate
            Replies {
              Id
              Comment
              UserName
              ImagePath
              DeliveredDate
            }
          }
        }
        TotalCount
      }
    }
  }
`;
