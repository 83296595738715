import { Component, Input, OnInit } from '@angular/core';
import { Attachment, FileType } from '@generated-types';
import { IAlbum, Lightbox, LightboxConfig } from 'ngx-lightbox';

@Component({
  selector: 'app-attachments-group',
  templateUrl: './attachments-group.component.html',
  styleUrls: ['./attachments-group.component.scss'],
})
export class AttachmentsGroupComponent implements OnInit {
  @Input() public attachments: Attachment[] = [];
  public readonly FILE_TYPE = FileType;
  public imageAttachments: Attachment[] = [];
  public pdfAttachments: Attachment[] = [];

  public constructor(
    private readonly lightbox: Lightbox,
    private readonly lightboxConfig: LightboxConfig
  ) {}

  public ngOnInit(): void {
    this.imageAttachments = this.attachments?.filter(
      (attachment) => attachment.FileType === FileType.IMAGE
    );

    this.pdfAttachments = this.attachments.filter(
      (attachment) => attachment.FileType === FileType.FILE
    );
  }

  public openLightbox(index: number): void {
    const lightboxAlbum: IAlbum[] = [];
    this.imageAttachments.forEach((attachment) => {
      lightboxAlbum.push({
        src: attachment.AttachmentPath,
        caption: attachment.AttachmentName,
        thumb: null,
      });
    });

    this.lightboxConfig.showZoom = true;
    this.lightboxConfig.showRotate = true;
    this.lightboxConfig.centerVertically = true;
    this.lightboxConfig.fitImageInViewPort = true;
    this.lightboxConfig.enableTransition = true;

    this.lightbox.open(lightboxAlbum, index);
  }
}
