import { gql } from '@apollo/client/core';

export const GET_STUDENT_REQUESTS = gql`
  query StudentRequests($criteria: RequestCriteria) {
    RequestQuery {
      StudentRequests(criteria: $criteria) {
        Result {
          Id
          TeacherName
          CourseGroupName
          CourseName
          ParentName
          RequestType
          RequestTypeName
          RequestStatus
          RequestStatusName
          SendDate
          SendTo
          SendFrom
        }
        TotalCount
      }
    }
  }
`;
