import { gql } from '@apollo/client/core';

export const GET_COURSE_GROUP_QUESTION_BANK = gql`
  query GetGroupQuestionBank($criteria: CourseQuestionCriteria) {
    CourseGroupQuery {
      QuestionBank(criteria: $criteria) {
        Result {
          Id
          CourseId
          StudentId
          QuestionText
          QuestionPath
          Answer
          AnswerPath
          IsVerified
          QuestionAttachments {
            AttachmentPath
            FileType
          }
          AnswerAttachments {
            AttachmentPath
            FileType
          }
        }
        TotalCount
      }
    }
  }
`;
