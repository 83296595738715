export * from './change-password.graphql';
export * from './check-email-and-mobile-number.graphql';
export * from './forget-password.graphql';
export * from './login.graphql';
export * from './refresh-token.graphql';
export * from './register.graphql';
export * from './resend-verification-code.graphql';
export * from './reset-password.graphql';
export * from './update-user-profile.graphql';
export * from './validate-reset-password-code.graphql';
export * from './verify-mobile.graphql';
export * from './submit-course-group-rate.graphql';
export * from './submit-course-group-report.graphql';
export * from './change-course-group.graphql';
export * from './cancel-request.graphql';
export * from './manage-add-son-request.graphql';
export * from './manage-add-to-course-request.graphql';
export * from './suggest-question.graphql';
export * from './submit-session-rate.graphql';
export * from './follow-teacher.graphql';
export * from './unfollow-teacher.graphql';
export * from './join-course-group.graphql';
export * from './absent-request.graphql';
export * from './suspend-student.graphql';
export * from './remove-student.graphql';
export * from './block-student.graphql';
export * from './create-course.graphql';
export * from './create-course-group.graphql';
export * from './update-course-group.graphql';
export * from './delete-course-group.graphql';
export * from './create-teacher-question-bank.graphql';
export * from './ask-session-question.graphql';
export * from './apply-activity-offline.graphql';
export * from './apply-activity-online.graphql';
export * from './create-quiz.graphql';
export * from './remove-quiz.graphql';
export * from './create-previous-exam.graphql';
export * from './update-previous-exam.graphql';
export * from './remove-previous-exam.graphql';
export * from './update-teacher-question-bank.graphql';
export * from './remove-teacher-question-bank.graphql';
export * from './create-post.graphql';
export * from './update-post.graphql';
export * from './remove-post.graphql';
export * from './create-session.graphql';
export * from './update-session.graphql';
export * from './remove-session.graphql';
export * from './generate-qr-code.graphql';
export * from './create-assignment.graphql';
export * from './update-assignment.graphql';
export * from './remove-assignment.graphql';
export * from './create-revision.graphql';
export * from './update-revision.graphql';
export * from './remove-revision.graphql';
export * from './view-revision.graphql';
export * from './online-session-info.graphql';
