import { gql } from '@apollo/client/core';

export const CHANGE_COURSE_GROUP = gql`
  mutation ChangeCourseGroupPermanent($model: ChangeCourseGroupInput!) {
    Request {
      StudentRequests {
        ChangeCourseGroup(model: $model) {
          Result {
            CourseGroupId
            CourseGroupName
            CourseName
            Id
          }
        }
      }
    }
  }
`;
