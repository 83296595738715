import { gql } from '@apollo/client/core';

export const VERIFY_MOBILE = gql`
  mutation VerifyMobile($model: VerifyMobileInput!) {
    Account {
      VerifyMobile(model: $model) {
        Result {
          AssistantId
          ImagePath
          Name
          RefreshToken
          StudentId
          ParentId
          TeacherId
          Token
          TokenExpirationDate
          UserId
          UserName
        }
        TotalCount
      }
    }
  }
`;
