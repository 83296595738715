<div class="attachments">
  <section *ngIf="imageAttachments?.length">
    <div class="attachments__images">
      <ng-container *ngFor="let image of imageAttachments; let i = index">
        <img [src]="image?.AttachmentPath" alt="" (click)="openLightbox(i)" />
      </ng-container>
    </div>
  </section>
  <section *ngIf="pdfAttachments?.length">
    <div class="attachments__pdf">
      <ng-container *ngFor="let pdf of pdfAttachments">
        <img
          src="../../../../assets/images/PDF.png"
          (click)="pdfInput.click()"
          [matTooltip]="pdf.AttachmentName"
        />
        <a
          style="display: none"
          [href]="pdf.AttachmentPath"
          #pdfInput
          download
        ></a>
      </ng-container>
    </div>
  </section>
</div>
