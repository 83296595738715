import { gql } from '@apollo/client/core';

export const GET_COUNTRIES = gql`
  query Countries($criteria: CountryCriteria) {
    LookupQuery {
      Countries(criteria: $criteria) {
        Result {
          Id
          ImagePath
          Name
        }
      }
    }
  }
`;
