import { Injectable } from '@angular/core';
import {
  ActivityCriteria,
  ActivityTypeListResponse,
  ActivityTypeResponse,
  ApplicationUserType,
  Assignment,
  AssignmentInput,
  AssignmentsQuery,
  AssignmentsQueryVariables,
  AssignmentTypeListResponse,
  BooleanGraphTypeResponse,
  CreateAssignmentMutation,
  CreateAssignmentMutationVariables,
  RemoveAssignmentMutation,
  RemoveAssignmentMutationVariables,
  UpdateAssignmentMutation,
  UpdateAssignmentMutationVariables,
  UserActivityCriteria,
} from '@generated-types';
import { GET_ASSIGNMENTS } from '@queries';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { AuthService } from './auth.service';
import {
  CREATE_ASSIGNMENT,
  REMOVE_ASSIGNMENT,
  UPDATE_ASSIGNMENT,
} from '@mutations';

@Injectable({
  providedIn: 'root',
})
export class AssignmentsService implements Resolve<Observable<Assignment>> {
  public constructor(
    private readonly dataService: DataService,
    private readonly authService: AuthService
  ) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Assignment> {
    if (
      this.authService.getUserState().UserType === ApplicationUserType.STUDENT
    ) {
      return this.dataService
        .query<AssignmentsQuery, AssignmentsQueryVariables>(GET_ASSIGNMENTS, {
          criteria: {
            Id: +route.params.Id,
            SessionId: +route.params.id,
            StudentId: this.authService.getUserState().StudentId,
            StudentIdCouserGroup: this.authService.getUserState().StudentId,
            TeacherId: this.authService.getUserState().TeacherId,
          },
          userActivityCriteria: {
            StudentId: this.authService.getUserState().StudentId,
          },
          studentCriteria: {
            StudentId: this.authService.getUserState().StudentId,
          },
        })
        .pipe(
          map((response) => {
            return response.data.ActivityQuery.Assignments.Result[0];
          }),
          first()
        );
    } else {
      return this.dataService
        .query<AssignmentsQuery, AssignmentsQueryVariables>(GET_ASSIGNMENTS, {
          criteria: {
            Id: +route.params.Id,
            SessionId: +route.params.id,
            IsDraft: route.params.isDraft,
            TeacherId: this.authService.getUserState().TeacherId,
          },
        })
        .pipe(
          map((response) => response.data.ActivityQuery.Assignments.Result[0]),
          first()
        );
    }
  }

  public getAssignemnts(
    criteria: ActivityCriteria,
    userActivityCriteria: UserActivityCriteria
  ): Observable<AssignmentTypeListResponse> {
    return this.dataService
      .query<AssignmentsQuery, AssignmentsQueryVariables>(GET_ASSIGNMENTS, {
        criteria,
        userActivityCriteria,
      })
      .pipe(map((response) => response.data.ActivityQuery.Assignments));
  }

  public createAssignment(
    model: AssignmentInput
  ): Observable<ActivityTypeListResponse> {
    return this.dataService
      .mutate<CreateAssignmentMutation, CreateAssignmentMutationVariables>(
        CREATE_ASSIGNMENT,
        {
          model,
        }
      )
      .pipe(map((response) => response.data.Activity.CreateAssignment));
  }

  public updateAssignment(
    model: AssignmentInput
  ): Observable<ActivityTypeResponse> {
    return this.dataService
      .mutate<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>(
        UPDATE_ASSIGNMENT,
        {
          model,
        }
      )
      .pipe(map((response) => response.data.Activity.UpdateAssignment));
  }

  public removeAssignment(
    activtiyId: number,
    criteria: ActivityCriteria
  ): Observable<BooleanGraphTypeResponse> {
    return this.dataService
      .mutateWithRefetch<
        RemoveAssignmentMutation,
        RemoveAssignmentMutationVariables,
        AssignmentsQuery,
        AssignmentsQueryVariables
      >(
        REMOVE_ASSIGNMENT,
        {
          activtiyId,
        },
        GET_ASSIGNMENTS,
        { criteria, userActivityCriteria: null }
      )
      .pipe(map((response) => response.data.Activity.DeleteActivtiy));
  }
}
