import { gql } from '@apollo/client/core';

export const REMOVE_PREVIOUS_EXAM = gql`
  mutation RemovePreviousExam($previousExamId: Int) {
    Teacher {
      DeletePreviousExam(previousExamId: $previousExamId) {
        Result
      }
    }
  }
`;
