import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { eduraAnimations } from '@animations';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [eduraAnimations],
})
export class SearchInputComponent implements AfterViewInit, OnDestroy {
  constructor(public translate: TranslateService) {}
  private readonly destroy$ = new Subject<void>();
  @Input() disabled: boolean;
  @ViewChild('searchInput') private readonly searchInput: ElementRef;
  @Output() public readonly searchTerm = new EventEmitter<string>();

  public ngAfterViewInit(): void {
    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(debounceTime(200), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe({
        next: () => this.searchTerm.emit(this.searchInput.nativeElement.value),
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
