import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-remove-dialog',
  templateUrl: './remove-dialog.component.html',
  styleUrls: ['./remove-dialog.component.scss'],
})
export class RemoveDialogComponent implements OnInit {
  public constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: any,
    private readonly dialogRef: MatDialogRef<RemoveDialogComponent>
  ) {}

  ngOnInit(): void {}

  public submit(): void {
    this.dialogRef.close({
      submitted: true,
      //typeMutation: this.data.type,
    });
  }
}
