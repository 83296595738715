import { gql } from '@apollo/client/core';

export const GET_ACTIVITY_QUESTIONS = gql`
  query ActivityQuestions(
    $activityCriteria: ActivityCriteria
    $withQuestion: Boolean!
  ) {
    ActivityQuery {
      Activities(criteria: $activityCriteria) {
        Result {
          Id
          ActivityType
          Name
          Date
          AddedDate
          StartDate
          DeadLine
          StartTime
          EndTime
          Creator
          CreatorPic
          ScientificSubjectName
          Grade
          ActivityQuestions @include(if: $withQuestion) {
            Id
            QuestionText
            QuestionType
            ChoiceOne
            ChoiceTwo
            ChoiceThree
            ChoiceFour
            QuestionAttachments {
              AttachmentName
              AttachmentPath
              FileType
            }
          }
        }
      }
    }
  }
`;
