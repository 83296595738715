import { gql } from '@apollo/client/core';

export const GENERATE_QR_CODE = gql`
  mutation GenerateQRCode($sessionId: Int) {
    Session {
      GenerateQRCode(sessionId: $sessionId) {
        Result
      }
    }
  }
`;
