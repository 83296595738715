import { gql } from '@apollo/client/core';

export const MANAGE_ADD_SON_REQUEST = gql`
  mutation ManageAddSonRequest($model: ManageRequestInput!) {
    Request {
      StudentRequests {
        ManageAddSonRequest(model: $model) {
          Result
        }
      }
    }
  }
`;
