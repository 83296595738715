import { gql } from '@apollo/client/core';

export const UNFOLLOW_TEACHER = gql`
  mutation UnFollowTeacher($model: TeacherFollowerInput) {
    Teacher {
      UnFollowTeacher(model: $model) {
        Result {
          TeacherId
          StudentId
          ParentId
        }
      }
    }
  }
`;
