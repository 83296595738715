import { gql } from '@apollo/client/core';

export const GET_SCHOOL_YEARS = gql`
  query SchoolYears($criteria: SchoolYearCriteria) {
    LookupQuery {
      SchoolYears(criteria: $criteria) {
        Result {
          Id
          Name
          Subjects {
            Id
            ScientificSubjectName
          }
        }
      }
    }
  }
`;
