import { gql } from '@apollo/client/core';

export const GET_REVISION_USER_ACIVITIES = gql`
  query GetRevisionUserActivities($criteria: UserActivityCriteria) {
    ActivityQuery {
      UserActivities(criteria: $criteria) {
        Result {
          Id
          StudentName
          StudentId
          ScientificSubjectName
          SchoolYearName
          CourseGroupName
          IsView
        }
        TotalCount
      }
    }
  }
`;
