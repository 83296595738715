import { gql } from '@apollo/client/core';

export const CREATE_SESSION = gql`
  mutation CreateSession($model: SessionInput) {
    Session {
      CreateSession(model: $model) {
        Result {
          Id
          QRCodePath
        }
      }
    }
  }
`;
