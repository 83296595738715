import { gql } from '@apollo/client/core';

export const GET_EDUCATION_TYPES = gql`
  query EducationTypes($criteria: EducationTypeCriteria) {
    LookupQuery {
      EducationTypes(criteria: $criteria) {
        Result {
          Id
          Name
        }
      }
    }
  }
`;
