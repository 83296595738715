import { gql } from '@apollo/client/core';

export const APPLY_ACTIVITY_ONLINE = gql`
  mutation ApplyActivityOnline($model: ApplyActivityOnlineInput!) {
    Student {
      ApplyActivityOnline(model: $model) {
        Result {
          Id
          ActivityId
          StudentId
          IsMarked
        }
      }
    }
  }
`;
