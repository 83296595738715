<mat-form-field appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    [(value)]="defaultOption"
    (selectionChange)="selectOption($event)"
    [multiple]="multiple"
  >
    <mat-option
      *ngFor="let option of options"
      [value]="valueKey ? option[valueKey] : option"
      >{{ (viewKey ? option[viewKey] : option) | titlecase }}</mat-option
    >
  </mat-select>
</mat-form-field>
