import { gql } from '@apollo/client/core';

export const GET_TEACHER_COURSES = gql`
  query TeacherCourses($criteria: CourseCriteria) {
    CourseQuery {
      Courses(criteria: $criteria) {
        Result {
          Id
          ImagePath
          ActualNumOfCourseGroups
          ActualNumOfStudents
          IsActive
          SchoolYearName
          NumOfStudents
          ScientificSubjectName
          CourseGroups {
            Id
          }
        }
        TotalCount
      }
    }
  }
`;
