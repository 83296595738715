import { Injectable } from '@angular/core';
import {
  ApplyActivityOfflineInput,
  ApplyActivityOfflineMutation,
  ApplyActivityOfflineMutationVariables,
  ApplyActivityOfflineTypeResponse,
  ApplyActivityOnlineInput,
  ApplyActivityOnlineMutation,
  ApplyActivityOnlineMutationVariables,
  UserActivityTypeResponse,
} from '@generated-types';
import { APPLY_ACTIVITY_OFFLINE, APPLY_ACTIVITY_ONLINE } from '@mutations';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class ActivitiesService {
  public constructor(private readonly dataService: DataService) {}

  public applyActivityOffline(
    model: ApplyActivityOfflineInput
  ): Observable<ApplyActivityOfflineTypeResponse> {
    return this.dataService
      .mutate<
        ApplyActivityOfflineMutation,
        ApplyActivityOfflineMutationVariables
      >(APPLY_ACTIVITY_OFFLINE, {
        model,
      })
      .pipe(map((response) => response.data.Activity.ApplyActivityOffline));
  }

  public applyActivityOnline(
    model: ApplyActivityOnlineInput
  ): Observable<UserActivityTypeResponse> {
    return this.dataService
      .mutate<
        ApplyActivityOnlineMutation,
        ApplyActivityOnlineMutationVariables
      >(APPLY_ACTIVITY_ONLINE, {
        model,
      })
      .pipe(map((response) => response.data.Student.ApplyActivityOnline));
  }
}
