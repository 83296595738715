import { gql } from '@apollo/client/core';

export const REMOVE_QUIZ = gql`
  mutation RemoveQuiz($activtiyId: Int) {
    Activity {
      DeleteActivtiy(activtiyId: $activtiyId) {
        Result
      }
    }
  }
`;
