import { Inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';

export type LocalStorageKey =
  | 'authToken'
  | 'uiLanguageCode'
  | 'zoomSettings'
  | 'savedQuizz';
export type LocalStorageLocationBasedKey = string;
const PREFIX = 'EDURA_';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor(private readonly location: Location) {}

  /**
   * Set a key-value pair in the browser's LocalStorage
   */
  public set(key: LocalStorageKey, value: any): void {
    const keyName = this.keyName(key);
    localStorage.setItem(keyName, JSON.stringify(value));
  }

  /**
   * Set a key-value pair specific to the current location (url)
   */
  public setForCurrentLocation(
    key: LocalStorageLocationBasedKey,
    value: any
  ): void {
    const compositeKey = this.getLocationBasedKey(key);
    this.set(compositeKey as any, value);
  }

  /**
   * Get the value of the given key from the SessionStorage or LocalStorage.
   */
  public get(key: LocalStorageKey): any {
    const keyName = this.keyName(key);
    const item = localStorage.getItem(keyName);
    let result: any;

    try {
      result = JSON.parse(item || 'null');
    } catch (e) {
      console.error(
        `Could not parse the localStorage value for "${key}" (${item})`
      );
    }

    return result;
  }

  public remove(key: LocalStorageKey): any {
    const keyName = this.keyName(key);
    localStorage.removeItem(keyName);
  }

  /**
   * Get the value of the given key for the current location (url)
   */
  public getForCurrentLocation(key: LocalStorageLocationBasedKey): any {
    const compositeKey = this.getLocationBasedKey(key);
    return this.get(compositeKey as any);
  }

  private getLocationBasedKey(key: LocalStorageLocationBasedKey): string {
    const path = this.location.path();
    return key + path;
  }

  private keyName(key: LocalStorageKey): string {
    return PREFIX + key;
  }
}
