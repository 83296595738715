import { gql } from '@apollo/client/core';

export const FORGET_PASSWORD = gql`
  mutation ForgetPassword($model: ForgetPasswordInput!) {
    Account {
      ForgetPassword(model: $model) {
        Result {
          Code
          UserId
        }
        TotalCount
      }
    }
  }
`;
