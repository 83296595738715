import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-table-image',
  templateUrl: './table-image.component.html',
  styleUrls: ['./table-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableImageComponent implements OnInit {
  @Input() public imagePath: string;
  ngOnInit(): void {
    console.log('img => ', this.imagePath);
  }
}
