import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseFormComponent } from '@base';

@Component({
  selector: 'app-absent-request',
  templateUrl: './absent-request.component.html',
  styleUrls: ['./absent-request.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AbsentRequestComponent
  extends BaseFormComponent
  implements OnInit {
  public constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: { sessionTitle: string },
    private readonly fb: FormBuilder,
    private readonly dialogRef: MatDialogRef<AbsentRequestComponent>
  ) {
    super();
  }

  public ngOnInit(): void {
    this.form = this.fb.group({
      Comment: [null, Validators.required],
    });
  }

  public onSubmitAbsent(): void {
    if (this.form.valid) {
      this.dialogRef.close({
        Comment: this.form.value.Comment,
      });
    }
  }
}
