import { gql } from '@apollo/client/core';

export const GET_QUESTION_BANK_TAGS = gql`
  query GetQuestionBankTags {
    TeacherQuery {
      QuestionTags {
        Result
      }
    }
  }
`;
