import { gql } from '@apollo/client/core';

export const GET_QUIZES_COURSES = gql`
  query GetCourseQuizzes($criteria: ActivityCriteria) {
    ActivityQuery {
      Quizzes(criteria: $criteria) {
        Result {
          Id
          Name
          ScientificSubjectName
          SchoolYearName
          CourseGroupName
          Date
          StartDate
          DeadLine
          IsOnline
          Description
          Grade
          ActivityDuration
          ServerDateTime
          UserActivityCount
          ActivityDuration
          CourseGroupId
          Content
          Attachments {
            AttachmentName
            AttachmentPath
            FileType
          }
          QuizQuestions {
            Id
            questionType: QuestionType
            QuestionText
            ChoiceOne
            ChoiceTwo
            ChoiceThree
            ChoiceFour
          }
          UserActivities {
            IsMarked
            Grade
          }
        }
      }
    }
  }
`;
