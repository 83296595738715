import { gql } from '@apollo/client/core';

export const CREATE_REVISION = gql`
  mutation CreateRevision($model: RevisionInput!) {
    Activity {
      CreateRevision(model: $model) {
        Result {
          Id
        }
      }
    }
  }
`;
