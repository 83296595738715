import { gql } from '@apollo/client/core';

export const GET_ASSIGNMENTS = gql`
  query Assignments(
    $criteria: ActivityCriteria
    $userActivityCriteria: UserActivityCriteria
    $studentCriteria: StudentActivityQuestionAnswersCriteria
  ) {
    ActivityQuery {
      Assignments(criteria: $criteria) {
        Result {
          Id
          Name
          ScientificSubjectName
          SchoolYearName
          CourseGroupName
          CourseGroup {
            Id
            CourseId
          }
          SessionName
          SessionId
          StartDate
          DeadLine
          Content
          Session {
            GroupedSessionGroups {
              CourseGroupName
              CourseGroupId
            }
          }
          Attatchments {
            AttachmentPath
            AttachmentName
            FileType
          }
          UserActivityCount
          StudentCount
          IsMarkedUserActivityCount
          IsMarked
          IsFinished
          IsDraft
          Grade
          IsOnline
          ServerDateTime
          IsScheduled
          ActivityQuestions {
            Id
            QuestionText
            ChoiceOne
            ChoiceTwo
            ChoiceThree
            ChoiceFour
            Answer
            QuestionType
            Grade
            QuestionAttachments {
              AttachmentName
              AttachmentPath
              IsAnswer
              FileType
            }
            QuestionAnswerAttachments {
              AttachmentName
              AttachmentPath
              IsAnswer
              FileType
            }
            StudentAnswer(criteria: $studentCriteria) {
              Id
              Answer
              IsCorrectAnswer
              IsAnswer
              IsMarked
              Grade
            }
          }
          UserActivities(criteria: $userActivityCriteria) {
            ActivityId
            ActivityGrad
            ActivityAddedDate
            ActivityDeadline
            ActivityIsOnline
            Comment
            Grade
            IsMarked
            Attachments {
              AttachmentName
              AttachmentPath
              FileType
            }
          }
        }
        TotalCount
      }
    }
  }
`;
