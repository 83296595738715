import { Injectable } from '@angular/core';
import {
  ActivityCriteria,
  ActivityTypeListResponse,
  ActivityTypeResponse,
  ActiviyQuery,
  BooleanGraphTypeResponse,
  ChallengeTypeListResponse,
  CreatePostMutation,
  CreatePostMutationVariables,
  Post,
  PostInput,
  PostsQuery,
  PostsQueryVariables,
  PostTypeListResponse,
  RemovePostMutation,
  RemovePostMutationVariables,
  RootQuery,
  UpdatePostMutation,
  UpdatePostMutationVariables,
} from '@generated-types';
import { GET_POSTS } from '@queries';
import { first, map } from 'rxjs/operators';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import { CREATE_POST, REMOVE_POST, UPDATE_POST } from '@mutations';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { AuthService } from './auth.service';
import { GET_TEACHER_DRAFT_POST } from 'src/app/common/graphql/query/get-teacher-draft-post.graphql';
import { GET_TEACHER_DRAFT_CHALLENGES } from 'src/app/common/graphql/query/get-teacher-draft-challenges.graphql';

@Injectable({
  providedIn: 'root',
})
export class PostsService implements Resolve<Observable<Post>> {
  public constructor(
    private readonly dataService: DataService,
    private readonly authService: AuthService
  ) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Post> {
    return this.getPosts({
      Id: +route.params.id,
      TeacherId: this.authService.getUserState().TeacherId,
    }).pipe(
      map((response) => response.Result[0]),
      first()
    );
  }

  public getPosts(
    criteria: ActivityCriteria
  ): Observable<PostTypeListResponse> {
    return this.dataService
      .query<PostsQuery, PostsQueryVariables>(GET_POSTS, {
        criteria,
      })
      .pipe(map((response) => response.data.ActivityQuery.Posts));
  }

  public createPost(model: PostInput): Observable<ActivityTypeListResponse> {
    return this.dataService
      .mutate<CreatePostMutation, CreatePostMutationVariables>(CREATE_POST, {
        model,
      })
      .pipe(map((response) => response.data.Activity.CreatePost));
  }

  public updatePost(model: PostInput): Observable<ActivityTypeResponse> {
    return this.dataService
      .mutate<UpdatePostMutation, UpdatePostMutationVariables>(UPDATE_POST, {
        model,
      })
      .pipe(map((response) => response.data.Activity.UpdatePost));
  }

  public removePost(
    activtiyId: number,
    criteria: ActivityCriteria
  ): Observable<BooleanGraphTypeResponse> {
    return this.dataService
      .mutateWithRefetch<
        RemovePostMutation,
        RemovePostMutationVariables,
        PostsQuery,
        PostsQueryVariables
      >(
        REMOVE_POST,
        {
          activtiyId,
        },
        GET_POSTS,
        {
          criteria,
        }
      )
      .pipe(map((response) => response.data.Activity.DeleteActivtiy));
  }
}
