import { gql } from '@apollo/client/core';

export const CREATE_COURSE = gql`
  mutation CreateCourse($model: CourseInput) {
    Course {
      CreateCourse(model: $model) {
        Result {
          Id
          SubjectId
          TeacherId
          IsActive
          ImagePath
        }
      }
    }
  }
`;
