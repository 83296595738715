import { gql } from '@apollo/client/core';

export const GET_SESSIONS = gql`
  query Sessions($criteria: SessionCriteria) {
    SessionQuery {
      Sessions(criteria: $criteria) {
        Result {
          Id
          Title
          Date
          SchoolYearName
          CourseGroupName
          Description
          CourseGroup {
            Name
            CourseId
            TeacherId
            CourseGroupHours {
              Day
              StartTime
              EndTime
            }
          }
          StartTime
          EndTime
          IsLocked
          AssignmentCount
          StudentCount
          ScientificSubjectName
          QRCodePath
          SessionType
          ZoomMeetingSettings {
            Topic
            Agenda
            AutoRecording
          }
          SessionsAttendance {
            StudentName
          }
          StudentSessionAttendance {
            StudentAttendanceCode
            IsAttended
            Day
          }
          Assignments {
            IsFinished
          }
          SessionAttendType
          CourseGroupId
          Attachments {
            AttachmentName
            AttachmentPath
            FileType
          }
        }
        TotalCount
      }
    }
  }
`;
