import { gql } from '@apollo/client/core';

export const MARK_STUDENT_ANSWER_ONLINE = gql`
  mutation MarkStudentAnswerOnline($model: MarkStudentAnswerOnlineInput) {
    Teacher {
      MarkStudentAnswerOnline(model: $model) {
        Result
      }
    }
  }
`;
