import { gql } from '@apollo/client/core';

export const GET_TEACHER_COURSE_GROUPS = gql`
  query TeacherCourseGroups(
    $criteria: CourseGroupCriteria
    $courseHours: Boolean!
  ) {
    CourseGroupQuery {
      CourseGroups(criteria: $criteria) {
        Result {
          Id
          ScientificSubjectName
          TeacherName
          MainAssistantName
          Name
          NumberOfStudents
          SchoolYearName
          IsNeedAction
          AllowViewActivitiesForAssistant
          IsActive
          CourseId
          AllowAbsentRequest
          AllowChangeGroupRequest
          AllowNewRequests
          ActualNumOfStudents
          ImagePath
          CourseGroupAssistants @include(if: $courseHours) {
            AssistantId
            AssistantName
            AssistantRoles {
              RoleId
              RoleName
            }
          }
          CourseGroupStudent {
            StudentStatus
            IsSubscribed
          }
          Teacher {
            Id
            ImagePath
          }
          CourseGroupHours @include(if: $courseHours) {
            Day
            StartTime
            EndTime
          }
        }
        TotalCount
      }
    }
  }
`;
