import { Injectable } from '@angular/core';
import {
  BooleanGraphTypeResponse,
  CourseGroupQuery,
  CourseGroupsQueryVariables,
  CourseQuestionCriteria,
  CourseQuestionTypeListResponse,
  CourseQuestionTypeResponse,
  CreateTeacherQuestionBankMutation,
  CreateTeacherQuestionBankMutationVariables,
  LookupsQuery,
  RemoveTeacherQuestionbankMutation,
  RemoveTeacherQuestionbankMutationVariables,
  StringGraphTypeListResponse,
  SubjectCriteria,
  SubjectsQuery,
  SubjectsQueryVariables,
  SubjectTypeListResponse,
  SuggestQuestionInput,
  SuggestQuestionMutation,
  SuggestQuestionMutationVariables,
  TeacherQuery,
  TeacherQuestion,
  TeacherQuestionCriteria,
  TeacherQuestionInput,
  TeacherQuestionsBankQuery,
  TeacherQuestionsBankQueryVariables,
  TeacherQuestionTypeListResponse,
  TeacherQuestionTypeResponse,
  TeacherSubjectCriteria,
  TeacherSubjectsQuery,
  TeacherSubjectsQueryVariables,
  TeacherSubjectTypeListResponse,
  UpdateTeacherQuestionbankMutation,
  UpdateTeacherQuestionbankMutationVariables,
} from '@generated-types';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import { GET_TEACHER_QUESTIONS_BANK, GET_TEACHER_SUBJECTS } from '@queries';
import { first, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import {
  CREATE_TEACHER_QUESTION_BANK,
  REMOVE_TEACHER_QUESTION_BANK,
  SUGGEST_QUESTION,
  UPDATE_TEACHER_QUESTION_BANK,
} from '@mutations';
import { GET_QUESTION_BANK_TAGS } from 'src/app/common/graphql/query/get0questions-bank-tags.graphql';
import { GET_TEACHER_SUBJECT } from 'src/app/common/graphql/query/get-teacher-subject.grapgql';
import { GET_COURSE_GROUP_QUESTION_BANK } from 'src/app/common/graphql/query/get-group-question-bank.graphql';

@Injectable({
  providedIn: 'root',
})
export class QuestionsBankService
  implements Resolve<Observable<TeacherQuestion>>
{
  public constructor(private readonly dataService: DataService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<TeacherQuestion> {
    return this.getTeacherQuestionsBank({
      Id: +route.params.id,
    }).pipe(
      map((response) => response.Result[0]),
      first()
    );
  }

  public getTeacherQuestionsBank(
    criteria: TeacherQuestionCriteria
  ): Observable<TeacherQuestionTypeListResponse> {
    return this.dataService
      .query<TeacherQuestionsBankQuery, TeacherQuestionsBankQueryVariables>(
        GET_TEACHER_QUESTIONS_BANK,
        {
          criteria,
        }
      )
      .pipe(map((response) => response.data.TeacherQuery.QuestionBank));
  }

  public getTeacherQuestionBankTags(): Observable<string[]> {
    return this.dataService
      .query(GET_QUESTION_BANK_TAGS)
      .pipe(map((response) => response.data.TeacherQuery.QuestionTags.Result));
  }

  public getTeacherSubjects(
    criteria: SubjectCriteria
  ): Observable<SubjectTypeListResponse> {
    return this.dataService
      .query<SubjectsQuery, SubjectsQueryVariables>(GET_TEACHER_SUBJECT, {
        criteria,
      })
      .pipe(map((response) => response.data.LookupQuery.Subjects));
  }

  public getCourseGroupQuestionBank(
    criteria: CourseQuestionCriteria
  ): Observable<CourseQuestionTypeListResponse> {
    return this.dataService
      .query(GET_COURSE_GROUP_QUESTION_BANK, { criteria: criteria })
      .pipe(map((response) => response.data.CourseGroupQuery.QuestionBank));
  }

  public suggestQuestionBank(
    model: SuggestQuestionInput
  ): Observable<CourseQuestionTypeResponse> {
    return this.dataService
      .mutate<SuggestQuestionMutation, SuggestQuestionMutationVariables>(
        SUGGEST_QUESTION,
        { model }
      )
      .pipe(map((response) => response.data.Student.SuggestQuestion));
  }

  public createTeacherQuestionBank(
    model: TeacherQuestionInput
  ): Observable<TeacherQuestionTypeResponse> {
    return this.dataService
      .mutate<
        CreateTeacherQuestionBankMutation,
        CreateTeacherQuestionBankMutationVariables
      >(CREATE_TEACHER_QUESTION_BANK, {
        model,
      })
      .pipe(map((response) => response.data.Teacher.CreateTeacherQuestion));
  }

  public updateTeacherQuestionBank(
    model: TeacherQuestionInput
  ): Observable<TeacherQuestionTypeResponse> {
    return this.dataService
      .mutate<
        UpdateTeacherQuestionbankMutation,
        UpdateTeacherQuestionbankMutationVariables
      >(UPDATE_TEACHER_QUESTION_BANK, {
        model,
      })
      .pipe(map((response) => response.data.Teacher.EditTeacherQuestion));
  }

  public removeTeacherQuestionBank(
    questionId: number,
    criteria: TeacherQuestionCriteria
  ): Observable<BooleanGraphTypeResponse> {
    return this.dataService
      .mutateWithRefetch<
        RemoveTeacherQuestionbankMutation,
        RemoveTeacherQuestionbankMutationVariables,
        TeacherQuestionsBankQuery,
        TeacherQuestionsBankQueryVariables
      >(
        REMOVE_TEACHER_QUESTION_BANK,
        {
          questionId,
        },
        GET_TEACHER_QUESTIONS_BANK,
        {
          criteria,
        }
      )
      .pipe(map((response) => response.data.Teacher.DeleteTeacherQuestion));
  }
}
