import { gql } from '@apollo/client/core';

export const UPDATE_REVISION = gql`
  mutation UpdateRevision($model: RevisionInput!) {
    Activity {
      UpdateRevision(model: $model) {
        Result {
          Id
        }
      }
    }
  }
`;
