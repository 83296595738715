import { gql } from '@apollo/client/core';

export const SUBMIT_COURSE_GROUP_RATE = gql`
  mutation SubmitCourseGroupRate($model: CourseGroupRateInput) {
    Student {
      SubmitCourseGroupRate(model: $model) {
        Result {
          Comment
          CourseGroupId
          CourseGroupName
          CourseId
          Id
          ImagePath
          IsSelected
          IsSubscribed
          IsTeacherPaid
          ParentId
          Rate
          Report
          SchoolYearName
          ScientificSubjectName
          StudentId
          StudentMobileNumber
          StudentName
          StudentStatus
          TeacherName
        }
        TotalCount
      }
    }
  }
`;
