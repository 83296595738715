import { gql } from '@apollo/client/core';

export const GET_SCIENTIFIC_SUBJECTS = gql`
  query ScientificSubjects($criteria: ScientificSubjectCriteria) {
    LookupQuery {
      ScientificSubjects(criteria: $criteria) {
        Result {
          Id
          Name
        }
        TotalCount
      }
    }
  }
`;
