<section fxLayout="column" fxLayoutAlign="space-between center">
  <mat-icon>sync_alt</mat-icon>
  <h1>Change Group</h1>
  <h2 mat-dialog-title>{{ data?.courseGroup?.Name }}</h2>
  <form
    [formGroup]="changeGroupForm"
    *ngIf="data?.alternativeCourseGroups?.length; else noDataFound"
  >
    <mat-radio-group formControlName="AlternativeCourseGroup">
      <mat-label>Select Alternative Course</mat-label>

      <ng-container
        *ngFor="let alternativeCourseGroup of data?.alternativeCourseGroups"
      >
        <mat-radio-button
          [value]="alternativeCourseGroup"
          [checked]="data?.courseGroup?.Id === alternativeCourseGroup?.Id"
          (change)="onHandelChange(alternativeCourseGroup)"
        >
          {{ alternativeCourseGroup?.Name }}
        </mat-radio-button>
        <div fxLayout="row wrap" fxLayoutAlign="start space-between">
          <span
            class="courseGroupDay"
            *ngFor="
              let courseGroupHours of alternativeCourseGroup?.CourseGroupHours
            "
            >{{ courseGroupHours?.Day | titlecase }}
            {{ courseGroupHours?.StartTime }} -
            {{ courseGroupHours?.EndTime }}</span
          >
        </div>
      </ng-container>
    </mat-radio-group>
  </form>
  <mat-dialog-actions align="center">
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button
      mat-raised-button
      color="accent"
      [disabled]="
        changeGroupForm.controls['AlternativeCourseGroup'].hasError('required')
      "
      (click)="onSubmitChangeGroup()"
    >
      Submit
    </button>
  </mat-dialog-actions>
</section>

<ng-template #noDataFound>
  <mat-icon>sentiment_very_dissatisfied </mat-icon>
  <h2>There's no alternative courses for now.</h2>
</ng-template>
