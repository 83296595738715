import { gql } from '@apollo/client/core';

export const GET_TEACHER_PROFILE = gql`
  query GetTeacherProfile($criteria: TeacherCriteria) {
    TeacherQuery {
      Teachers(criteria: $criteria) {
        Result {
          Id
          Name
          MobileNumber
          NumberOfFollowers
          Slogan
          Gender
          Email
          ImagePath
          VideoPath
          VideoPicturePath
          About
          Bio
          Subjects {
            ScientificSubjectName
            SchoolYearName
            EducationTypeName
          }
          TeacherFeatures {
            Id
            Name
          }
          AvailableCallHours {
            Day
            From
            To
          }
          AvailableVisitHours {
            Day
            From
            To
          }
        }
        TotalCount
      }
    }
  }
`;
