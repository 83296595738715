import { Injectable } from '@angular/core';
import {
  EducationStage,
  EducationStageCriteria,
  EducationType,
  EducationTypeCriteria,
  SchoolYear,
  SchoolYearCriteria,
  SchoolYearsQuery,
  SchoolYearsQueryVariables,
  ScientificSubject,
  ScientificSubjectCriteria,
  ScientificSubjectsQuery,
  ScientificSubjectsQueryVariables,
  Subject,
  SubjectCriteria,
  SubjectsQuery,
  SubjectsQueryVariables,
} from '@generated-types';
import {
  GET_EDUCATION_TYPES,
  GET_SCHOOL_YEARS,
  GET_SCIENTIFIC_SUBJECTS,
  GET_SUBJECTS,
} from '@queries';
import { response } from 'express';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GET_EDUCATION_STAGES } from 'src/app/common/graphql/query/education-stage.graphql';
import { DataService } from './data.service';

@Injectable({ providedIn: 'root' })
export class LookupsService {
  public constructor(private readonly dataService: DataService) {}

  public getEducationTypes(
    criteria?: EducationTypeCriteria
  ): Observable<EducationType[]> {
    return this.dataService
      .query(GET_EDUCATION_TYPES, { criteria })
      .pipe(map((response) => response.data.LookupQuery.EducationTypes.Result));
  }

  public getEducationStages(
    criteria?: EducationStageCriteria
  ): Observable<EducationStage[]> {
    return this.dataService
      .query(GET_EDUCATION_STAGES, { criteria })
      .pipe(
        map((response) => response.data.LookupQuery.EducationStages.Result)
      );
  }

  public getSchoolYears(
    criteria: SchoolYearCriteria
  ): Observable<SchoolYear[]> {
    return this.dataService
      .query<SchoolYearsQuery, SchoolYearsQueryVariables>(GET_SCHOOL_YEARS, {
        criteria,
      })
      .pipe(map((response) => response.data.LookupQuery.SchoolYears.Result));
  }

  public getScientificSubjects(
    criteria?: ScientificSubjectCriteria
  ): Observable<ScientificSubject[]> {
    return this.dataService
      .query<ScientificSubjectsQuery, ScientificSubjectsQueryVariables>(
        GET_SCIENTIFIC_SUBJECTS,
        {
          criteria,
        }
      )
      .pipe(
        map((response) => response.data.LookupQuery.ScientificSubjects.Result)
      );
  }

  public getSubjects(criteria?: SubjectCriteria): Observable<Subject[]> {
    return this.dataService
      .query<SubjectsQuery, SubjectsQueryVariables>(GET_SUBJECTS, {
        criteria,
      })
      .pipe(map((response) => response.data.LookupQuery.Subjects.Result));
  }
}
