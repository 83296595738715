<div class="attachments">
  <ng-container *ngIf="fileType === FILE_TYPE.IMAGE">
    <div class="attachments__images">
      <img [src]="attacmentPath" alt="" (click)="openLightbox()" />
    </div>
  </ng-container>
  <ng-container *ngIf="fileType === FILE_TYPE.FILE">
    <div class="attachments__pdf">
      <img src="assets/images/PDF.png" (click)="pdfInput.click()" />
      <a style="display: none" [href]="attacmentPath" #pdfInput download></a>
    </div>
  </ng-container>
</div>
