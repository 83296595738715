import { gql } from '@apollo/client/core';

export const UPDATE_COURSE_GROUP = gql`
  mutation UpdateCourseGroup($model: CourseGroupInput!) {
    CourseGroup {
      UpdateCourseGroup(model: $model) {
        Result {
          Id
        }
      }
    }
  }
`;
