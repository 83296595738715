import { gql } from '@apollo/client/core';

export const CREATE_PREVIOUS_EXAM = gql`
  mutation CreatePreviouExam($model: AddPreviousExamInput) {
    Teacher {
      CreatePreviousExam(model: $model) {
        Result {
          TeacherId
          CourseId
          Title
          ExamYear
          AttachmentPath
        }
      }
    }
  }
`;
