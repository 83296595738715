import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Attachment, FileType, Post } from '@generated-types';
import { UploadService } from '@services';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { VideoModalComponent } from '../video-modal/video-modal.component';

@Component({
  selector: 'app-preview-attachments',
  templateUrl: './preview-attachments.component.html',
  styleUrls: ['./preview-attachments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewAttachmentsComponent {
  @Input() public attachmentsForPreview: {
    AttachmentName?: string;
    AttachmentPath?: string;
    VideoPicturePath?: string;
    VideoPath?: string;
    VedioId?: string;
    FileType?: FileType;
  }[] = [];
  @Input() public vedioAttachement: any;
  @Input() public readonly title: string;
  @Input() public readonly previewOnly: boolean;
  @Input() public post: Post;
  @Input() public showVedio: boolean;
  @Input() public readonly vedioOnly: boolean;

  public attachmentsFileNames: string[] = [];
  public uploadImageLoading: boolean;
  public uploadPDFLoading: boolean;
  public uploadVedioLoading: boolean;

  public readonly FILE_TYPE = FileType;
  private readonly destroy$ = new Subject<void>();

  public constructor(
    private readonly uploadService: UploadService,
    private readonly lightbox: Lightbox,
    private readonly lightboxConfig: LightboxConfig,
    private readonly cdr: ChangeDetectorRef,
    private readonly videoModal: MatDialog
  ) {
    this.attachmentsForPreview.forEach((attachment) => {
      this.attachmentsFileNames.push(attachment.AttachmentPath);
    });
  }

  public reset(): void {
    this.attachmentsForPreview = [];
    this.attachmentsFileNames = [];
    this.cdr.detectChanges();
  }

  public update(
    values: {
      AttachmentPath: string;
      AttachmentName: string;
      FileType: FileType;
    }[]
  ): void {
    this.attachmentsForPreview = values;
    this.attachmentsFileNames = [];
    values.forEach((attachment) => {
      if (attachment.FileType !== 'VIDEO') {
        this.attachmentsFileNames.push(attachment.AttachmentName);
      }
    });
    this.cdr.detectChanges();
  }

  public uploadAttachment(event: Event, type: string): void {
    const file = (event.target as HTMLInputElement).files[0];
    console.log(file);
    if (type === 'PDF') {
      this.uploadPDFLoading = true;
    } else if (type === 'IMAGE') {
      this.uploadImageLoading = true;
    } else {
      this.uploadVedioLoading = true;
    }

    this.uploadService
      .upload(file)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (result) => {
          this.attachmentsFileNames.push(result[0]);
          if (type === 'PDF') {
            this.attachmentsForPreview.push({
              AttachmentName: result[0],
              AttachmentPath: 'assets/images/PDF.png',
              FileType: FileType.FILE,
            });
            this.uploadPDFLoading = false;
          } else if (type === 'IMAGE') {
            this.attachmentsForPreview.push({
              AttachmentName: result[0],
              AttachmentPath: `${environment.IMAGE_ENDPOINT}/${result[0]}`,
              FileType: FileType.IMAGE,
            });
            this.uploadImageLoading = false;
          }
          this.cdr.detectChanges();
        },
      });
  }

  public uploadAttachmentVedio(event: Event): void {
    this.uploadVedioLoading = true;
    const file = (event.target as HTMLInputElement).files[0];

    this.uploadService.uploadVedio(file).subscribe((res) => {
      if (res[0] == null && res[1].transcode.status === 'in_progress') {
        console.log(res[1]);

        const checkInterval = setInterval(() => {
          this.uploadService
            .checkViemoProgressUploadVedio(res[1].uri)
            .subscribe((checkResponse: any) => {
              console.log('3000 -> ', checkResponse);
              if (checkResponse.transcode.status === 'complete') {
                clearInterval(checkInterval);
                this.uploadVedioLoading = false;
                this.vedioAttachement = {
                  VideoPath:
                    'https://vimeo.com/' + checkResponse.link.split('/')[3],
                  VideoPicturePath: 'assets/images/vedio.png',
                  FileType: 'VIDEO',
                };
                sessionStorage.setItem(
                  'VEDIO_ID',
                  checkResponse.link.split('/')[3]
                );
                this.cdr.detectChanges();
                console.log(this.attachmentsForPreview);
              }
            });
        }, 3000);
      }
    });
  }

  public removeAttachment(index: number): void {
    this.attachmentsForPreview.splice(index, 1);
    this.attachmentsFileNames.splice(index, 1);
  }

  public openLightbox(index: number): void {
    const lightboxAlbum = [];
    this.attachmentsForPreview.forEach((attachment) => {
      if (attachment.FileType === FileType.IMAGE) {
        lightboxAlbum.push({
          src: attachment.AttachmentPath,
          caption: attachment.AttachmentName,
          thumb: null,
        });
      }
    });

    this.lightboxConfig.showZoom = true;
    this.lightboxConfig.showRotate = true;
    this.lightboxConfig.centerVertically = true;
    this.lightboxConfig.fitImageInViewPort = true;
    this.lightboxConfig.enableTransition = true;

    this.lightbox.open(lightboxAlbum, index);
  }

  public openVideoModal(attachment: any): void {
    console.log(attachment);
    this.videoModal.open(VideoModalComponent, {
      data: {
        videoPath: attachment?.AttachmentPath
          ? attachment?.AttachmentPath
          : attachment?.VideoPath,
      },
      hasBackdrop: true,
      minWidth: 400,
      minHeight: 400,
    });
  }

  removeVedioAttachment(): void {
    sessionStorage.removeItem('VEDIO_ID');
    this.vedioAttachement = {
      VideoPath: '',
      VideoPicturePath: '',
      FileType: '',
    };
  }
}
