import { gql } from '@apollo/client/core';

export const GET_COURSE_GROUPS = gql`
  query CourseGroups($criteria: CourseGroupCriteria, $courseHours: Boolean!) {
    CourseGroupQuery {
      CourseGroups(criteria: $criteria) {
        Result {
          Id
          ScientificSubjectName
          TeacherName
          MainAssistantName
          Name
          NumberOfStudents
          SchoolYearName
          IsNeedAction
          AllowViewActivitiesForAssistant
          IsActive
          CourseId
          AllowAbsentRequest
          AllowChangeGroupRequest
          AllowNewRequests
          ActualNumOfStudents
          CourseGroupAssistants @include(if: $courseHours) {
            AssistantId
            AssistantName
            AssistantRoles {
              RoleId
              RoleName
            }
          }
          CourseGroupHours @include(if: $courseHours) {
            Day
            EndTime
            StartTime
          }
          Course {
            Id
          }
          CourseGroupStudent {
            StudentStatus
            IsSubscribed
          }
          Teacher {
            Id
            ImagePath
          }
        }
      }
    }
  }
`;
