import { gql } from '@apollo/client/core';

export const APPLY_ACTIVITY_OFFLINE = gql`
  mutation ApplyActivityOffline($model: ApplyActivityOfflineInput) {
    Activity {
      ApplyActivityOffline(model: $model) {
        Result {
          ActivityId
          StudentId
          Attachments
        }
      }
    }
  }
`;
