import { gql } from '@apollo/client/core';

export const GET_TEACHER_SUBJECT = gql`
  query GetTeacherSubjects($criteria: SubjectCriteria) {
    LookupQuery {
      Subjects(criteria: $criteria) {
        Result {
          Id
          Name
          ScientificSubjectName
          SchoolYearName
          EducationTypeName
          SemesterId
        }
        TotalCount
      }
    }
  }
`;
