import { gql } from '@apollo/client/core';

export const GET_TEACHER_QUESTIONS_BANK = gql`
  query TeacherQuestionsBank($criteria: TeacherQuestionCriteria) {
    TeacherQuery {
      QuestionBank(criteria: $criteria) {
        Result {
          Id
          Tags
          SubjectId
          QuestionText
          QuestionAttachments {
            AttachmentName
            AttachmentPath
            FileType
          }
          Answer
          AnswerAttachments {
            AttachmentName
            AttachmentPath
            FileType
          }
          ChoiceOne
          ChoiceTwo
          ChoiceThree
          ChoiceFour
          QuestionType
          SubjectName
          SchoolYearName
          Teacher {
            Name
          }
          AddedDate
          Level
          Subject {
            SchoolYearId
            EducationTypeId
          }
        }
        TotalCount
      }
    }
  }
`;
