import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChangeGroupType, CourseGroup } from '@generated-types';
import { AuthService } from '@services';

@Component({
  templateUrl: './change-group.component.html',
  styleUrls: ['./change-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeGroupComponent implements OnInit {
  public changeGroupForm: FormGroup;
  public oldGroup: CourseGroup;

  public constructor(
    private readonly fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public readonly data: {
      courseGroup: CourseGroup;
      alternativeCourseGroups: CourseGroup[];
    },
    private readonly dialogRef: MatDialogRef<ChangeGroupComponent>,
    private readonly authService: AuthService
  ) {}

  public ngOnInit(): void {
    console.log(this.data);
    this.oldGroup = { ...this.data.courseGroup };
    this.changeGroupForm = this.fb.group({
      AlternativeCourseGroup: [null, Validators.required],
    });
  }

  onHandelChange(alternativeCourseGroup: CourseGroup): void {
    this.data.courseGroup = alternativeCourseGroup;
  }

  public onSubmitChangeGroup(): void {
    this.dialogRef.close({
      CourseGroupId: this.oldGroup.Id,
      AlternativeCourseGroupId: this.changeGroupForm.value
        .AlternativeCourseGroup.Id,
      ChangeGroupType: ChangeGroupType.PERMANENT,
      StudentId: +this.authService.getUserState().StudentId,
    });
  }
}
