import { Injectable } from '@angular/core';
import { GetOnlineSessionInfo } from '@generated-types';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { LocalStorageService } from './local-storage.service';
import { ZoomMtg } from '@zoomus/websdk';

ZoomMtg.preLoadWasm();
ZoomMtg.prepareJssdk();

@Injectable({
  providedIn: 'root',
})
export class ZoomService {
  public constructor(
    private readonly authService: AuthService,
    private readonly localStorageService: LocalStorageService
  ) {}

  public joinZoomMeeting({
    Signature,
    MeetingId,
    APIKey,
    MeetingPassword,
    userName,
    role,
    topic
  }: any): void {
   
      console.log(
        Signature,
        MeetingId,
        APIKey,
        MeetingPassword,
        userName,
        role,
        topic
      );
      this.localStorageService.set('zoomSettings', {
        signature: Signature,
        apiKey: APIKey,
        meetingNumber: MeetingId,
        password: MeetingPassword,
        role: role,
        topic:topic,
        userName: this.authService.getUserState().Name,
        userEmail: this.authService.getUserState().Email,
      });
      location.href = '/dashboard/sessions/meeting';
    
  }
}
