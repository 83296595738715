import { gql } from '@apollo/client/core';

export const GET_SESSION_QUESTIONS = gql`
  query SessionQuestions($criteria: SessionQuestionCriteria) {
    SessionQuery {
      SessionsQuestions(criteria: $criteria) {
        Result {
          Id
          StudentName
          Question
          Answer
          IsVerified
          QuestionAttachments {
            AttachmentPath
            AttachmentName
            FileType
          }
          AnswerAttachments {
            AttachmentPath
            AttachmentName
            FileType
          }
        }
        TotalCount
      }
    }
  }
`;
