import { gql } from '@apollo/client/core';

export const GET_AREAS = gql`
  query Areas($criteria: AreaCriteria) {
    LookupQuery {
      Areas(criteria: $criteria) {
        Result {
          Id
          Name
        }
        TotalCount
      }
    }
  }
`;
