<section class="absent-request">
  <mat-icon>mode_standby</mat-icon>
  <h1>Absent Request</h1>
  <h2 mat-dialog-title>{{ data?.sessionTitle }}</h2>
  <mat-dialog-content>
    <form [formGroup]="form">
      <mat-form-field appearance="outline">
        <mat-label>Leave a comment</mat-label>
        <textarea
          matInput
          rows="6"
          formControlName="Comment"
          required
        ></textarea>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button
      mat-raised-button
      [disabled]="form.invalid"
      color="accent"
      (click)="onSubmitAbsent()"
    >
      Submit
    </button>
  </mat-dialog-actions>
</section>
