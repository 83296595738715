import { gql } from '@apollo/client/core';

export const GET_ACTIVITY_QUESTIONS_WITH_ANSWERS = gql`
  query ActivityQuestionsWithAnswers(
    $criteria: ActivityQuestionCriteria
    $studentCriteria: StudentActivityQuestionAnswersCriteria
  ) {
    ActivityQuery {
      ActivityQuestions(criteria: $criteria) {
        Result {
          Id
          QuestionText
          ChoiceOne
          ChoiceTwo
          ChoiceThree
          ChoiceFour
          Answer
          QuestionType
          Grade
          QuestionAttachments {
            AttachmentName
            AttachmentPath
            IsAnswer
            FileType
          }
          StudentAnswer(criteria: $studentCriteria) {
            Id
            Answer
            IsCorrectAnswer
            IsAnswer
            IsMarked
            Grade
          }
          Activity {
            Id
            Name
            StartDate
            DeadLine
            Grade
            CourseGroupName
            ScientificSubjectName
          }
        }
        TotalCount
      }
    }
  }
`;
