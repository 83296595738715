// tslint:disable
export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Date` scalar type represents a year, month and day in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  Date: any;
  /** The `DateTime` scalar type represents a date and time. `DateTime` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTime: any;
  /** The `DateTimeOffset` scalar type represents a date, time and offset from UTC. `DateTimeOffset` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTimeOffset: any;
  /** The `Seconds` scalar type represents a period of time represented as the total number of seconds. */
  Seconds: any;
  /** The `Milliseconds` scalar type represents a period of time represented as the total number of milliseconds. */
  Milliseconds: any;
  Decimal: any;
  Uri: any;
  Guid: any;
  Short: any;
  UShort: any;
  UInt: any;
  Long: any;
  BigInt: any;
  ULong: any;
  Byte: any;
  SByte: any;
};

















export type RootQuery = {
  __typename?: 'RootQuery';
  AcademyQuery?: Maybe<AcademyQuery>;
  AccountQuery?: Maybe<AccountQuery>;
  ActivityQuery?: Maybe<ActiviyQuery>;
  AssistantQuery?: Maybe<AssistantQuery>;
  CommonQuery?: Maybe<CommonQuery>;
  CourseGroupQuery?: Maybe<CourseGroupQuery>;
  CourseQuery?: Maybe<CourseQuery>;
  EventQuery?: Maybe<EventQuery>;
  FawryQuery?: Maybe<FawryQuery>;
  LicenceQuery?: Maybe<LicenceMutation>;
  LookupQuery?: Maybe<LookupsQuery>;
  MarketingQuery?: Maybe<MarketingQuery>;
  NotificationQuery?: Maybe<NotificationQuery>;
  OnlineContentQuery?: Maybe<OnlineContentQuery>;
  ParentQuery?: Maybe<ParentQuery>;
  PaymentQuery?: Maybe<PaymentQuery>;
  ReportsQuery?: Maybe<ReportsQuery>;
  RequestQuery?: Maybe<RequestQuery>;
  SessionQuery?: Maybe<SessionQuery>;
  StudentQuery?: Maybe<StudentQuery>;
  TaskQuery?: Maybe<TaskQuery>;
  TeacherLicenceQuery?: Maybe<TeacherLicenceQuery>;
  TeacherQuery?: Maybe<TeacherQuery>;
  UesrBlockQuery?: Maybe<UserBlockQuery>;
};

export type LookupsQuery = {
  __typename?: 'LookupsQuery';
  ActionReasons?: Maybe<ActionReasonTypeListResponse>;
  AppGeneralResources?: Maybe<AppResourcesTypeListResponse>;
  AppSocialResources?: Maybe<AppResourcesTypeListResponse>;
  Areas?: Maybe<AreaTypeListResponse>;
  BasicRoles?: Maybe<BasicAssistantRoleTypeListResponse>;
  Cities?: Maybe<CityTypeListResponse>;
  Countries?: Maybe<CountryTypeListResponse>;
  CourseContentRejectReasons?: Maybe<DetailCodeTypeListResponse>;
  EducationStages?: Maybe<EducationStageTypeListResponse>;
  EducationTypes?: Maybe<EducationTypeTypeListResponse>;
  Languages?: Maybe<LanguageTypeListResponse>;
  MessageTemplate?: Maybe<MessageTemplateTypeResponse>;
  Notes?: Maybe<NoteTypeListResponse>;
  SchoolYears?: Maybe<SchoolYearTypeListResponse>;
  ScientificSubjects?: Maybe<ScientificSubjectTypeListResponse>;
  Semesters?: Maybe<SemesterTypeListResponse>;
  SessionContentRejectReasons?: Maybe<DetailCodeTypeListResponse>;
  Subjects?: Maybe<SubjectTypeListResponse>;
  TeacherBlockAssistantReasons?: Maybe<ActionReasonTypeListResponse>;
  TeacherBlockStudentReasons?: Maybe<ActionReasonTypeListResponse>;
  TeacherFeatures?: Maybe<DetailCodeTypeListResponse>;
  TeacherRemoveAssistantReasons?: Maybe<ActionReasonTypeListResponse>;
  TeacherRemoveStudentReasons?: Maybe<ActionReasonTypeListResponse>;
  TeacherSuspendAssistantReasons?: Maybe<ActionReasonTypeListResponse>;
  TeacherSuspendStudentReasons?: Maybe<ActionReasonTypeListResponse>;
  Units?: Maybe<UnitTypeListResponse>;
};


export type LookupsQueryActionReasonsArgs = {
  criteria?: Maybe<ActionReasonCriteria>;
};


export type LookupsQueryAppGeneralResourcesArgs = {
  criteria?: Maybe<AppResourcesCriteria>;
};


export type LookupsQueryAppSocialResourcesArgs = {
  criteria?: Maybe<AppResourcesCriteria>;
};


export type LookupsQueryAreasArgs = {
  criteria?: Maybe<AreaCriteria>;
};


export type LookupsQueryBasicRolesArgs = {
  criteria?: Maybe<BasicAssistantRoleCriteria>;
};


export type LookupsQueryCitiesArgs = {
  criteria?: Maybe<CityCriteria>;
};


export type LookupsQueryCountriesArgs = {
  criteria?: Maybe<CountryCriteria>;
};


export type LookupsQueryEducationStagesArgs = {
  criteria?: Maybe<EducationStageCriteria>;
};


export type LookupsQueryEducationTypesArgs = {
  criteria?: Maybe<EducationTypeCriteria>;
};


export type LookupsQueryLanguagesArgs = {
  criteria?: Maybe<LanguageCriteria>;
};


export type LookupsQueryMessageTemplateArgs = {
  criteria?: Maybe<MessageTemplateCriteria>;
};


export type LookupsQueryNotesArgs = {
  criteria?: Maybe<NoteCriteria>;
};


export type LookupsQuerySchoolYearsArgs = {
  criteria?: Maybe<SchoolYearCriteria>;
};


export type LookupsQueryScientificSubjectsArgs = {
  criteria?: Maybe<ScientificSubjectCriteria>;
};


export type LookupsQuerySemestersArgs = {
  criteria?: Maybe<SemesterCriteria>;
};


export type LookupsQuerySubjectsArgs = {
  criteria?: Maybe<SubjectCriteria>;
};


export type LookupsQueryTeacherBlockAssistantReasonsArgs = {
  criteria?: Maybe<ActionReasonCriteria>;
};


export type LookupsQueryTeacherBlockStudentReasonsArgs = {
  criteria?: Maybe<ActionReasonCriteria>;
};


export type LookupsQueryTeacherRemoveAssistantReasonsArgs = {
  criteria?: Maybe<ActionReasonCriteria>;
};


export type LookupsQueryTeacherRemoveStudentReasonsArgs = {
  criteria?: Maybe<ActionReasonCriteria>;
};


export type LookupsQueryTeacherSuspendAssistantReasonsArgs = {
  criteria?: Maybe<ActionReasonCriteria>;
};


export type LookupsQueryTeacherSuspendStudentReasonsArgs = {
  criteria?: Maybe<ActionReasonCriteria>;
};


export type LookupsQueryUnitsArgs = {
  criteria?: Maybe<UnitCriteria>;
};

export type CountryTypeListResponse = {
  __typename?: 'CountryTypeListResponse';
  Result?: Maybe<Array<Maybe<Country>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type Country = {
  __typename?: 'Country';
  Cities?: Maybe<Array<Maybe<City>>>;
  Code?: Maybe<Scalars['String']>;
  CountryCode?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  ImagePath?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  NameAr?: Maybe<Scalars['String']>;
  NameEn?: Maybe<Scalars['String']>;
};


export type CountryCitiesArgs = {
  criteria?: Maybe<CityCriteria>;
};

export type City = {
  __typename?: 'City';
  Areas?: Maybe<Array<Maybe<Area>>>;
  Code?: Maybe<Scalars['String']>;
  Country?: Maybe<Country>;
  CountryId?: Maybe<Scalars['Int']>;
  CountryName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  Name?: Maybe<Scalars['String']>;
  NameAr?: Maybe<Scalars['String']>;
  NameEn?: Maybe<Scalars['String']>;
};


export type CityAreasArgs = {
  criteria?: Maybe<AreaCriteria>;
};

export type Area = {
  __typename?: 'Area';
  CityId?: Maybe<Scalars['Int']>;
  CityName?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  CountryName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  Name?: Maybe<Scalars['String']>;
  NameAr?: Maybe<Scalars['String']>;
  NameEn?: Maybe<Scalars['String']>;
};

export type AreaCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  Code?: Maybe<Scalars['String']>;
  CityId?: Maybe<Scalars['Int']>;
  CountryId?: Maybe<Scalars['Int']>;
};

export enum OrderDirection {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING'
}

export type CityCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  Area?: Maybe<AreaCriteria>;
  CountryId?: Maybe<Scalars['Int']>;
};

export type CountryCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  Code?: Maybe<Scalars['String']>;
  CountryCode?: Maybe<Scalars['String']>;
  CityId?: Maybe<Scalars['Int']>;
  City?: Maybe<CityCriteria>;
};

export type CityTypeListResponse = {
  __typename?: 'CityTypeListResponse';
  Result?: Maybe<Array<Maybe<City>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type AreaTypeListResponse = {
  __typename?: 'AreaTypeListResponse';
  Result?: Maybe<Array<Maybe<Area>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type LanguageTypeListResponse = {
  __typename?: 'LanguageTypeListResponse';
  Result?: Maybe<Array<Maybe<Language>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type Language = {
  __typename?: 'Language';
  Code?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  Name?: Maybe<Scalars['String']>;
  NameAr?: Maybe<Scalars['String']>;
  NameEn?: Maybe<Scalars['String']>;
};

export type LanguageCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
};

export type SemesterTypeListResponse = {
  __typename?: 'SemesterTypeListResponse';
  Result?: Maybe<Array<Maybe<Semester>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type Semester = {
  __typename?: 'Semester';
  Code?: Maybe<Scalars['String']>;
  Id: Scalars['Int'];
  Name?: Maybe<Scalars['String']>;
  NameAr?: Maybe<Scalars['String']>;
  NameEn?: Maybe<Scalars['String']>;
};

export type SemesterCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
};

export type SchoolYearTypeListResponse = {
  __typename?: 'SchoolYearTypeListResponse';
  Result?: Maybe<Array<Maybe<SchoolYear>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type SchoolYear = {
  __typename?: 'SchoolYear';
  AcademyIds?: Maybe<Array<Scalars['Int']>>;
  Description?: Maybe<Scalars['String']>;
  EducationStageId?: Maybe<Scalars['Int']>;
  EducationStageName?: Maybe<Scalars['String']>;
  EducationTypeIds?: Maybe<Array<Scalars['Int']>>;
  Id?: Maybe<Scalars['Int']>;
  Name?: Maybe<Scalars['String']>;
  NameAr?: Maybe<Scalars['String']>;
  NameEn?: Maybe<Scalars['String']>;
  ScientificSubjectIds?: Maybe<Array<Scalars['Int']>>;
  Subjects?: Maybe<Array<Maybe<Subject>>>;
};

export type Subject = {
  __typename?: 'Subject';
  Description?: Maybe<Scalars['String']>;
  EducationType?: Maybe<EducationType>;
  EducationTypeId?: Maybe<Scalars['Int']>;
  EducationTypeName?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  Name?: Maybe<Scalars['String']>;
  NameAr?: Maybe<Scalars['String']>;
  NameEn?: Maybe<Scalars['String']>;
  SchoolYear?: Maybe<SchoolYear>;
  SchoolYearId?: Maybe<Scalars['Int']>;
  SchoolYearName?: Maybe<Scalars['String']>;
  ScientificSubject?: Maybe<ScientificSubject>;
  ScientificSubjectId?: Maybe<Scalars['Int']>;
  ScientificSubjectName?: Maybe<Scalars['String']>;
  SemesterId?: Maybe<Scalars['Int']>;
};

export type ScientificSubject = {
  __typename?: 'ScientificSubject';
  AcademyIds?: Maybe<Array<Scalars['Int']>>;
  Description?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  ImagePath?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  NameAr?: Maybe<Scalars['String']>;
  NameEn?: Maybe<Scalars['String']>;
  Subjects?: Maybe<Array<Maybe<Subject>>>;
};

export type EducationType = {
  __typename?: 'EducationType';
  AcademyIds?: Maybe<Array<Scalars['Int']>>;
  Description?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  Name?: Maybe<Scalars['String']>;
  NameAr?: Maybe<Scalars['String']>;
  NameEn?: Maybe<Scalars['String']>;
  SchoolYears?: Maybe<Array<Maybe<SchoolYear>>>;
};


export type EducationTypeSchoolYearsArgs = {
  criteria?: Maybe<SchoolYearCriteria>;
};

export type SchoolYearCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  EducationTypeId?: Maybe<Scalars['Int']>;
  EducationStageId?: Maybe<Scalars['Int']>;
};

export type EducationTypeTypeListResponse = {
  __typename?: 'EducationTypeTypeListResponse';
  Result?: Maybe<Array<Maybe<EducationType>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type EducationTypeCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  SchoolYear?: Maybe<SchoolYearCriteria>;
};

export type EducationStageTypeListResponse = {
  __typename?: 'EducationStageTypeListResponse';
  Result?: Maybe<Array<Maybe<EducationStage>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type EducationStage = {
  __typename?: 'EducationStage';
  AcademyIds?: Maybe<Array<Scalars['Int']>>;
  Description?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  Name?: Maybe<Scalars['String']>;
  NameAr?: Maybe<Scalars['String']>;
  NameEn?: Maybe<Scalars['String']>;
  SchoolYears?: Maybe<Array<Maybe<SchoolYear>>>;
};


export type EducationStageSchoolYearsArgs = {
  criteria?: Maybe<SchoolYearCriteria>;
};

export type EducationStageCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  SchoolYear?: Maybe<SchoolYearCriteria>;
};

export type ScientificSubjectTypeListResponse = {
  __typename?: 'ScientificSubjectTypeListResponse';
  Result?: Maybe<Array<Maybe<ScientificSubject>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type ScientificSubjectCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
};

export type SubjectTypeListResponse = {
  __typename?: 'SubjectTypeListResponse';
  Result?: Maybe<Array<Maybe<Subject>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type SubjectCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  ScientificSubjectId?: Maybe<Scalars['Int']>;
  SemesterId?: Maybe<Scalars['Int']>;
  SchoolYearId?: Maybe<Scalars['Int']>;
  EducationTypeId?: Maybe<Scalars['Int']>;
  Name?: Maybe<Scalars['String']>;
  TeacherId?: Maybe<Scalars['Int']>;
};

export type ActionReasonTypeListResponse = {
  __typename?: 'ActionReasonTypeListResponse';
  Result?: Maybe<Array<Maybe<ActionReason>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type ActionReason = {
  __typename?: 'ActionReason';
  Code?: Maybe<Scalars['String']>;
  Id: Scalars['Int'];
  Name?: Maybe<Scalars['String']>;
  NameAr?: Maybe<Scalars['String']>;
  NameEn?: Maybe<Scalars['String']>;
};

export type ActionReasonCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  ResonType?: Maybe<ActionReasonType>;
};

export enum ActionReasonType {
  TEACHER_SUSPEND_STUDENT_REASONS = 'TEACHER_SUSPEND_STUDENT_REASONS',
  TEACHER_REMOVE_STUDENT_REASONS = 'TEACHER_REMOVE_STUDENT_REASONS',
  TEACHER_BLOCK_STUDENT_REASONS = 'TEACHER_BLOCK_STUDENT_REASONS',
  TEACHER_SUSPEND_ASSISTANT_REASONS = 'TEACHER_SUSPEND_ASSISTANT_REASONS',
  TEACHER_REMOVE_ASSISTANT_REASONS = 'TEACHER_REMOVE_ASSISTANT_REASONS',
  TEACHER_BLOCK_ASSISTANT_REASONS = 'TEACHER_BLOCK_ASSISTANT_REASONS'
}

export type BasicAssistantRoleTypeListResponse = {
  __typename?: 'BasicAssistantRoleTypeListResponse';
  Result?: Maybe<Array<Maybe<BasicAssistantRole>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type BasicAssistantRole = {
  __typename?: 'BasicAssistantRole';
  Id?: Maybe<Scalars['Int']>;
  IsExpanded?: Maybe<Scalars['Boolean']>;
  IsSelected?: Maybe<Scalars['Boolean']>;
  Name?: Maybe<Scalars['String']>;
  Permissions?: Maybe<Array<Maybe<Permission>>>;
};


export type BasicAssistantRolePermissionsArgs = {
  criteria?: Maybe<PermissionCriteria>;
};

export type Permission = {
  __typename?: 'Permission';
  Id?: Maybe<Scalars['Int']>;
  IsSelected?: Maybe<Scalars['Boolean']>;
  Name: Scalars['String'];
  RoleName: Scalars['String'];
};

export type PermissionCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
};

export type BasicAssistantRoleCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  IsCourseGroup?: Maybe<Scalars['Boolean']>;
};

export type DetailCodeTypeListResponse = {
  __typename?: 'DetailCodeTypeListResponse';
  Result?: Maybe<Array<Maybe<DetailCode>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type DetailCode = {
  __typename?: 'DetailCode';
  Id?: Maybe<Scalars['Int']>;
  IsSelected: Scalars['Boolean'];
  Name?: Maybe<Scalars['String']>;
};

export type AppResourcesTypeListResponse = {
  __typename?: 'AppResourcesTypeListResponse';
  Result?: Maybe<Array<Maybe<AppResources>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type AppResources = {
  __typename?: 'AppResources';
  Description?: Maybe<Scalars['String']>;
  Key?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
  ValueAr?: Maybe<Scalars['String']>;
  ValueEn?: Maybe<Scalars['String']>;
  ValueType?: Maybe<ResourceValueType>;
};

export enum ResourceValueType {
  TEXT = 'TEXT',
  HTML = 'HTML'
}

export type AppResourcesCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  AcademyId?: Maybe<Scalars['Int']>;
};

export type MessageTemplateTypeResponse = {
  __typename?: 'MessageTemplateTypeResponse';
  Result?: Maybe<MessageTemplate>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type MessageTemplate = {
  __typename?: 'MessageTemplate';
  AcademyId?: Maybe<Scalars['Int']>;
  AcademyName?: Maybe<Scalars['String']>;
  EmailEnabled?: Maybe<Scalars['Boolean']>;
  EmailTemplate?: Maybe<Scalars['String']>;
  EmailTemplateAr?: Maybe<Scalars['String']>;
  EmailTemplateEn?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  NotificationEnabled?: Maybe<Scalars['Boolean']>;
  NotificationTemplate?: Maybe<Scalars['String']>;
  NotificationTemplateAr?: Maybe<Scalars['String']>;
  NotificationTemplateEn?: Maybe<Scalars['String']>;
  SmsEnabled?: Maybe<Scalars['Boolean']>;
  SmsTemplate?: Maybe<Scalars['String']>;
  SmsTemplateAr?: Maybe<Scalars['String']>;
  SmsTemplateEn?: Maybe<Scalars['String']>;
  TemplateType?: Maybe<MessageTemplateType>;
  Title?: Maybe<Scalars['String']>;
  TitleAr?: Maybe<Scalars['String']>;
  TitleEn?: Maybe<Scalars['String']>;
};

export enum MessageTemplateType {
  TEACHER_VERIFY_PHONE_NUMBER = 'TEACHER_VERIFY_PHONE_NUMBER',
  TEACHER_WELCOME = 'TEACHER_WELCOME',
  TEACHER_FORGET_PASSWORD = 'TEACHER_FORGET_PASSWORD',
  TEACHER_FOLLOWUP_TO_FILL_PROFILE = 'TEACHER_FOLLOWUP_TO_FILL_PROFILE',
  TEACHER_FOLLOWUP_TO_ORDER_NEW_LICENSE = 'TEACHER_FOLLOWUP_TO_ORDER_NEW_LICENSE',
  TEACHER_FOLLOWUP_TO_CREATE_GROUP = 'TEACHER_FOLLOWUP_TO_CREATE_GROUP',
  TEACHER_FOLLOWUP_TO_ADD_STUDENTS = 'TEACHER_FOLLOWUP_TO_ADD_STUDENTS',
  TEACHER_FOLLOWUP_TO_ADD_QUESTION_BANKS = 'TEACHER_FOLLOWUP_TO_ADD_QUESTION_BANKS',
  TEACHER_FOLLOWUP_TO_ADD_FIRST_ACTIVITY = 'TEACHER_FOLLOWUP_TO_ADD_FIRST_ACTIVITY',
  JOIN_COURSE_GROUP_REQUEST_FROM_STUDENT = 'JOIN_COURSE_GROUP_REQUEST_FROM_STUDENT',
  JOIN_COURSE_GROUP_REQUEST_FROM_STUDENT_ACCEPTED = 'JOIN_COURSE_GROUP_REQUEST_FROM_STUDENT_ACCEPTED',
  JOIN_COURSE_GROUP_REQUEST_FROM_STUDENT_REJECTED = 'JOIN_COURSE_GROUP_REQUEST_FROM_STUDENT_REJECTED',
  CHANGE_COURSE_GROUP_TEMP_REQUEST = 'CHANGE_COURSE_GROUP_TEMP_REQUEST',
  CHANGE_COURSE_GROUP_TEMP_REQUEST_ACCEPTED = 'CHANGE_COURSE_GROUP_TEMP_REQUEST_ACCEPTED',
  CHANGE_COURSE_GROUP_TEMP_REQUEST_REJECTED = 'CHANGE_COURSE_GROUP_TEMP_REQUEST_REJECTED',
  CHANGE_COURSE_GROUP_PERM_REQUEST = 'CHANGE_COURSE_GROUP_PERM_REQUEST',
  CHANGE_COURSE_GROUP_PERM_REQUEST_ACCEPTED = 'CHANGE_COURSE_GROUP_PERM_REQUEST_ACCEPTED',
  CHANGE_COURSE_GROUP_PERM_REQUEST_REJECTED = 'CHANGE_COURSE_GROUP_PERM_REQUEST_REJECTED',
  ABSENT_REQUEST_FROM_STUDENT = 'ABSENT_REQUEST_FROM_STUDENT',
  ABSENT_REQUEST_FROM_STUDENT_ACCEPTED = 'ABSENT_REQUEST_FROM_STUDENT_ACCEPTED',
  ABSENT_REQUEST_FROM_STUDENT_REJECTED = 'ABSENT_REQUEST_FROM_STUDENT_REJECTED',
  TEACHER_JOIN_REQUEST_FROM_ASSISTANT = 'TEACHER_JOIN_REQUEST_FROM_ASSISTANT',
  TEACHER_JOIN_REQUEST_FROM_ASSISTANT_ACCEPTED = 'TEACHER_JOIN_REQUEST_FROM_ASSISTANT_ACCEPTED',
  TEACHER_JOIN_REQUEST_FROM_ASSISTANT_REJECTED = 'TEACHER_JOIN_REQUEST_FROM_ASSISTANT_REJECTED',
  JOIN_COURSE_GROUP_REQUEST_FROM_PARENT = 'JOIN_COURSE_GROUP_REQUEST_FROM_PARENT',
  JOIN_COURSE_GROUP_REQUEST_FROM_PARENT_ACCEPTED = 'JOIN_COURSE_GROUP_REQUEST_FROM_PARENT_ACCEPTED',
  JOIN_COURSE_GROUP_REQUEST_FROM_PARENT_REJECTED = 'JOIN_COURSE_GROUP_REQUEST_FROM_PARENT_REJECTED',
  ABSENT_REQUEST_FROM_PARENT = 'ABSENT_REQUEST_FROM_PARENT',
  ABSENT_REQUEST_FROM_PARENT_ACCEPTED = 'ABSENT_REQUEST_FROM_PARENT_ACCEPTED',
  ABSENT_REQUEST_FROM_PARENT_REJECTED = 'ABSENT_REQUEST_FROM_PARENT_REJECTED',
  ORDER_LICENSE_FROM_EDURA = 'ORDER_LICENSE_FROM_EDURA',
  ORDER_LICENSE_ID_SUCCESS = 'ORDER_LICENSE_ID_SUCCESS',
  ORDER_LICENSE_ID_FAILED = 'ORDER_LICENSE_ID_FAILED',
  ORDER_LICENSE_FROM_EDURA_ACCEPTED = 'ORDER_LICENSE_FROM_EDURA_ACCEPTED',
  ORDER_LICENSE_FROM_EDURA_OFFLINE_PAYMENT_APPROVED = 'ORDER_LICENSE_FROM_EDURA_OFFLINE_PAYMENT_APPROVED',
  ORDER_LICENSE_FROM_EDURA_REJECTED = 'ORDER_LICENSE_FROM_EDURA_REJECTED',
  ORDER_LICENSE_FROM_EDURA_PAY_WITHIN_XX_DAYS = 'ORDER_LICENSE_FROM_EDURA_PAY_WITHIN_XX_DAYS',
  ORDER_LICENSE_FROM_EDURA_SUSPENDED = 'ORDER_LICENSE_FROM_EDURA_SUSPENDED',
  ORDER_LICENSE_ID_STATUS = 'ORDER_LICENSE_ID_STATUS',
  ORDER_LICENSE_ID_CASH_REMINDER = 'ORDER_LICENSE_ID_CASH_REMINDER',
  ORDER_LICENSE_ID_INSTALLMENT_REMINDER = 'ORDER_LICENSE_ID_INSTALLMENT_REMINDER',
  ORDER_LICENSE_ID_REMAIN = 'ORDER_LICENSE_ID_REMAIN',
  ORDER_LICENSE_ID_FULL_PAID = 'ORDER_LICENSE_ID_FULL_PAID',
  ASSIGN_LICENSE_TO_STUDENT = 'ASSIGN_LICENSE_TO_STUDENT',
  ASSIGN_LICENSE_TO_STUDENT_ASSIGNED = 'ASSIGN_LICENSE_TO_STUDENT_ASSIGNED',
  ASSIGN_LICENSE_TO_STUDENT_NOT_SUCCESS = 'ASSIGN_LICENSE_TO_STUDENT_NOT_SUCCESS',
  ASSIGN_LICENSE_TO_STUDENT_REMOVED = 'ASSIGN_LICENSE_TO_STUDENT_REMOVED',
  ASSIGN_LICENSE_TO_PARENT = 'ASSIGN_LICENSE_TO_PARENT',
  ASSIGN_LICENSE_TO_PARENT_ASSIGNED = 'ASSIGN_LICENSE_TO_PARENT_ASSIGNED',
  ASSIGN_LICENSE_TO_PARENT_NOT_SUCCESS = 'ASSIGN_LICENSE_TO_PARENT_NOT_SUCCESS',
  ASSIGN_LICENSE_TO_PARENT_REMOVED = 'ASSIGN_LICENSE_TO_PARENT_REMOVED',
  ASSIGN_LICENSE_TO_ASSISTANT = 'ASSIGN_LICENSE_TO_ASSISTANT',
  ASSIGN_LICENSE_TO_ASSISTANT_ASSIGNED = 'ASSIGN_LICENSE_TO_ASSISTANT_ASSIGNED',
  ASSIGN_LICENSE_TO_ASSISTANT_NOT_SUCCESS = 'ASSIGN_LICENSE_TO_ASSISTANT_NOT_SUCCESS',
  ASSIGN_LICENSE_TO_ASSISTANT_REMOVED = 'ASSIGN_LICENSE_TO_ASSISTANT_REMOVED',
  ASSIGN_LICENSE_TO_ASSISTANT_ASSIGEND_TO_STUDENT = 'ASSIGN_LICENSE_TO_ASSISTANT_ASSIGEND_TO_STUDENT',
  TEACHERCREATE_GROUPCREATED = 'TEACHERCREATE_GROUPCREATED',
  TEACHER_CREATE_GROUP_CREATE_FOLLOWUP = 'TEACHER_CREATE_GROUP_CREATE_FOLLOWUP',
  TEACHER_CREATE_GROUP_NOT_SUCCESS = 'TEACHER_CREATE_GROUP_NOT_SUCCESS',
  TEACHER_GROUP_DISABLED = 'TEACHER_GROUP_DISABLED',
  TEACHER_GROUP_REMOVED = 'TEACHER_GROUP_REMOVED',
  TEACHER_GROUP_CAPACITY_FULL = 'TEACHER_GROUP_CAPACITY_FULL',
  TEACHER_GROUP_CAPACITY_LOW = 'TEACHER_GROUP_CAPACITY_LOW',
  TEACHER_GROUP_NEW_WAITING_STUDENT = 'TEACHER_GROUP_NEW_WAITING_STUDENT',
  ASSISTANT_GROUP_STUDENT_TRANSFERRED = 'ASSISTANT_GROUP_STUDENT_TRANSFERRED',
  TEACHER_LECTURE_CREATED = 'TEACHER_LECTURE_CREATED',
  TEACHER_LECTURE_REMINDER = 'TEACHER_LECTURE_REMINDER',
  TEACHER_LECTURE_REMOVED = 'TEACHER_LECTURE_REMOVED',
  TEACHER_LECTURE_CANCELED = 'TEACHER_LECTURE_CANCELED',
  TEACHER_LECTURE_TIME_CHANGED_TO = 'TEACHER_LECTURE_TIME_CHANGED_TO',
  TEACHER_LECTUURE_ATTENDANCE_RESTRICTED = 'TEACHER_LECTUURE_ATTENDANCE_RESTRICTED',
  TEACHER_LECTUURE_ATTENDANCE_PERCENTAGE_REPORT = 'TEACHER_LECTUURE_ATTENDANCE_PERCENTAGE_REPORT',
  TEACHER_ASSIGNMENT_CREATED = 'TEACHER_ASSIGNMENT_CREATED',
  TEACHER_ASSIGNMENT_SCHEDULED = 'TEACHER_ASSIGNMENT_SCHEDULED',
  TEACHER_ASSIGNMENT_SAVED_DRAFT = 'TEACHER_ASSIGNMENT_SAVED_DRAFT',
  TEACHER_ASSIGNMENT_NEW_SUBMIT_RECEIVED = 'TEACHER_ASSIGNMENT_NEW_SUBMIT_RECEIVED',
  TEACHER_ASSIGNMENT_NOT_MARKED_REMINDER = 'TEACHER_ASSIGNMENT_NOT_MARKED_REMINDER',
  TEACHER_ASSIGNMENT_PERCENTAGE_REPORT = 'TEACHER_ASSIGNMENT_PERCENTAGE_REPORT',
  TEACHER_QAND_A_NEW_SUBMIT_ON_LECTURE = 'TEACHER_QAND_A_NEW_SUBMIT_ON_LECTURE',
  TEACHER_QAND_A_NOT_ANSWERED_REMINDER = 'TEACHER_QAND_A_NOT_ANSWERED_REMINDER',
  TEACHER_POST_CREATED = 'TEACHER_POST_CREATED',
  TEACHER_POST_REMOVED = 'TEACHER_POST_REMOVED',
  TEACHER_POST_SCHEDULED = 'TEACHER_POST_SCHEDULED',
  TEACHER_POST_SAVED_DRAFT = 'TEACHER_POST_SAVED_DRAFT',
  TEACHER_POST_COMMENTED = 'TEACHER_POST_COMMENTED',
  TEACHER_QUIZ_CREATED = 'TEACHER_QUIZ_CREATED',
  TEACHER_QUIZ_SCHEDULED = 'TEACHER_QUIZ_SCHEDULED',
  TEACHER_QUIZ_SAVED_DRAFT = 'TEACHER_QUIZ_SAVED_DRAFT',
  TEACHER_QUIZ_NEW_SUBMIT_RECEIVED = 'TEACHER_QUIZ_NEW_SUBMIT_RECEIVED',
  TEACHER_QUIZ_NOT_MARKED_REMINDER = 'TEACHER_QUIZ_NOT_MARKED_REMINDER',
  TEACHER_QUIZ_PERCENTAGE_REPORT = 'TEACHER_QUIZ_PERCENTAGE_REPORT',
  TEACHER_COURSE_QB_CREATED = 'TEACHER_COURSE_QB_CREATED',
  TEACHER_COURSE_QB_QUESTION_REMOVED = 'TEACHER_COURSE_QB_QUESTION_REMOVED',
  TEACHER_COURSE_QB_NEW_QUESTION_SAVED_DRAFT = 'TEACHER_COURSE_QB_NEW_QUESTION_SAVED_DRAFT',
  TEACHER_COURSE_QB_NEW_QUESTION_PUBLISH_FOLLOWUP = 'TEACHER_COURSE_QB_NEW_QUESTION_PUBLISH_FOLLOWUP',
  TEACHER_COURSE_QB_NEW_QUESTION_PUBLISHED = 'TEACHER_COURSE_QB_NEW_QUESTION_PUBLISHED',
  TEACHER_COURSE_QB_USED_QUESTION_PUBLISHED = 'TEACHER_COURSE_QB_USED_QUESTION_PUBLISHED',
  TEACHER_GROUP_CHALLENGE_CREATED = 'TEACHER_GROUP_CHALLENGE_CREATED',
  TEACHER_GROUP_CHALLENGE_SAVED_DRAFT = 'TEACHER_GROUP_CHALLENGE_SAVED_DRAFT',
  TEACHER_GROUP_CHALLENGE_SCHEDULED = 'TEACHER_GROUP_CHALLENGE_SCHEDULED',
  TEACHER_GROUP_CHALLENGE_REMOVED = 'TEACHER_GROUP_CHALLENGE_REMOVED',
  TEACHER_GROUP_CHALLENGE_PUBLISH_FOLLOWUP = 'TEACHER_GROUP_CHALLENGE_PUBLISH_FOLLOWUP',
  TEACHER_GROUP_CHALLENGE_PUBLISHED = 'TEACHER_GROUP_CHALLENGE_PUBLISHED',
  TEACHER_GROUP_CHALLENGE_STARTED = 'TEACHER_GROUP_CHALLENGE_STARTED',
  TEACHER_GROUP_CHALLENGE_ENDED = 'TEACHER_GROUP_CHALLENGE_ENDED',
  TEACHER_GROUP_CHALLENGE_NEW_SUBMIT_RECEIVED = 'TEACHER_GROUP_CHALLENGE_NEW_SUBMIT_RECEIVED',
  TEACHER_GROUP_CHALLENGE_WINNER = 'TEACHER_GROUP_CHALLENGE_WINNER',
  TEACHER_COURSE_CHALLENGE_CREATED = 'TEACHER_COURSE_CHALLENGE_CREATED',
  TEACHER_COURSE_CHALLENGE_SCHEDULED = 'TEACHER_COURSE_CHALLENGE_SCHEDULED',
  TEACHER_COURSE_CHALLENGE_REMOVED = 'TEACHER_COURSE_CHALLENGE_REMOVED',
  TEACHER_COURSE_CHALLENGE_PUBLISHED = 'TEACHER_COURSE_CHALLENGE_PUBLISHED',
  TEACHER_COURSE_CHALLENGE_STARTED = 'TEACHER_COURSE_CHALLENGE_STARTED',
  TEACHER_COURSE_CHALLENGE_ENDED = 'TEACHER_COURSE_CHALLENGE_ENDED',
  TEACHER_COURSE_CHALLENGE_NEW_SUBMIT_RECEIVED = 'TEACHER_COURSE_CHALLENGE_NEW_SUBMIT_RECEIVED',
  TEACHER_COURSE_CHALLENGE_WINNER = 'TEACHER_COURSE_CHALLENGE_WINNER',
  TEACHER_SURVEY_CREATED = 'TEACHER_SURVEY_CREATED',
  TEACHER_SURVEY_SCHEDULED = 'TEACHER_SURVEY_SCHEDULED',
  TEACHER_SURVEY_REMOVED = 'TEACHER_SURVEY_REMOVED',
  TEACHER_SURVEY_SAVED_DRAFT = 'TEACHER_SURVEY_SAVED_DRAFT',
  TEACHER_SURVEY_PUBLISH_FOLLOWUP = 'TEACHER_SURVEY_PUBLISH_FOLLOWUP',
  TEACHER_SURVEY_PUBLISHED = 'TEACHER_SURVEY_PUBLISHED',
  TEACHER_SURVEY_RESUT = 'TEACHER_SURVEY_RESUT',
  ASSISTANT_VERIFY_PHONE_NUMBER = 'ASSISTANT_VERIFY_PHONE_NUMBER',
  ASSISTANT_WELCOME = 'ASSISTANT_WELCOME',
  ASSISTANT_FORGET_PASSWORD = 'ASSISTANT_FORGET_PASSWORD',
  ASSISTANT_FOLLOWUP_TO_FILL_PROFILE = 'ASSISTANT_FOLLOWUP_TO_FILL_PROFILE',
  ADD_ASSISTANT_TO_COURSE_GROUP_REQUEST = 'ADD_ASSISTANT_TO_COURSE_GROUP_REQUEST',
  ASSISTANT_REQUESTED_BY_TEACHER_FOLLOWUP = 'ASSISTANT_REQUESTED_BY_TEACHER_FOLLOWUP',
  ASSISTANT_JOIN_REQUEST_RECEIVED_ACCEPTED = 'ASSISTANT_JOIN_REQUEST_RECEIVED_ACCEPTED',
  ASSISTANT_JOIN_REQUEST_RECEIVED_REJECTED = 'ASSISTANT_JOIN_REQUEST_RECEIVED_REJECTED',
  ASSISTANT_JOIN_REQUEST_SENT_ACCEPTED = 'ASSISTANT_JOIN_REQUEST_SENT_ACCEPTED',
  ASSISTANT_JOIN_REQUEST_SENT_REJECTED = 'ASSISTANT_JOIN_REQUEST_SENT_REJECTED',
  ASSISTANT_NEW_LICENSE_ASSIGNED = 'ASSISTANT_NEW_LICENSE_ASSIGNED',
  ASSISTANT_NEW_GROUP_ASSIGNED = 'ASSISTANT_NEW_GROUP_ASSIGNED',
  ASSISTANT_NEW_ROLE_ASSIGNED = 'ASSISTANT_NEW_ROLE_ASSIGNED',
  ASSISTANT_NEW_ACTIVITY_ASSIGNED = 'ASSISTANT_NEW_ACTIVITY_ASSIGNED',
  ASSISTANT_NEW_TASK_ASSIGNED = 'ASSISTANT_NEW_TASK_ASSIGNED',
  PARENT_VERIFY_PHONE_NUMBER = 'PARENT_VERIFY_PHONE_NUMBER',
  PARENT_WELCOME = 'PARENT_WELCOME',
  PARENT_FORGET_PASSWORD = 'PARENT_FORGET_PASSWORD',
  PARENT_FOLLOWUP_TO_FILL_PROFILE = 'PARENT_FOLLOWUP_TO_FILL_PROFILE',
  ACCEPT_PARENT_JOIN_COURSE_GROUP = 'ACCEPT_PARENT_JOIN_COURSE_GROUP',
  ACCEPT_ABSENT_REQUEST_FROM_PARENT = 'ACCEPT_ABSENT_REQUEST_FROM_PARENT',
  REJECT_ABSENT_REQUEST_FROM_PARENT = 'REJECT_ABSENT_REQUEST_FROM_PARENT',
  ADD_PARENT = 'ADD_PARENT',
  ACCEPT_ADD_SON = 'ACCEPT_ADD_SON',
  REJECT_ADD_SON = 'REJECT_ADD_SON',
  STUDENT_VERIFY_PHONE_NUMBER = 'STUDENT_VERIFY_PHONE_NUMBER',
  STUDENT_WELCOME = 'STUDENT_WELCOME',
  STUDENT_FORGET_PASSWORD = 'STUDENT_FORGET_PASSWORD',
  STUDENT_FOLLOWUP_TO_FILL_PROFILE = 'STUDENT_FOLLOWUP_TO_FILL_PROFILE',
  STUDENT_FOLLOWUP_TO_JOIN_FIRST_COURSE = 'STUDENT_FOLLOWUP_TO_JOIN_FIRST_COURSE',
  STUDENT_FOLLOWUP_TO_JOIN_SECOND_COURSE = 'STUDENT_FOLLOWUP_TO_JOIN_SECOND_COURSE',
  STUDENT_FOLLOWUP_TO_ADD_PARENT = 'STUDENT_FOLLOWUP_TO_ADD_PARENT',
  STUDENT_COURSE_GROUP_LICENSE_ASSIGNED = 'STUDENT_COURSE_GROUP_LICENSE_ASSIGNED',
  STUDENT_COURSE_GROUP_LICENSE_SUSPENDED_FROM_TEACHER = 'STUDENT_COURSE_GROUP_LICENSE_SUSPENDED_FROM_TEACHER',
  STUDENT_COURSE_GROUP_LICENSE_SUSPENDED_FROM_EDURA = 'STUDENT_COURSE_GROUP_LICENSE_SUSPENDED_FROM_EDURA',
  STUDENT_COURSE_GROUP_LICENSE_REMOVED = 'STUDENT_COURSE_GROUP_LICENSE_REMOVED',
  STUDENT_JOIN_COURSE_GROUP_SENT = 'STUDENT_JOIN_COURSE_GROUP_SENT',
  STUDENT_JOIN_COURSE_GROUP_SENT_ACCEPTED = 'STUDENT_JOIN_COURSE_GROUP_SENT_ACCEPTED',
  STUDENT_JOIN_COURSE_GROUP_SENT_REJECTED = 'STUDENT_JOIN_COURSE_GROUP_SENT_REJECTED',
  STUDENT_JOIN_COURSE_GROUP_SENT_BLOCKED = 'STUDENT_JOIN_COURSE_GROUP_SENT_BLOCKED',
  STUDENT_JOIN_COURSE_GROUP_RECEIVED_FOLLOWUP = 'STUDENT_JOIN_COURSE_GROUP_RECEIVED_FOLLOWUP',
  STUDENT_CHANGE_COURSE_GROUP_TEMP_REQUESTED = 'STUDENT_CHANGE_COURSE_GROUP_TEMP_REQUESTED',
  STUDENT_CHANGE_COURSE_GROUP_TEMP_ACCEPTED = 'STUDENT_CHANGE_COURSE_GROUP_TEMP_ACCEPTED',
  STUDENT_CHANGE_COURSE_GROUP_TEMP_REJECTED = 'STUDENT_CHANGE_COURSE_GROUP_TEMP_REJECTED',
  STUDENT_CHANGE_COURSE_GROUP_PERM_REQUESTED = 'STUDENT_CHANGE_COURSE_GROUP_PERM_REQUESTED',
  STUDENT_CHANGE_COURSE_GROUP_PERM_ACCEPTED = 'STUDENT_CHANGE_COURSE_GROUP_PERM_ACCEPTED',
  STUDENT_CHANGE_COURSE_GROUP_PERM_REJECTED = 'STUDENT_CHANGE_COURSE_GROUP_PERM_REJECTED',
  STUDENT_ADDED_PARENT = 'STUDENT_ADDED_PARENT',
  STUDENT_ADDED_PARENT_FOLLOWUP = 'STUDENT_ADDED_PARENT_FOLLOWUP',
  STUDENT_ADDED_PARENT_ACCEPTED = 'STUDENT_ADDED_PARENT_ACCEPTED',
  STUDENT_ADDED_PARENT_REJECTED = 'STUDENT_ADDED_PARENT_REJECTED',
  ADD_STUDENT_TO_COURSE_GROUP = 'ADD_STUDENT_TO_COURSE_GROUP',
  STUDENT_JOIN_COURSE_GROUP_RECEIVED_ACCEPTED = 'STUDENT_JOIN_COURSE_GROUP_RECEIVED_ACCEPTED',
  STUDENT_JOIN_COURSE_GROUP_RECEIVED_REJECTED = 'STUDENT_JOIN_COURSE_GROUP_RECEIVED_REJECTED',
  TEACHER_REVISION_CREATED = 'TEACHER_REVISION_CREATED',
  TEACHER_REVISION_REMOVED = 'TEACHER_REVISION_REMOVED',
  TEACHER_REVISION_SCHEDULED = 'TEACHER_REVISION_SCHEDULED',
  TEACHER_REVISION_SAVED_DRAFT = 'TEACHER_REVISION_SAVED_DRAFT',
  TEACHER_REVISION_COMMENTED = 'TEACHER_REVISION_COMMENTED',
  TEACHER_FOLLOWED_BY_STUDENT = 'TEACHER_FOLLOWED_BY_STUDENT',
  TEACHER_FOLLOWED_BY_PARENT = 'TEACHER_FOLLOWED_BY_PARENT',
  TEACHER_SEND_REQUEST_JOIN_CONTENT_REQUEST = 'TEACHER_SEND_REQUEST_JOIN_CONTENT_REQUEST',
  ASSISTANT_SEND_REQUEST_JOIN_CONTENT_REQUEST = 'ASSISTANT_SEND_REQUEST_JOIN_CONTENT_REQUEST',
  TEACHER_CANCEL_REQUEST_JOIN_CONTENT_REQUEST = 'TEACHER_CANCEL_REQUEST_JOIN_CONTENT_REQUEST',
  ASSISTANT_CANCEL_REQUEST_JOIN_CONTENT_REQUEST = 'ASSISTANT_CANCEL_REQUEST_JOIN_CONTENT_REQUEST',
  ATTENTION_SESSION_CONTENT_FOR_STUDENT_BECAUSE_NOT_PAID = 'ATTENTION_SESSION_CONTENT_FOR_STUDENT_BECAUSE_NOT_PAID',
  CANCEL_SESSION_CONTENT_FOR_STUDENT_BECAUSE_NOT_PAID = 'CANCEL_SESSION_CONTENT_FOR_STUDENT_BECAUSE_NOT_PAID',
  TEACHER_CREATE_COURSE_CONTENT_ACCEPTED = 'TEACHER_CREATE_COURSE_CONTENT_ACCEPTED',
  TEACHER_CREATE_COURSE_CONTENT_REJECTED = 'TEACHER_CREATE_COURSE_CONTENT_REJECTED',
  TEACHER_CREATE_SESSION_CONTENT_ACCEPTED = 'TEACHER_CREATE_SESSION_CONTENT_ACCEPTED',
  TEACHER_CREATE_SESSION_CONTENT_REJECTED = 'TEACHER_CREATE_SESSION_CONTENT_REJECTED'
}

export type MessageTemplateCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  AcademyId?: Maybe<Scalars['Int']>;
  TemplateType?: Maybe<MessageTemplateType>;
};

export type NoteTypeListResponse = {
  __typename?: 'NoteTypeListResponse';
  Result?: Maybe<Array<Maybe<Note>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type Note = {
  __typename?: 'Note';
  AccountManagerId?: Maybe<Scalars['Int']>;
  AccountManagerName?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  NoteContent?: Maybe<Scalars['String']>;
  NoteDirection?: Maybe<NoteDirection>;
  NoteType?: Maybe<NoteType>;
  TeacherId?: Maybe<Scalars['Int']>;
  TeacherMobileNumber?: Maybe<Scalars['String']>;
  TeacherName?: Maybe<Scalars['String']>;
};

export enum NoteDirection {
  FROM_ACCOUNT_MANAGER_TO_TEACHER = 'FROM_ACCOUNT_MANAGER_TO_TEACHER'
}

export enum NoteType {
  LICENCE_NOTE = 'LICENCE_NOTE'
}

export type NoteCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  TeacherId?: Maybe<Scalars['Int']>;
  AccountManagerId?: Maybe<Scalars['Int']>;
  TeacherMobileNumber?: Maybe<Scalars['String']>;
  NoteDirection?: Maybe<NoteDirection>;
  NoteType?: Maybe<NoteType>;
};

export type UnitTypeListResponse = {
  __typename?: 'UnitTypeListResponse';
  Result?: Maybe<Array<Maybe<Unit>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type Unit = {
  __typename?: 'Unit';
  Chapters?: Maybe<Array<Maybe<Chapter>>>;
  Id?: Maybe<Scalars['Int']>;
  ImagePath?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};


export type UnitChaptersArgs = {
  criteria?: Maybe<ChapterCriteria>;
};

export type Chapter = {
  __typename?: 'Chapter';
  Id?: Maybe<Scalars['Int']>;
  Name?: Maybe<Scalars['String']>;
  Unit?: Maybe<Unit>;
};

export type ChapterCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
};

export type UnitCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  ChapterId?: Maybe<Scalars['Int']>;
  CurrentState?: Maybe<CurrentState>;
  Chapter?: Maybe<ChapterCriteria>;
};

export enum CurrentState {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING'
}

export type MarketingQuery = {
  __typename?: 'MarketingQuery';
  VoucherDetails?: Maybe<VoucherDetailTypeListResponse>;
  Vouchers?: Maybe<VoucherMasterTypeListResponse>;
};


export type MarketingQueryVoucherDetailsArgs = {
  criteria?: Maybe<VoucherDetailCriteria>;
};


export type MarketingQueryVouchersArgs = {
  criteria?: Maybe<VoucherMasterCriteria>;
};

export type VoucherMasterTypeListResponse = {
  __typename?: 'VoucherMasterTypeListResponse';
  Result?: Maybe<Array<Maybe<VoucherMaster>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type VoucherMaster = {
  __typename?: 'VoucherMaster';
  AcademyId?: Maybe<Scalars['Int']>;
  AcademyName?: Maybe<Scalars['String']>;
  Cost?: Maybe<Scalars['Decimal']>;
  Details?: Maybe<Array<Maybe<VoucherDetail>>>;
  DetailsCount?: Maybe<Scalars['Int']>;
  ExpirationDate?: Maybe<Scalars['Date']>;
  Id?: Maybe<Scalars['Int']>;
  RowStatus?: Maybe<ResourceStatus>;
  Serial?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['Decimal']>;
};

export enum ResourceStatus {
  SUSPENDED = 'SUSPENDED',
  ACTIVE = 'ACTIVE',
  NOT_DETERMINED = 'NOT_DETERMINED'
}

export type VoucherDetail = {
  __typename?: 'VoucherDetail';
  Amount?: Maybe<Scalars['Decimal']>;
  Code?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  StudentId?: Maybe<Scalars['Int']>;
  StudentName?: Maybe<Scalars['String']>;
};

export type VoucherMasterCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  AcademyId?: Maybe<Scalars['Int']>;
  Status?: Maybe<ResourceStatus>;
};

export type VoucherDetailTypeListResponse = {
  __typename?: 'VoucherDetailTypeListResponse';
  Result?: Maybe<Array<Maybe<VoucherDetail>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type VoucherDetailCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
};

export type CommonQuery = {
  __typename?: 'CommonQuery';
  EduraNews?: Maybe<EduraNewsTypeListResponse>;
  EduraResources?: Maybe<AppResourcesTypeListResponse>;
};


export type CommonQueryEduraNewsArgs = {
  criteria?: Maybe<EduraNewsCriteria>;
};


export type CommonQueryEduraResourcesArgs = {
  criteria?: Maybe<AppResourcesCriteria>;
};

export type EduraNewsTypeListResponse = {
  __typename?: 'EduraNewsTypeListResponse';
  Result?: Maybe<Array<Maybe<EduraNews>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type EduraNews = {
  __typename?: 'EduraNews';
  AddedDate?: Maybe<Scalars['Date']>;
  Content?: Maybe<Scalars['String']>;
  Country?: Maybe<Country>;
  CountryId?: Maybe<Scalars['Int']>;
  Description?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  ImagePath?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
};

export type EduraNewsCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
};

/** Contains queries for account. */
export type AccountQuery = {
  __typename?: 'AccountQuery';
  StudentProfile?: Maybe<UserProfileTypeResponse>;
  TeacherProfile?: Maybe<UserProfileTypeResponse>;
  /** Used to get the user profile data. */
  UserProfile?: Maybe<UserProfileTypeResponse>;
  UserProfileForAdmin?: Maybe<UserProfileTypeResponse>;
};


/** Contains queries for account. */
export type AccountQueryStudentProfileArgs = {
  studentId?: Maybe<Scalars['Int']>;
};


/** Contains queries for account. */
export type AccountQueryTeacherProfileArgs = {
  teacherId?: Maybe<Scalars['Int']>;
};


/** Contains queries for account. */
export type AccountQueryUserProfileForAdminArgs = {
  userId?: Maybe<Scalars['Int']>;
};

export type UserProfileTypeResponse = {
  __typename?: 'UserProfileTypeResponse';
  Result?: Maybe<UserProfile>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  AcademyId: Scalars['Int'];
  AssistantId: Scalars['Int'];
  Bio?: Maybe<Scalars['String']>;
  EducationTypeId?: Maybe<Scalars['Int']>;
  Email?: Maybe<Scalars['String']>;
  Gender?: Maybe<Gender>;
  GenderName?: Maybe<Scalars['String']>;
  Id: Scalars['Int'];
  ImagePath?: Maybe<Scalars['String']>;
  IsConfirmed?: Maybe<Scalars['Boolean']>;
  MobileNumber?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  ParentId: Scalars['Int'];
  SchoolYearId?: Maybe<Scalars['Int']>;
  Slogan?: Maybe<Scalars['String']>;
  Status?: Maybe<ResourceStatus>;
  StudentEducationTypeName?: Maybe<Scalars['String']>;
  StudentId: Scalars['Int'];
  StudentSchoolYearName?: Maybe<Scalars['String']>;
  TeacherId: Scalars['Int'];
  UserId: Scalars['Int'];
  UserType?: Maybe<ApplicationUserType>;
  VideoPath?: Maybe<Scalars['String']>;
  VideoPathId?: Maybe<Scalars['String']>;
  VideoPicturePath?: Maybe<Scalars['String']>;
};

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE'
}

export enum ApplicationUserType {
  UNKNOWN = 'UNKNOWN',
  TEACHER = 'TEACHER',
  ASSISTANT = 'ASSISTANT',
  STUDENT = 'STUDENT',
  PARENT = 'PARENT',
  ACCOUNT_MANAGER = 'ACCOUNT_MANAGER',
  EDURA = 'EDURA'
}

export type RequestQuery = {
  __typename?: 'RequestQuery';
  AssistantRequests?: Maybe<RequestTypeListResponse>;
  ParentRequests?: Maybe<RequestTypeListResponse>;
  StudentRequests?: Maybe<RequestTypeListResponse>;
  TeacherPendingRequestesFromAssistant?: Maybe<RequestTypeListResponse>;
  TeacherPendingRequestesFromParent?: Maybe<RequestTypeListResponse>;
  TeacherPendingRequestesFromStudent?: Maybe<RequestTypeListResponse>;
};


export type RequestQueryAssistantRequestsArgs = {
  criteria?: Maybe<RequestCriteria>;
};


export type RequestQueryParentRequestsArgs = {
  criteria?: Maybe<RequestCriteria>;
};


export type RequestQueryStudentRequestsArgs = {
  criteria?: Maybe<RequestCriteria>;
};


export type RequestQueryTeacherPendingRequestesFromAssistantArgs = {
  criteria?: Maybe<RequestCriteria>;
};


export type RequestQueryTeacherPendingRequestesFromParentArgs = {
  criteria?: Maybe<RequestCriteria>;
};


export type RequestQueryTeacherPendingRequestesFromStudentArgs = {
  criteria?: Maybe<RequestCriteria>;
};

export type RequestTypeListResponse = {
  __typename?: 'RequestTypeListResponse';
  Result?: Maybe<Array<Maybe<Request>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type Request = {
  __typename?: 'Request';
  AlterGroupStudentCount?: Maybe<Scalars['Int']>;
  AlternativeCourseGroupName?: Maybe<Scalars['String']>;
  Assistant?: Maybe<Assistant>;
  AssistantId?: Maybe<Scalars['Int']>;
  AssistantName?: Maybe<Scalars['String']>;
  CourseGroup?: Maybe<CourseGroup>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  CourseGroupName?: Maybe<Scalars['String']>;
  CourseName?: Maybe<Scalars['String']>;
  GroupStudentCount?: Maybe<Scalars['Int']>;
  Id?: Maybe<Scalars['Int']>;
  ManageAbsentFromParentRequest?: Maybe<Scalars['Boolean']>;
  ManageAbsentStudentRequest?: Maybe<Scalars['Boolean']>;
  ManageAddStudentoCourseGroupStudentRequest?: Maybe<Scalars['Boolean']>;
  ManageChangePermStudentRequest?: Maybe<Scalars['Boolean']>;
  ManageChangeTempStudentRequest?: Maybe<Scalars['Boolean']>;
  ManageJoinCourseGroupFromParentRequest?: Maybe<Scalars['Boolean']>;
  ManageJoinCourseGroupRequest?: Maybe<Scalars['Boolean']>;
  Parent?: Maybe<Parent>;
  ParentId?: Maybe<Scalars['Int']>;
  ParentName?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  RequestChangeStatusLog?: Maybe<Array<Maybe<RequestChangeStatusLog>>>;
  RequestStatus?: Maybe<RequestStatus>;
  RequestStatusName?: Maybe<Scalars['String']>;
  RequestType?: Maybe<RequestType>;
  RequestTypeName?: Maybe<Scalars['String']>;
  SchoolYearName?: Maybe<Scalars['String']>;
  SendDate?: Maybe<Scalars['Date']>;
  SendFrom?: Maybe<ApplicationUserType>;
  SendTo?: Maybe<ApplicationUserType>;
  Session?: Maybe<Session>;
  Student?: Maybe<Student>;
  StudentId?: Maybe<Scalars['Int']>;
  StudentMobile?: Maybe<Scalars['String']>;
  StudentName?: Maybe<Scalars['String']>;
  Teacher?: Maybe<Teacher>;
  TeacherName?: Maybe<Scalars['String']>;
};

export enum RequestStatus {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  BLOCKED = 'BLOCKED'
}

export enum RequestType {
  ADD_PARENT = 'ADD_PARENT',
  ABSENT = 'ABSENT',
  CHANGE_PERM = 'CHANGE_PERM',
  CHANGE_TEMP = 'CHANGE_TEMP',
  JOIN_TEACHER = 'JOIN_TEACHER',
  ADD_STUDENTO_COURSE_GROUP = 'ADD_STUDENTO_COURSE_GROUP',
  ADD_SON = 'ADD_SON',
  JOIN_COURSE_GROUP = 'JOIN_COURSE_GROUP',
  ABSENT_FROM_PARENT = 'ABSENT_FROM_PARENT',
  JOIN_COURSE_GROUP_FROM_PARENT = 'JOIN_COURSE_GROUP_FROM_PARENT',
  ADD_ASSISTANT_TO_COURSE_GROUP = 'ADD_ASSISTANT_TO_COURSE_GROUP'
}

export type Assistant = {
  __typename?: 'Assistant';
  About?: Maybe<Scalars['String']>;
  AssistantRoles?: Maybe<Array<Maybe<AssistantRoles>>>;
  Country?: Maybe<Country>;
  CourseGroups?: Maybe<Array<Maybe<CourseGroup>>>;
  Email?: Maybe<Scalars['String']>;
  GenderName?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  ImagePath?: Maybe<Scalars['String']>;
  MobileNumber?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type AssistantRoles = {
  __typename?: 'AssistantRoles';
  CourseGroupId?: Maybe<Scalars['Int']>;
  CourseGroupName?: Maybe<Scalars['String']>;
  Permission?: Maybe<AssistantPermission>;
  RoleId?: Maybe<Scalars['Int']>;
  RoleName?: Maybe<Scalars['String']>;
  SchoolYearName?: Maybe<Scalars['String']>;
  ScientificSubjectName?: Maybe<Scalars['String']>;
};

export enum AssistantPermission {
  SUBJECT_ROLES = 'SUBJECT_ROLES',
  COURSE_ROLES = 'COURSE_ROLES',
  COURSE_GROUP_ROLES = 'COURSE_GROUP_ROLES',
  ACTIVITY_ROLES = 'ACTIVITY_ROLES',
  SESSION_ROLES = 'SESSION_ROLES',
  STUDENT_ROLES = 'STUDENT_ROLES',
  PARENT_ROLES = 'PARENT_ROLES',
  PREVIOUS_EXAM_ROLES = 'PREVIOUS_EXAM_ROLES',
  REQUEST_ROLES = 'REQUEST_ROLES',
  USER_ACTIVITY_ROLES = 'USER_ACTIVITY_ROLES',
  LICENCE_ROLES = 'LICENCE_ROLES',
  ONLINE_CONTENT_ROLES = 'ONLINE_CONTENT_ROLES',
  GROUPED_SESSION = 'GROUPED_SESSION',
  CREATE_SUBJECT = 'CREATE_SUBJECT',
  UPDATE_SUBJECT = 'UPDATE_SUBJECT',
  DELETE_SUBJECT = 'DELETE_SUBJECT',
  VIEW_SUBJECT = 'VIEW_SUBJECT',
  CREATE_COURSE = 'CREATE_COURSE',
  UPDATE_COURSE = 'UPDATE_COURSE',
  DELETE_COURSE = 'DELETE_COURSE',
  VIEW_COURSE = 'VIEW_COURSE',
  ADD_COURSE_GROUP = 'ADD_COURSE_GROUP',
  UPDATE_COURSE_GROUP = 'UPDATE_COURSE_GROUP',
  DELETE_COURSE_GROUP = 'DELETE_COURSE_GROUP',
  VIEW_COURSE_GROUP = 'VIEW_COURSE_GROUP',
  CREATE_POST = 'CREATE_POST',
  UPDATE_POST = 'UPDATE_POST',
  DELETE_POST = 'DELETE_POST',
  VIEW_POST = 'VIEW_POST',
  CREATE_REVISION = 'CREATE_REVISION',
  UPDATE_REVISION = 'UPDATE_REVISION',
  DELETE_REVISION = 'DELETE_REVISION',
  VIEW_REVISION = 'VIEW_REVISION',
  CREATE_QUIZ = 'CREATE_QUIZ',
  UPDATE_QUIZ = 'UPDATE_QUIZ',
  DELETE_QUIZ = 'DELETE_QUIZ',
  VIEW_QUIZ = 'VIEW_QUIZ',
  MARK_QUIZ = 'MARK_QUIZ',
  UPDATE_QUESTION_BANK = 'UPDATE_QUESTION_BANK',
  VIEW_QUESTION_BANK = 'VIEW_QUESTION_BANK',
  CREATE_CHALLENGE = 'CREATE_CHALLENGE',
  UPDATE_CHALLENGE = 'UPDATE_CHALLENGE',
  DELETE_CHALLENGE = 'DELETE_CHALLENGE',
  VIEW_CHALLENGE = 'VIEW_CHALLENGE',
  MARK_CHALLENGE = 'MARK_CHALLENGE',
  CREATE_SURVEY = 'CREATE_SURVEY',
  UPDATE_SURVEY = 'UPDATE_SURVEY',
  DELETE_SURVEY = 'DELETE_SURVEY',
  VIEW_SURVEY = 'VIEW_SURVEY',
  CREATE_ASSIGNMENT = 'CREATE_ASSIGNMENT',
  UPDATE_ASSIGNMENT = 'UPDATE_ASSIGNMENT',
  DELETE_ASSIGNMENT = 'DELETE_ASSIGNMENT',
  VIEW_ASSIGNMENT = 'VIEW_ASSIGNMENT',
  MARK_ASSIGNMENT = 'MARK_ASSIGNMENT',
  CREATE_SESSION = 'CREATE_SESSION',
  UPDATE_SESSION = 'UPDATE_SESSION',
  DELETE_SESSION = 'DELETE_SESSION',
  VIEW_SESSION = 'VIEW_SESSION',
  CONFIRM_SESSION_ATTENDANCE = 'CONFIRM_SESSION_ATTENDANCE',
  ANSWER_SESSION_QUESTION = 'ANSWER_SESSION_QUESTION',
  SUSPEND_AND_ACTIVE_COURSE_GROUP_STUDENT = 'SUSPEND_AND_ACTIVE_COURSE_GROUP_STUDENT',
  REMOVE_COURSE_GROUP_STUDENT = 'REMOVE_COURSE_GROUP_STUDENT',
  REMOVE_COURSE_GROUP_PARENT = 'REMOVE_COURSE_GROUP_PARENT',
  CREATE_PREVIOUS_EXAM = 'CREATE_PREVIOUS_EXAM',
  UPDATE_PREVIOUS_EXAM = 'UPDATE_PREVIOUS_EXAM',
  DELETE_PREVIOUS_EXAM = 'DELETE_PREVIOUS_EXAM',
  VIEW_PREVIOUS_EXAM = 'VIEW_PREVIOUS_EXAM',
  MANAGE_ABSENT_STUDENT_REQUEST = 'MANAGE_ABSENT_STUDENT_REQUEST',
  MANAGE_CHANGE_PERM_STUDENT_REQUEST = 'MANAGE_CHANGE_PERM_STUDENT_REQUEST',
  MANAGE_CHANGE_TEMP_STUDENT_REQUEST = 'MANAGE_CHANGE_TEMP_STUDENT_REQUEST',
  MANAGE_ADD_STUDENTO_COURSE_GROUP_STUDENT_REQUEST = 'MANAGE_ADD_STUDENTO_COURSE_GROUP_STUDENT_REQUEST',
  MANAGE_JOIN_COURSE_GROUP_REQUEST = 'MANAGE_JOIN_COURSE_GROUP_REQUEST',
  MANAGE_ABSENT_FROM_PARENT_REQUEST = 'MANAGE_ABSENT_FROM_PARENT_REQUEST',
  MANAGE_JOIN_COURSE_GROUP_FROM_PARENT_REQUEST = 'MANAGE_JOIN_COURSE_GROUP_FROM_PARENT_REQUEST',
  VIEW_USER_ACTIVITY = 'VIEW_USER_ACTIVITY',
  ASSIGN_LICENCE_TO_STUDENT = 'ASSIGN_LICENCE_TO_STUDENT',
  VIEW_COURSE_GROUP_STUDENT_REQUESTS = 'VIEW_COURSE_GROUP_STUDENT_REQUESTS',
  VIEW_COURSE_GROUP_PARENT_REQUESTS = 'VIEW_COURSE_GROUP_PARENT_REQUESTS',
  SEND_JOIN_CONTENT_REQUEST = 'SEND_JOIN_CONTENT_REQUEST',
  CANCEL_SEND_JOIN_CONTENT_REQUEST = 'CANCEL_SEND_JOIN_CONTENT_REQUEST',
  VIEW_SEND_JOIN_CONTENT_REQUEST = 'VIEW_SEND_JOIN_CONTENT_REQUEST',
  SEND_COURSE_CONTENT_REQUEST = 'SEND_COURSE_CONTENT_REQUEST',
  VIEW_COURSE_CONTENTS = 'VIEW_COURSE_CONTENTS',
  CANCEL_COURSE_CONTENTS = 'CANCEL_COURSE_CONTENTS',
  VIEW_SESSION_CONTENT = 'VIEW_SESSION_CONTENT',
  CREATE_SESSION_CONTENT = 'CREATE_SESSION_CONTENT',
  UPDATE_SESSION_CONTENT = 'UPDATE_SESSION_CONTENT',
  VIEW_SESSION_CONTENT_STUDENTS = 'VIEW_SESSION_CONTENT_STUDENTS',
  VIEW_SESSION_CONTENT_ACTIVITIES = 'VIEW_SESSION_CONTENT_ACTIVITIES',
  VIEW_SESSION_CONTENT_QUESTION = 'VIEW_SESSION_CONTENT_QUESTION',
  ANSWER_SESSION_CONTENT_QUESTION = 'ANSWER_SESSION_CONTENT_QUESTION',
  VIEW_SESSION_CONTENT_ASSIGNMENTS = 'VIEW_SESSION_CONTENT_ASSIGNMENTS',
  UPDATE_SESSION_CONTENT_ASSIGNMENTS = 'UPDATE_SESSION_CONTENT_ASSIGNMENTS',
  UPDATE_SESSION_CONTENT_PART = 'UPDATE_SESSION_CONTENT_PART',
  CREATE_GROUPED_SESSION = 'CREATE_GROUPED_SESSION',
  CREATE_GROUPED_SESSION_ASSIGNMENT = 'CREATE_GROUPED_SESSION_ASSIGNMENT'
}

export type CourseGroup = {
  __typename?: 'CourseGroup';
  Activities?: Maybe<Array<Maybe<Activity>>>;
  ActualNumOfStudents?: Maybe<Scalars['Int']>;
  AllowAbsentRequest?: Maybe<Scalars['Boolean']>;
  AllowChangeGroupRequest?: Maybe<Scalars['Boolean']>;
  AllowNewRequests?: Maybe<Scalars['Boolean']>;
  AllowViewActivitiesForAssistant?: Maybe<Scalars['Boolean']>;
  Course?: Maybe<Course>;
  CourseGroupAssistants?: Maybe<Array<Maybe<CourseGroupAssistant>>>;
  CourseGroupHours?: Maybe<Array<Maybe<CourseGroupHours>>>;
  CourseGroupStudent?: Maybe<CourseGroupStudents>;
  CourseId?: Maybe<Scalars['Int']>;
  Events?: Maybe<Array<Maybe<Event>>>;
  Id?: Maybe<Scalars['Int']>;
  ImagePath?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  IsLimited?: Maybe<Scalars['Boolean']>;
  IsNeedAction?: Maybe<Scalars['Boolean']>;
  IsSelected?: Maybe<Scalars['Boolean']>;
  LimitationPeriodInDays?: Maybe<Scalars['Int']>;
  MainAssistantId?: Maybe<Scalars['Int']>;
  MainAssistantName?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  NumberOfStudents?: Maybe<Scalars['Int']>;
  SchoolYearName?: Maybe<Scalars['String']>;
  ScientificSubjectName?: Maybe<Scalars['String']>;
  Sessions?: Maybe<Session>;
  Teacher?: Maybe<Teacher>;
  TeacherId?: Maybe<Scalars['Int']>;
  TeacherName?: Maybe<Scalars['String']>;
};


export type CourseGroupEventsArgs = {
  criteria?: Maybe<EventCriteria>;
};

export type Session = {
  __typename?: 'Session';
  Activities?: Maybe<Array<Maybe<Activity>>>;
  ActualAttendanceCount?: Maybe<Scalars['Int']>;
  AllSessionTasks?: Maybe<Scalars['Int']>;
  AnswerdSessionQuestionCount?: Maybe<Scalars['Int']>;
  AssignmentCount?: Maybe<Scalars['Int']>;
  Assignments?: Maybe<Array<Maybe<Assignment>>>;
  Attachments?: Maybe<Array<Maybe<Attachment>>>;
  AttendanceMethod?: Maybe<AttendanceMethod>;
  CourseGroup?: Maybe<CourseGroup>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  CourseGroupName?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['Date']>;
  Description?: Maybe<Scalars['String']>;
  EndTime?: Maybe<Scalars['String']>;
  GroupedSessionGroups?: Maybe<Array<Maybe<GroupedSessionGroup>>>;
  Id?: Maybe<Scalars['Int']>;
  IsFinished?: Maybe<Scalars['Boolean']>;
  IsLocked?: Maybe<Scalars['Boolean']>;
  NotAnswerdSessionQuestionCount?: Maybe<Scalars['Int']>;
  Posts?: Maybe<Array<Maybe<Post>>>;
  QRCodePath?: Maybe<Scalars['String']>;
  Requests?: Maybe<Array<Maybe<Request>>>;
  SchoolYearName?: Maybe<Scalars['String']>;
  ScientificSubjectName?: Maybe<Scalars['String']>;
  ServerDateTime?: Maybe<Scalars['String']>;
  SessionAttendType?: Maybe<SessionAttendType>;
  SessionCompletedTasks?: Maybe<Scalars['Int']>;
  SessionQuestionCount?: Maybe<Scalars['Int']>;
  SessionQuestions?: Maybe<Array<Maybe<SessionQuestion>>>;
  SessionsAttendance?: Maybe<Array<Maybe<SessionAttendance>>>;
  SessionStatus?: Maybe<SessionStatus>;
  SessionType?: Maybe<SessionType>;
  SessionVideos?: Maybe<Array<Maybe<SessionVideo>>>;
  StartTime?: Maybe<Scalars['String']>;
  StudentCount?: Maybe<Scalars['Int']>;
  StudentSessionAttendance?: Maybe<SessionAttendance>;
  Title?: Maybe<Scalars['String']>;
  ZoomMeetings?: Maybe<Array<Maybe<ZoomMeeting>>>;
  ZoomMeetingSettings?: Maybe<ZoomMeetingSettigs>;
};


export type SessionActivitiesArgs = {
  criteria?: Maybe<ActivityCriteria>;
};

export type Post = {
  __typename?: 'Post';
  ActivitySource?: Maybe<ActivitySource>;
  ActivitySourceName?: Maybe<Scalars['String']>;
  ActivityType?: Maybe<ActivityType>;
  ActualCreator?: Maybe<Scalars['String']>;
  ActualCreatorImage?: Maybe<Scalars['String']>;
  AddedDate?: Maybe<Scalars['String']>;
  Attachments?: Maybe<Array<Maybe<Attachment>>>;
  Comments?: Maybe<Array<Maybe<StudentActivity>>>;
  Content?: Maybe<Scalars['String']>;
  CourseGroup?: Maybe<CourseGroup>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  CourseGroupName?: Maybe<Scalars['String']>;
  CourseId?: Maybe<Scalars['Int']>;
  Creator?: Maybe<Scalars['String']>;
  CreatorImage?: Maybe<Scalars['String']>;
  CurrentTime?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['Date']>;
  HideFromRecording?: Maybe<Scalars['Boolean']>;
  Id?: Maybe<Scalars['Int']>;
  IsDraft?: Maybe<Scalars['Boolean']>;
  IsScheduled?: Maybe<Scalars['Boolean']>;
  Name?: Maybe<Scalars['String']>;
  SchoolYearName?: Maybe<Scalars['String']>;
  ScientificSubjectName?: Maybe<Scalars['String']>;
  ServerDateTime?: Maybe<Scalars['String']>;
  SessionId?: Maybe<Scalars['Int']>;
  SessionName?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  VideoPath?: Maybe<Scalars['String']>;
  VideoPathId?: Maybe<Scalars['String']>;
  VideoPicturePath?: Maybe<Scalars['String']>;
};

export enum ActivitySource {
  BY_EDURA = 'BY_EDURA',
  BY_TEACHER = 'BY_TEACHER'
}

export enum ActivityType {
  REVISION = 'REVISION',
  QUIZ = 'QUIZ',
  QUESTION = 'QUESTION',
  CHALLENGE = 'CHALLENGE',
  SURVEY = 'SURVEY',
  POST = 'POST',
  ASSIGNMENT = 'ASSIGNMENT',
  OTHER = 'OTHER'
}

export type StudentActivity = {
  __typename?: 'StudentActivity';
  Activity?: Maybe<Activity>;
  ActivityAddedDate?: Maybe<Scalars['Date']>;
  ActivityDeadline?: Maybe<Scalars['Date']>;
  ActivityDescription?: Maybe<Scalars['String']>;
  ActivityGrad?: Maybe<Scalars['Float']>;
  ActivityId?: Maybe<Scalars['Int']>;
  ActivityIsFinished?: Maybe<Scalars['Boolean']>;
  ActivityIsOnline?: Maybe<Scalars['Boolean']>;
  ActivityName?: Maybe<Scalars['String']>;
  ActivityTypeName?: Maybe<Scalars['String']>;
  AssistantName?: Maybe<Scalars['String']>;
  AttachmentPath?: Maybe<Scalars['String']>;
  AttachmentPathFileType?: Maybe<FileType>;
  Attachments?: Maybe<Array<Maybe<Attachment>>>;
  Comment?: Maybe<Scalars['String']>;
  CourseGroupName?: Maybe<Scalars['String']>;
  DeliveredDate?: Maybe<Scalars['Date']>;
  Grade?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['Int']>;
  ImagePath?: Maybe<Scalars['String']>;
  IsMarked?: Maybe<Scalars['Boolean']>;
  IsView?: Maybe<Scalars['Boolean']>;
  IsWinner?: Maybe<Scalars['Boolean']>;
  ParentUserActivityId?: Maybe<Scalars['Int']>;
  Replies?: Maybe<Array<Maybe<StudentActivity>>>;
  SchoolYearName?: Maybe<Scalars['String']>;
  ScientificSubjectName?: Maybe<Scalars['String']>;
  ScoreOrder?: Maybe<Scalars['String']>;
  SessionName?: Maybe<Scalars['String']>;
  Student?: Maybe<Student>;
  StudentId?: Maybe<Scalars['Int']>;
  StudentName?: Maybe<Scalars['String']>;
  TeacherName?: Maybe<Scalars['String']>;
  UserName?: Maybe<Scalars['String']>;
};

export type Activity = {
  __typename?: 'Activity';
  ActivityDuration?: Maybe<Scalars['Seconds']>;
  ActivityQuestions?: Maybe<Array<Maybe<ActivityQuestion>>>;
  ActivitySource?: Maybe<ActivitySource>;
  ActivityType?: Maybe<ActivityType>;
  ActivityTypeName?: Maybe<Scalars['String']>;
  AddedDate?: Maybe<Scalars['Date']>;
  AreTasksCompleted?: Maybe<Scalars['Boolean']>;
  AttachmentPath?: Maybe<Scalars['String']>;
  Attachments?: Maybe<Array<Maybe<Attachment>>>;
  CanResubmite?: Maybe<Scalars['Boolean']>;
  CanSeeAnswer?: Maybe<Scalars['Boolean']>;
  Content?: Maybe<Scalars['String']>;
  CourseGroup?: Maybe<CourseGroup>;
  CourseGroupName?: Maybe<Scalars['String']>;
  Creator?: Maybe<Scalars['String']>;
  CreatorPic?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['Date']>;
  DeadLine?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DownloadLink?: Maybe<Scalars['String']>;
  EndTime?: Maybe<Scalars['String']>;
  FileType?: Maybe<FileType>;
  Grade?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['Int']>;
  IsMarked?: Maybe<Scalars['Boolean']>;
  IsMarkedUserActivityCount?: Maybe<Scalars['Int']>;
  IsOnline?: Maybe<Scalars['Boolean']>;
  Name?: Maybe<Scalars['String']>;
  SchoolYearName?: Maybe<Scalars['String']>;
  ScientificSubjectName?: Maybe<Scalars['String']>;
  ServerDateTime?: Maybe<Scalars['String']>;
  Session?: Maybe<Session>;
  SessionName?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  StartTime?: Maybe<Scalars['String']>;
  StudentCount?: Maybe<Scalars['Int']>;
  Teacher?: Maybe<Teacher>;
  UserAcivityId?: Maybe<Scalars['Int']>;
  UserActivities?: Maybe<Array<Maybe<StudentActivity>>>;
  UserActivityCount?: Maybe<Scalars['Int']>;
};


export type ActivityUserActivitiesArgs = {
  criteria?: Maybe<UserActivityCriteria>;
};

export type Teacher = {
  __typename?: 'Teacher';
  About?: Maybe<Scalars['String']>;
  Academy?: Maybe<Academy>;
  AcademyName?: Maybe<Scalars['String']>;
  AreaName?: Maybe<Scalars['String']>;
  Assistants?: Maybe<Array<Maybe<Assistant>>>;
  AvailableCallHours?: Maybe<Array<Maybe<TeacherAvailableHours>>>;
  AvailableVisitHours?: Maybe<Array<Maybe<TeacherAvailableHours>>>;
  Bio?: Maybe<Scalars['String']>;
  CityName?: Maybe<Scalars['String']>;
  CountryName?: Maybe<Scalars['String']>;
  CourseGroups?: Maybe<Array<Maybe<CourseGroup>>>;
  Email?: Maybe<Scalars['String']>;
  Gender?: Maybe<Gender>;
  GenderName?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  ImagePath?: Maybe<Scalars['String']>;
  MobileNumber?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  NumberOfCourses?: Maybe<Scalars['Int']>;
  NumberOfFollowers?: Maybe<Scalars['Int']>;
  RankScore?: Maybe<Scalars['Float']>;
  Removed?: Maybe<Scalars['Boolean']>;
  SchoolYears?: Maybe<Array<Maybe<SchoolYear>>>;
  Slogan?: Maybe<Scalars['String']>;
  Status?: Maybe<ResourceStatus>;
  Subjects?: Maybe<Array<Maybe<Subject>>>;
  TeacherFeatures?: Maybe<Array<Maybe<TeacherFeature>>>;
  TeacherNotes?: Maybe<Array<Maybe<Note>>>;
  UserId?: Maybe<Scalars['Int']>;
  VideoPath?: Maybe<Scalars['String']>;
  VideoPicturePath?: Maybe<Scalars['String']>;
};

export type TeacherFeature = {
  __typename?: 'TeacherFeature';
  FeatureId?: Maybe<Scalars['Int']>;
  Id?: Maybe<Scalars['Int']>;
  IsSelected: Scalars['Boolean'];
  Name?: Maybe<Scalars['String']>;
  TeacherId?: Maybe<Scalars['Int']>;
};

export type TeacherAvailableHours = {
  __typename?: 'TeacherAvailableHours';
  AvailabilityType?: Maybe<AvailabilityType>;
  AvailablityStatus?: Maybe<AvailablityStatus>;
  Day?: Maybe<Day>;
  DayName?: Maybe<Scalars['String']>;
  From?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  TeacherId?: Maybe<Scalars['Int']>;
  To?: Maybe<Scalars['String']>;
};

export enum AvailabilityType {
  CALLS = 'CALLS',
  VISITS = 'VISITS'
}

export enum AvailablityStatus {
  AVILABLE = 'AVILABLE',
  NOT_AVILABLE = 'NOT_AVILABLE'
}

export enum Day {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY'
}

export type Academy = {
  __typename?: 'Academy';
  Address?: Maybe<Scalars['String']>;
  AddressAr?: Maybe<Scalars['String']>;
  AddressEn?: Maybe<Scalars['String']>;
  Area?: Maybe<Area>;
  City?: Maybe<City>;
  Code?: Maybe<Scalars['String']>;
  Country?: Maybe<Country>;
  Id?: Maybe<Scalars['Int']>;
  ManagerEmail?: Maybe<Scalars['String']>;
  ManagerFirstName?: Maybe<Scalars['String']>;
  ManagerLastName?: Maybe<Scalars['String']>;
  ManagerMobileNumber?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  NameAr?: Maybe<Scalars['String']>;
  NameEn?: Maybe<Scalars['String']>;
  Owner?: Maybe<Teacher>;
  Status?: Maybe<ResourceStatus>;
  TemplateId?: Maybe<Scalars['Int']>;
};

export type UserActivityCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  StudentId?: Maybe<Scalars['Int']>;
  ActivityId?: Maybe<Scalars['Int']>;
  ActivityType?: Maybe<ActivityType>;
  FilterMark?: Maybe<FilterMark>;
  FilterDeliver?: Maybe<FilterDeliver>;
  IsView?: Maybe<Scalars['Boolean']>;
  TeacherId?: Maybe<Scalars['Int']>;
  IsMarked?: Maybe<Scalars['Boolean']>;
  CourseGroupId?: Maybe<Scalars['Int']>;
};

export enum FilterMark {
  NOT_FILTER = 'NOT_FILTER',
  HIGH_MARK = 'HIGH_MARK',
  LOW_MARK = 'LOW_MARK'
}

export enum FilterDeliver {
  NOT_FILTER = 'NOT_FILTER',
  DELIVER_EARLY = 'DELIVER_EARLY',
  DELIVER_LATE = 'DELIVER_LATE'
}

export type ActivityQuestion = {
  __typename?: 'ActivityQuestion';
  Activity?: Maybe<Activity>;
  ActivityId?: Maybe<Scalars['Int']>;
  Answer?: Maybe<Scalars['String']>;
  AnswerPath?: Maybe<Scalars['String']>;
  AnswerPathFileType?: Maybe<FileType>;
  ChoiceFour?: Maybe<Scalars['String']>;
  ChoiceFourCount?: Maybe<Scalars['Int']>;
  ChoiceOne?: Maybe<Scalars['String']>;
  ChoiceOneCount?: Maybe<Scalars['Int']>;
  ChoiceThree?: Maybe<Scalars['String']>;
  ChoiceThreeCount?: Maybe<Scalars['Int']>;
  ChoiceTwo?: Maybe<Scalars['String']>;
  ChoiceTwoCount?: Maybe<Scalars['Int']>;
  Description?: Maybe<Scalars['String']>;
  Grade?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['Int']>;
  Level?: Maybe<QuestionLevel>;
  QuestionAnswerAttachments?: Maybe<Array<Maybe<Attachment>>>;
  QuestionAttachments?: Maybe<Array<Maybe<Attachment>>>;
  QuestionLevelName?: Maybe<Scalars['String']>;
  QuestionPath?: Maybe<Scalars['String']>;
  QuestionPathFileType?: Maybe<FileType>;
  QuestionText?: Maybe<Scalars['String']>;
  QuestionType?: Maybe<QuestionType>;
  QuestionTypeName?: Maybe<Scalars['String']>;
  StudentAnswer?: Maybe<StudentActivityQuestionAnswer>;
  StudentAnswersCount?: Maybe<Scalars['Int']>;
};


export type ActivityQuestionStudentAnswerArgs = {
  criteria?: Maybe<StudentActivityQuestionAnswersCriteria>;
};

export enum QuestionLevel {
  HARD = 'HARD',
  MEDIUM = 'MEDIUM',
  EASY = 'EASY'
}

export enum QuestionType {
  MCQ = 'MCQ',
  TEXT = 'TEXT'
}

export type StudentActivityQuestionAnswer = {
  __typename?: 'StudentActivityQuestionAnswer';
  ActivityQuestion?: Maybe<ActivityQuestion>;
  ActivityQuestionId?: Maybe<Scalars['Int']>;
  Answer?: Maybe<Scalars['String']>;
  AnswerComment?: Maybe<Scalars['String']>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  Grade?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['Int']>;
  IsAnswer?: Maybe<Scalars['Boolean']>;
  IsCorrectAnswer?: Maybe<Scalars['Boolean']>;
  IsMarked?: Maybe<Scalars['Boolean']>;
  IsPassed?: Maybe<Scalars['Boolean']>;
  Student?: Maybe<Student>;
  StudentId?: Maybe<Scalars['Int']>;
  StudentName?: Maybe<Scalars['String']>;
};

export type Student = {
  __typename?: 'Student';
  Academy?: Maybe<Academy>;
  AcademyName?: Maybe<Scalars['String']>;
  AreaName?: Maybe<Scalars['String']>;
  CityName?: Maybe<Scalars['String']>;
  Country?: Maybe<Country>;
  CountryName?: Maybe<Scalars['String']>;
  CourseGroups?: Maybe<Array<Maybe<CourseGroup>>>;
  EducationTypeName?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Gender?: Maybe<Gender>;
  GenderName?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  ImagePath?: Maybe<Scalars['String']>;
  IsSelected?: Maybe<Scalars['Boolean']>;
  MobileNumber?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Removed?: Maybe<Scalars['Boolean']>;
  SchoolYearName?: Maybe<Scalars['String']>;
  UserId?: Maybe<Scalars['Int']>;
};

export type StudentActivityQuestionAnswersCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  StudentId?: Maybe<Scalars['Int']>;
  IsMarked?: Maybe<Scalars['Boolean']>;
  CourseGroupId?: Maybe<Scalars['Int']>;
};

export enum FileType {
  FILE = 'FILE',
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE'
}

export type Attachment = {
  __typename?: 'Attachment';
  ActivityId?: Maybe<Scalars['Int']>;
  AttachmentName?: Maybe<Scalars['String']>;
  AttachmentPath?: Maybe<Scalars['String']>;
  FileType?: Maybe<FileType>;
  Id?: Maybe<Scalars['Int']>;
  IsAnswer?: Maybe<Scalars['Boolean']>;
  MainImage?: Maybe<Scalars['Boolean']>;
};

export type ZoomMeetingSettigs = {
  __typename?: 'ZoomMeetingSettigs';
  Agenda?: Maybe<Scalars['String']>;
  AutoRecording?: Maybe<Scalars['Boolean']>;
  Topic?: Maybe<Scalars['String']>;
};

export type Assignment = {
  __typename?: 'Assignment';
  ActivityDuration?: Maybe<Scalars['Seconds']>;
  ActivityQuestions?: Maybe<Array<Maybe<ActivityQuestion>>>;
  AddedDate?: Maybe<Scalars['String']>;
  Attatchments?: Maybe<Array<Maybe<Attachment>>>;
  CanResubmite?: Maybe<Scalars['Boolean']>;
  CanSeeAnswer?: Maybe<Scalars['Boolean']>;
  Content?: Maybe<Scalars['String']>;
  CourseGroup?: Maybe<CourseGroup>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  CourseGroupName?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['Date']>;
  DeadLine?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  EndTime?: Maybe<Scalars['String']>;
  Grade?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['Int']>;
  IsDraft?: Maybe<Scalars['Boolean']>;
  IsFinished?: Maybe<Scalars['Boolean']>;
  IsMarked?: Maybe<Scalars['Boolean']>;
  IsMarkedUserActivityCount?: Maybe<Scalars['Int']>;
  IsOnline?: Maybe<Scalars['Boolean']>;
  IsScheduled?: Maybe<Scalars['Boolean']>;
  Name?: Maybe<Scalars['String']>;
  SchoolYearName?: Maybe<Scalars['String']>;
  ScientificSubjectName?: Maybe<Scalars['String']>;
  ServerDateTime?: Maybe<Scalars['String']>;
  Session?: Maybe<Session>;
  SessionId?: Maybe<Scalars['Int']>;
  SessionName?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  StartTime?: Maybe<Scalars['String']>;
  StudentCount?: Maybe<Scalars['Int']>;
  UserActivities?: Maybe<Array<Maybe<StudentActivity>>>;
  UserActivityCount?: Maybe<Scalars['Int']>;
};


export type AssignmentUserActivitiesArgs = {
  criteria?: Maybe<UserActivityCriteria>;
};

export type SessionAttendance = {
  __typename?: 'SessionAttendance';
  AlternativeSessionId?: Maybe<Scalars['Int']>;
  AlternativeSessionName?: Maybe<Scalars['String']>;
  CourseGroupName?: Maybe<Scalars['String']>;
  Day?: Maybe<Scalars['String']>;
  EndTime?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  IsAttended?: Maybe<Scalars['Boolean']>;
  SchoolYearName?: Maybe<Scalars['String']>;
  ScientificSubjectName?: Maybe<Scalars['String']>;
  Session?: Maybe<Session>;
  SessionDate?: Maybe<Scalars['Date']>;
  SessionId?: Maybe<Scalars['Int']>;
  SessionName?: Maybe<Scalars['String']>;
  StartTime?: Maybe<Scalars['String']>;
  StudentAttendanceCode?: Maybe<Scalars['String']>;
  StudentId?: Maybe<Scalars['Int']>;
  StudentImage?: Maybe<Scalars['String']>;
  StudentName?: Maybe<Scalars['String']>;
  StudentQRCode?: Maybe<Scalars['String']>;
};

export enum AttendanceMethod {
  NOT_DETERMINED = 'NOT_DETERMINED',
  QR_CODE_PATH = 'QR_CODE_PATH',
  PATH_CODE = 'PATH_CODE'
}

export enum SessionType {
  NORMAL = 'NORMAL',
  EXTRA = 'EXTRA'
}

export enum SessionAttendType {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE'
}

export enum SessionStatus {
  CREATED = 'CREATED',
  STARTED = 'STARTED',
  FINISHED = 'FINISHED'
}

export type ActivityCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  ActivityType?: Maybe<ActivityType>;
  ActivitySource?: Maybe<ActivitySource>;
  DateFrom?: Maybe<Scalars['Date']>;
  IsFinished?: Maybe<Scalars['Boolean']>;
  SessionId?: Maybe<Scalars['Int']>;
  ScientificSubjectIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  DateTo?: Maybe<Scalars['Date']>;
  TeacherId?: Maybe<Scalars['Int']>;
  SessionIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  ActivityTypes?: Maybe<Array<Maybe<ActivityType>>>;
  StudentId?: Maybe<Scalars['Int']>;
  WinnerId?: Maybe<Scalars['Int']>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  ScientificSubjectId?: Maybe<Scalars['Int']>;
  Date?: Maybe<Scalars['Date']>;
  CourseId?: Maybe<Scalars['Int']>;
  SchoolYearId?: Maybe<Scalars['Int']>;
  SubjectId?: Maybe<Scalars['Int']>;
  ActivityQuestionId?: Maybe<Scalars['Int']>;
  QuestionLevel?: Maybe<QuestionLevel>;
  IsDraft?: Maybe<Scalars['Boolean']>;
  StudentIdCouserGroup?: Maybe<Scalars['Int']>;
  SessionContentId?: Maybe<Scalars['Int']>;
};

export type SessionQuestion = {
  __typename?: 'SessionQuestion';
  Answer?: Maybe<Scalars['String']>;
  AnswerAttachments?: Maybe<Array<Maybe<Attachment>>>;
  AttachmentPath?: Maybe<Scalars['String']>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  Id?: Maybe<Scalars['Int']>;
  IsVerified?: Maybe<Scalars['Boolean']>;
  Question?: Maybe<Scalars['String']>;
  QuestionAttachments?: Maybe<Array<Maybe<Attachment>>>;
  Session?: Maybe<Session>;
  SessionId?: Maybe<Scalars['Int']>;
  Student?: Maybe<Student>;
  StudentId?: Maybe<Scalars['Int']>;
  StudentName?: Maybe<Scalars['String']>;
};

export type ZoomMeeting = {
  __typename?: 'ZoomMeeting';
  EndTime?: Maybe<Scalars['Date']>;
  StartTime?: Maybe<Scalars['Date']>;
};

export type GroupedSessionGroup = {
  __typename?: 'GroupedSessionGroup';
  CourseGroup?: Maybe<CourseGroup>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  CourseGroupName?: Maybe<Scalars['String']>;
  SessionId?: Maybe<Scalars['Int']>;
};

export type SessionVideo = {
  __typename?: 'SessionVideo';
  Id?: Maybe<Scalars['Int']>;
  VideoPath?: Maybe<Scalars['String']>;
  VideoPathId?: Maybe<Scalars['String']>;
  VideoPicturePath?: Maybe<Scalars['String']>;
};

export type CourseGroupHours = {
  __typename?: 'CourseGroupHours';
  Day?: Maybe<Day>;
  EndTime?: Maybe<Scalars['String']>;
  StartTime?: Maybe<Scalars['String']>;
};

export type Event = {
  __typename?: 'Event';
  Activity?: Maybe<Activity>;
  CourseGroup?: Maybe<CourseGroup>;
  CourseGroupName?: Maybe<Scalars['String']>;
  CourseName?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['Date']>;
  DeadLine?: Maybe<Scalars['Date']>;
  EventType?: Maybe<EventType>;
  EventTypeName?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  IsSelected?: Maybe<Scalars['Boolean']>;
  Name?: Maybe<Scalars['String']>;
  ResourceType?: Maybe<ApplicationUserType>;
  SchoolYearName?: Maybe<Scalars['String']>;
  ScientificSubjectName?: Maybe<Scalars['String']>;
  ServerDateTime?: Maybe<Scalars['String']>;
  Session?: Maybe<Session>;
  StartDate?: Maybe<Scalars['Date']>;
  Teacher?: Maybe<Teacher>;
  TeacherName?: Maybe<Scalars['String']>;
  TimeFrom?: Maybe<Scalars['String']>;
  TimeTo?: Maybe<Scalars['String']>;
};

export enum EventType {
  REVISION = 'REVISION',
  QUIZ = 'QUIZ',
  CHALLENGE = 'CHALLENGE',
  SURVEY = 'SURVEY',
  POST = 'POST',
  ASSIGNMENT = 'ASSIGNMENT',
  SESSION = 'SESSION'
}

export type EventCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  EventType?: Maybe<EventType>;
  StudentIdCouserGroup?: Maybe<Scalars['Int']>;
  TeacherId?: Maybe<Scalars['Int']>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  ResourceType?: Maybe<ApplicationUserType>;
  CourseGroup?: Maybe<CourseGroupCriteria>;
  Date?: Maybe<Scalars['Date']>;
  IsUpComing?: Maybe<Scalars['Boolean']>;
};

export type CourseGroupCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  CourseId?: Maybe<Scalars['Int']>;
  TeacherId?: Maybe<Scalars['Int']>;
  AssistantId?: Maybe<Scalars['Int']>;
  SchoolYearIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  SchoolYearId?: Maybe<Scalars['Int']>;
  SubjectId?: Maybe<Scalars['Int']>;
  StudentStatus?: Maybe<CourseGroupStudentStatus>;
  MainAssistantId?: Maybe<Scalars['Int']>;
  Events?: Maybe<EventCriteria>;
  ScientificSubjectId?: Maybe<Scalars['Int']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  RoleId?: Maybe<Scalars['Int']>;
};

export enum CourseGroupStudentStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  SUSPEND = 'SUSPEND',
  BLOCK = 'BLOCK',
  REQUESTED = 'REQUESTED',
  TEACHET_NOT_PAID = 'TEACHET_NOT_PAID'
}

export type Course = {
  __typename?: 'Course';
  ActualNumOfCourseGroups?: Maybe<Scalars['Int']>;
  ActualNumOfStudents?: Maybe<Scalars['Int']>;
  CourseGroups?: Maybe<Array<Maybe<CourseGroup>>>;
  Id?: Maybe<Scalars['Int']>;
  ImagePath?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  IsSelected?: Maybe<Scalars['Boolean']>;
  NumOfCourseGroups?: Maybe<Scalars['Int']>;
  NumOfStudents?: Maybe<Scalars['Int']>;
  SchoolYearName?: Maybe<Scalars['String']>;
  ScientificSubjectName?: Maybe<Scalars['String']>;
  Subject?: Maybe<Subject>;
  SubjectId?: Maybe<Scalars['Int']>;
  Teacher?: Maybe<Teacher>;
  TeacherId?: Maybe<Scalars['Int']>;
};

export type CourseGroupAssistant = {
  __typename?: 'CourseGroupAssistant';
  Assistant?: Maybe<Assistant>;
  AssistantId?: Maybe<Scalars['Int']>;
  AssistantName?: Maybe<Scalars['String']>;
  AssistantRoles?: Maybe<Array<Maybe<AssistantRoles>>>;
  AssistantStatus?: Maybe<CourseGroupAssistantStatus>;
  AssistantStatusName?: Maybe<Scalars['String']>;
  CourseGroup?: Maybe<CourseGroup>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  CourseGroupName?: Maybe<Scalars['String']>;
  IsMainAssistant?: Maybe<Scalars['Boolean']>;
  SchoolYearName?: Maybe<Scalars['String']>;
  ScientificSubjectName?: Maybe<Scalars['String']>;
};


export type CourseGroupAssistantAssistantRolesArgs = {
  criteria?: Maybe<AssistantRoleCriteria>;
};

export enum CourseGroupAssistantStatus {
  ACTIVE = 'ACTIVE',
  SUSPEND = 'SUSPEND',
  BLOCK = 'BLOCK',
  DELETED = 'DELETED'
}

export type AssistantRoleCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  TeacherId?: Maybe<Scalars['Int']>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  AssistantId?: Maybe<Scalars['Int']>;
  RoleId?: Maybe<Scalars['Int']>;
  IsCourseGroup?: Maybe<Scalars['Boolean']>;
};

export type CourseGroupStudents = {
  __typename?: 'CourseGroupStudents';
  ActivationDate?: Maybe<Scalars['Date']>;
  AddedDate?: Maybe<Scalars['Date']>;
  AllTasks?: Maybe<Scalars['Int']>;
  AssignLicense?: Maybe<Scalars['Boolean']>;
  Comment?: Maybe<Scalars['String']>;
  CompletedTasksByStudent?: Maybe<Scalars['Int']>;
  CourseGroup?: Maybe<CourseGroup>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  CourseGroupName?: Maybe<Scalars['String']>;
  CourseId?: Maybe<Scalars['Int']>;
  Id?: Maybe<Scalars['Int']>;
  ImagePath?: Maybe<Scalars['String']>;
  IsSelected?: Maybe<Scalars['Boolean']>;
  IsSubscribed?: Maybe<Scalars['Boolean']>;
  IsTeacherPaid?: Maybe<Scalars['Boolean']>;
  Parent?: Maybe<Parent>;
  ParentId?: Maybe<Scalars['Int']>;
  Rate?: Maybe<Scalars['Float']>;
  Report?: Maybe<Scalars['String']>;
  SchoolYearName?: Maybe<Scalars['String']>;
  ScientificSubjectName?: Maybe<Scalars['String']>;
  Student?: Maybe<Student>;
  StudentId?: Maybe<Scalars['Int']>;
  StudentMobileNumber?: Maybe<Scalars['String']>;
  StudentName?: Maybe<Scalars['String']>;
  StudentStatus?: Maybe<CourseGroupStudentStatus>;
  StudentStatusName?: Maybe<Scalars['String']>;
  SuspensionDate?: Maybe<Scalars['Date']>;
  TeacherName?: Maybe<Scalars['String']>;
};

export type Parent = {
  __typename?: 'Parent';
  Country?: Maybe<Country>;
  Email?: Maybe<Scalars['String']>;
  Gender?: Maybe<Gender>;
  GenderName?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  ImagePath?: Maybe<Scalars['String']>;
  MobileNumber?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Students?: Maybe<Array<Maybe<Student>>>;
};

export type RequestChangeStatusLog = {
  __typename?: 'RequestChangeStatusLog';
  ActionDate?: Maybe<Scalars['Date']>;
  NewStatus?: Maybe<Scalars['String']>;
  PreviousStatus?: Maybe<Scalars['String']>;
  Request?: Maybe<Request>;
};

export type RequestCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  StudentId?: Maybe<Scalars['Int']>;
  ParentId?: Maybe<Scalars['Int']>;
  TeacherId?: Maybe<Scalars['Int']>;
  RequestType?: Maybe<RequestType>;
  RequestStatus?: Maybe<RequestStatus>;
  RequestDirection?: Maybe<RequestDirection>;
  RequestFrom?: Maybe<RequestSender>;
  SendFrom?: Maybe<ApplicationUserType>;
  SendTo?: Maybe<ApplicationUserType>;
  CourseGroupId?: Maybe<Scalars['Int']>;
};

export enum RequestDirection {
  SEND = 'SEND',
  RECIEVED = 'RECIEVED',
  ALL = 'ALL'
}

export enum RequestSender {
  STUDENT = 'STUDENT',
  PARENT = 'PARENT',
  ASSISTANT = 'ASSISTANT',
  TEACHER = 'TEACHER'
}

export type CourseQuery = {
  __typename?: 'CourseQuery';
  Courses?: Maybe<CourseTypeListResponse>;
  StudentCourses?: Maybe<StudentCourseTypeListResponse>;
};


export type CourseQueryCoursesArgs = {
  criteria?: Maybe<CourseCriteria>;
};


export type CourseQueryStudentCoursesArgs = {
  criteria?: Maybe<CourseCriteria>;
};

export type CourseTypeListResponse = {
  __typename?: 'CourseTypeListResponse';
  Result?: Maybe<Array<Maybe<Course>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type CourseCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  TeacherId?: Maybe<Scalars['Int']>;
  SubjectId?: Maybe<Scalars['Int']>;
  SessionId?: Maybe<Scalars['Int']>;
  StudentId?: Maybe<Scalars['Int']>;
  AssistantId?: Maybe<Scalars['Int']>;
};

export type StudentCourseTypeListResponse = {
  __typename?: 'StudentCourseTypeListResponse';
  Result?: Maybe<Array<Maybe<StudentCourse>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type StudentCourse = {
  __typename?: 'StudentCourse';
  Id?: Maybe<Scalars['Int']>;
  ImagePath?: Maybe<Scalars['String']>;
  SchoolYearName?: Maybe<Scalars['String']>;
  ScientificSubjectName?: Maybe<Scalars['String']>;
  SubjectId?: Maybe<Scalars['Int']>;
  TeacherImagePath?: Maybe<Scalars['String']>;
  TeacherName?: Maybe<Scalars['String']>;
};

export type TeacherQuery = {
  __typename?: 'TeacherQuery';
  PreviousExams?: Maybe<PreviousExamTypeListResponse>;
  QuestionBank?: Maybe<TeacherQuestionTypeListResponse>;
  QuestionTags?: Maybe<StringGraphTypeListResponse>;
  Teachers?: Maybe<TeacherTypeListResponse>;
  TeacherStatistics?: Maybe<TeacherStatisticsTypeResponse>;
  TeacherSubjects?: Maybe<TeacherSubjectTypeListResponse>;
};


export type TeacherQueryPreviousExamsArgs = {
  criteria?: Maybe<PreviousExamCriteria>;
};


export type TeacherQueryQuestionBankArgs = {
  criteria?: Maybe<TeacherQuestionCriteria>;
};


export type TeacherQueryTeachersArgs = {
  criteria?: Maybe<TeacherCriteria>;
};


export type TeacherQueryTeacherSubjectsArgs = {
  criteria?: Maybe<TeacherSubjectCriteria>;
};

export type TeacherTypeListResponse = {
  __typename?: 'TeacherTypeListResponse';
  Result?: Maybe<Array<Maybe<Teacher>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type TeacherCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  ScientificSubjectId?: Maybe<Scalars['Int']>;
  SchoolYearId?: Maybe<Scalars['Int']>;
  CountryId?: Maybe<Scalars['Int']>;
  CityId?: Maybe<Scalars['Int']>;
  ScientificSubjectIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  SchoolYearIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  AreaId?: Maybe<Scalars['Int']>;
  StudentId?: Maybe<Scalars['Int']>;
  AcademyId?: Maybe<Scalars['Int']>;
};

export type TeacherQuestionTypeListResponse = {
  __typename?: 'TeacherQuestionTypeListResponse';
  Result?: Maybe<Array<Maybe<TeacherQuestion>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type TeacherQuestion = {
  __typename?: 'TeacherQuestion';
  AddedDate?: Maybe<Scalars['Date']>;
  Answer?: Maybe<Scalars['String']>;
  AnswerAttachments?: Maybe<Array<Maybe<Attachment>>>;
  ChoiceFour?: Maybe<Scalars['String']>;
  ChoiceOne?: Maybe<Scalars['String']>;
  ChoiceThree?: Maybe<Scalars['String']>;
  ChoiceTwo?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  Level?: Maybe<QuestionLevel>;
  QuestionAttachments?: Maybe<Array<Maybe<Attachment>>>;
  QuestionLevelName?: Maybe<Scalars['String']>;
  QuestionText?: Maybe<Scalars['String']>;
  QuestionType?: Maybe<QuestionType>;
  QuestionTypeName?: Maybe<Scalars['String']>;
  SchoolYearName?: Maybe<Scalars['String']>;
  ScientificSubjectName?: Maybe<Scalars['String']>;
  Subject?: Maybe<Subject>;
  SubjectId?: Maybe<Scalars['Int']>;
  SubjectName?: Maybe<Scalars['String']>;
  Tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  Teacher?: Maybe<Teacher>;
  TeacherId?: Maybe<Scalars['Int']>;
};

export type TeacherQuestionCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  TeacherId?: Maybe<Scalars['Int']>;
  SchoolYearIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  QuestionLevels?: Maybe<Array<Maybe<QuestionLevel>>>;
  ScientificSubjectIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  SubjectId?: Maybe<Scalars['Int']>;
  SchoolYearId?: Maybe<Scalars['Int']>;
  ScientificSubjectId?: Maybe<Scalars['Int']>;
  Tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  QuestionType?: Maybe<QuestionType>;
  QuestionLevel?: Maybe<QuestionLevel>;
};

export type StringGraphTypeListResponse = {
  __typename?: 'StringGraphTypeListResponse';
  Result?: Maybe<Array<Maybe<Scalars['String']>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type TeacherSubjectTypeListResponse = {
  __typename?: 'TeacherSubjectTypeListResponse';
  Result?: Maybe<Array<Maybe<TeacherSubject>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type TeacherSubject = {
  __typename?: 'TeacherSubject';
  SchoolYearName?: Maybe<Scalars['String']>;
  ScientificSubjectName?: Maybe<Scalars['String']>;
  Subject?: Maybe<Subject>;
  SubjectId?: Maybe<Scalars['Int']>;
  Teacher?: Maybe<Teacher>;
  TeacherId?: Maybe<Scalars['Int']>;
};

export type TeacherSubjectCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  SubjectId?: Maybe<Scalars['Int']>;
  TeacherId?: Maybe<Scalars['Int']>;
};

export type PreviousExamTypeListResponse = {
  __typename?: 'PreviousExamTypeListResponse';
  Result?: Maybe<Array<Maybe<PreviousExam>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type PreviousExam = {
  __typename?: 'PreviousExam';
  Added?: Maybe<Scalars['Date']>;
  AssistantId?: Maybe<Scalars['Int']>;
  AttachmentPath?: Maybe<Scalars['String']>;
  Attachments?: Maybe<Array<Maybe<Attachment>>>;
  CourseId?: Maybe<Scalars['Int']>;
  ExamYear?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  SchoolYearName?: Maybe<Scalars['String']>;
  ScientificSubjectName?: Maybe<Scalars['String']>;
  TeacherId?: Maybe<Scalars['Int']>;
  Title?: Maybe<Scalars['String']>;
};

export type PreviousExamCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  CourseIdS?: Maybe<Array<Maybe<Scalars['Int']>>>;
  TeacherId?: Maybe<Scalars['Int']>;
  StudentId?: Maybe<Scalars['Int']>;
};

export type TeacherStatisticsTypeResponse = {
  __typename?: 'TeacherStatisticsTypeResponse';
  Result?: Maybe<TeacherStatisticsOutput>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type TeacherStatisticsOutput = {
  __typename?: 'TeacherStatisticsOutput';
  NumOfAssistants?: Maybe<Scalars['Int']>;
  NumOfParents?: Maybe<Scalars['Int']>;
  NumOfStudents?: Maybe<Scalars['Int']>;
  NumOfSubjects?: Maybe<Scalars['Int']>;
};

export type StudentQuery = {
  __typename?: 'StudentQuery';
  CheckTeacherFollower?: Maybe<CheckTeacherFollowerTypeResponse>;
  SearchStudent?: Maybe<StudentTypeListResponse>;
  StudentParents?: Maybe<StudentParentTypeListResponse>;
  Students?: Maybe<StudentTypeListResponse>;
};


export type StudentQueryCheckTeacherFollowerArgs = {
  criteria?: Maybe<TeacherFollowerCriteria>;
};


export type StudentQuerySearchStudentArgs = {
  criteria?: Maybe<StudentCriteria>;
};


export type StudentQueryStudentParentsArgs = {
  criteria?: Maybe<StudentParentCriteria>;
};


export type StudentQueryStudentsArgs = {
  criteria?: Maybe<StudentCriteria>;
};

export type StudentTypeListResponse = {
  __typename?: 'StudentTypeListResponse';
  Result?: Maybe<Array<Maybe<Student>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type StudentCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  TeacherId?: Maybe<Scalars['Int']>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  ParentId?: Maybe<Scalars['Int']>;
  CountryId?: Maybe<Scalars['Int']>;
  CityId?: Maybe<Scalars['Int']>;
  AreaId?: Maybe<Scalars['Int']>;
  IsForTeacher?: Maybe<Scalars['Boolean']>;
  AcademyId?: Maybe<Scalars['Int']>;
};

export type StudentParentTypeListResponse = {
  __typename?: 'StudentParentTypeListResponse';
  Result?: Maybe<Array<Maybe<StudentParent>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type StudentParent = {
  __typename?: 'StudentParent';
  EducationTypeName?: Maybe<Scalars['String']>;
  ParentEmail?: Maybe<Scalars['String']>;
  ParentGender?: Maybe<Gender>;
  ParentGenderName?: Maybe<Scalars['String']>;
  ParentId?: Maybe<Scalars['Int']>;
  ParentImage?: Maybe<Scalars['String']>;
  ParentName?: Maybe<Scalars['String']>;
  ParentNumber?: Maybe<Scalars['String']>;
  SchoolYearName?: Maybe<Scalars['String']>;
  StudentGenderName?: Maybe<Scalars['String']>;
  StudentId?: Maybe<Scalars['Int']>;
  StudentImage?: Maybe<Scalars['String']>;
  StudentMobileNumber?: Maybe<Scalars['String']>;
  StudentName?: Maybe<Scalars['String']>;
};

export type StudentParentCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  StudentId?: Maybe<Scalars['Int']>;
  ParentId?: Maybe<Scalars['Int']>;
};

export type CheckTeacherFollowerTypeResponse = {
  __typename?: 'CheckTeacherFollowerTypeResponse';
  Result?: Maybe<CheckTeacherFollowerOutput>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type CheckTeacherFollowerOutput = {
  __typename?: 'CheckTeacherFollowerOutput';
  IsFollow: Scalars['Boolean'];
};

export type TeacherFollowerCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  StudentId?: Maybe<Scalars['Int']>;
  ParentId?: Maybe<Scalars['Int']>;
  TeacherId?: Maybe<Scalars['Int']>;
};

export type AssistantQuery = {
  __typename?: 'AssistantQuery';
  AssistantCourseGroups?: Maybe<CourseGroupAssistantTypeListResponse>;
  AssistantRoles?: Maybe<AssistantRoleTypeListResponse>;
  Assistants?: Maybe<AssistantTypeListResponse>;
  EnsureAssistantRoles?: Maybe<EnsureAssistantRolesTypeResponse>;
  SearchAssistants?: Maybe<AssistantTypeListResponse>;
};


export type AssistantQueryAssistantCourseGroupsArgs = {
  criteria?: Maybe<CourseGroupCriteria>;
};


export type AssistantQueryAssistantRolesArgs = {
  criteria?: Maybe<AssistantRoleCriteria>;
};


export type AssistantQueryAssistantsArgs = {
  criteria?: Maybe<AssistantCriteria>;
};


export type AssistantQuerySearchAssistantsArgs = {
  criteria?: Maybe<AssistantCriteria>;
};

export type AssistantTypeListResponse = {
  __typename?: 'AssistantTypeListResponse';
  Result?: Maybe<Array<Maybe<Assistant>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type AssistantCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  TeacherAssistant?: Maybe<TeacherAssistantCriteria>;
  TeacherId?: Maybe<Scalars['Int']>;
  CourseGroupId?: Maybe<Scalars['Int']>;
};

export type TeacherAssistantCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  TeacherId?: Maybe<Scalars['Int']>;
  AssistantId?: Maybe<Scalars['Int']>;
};

export type AssistantRoleTypeListResponse = {
  __typename?: 'AssistantRoleTypeListResponse';
  Result?: Maybe<Array<Maybe<AssistantRoles>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type EnsureAssistantRolesTypeResponse = {
  __typename?: 'EnsureAssistantRolesTypeResponse';
  Result?: Maybe<EnsureAssistantRoles>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type EnsureAssistantRoles = {
  __typename?: 'EnsureAssistantRoles';
  AddAssignment?: Maybe<Scalars['Boolean']>;
  AddChallenge?: Maybe<Scalars['Boolean']>;
  AddPost?: Maybe<Scalars['Boolean']>;
  AddQuiz?: Maybe<Scalars['Boolean']>;
  AddRevision?: Maybe<Scalars['Boolean']>;
  AddSurvey?: Maybe<Scalars['Boolean']>;
  AllowSearchTeacher?: Maybe<Scalars['Boolean']>;
  AssignLicence?: Maybe<Scalars['Boolean']>;
  RoleIdAddAssignment?: Maybe<Scalars['Int']>;
  RoleIdAddChallenge?: Maybe<Scalars['Int']>;
  RoleIdAddPost?: Maybe<Scalars['Int']>;
  RoleIdAddQuiz?: Maybe<Scalars['Int']>;
  RoleIdAddRevision?: Maybe<Scalars['Int']>;
  RoleIdAddSurvey?: Maybe<Scalars['Int']>;
  RoleIdAssignLicence?: Maybe<Scalars['Int']>;
  RoleIdViewParentRequests?: Maybe<Scalars['Int']>;
  RoleIdViewPreviousExam?: Maybe<Scalars['Int']>;
  RoleIdViewStudentRequests?: Maybe<Scalars['Int']>;
  RoleIdViewSurvey?: Maybe<Scalars['Int']>;
  ViewParentRequests?: Maybe<Scalars['Boolean']>;
  ViewPreviousExam?: Maybe<Scalars['Boolean']>;
  ViewStudentRequests?: Maybe<Scalars['Boolean']>;
  ViewSurvey?: Maybe<Scalars['Boolean']>;
};

export type CourseGroupAssistantTypeListResponse = {
  __typename?: 'CourseGroupAssistantTypeListResponse';
  Result?: Maybe<Array<Maybe<CourseGroupAssistant>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type CourseGroupQuery = {
  __typename?: 'CourseGroupQuery';
  CourseGroupAssistants?: Maybe<CourseGroupAssistantTypeListResponse>;
  CourseGroups?: Maybe<CourseGroupTypeListResponse>;
  CourseGroupStudents?: Maybe<CourseGroupStudentsTypeListResponse>;
  QuestionBank?: Maybe<CourseQuestionTypeListResponse>;
};


export type CourseGroupQueryCourseGroupAssistantsArgs = {
  criteria?: Maybe<CourseGroupAssistantCriteria>;
};


export type CourseGroupQueryCourseGroupsArgs = {
  criteria?: Maybe<CourseGroupCriteria>;
};


export type CourseGroupQueryCourseGroupStudentsArgs = {
  criteria?: Maybe<CourseGroupStudentCriteria>;
};


export type CourseGroupQueryQuestionBankArgs = {
  criteria?: Maybe<CourseQuestionCriteria>;
};

export type CourseGroupTypeListResponse = {
  __typename?: 'CourseGroupTypeListResponse';
  Result?: Maybe<Array<Maybe<CourseGroup>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type CourseGroupStudentsTypeListResponse = {
  __typename?: 'CourseGroupStudentsTypeListResponse';
  Result?: Maybe<Array<Maybe<CourseGroupStudents>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type CourseGroupStudentCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  StudentId?: Maybe<Scalars['Int']>;
  StudentStatus?: Maybe<CourseGroupStudentStatus>;
  TeacherId?: Maybe<Scalars['Int']>;
  CourseId?: Maybe<Scalars['Int']>;
  SubjectId?: Maybe<Scalars['Int']>;
  IsStatusActive?: Maybe<Scalars['Boolean']>;
  AssistantId?: Maybe<Scalars['Int']>;
  IsTeacherPaid?: Maybe<Scalars['Boolean']>;
};

export type CourseGroupAssistantCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  TeacherId?: Maybe<Scalars['Int']>;
  CourseId?: Maybe<Scalars['Int']>;
  AssistantId?: Maybe<Scalars['Int']>;
  IsMainAssistant?: Maybe<Scalars['Boolean']>;
  SubjectId?: Maybe<Scalars['Int']>;
  AssistantStatus?: Maybe<CourseGroupAssistantStatus>;
};

export type CourseQuestionTypeListResponse = {
  __typename?: 'CourseQuestionTypeListResponse';
  Result?: Maybe<Array<Maybe<CourseQuestion>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type CourseQuestion = {
  __typename?: 'CourseQuestion';
  Answer?: Maybe<Scalars['String']>;
  AnswerAttachments?: Maybe<Array<Maybe<Attachment>>>;
  AnswerPath?: Maybe<Scalars['String']>;
  CourseId?: Maybe<Scalars['Int']>;
  Id?: Maybe<Scalars['Int']>;
  IsVerified?: Maybe<Scalars['Boolean']>;
  QuestionAttachments?: Maybe<Array<Maybe<Attachment>>>;
  QuestionPath?: Maybe<Scalars['String']>;
  QuestionText?: Maybe<Scalars['String']>;
  StudentId?: Maybe<Scalars['Int']>;
};

export type CourseQuestionCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  StudentId?: Maybe<Scalars['Int']>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  TeacherId?: Maybe<Scalars['Int']>;
  CourseId?: Maybe<Scalars['Int']>;
};

export type ActiviyQuery = {
  __typename?: 'ActiviyQuery';
  Activities?: Maybe<ActivityTypeListResponse>;
  ActivityQuestions?: Maybe<ActivityQuestionTypeListResponse>;
  Assignments?: Maybe<AssignmentTypeListResponse>;
  Challenges?: Maybe<ChallengeTypeListResponse>;
  Posts?: Maybe<PostTypeListResponse>;
  Quizzes?: Maybe<QuizTypeListResponse>;
  Revisions?: Maybe<RevisionTypeListResponse>;
  Sessions?: Maybe<SessionTypeListResponse>;
  Surveys?: Maybe<SurveyTypeListResponse>;
  UserActivities?: Maybe<UserActivityTypeListResponse>;
};


export type ActiviyQueryActivitiesArgs = {
  criteria?: Maybe<ActivityCriteria>;
};


export type ActiviyQueryActivityQuestionsArgs = {
  criteria?: Maybe<ActivityQuestionCriteria>;
};


export type ActiviyQueryAssignmentsArgs = {
  criteria?: Maybe<ActivityCriteria>;
};


export type ActiviyQueryChallengesArgs = {
  criteria?: Maybe<ActivityCriteria>;
};


export type ActiviyQueryPostsArgs = {
  criteria?: Maybe<ActivityCriteria>;
};


export type ActiviyQueryQuizzesArgs = {
  criteria?: Maybe<ActivityCriteria>;
};


export type ActiviyQueryRevisionsArgs = {
  criteria?: Maybe<ActivityCriteria>;
};


export type ActiviyQuerySessionsArgs = {
  criteria?: Maybe<SessionCriteria>;
};


export type ActiviyQuerySurveysArgs = {
  criteria?: Maybe<ActivityCriteria>;
};


export type ActiviyQueryUserActivitiesArgs = {
  criteria?: Maybe<UserActivityCriteria>;
};

export type ActivityTypeListResponse = {
  __typename?: 'ActivityTypeListResponse';
  Result?: Maybe<Array<Maybe<Activity>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type SurveyTypeListResponse = {
  __typename?: 'SurveyTypeListResponse';
  Result?: Maybe<Array<Maybe<Survey>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type Survey = {
  __typename?: 'Survey';
  ActivityDuration?: Maybe<Scalars['Seconds']>;
  ActivityQuestions?: Maybe<Array<Maybe<ActivityQuestion>>>;
  ActivitySource?: Maybe<ActivitySource>;
  ActivitySourceName?: Maybe<Scalars['String']>;
  ActivityType?: Maybe<ActivityType>;
  AddedDate?: Maybe<Scalars['String']>;
  AttachmentPath?: Maybe<Scalars['String']>;
  CourseGroup?: Maybe<CourseGroup>;
  CourseGroupName?: Maybe<Scalars['String']>;
  Creator?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['Date']>;
  DeadLine?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  Images?: Maybe<Array<Maybe<Attachment>>>;
  IsFinished?: Maybe<Scalars['Boolean']>;
  IsMarked?: Maybe<Scalars['Boolean']>;
  IsOnline?: Maybe<Scalars['Boolean']>;
  Name?: Maybe<Scalars['String']>;
  SchoolYearName?: Maybe<Scalars['String']>;
  ScientificSubjectName?: Maybe<Scalars['String']>;
  ServerDateTime?: Maybe<Scalars['String']>;
  Session?: Maybe<Session>;
  StartDate?: Maybe<Scalars['String']>;
  StudentCount?: Maybe<Scalars['Int']>;
  UserActivities?: Maybe<Array<Maybe<StudentActivity>>>;
  UserActivityCount?: Maybe<Scalars['Int']>;
};


export type SurveyUserActivitiesArgs = {
  criteria?: Maybe<UserActivityCriteria>;
};

export type RevisionTypeListResponse = {
  __typename?: 'RevisionTypeListResponse';
  Result?: Maybe<Array<Maybe<Revision>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type Revision = {
  __typename?: 'Revision';
  AddedDate?: Maybe<Scalars['String']>;
  Attachments?: Maybe<Array<Maybe<Attachment>>>;
  Comments?: Maybe<Array<Maybe<StudentActivity>>>;
  Content?: Maybe<Scalars['String']>;
  CourseGroup?: Maybe<CourseGroup>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  CourseGroupName?: Maybe<Scalars['String']>;
  Creator?: Maybe<Scalars['String']>;
  CreatorImage?: Maybe<Scalars['String']>;
  CurrentTime?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['Date']>;
  DeadLine?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  EndTime?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  IsDraft?: Maybe<Scalars['Boolean']>;
  IsFinished?: Maybe<Scalars['Boolean']>;
  IsOnline?: Maybe<Scalars['Boolean']>;
  IsScheduled?: Maybe<Scalars['Boolean']>;
  Name?: Maybe<Scalars['String']>;
  RevisionQuestions?: Maybe<Array<Maybe<ActivityQuestion>>>;
  SchoolYearName?: Maybe<Scalars['String']>;
  ScientificSubjectName?: Maybe<Scalars['String']>;
  ServerDateTime?: Maybe<Scalars['String']>;
  Session?: Maybe<Session>;
  SessionId?: Maybe<Scalars['Int']>;
  StartDate?: Maybe<Scalars['String']>;
  StartTime?: Maybe<Scalars['String']>;
  StudentCount?: Maybe<Scalars['Int']>;
  UserActivityCount?: Maybe<Scalars['Int']>;
};

export type QuizTypeListResponse = {
  __typename?: 'QuizTypeListResponse';
  Result?: Maybe<Array<Maybe<Quiz>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type Quiz = {
  __typename?: 'Quiz';
  ActivityDuration?: Maybe<Scalars['Seconds']>;
  AddedDate?: Maybe<Scalars['String']>;
  Attachments?: Maybe<Array<Maybe<Attachment>>>;
  CanResubmite?: Maybe<Scalars['Boolean']>;
  CanSeeAnswer?: Maybe<Scalars['Boolean']>;
  Content?: Maybe<Scalars['String']>;
  CourseGroup?: Maybe<CourseGroup>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  CourseGroupName?: Maybe<Scalars['String']>;
  CourseId?: Maybe<Scalars['Int']>;
  Creator?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['Date']>;
  DeadLine?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  EndTime?: Maybe<Scalars['String']>;
  Grade?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['Int']>;
  IsDraft?: Maybe<Scalars['Boolean']>;
  IsMarked?: Maybe<Scalars['Boolean']>;
  IsMarkedUserActivityCount?: Maybe<Scalars['Int']>;
  IsMCQ?: Maybe<Scalars['Boolean']>;
  IsOnline?: Maybe<Scalars['Boolean']>;
  IsScheduled?: Maybe<Scalars['Boolean']>;
  Name?: Maybe<Scalars['String']>;
  QuizQuestions?: Maybe<Array<Maybe<ActivityQuestion>>>;
  SchoolYearName?: Maybe<Scalars['String']>;
  ScientificSubjectName?: Maybe<Scalars['String']>;
  ServerDateTime?: Maybe<Scalars['String']>;
  Session?: Maybe<Session>;
  SessionId?: Maybe<Scalars['Int']>;
  ShuffledQuestions?: Maybe<Scalars['Boolean']>;
  StartDate?: Maybe<Scalars['String']>;
  StartTime?: Maybe<Scalars['String']>;
  StudentCount?: Maybe<Scalars['Int']>;
  UserActivities?: Maybe<Array<Maybe<StudentActivity>>>;
  UserActivityCount?: Maybe<Scalars['Int']>;
};


export type QuizUserActivitiesArgs = {
  criteria?: Maybe<UserActivityCriteria>;
};

export type ChallengeTypeListResponse = {
  __typename?: 'ChallengeTypeListResponse';
  Result?: Maybe<Array<Maybe<Challenge>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type Challenge = {
  __typename?: 'Challenge';
  ActivityDuration?: Maybe<Scalars['Int']>;
  ActivitySource?: Maybe<ActivitySource>;
  ActivitySourceName?: Maybe<Scalars['String']>;
  AddedDate?: Maybe<Scalars['String']>;
  ChallengeAnswers?: Maybe<Array<Maybe<ChallengeAnswer>>>;
  ChallengeQuestions?: Maybe<Array<Maybe<ActivityQuestion>>>;
  Content?: Maybe<Scalars['String']>;
  CourseGroup?: Maybe<CourseGroup>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  CourseGroupName?: Maybe<Scalars['String']>;
  Creator?: Maybe<Scalars['String']>;
  CurrentTime?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['Date']>;
  DeadLine?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  EndTime?: Maybe<Scalars['String']>;
  Grade?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['Int']>;
  Images?: Maybe<Array<Maybe<Attachment>>>;
  IsFinished?: Maybe<Scalars['Boolean']>;
  IsMarked?: Maybe<Scalars['Boolean']>;
  IsMarkedUserActivityCount?: Maybe<Scalars['Int']>;
  IsOnline?: Maybe<Scalars['Boolean']>;
  Name?: Maybe<Scalars['String']>;
  SchoolYearName?: Maybe<Scalars['String']>;
  ScientificSubjectName?: Maybe<Scalars['String']>;
  ServerDateTime?: Maybe<Scalars['String']>;
  Session?: Maybe<Session>;
  SessionId?: Maybe<Scalars['Int']>;
  StartDate?: Maybe<Scalars['String']>;
  StartTime?: Maybe<Scalars['String']>;
  StudentCount?: Maybe<Scalars['Int']>;
  UserActivities?: Maybe<Array<Maybe<StudentActivity>>>;
  UserActivityCount?: Maybe<Scalars['Int']>;
};

export type ChallengeAnswer = {
  __typename?: 'ChallengeAnswer';
  ActivityId: Scalars['Int'];
};

export type AssignmentTypeListResponse = {
  __typename?: 'AssignmentTypeListResponse';
  Result?: Maybe<Array<Maybe<Assignment>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type PostTypeListResponse = {
  __typename?: 'PostTypeListResponse';
  Result?: Maybe<Array<Maybe<Post>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type SessionTypeListResponse = {
  __typename?: 'SessionTypeListResponse';
  Result?: Maybe<Array<Maybe<Session>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type SessionCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  SessionId?: Maybe<Scalars['Int']>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  ActivityId?: Maybe<Scalars['Int']>;
  RequestId?: Maybe<Scalars['Int']>;
  QuestionId?: Maybe<Scalars['Int']>;
  SessionType?: Maybe<SessionType>;
  AttendanceMethod?: Maybe<AttendanceMethod>;
  ActivityType?: Maybe<ActivityType>;
  TeacherId?: Maybe<Scalars['Int']>;
  CourseId?: Maybe<Scalars['Int']>;
  StudentIdCourseGroup?: Maybe<Scalars['Int']>;
  IsLocked?: Maybe<Scalars['Boolean']>;
  IsMoreThanOneGroup?: Maybe<Scalars['Boolean']>;
  IsForWeb?: Maybe<Scalars['Boolean']>;
  SessionAttendType?: Maybe<SessionAttendType>;
};

export type UserActivityTypeListResponse = {
  __typename?: 'UserActivityTypeListResponse';
  Result?: Maybe<Array<Maybe<StudentActivity>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type ActivityQuestionTypeListResponse = {
  __typename?: 'ActivityQuestionTypeListResponse';
  Result?: Maybe<Array<Maybe<ActivityQuestion>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type ActivityQuestionCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  ActivityId?: Maybe<Scalars['Int']>;
  QuestionType?: Maybe<QuestionType>;
  AnswerIsMarked?: Maybe<Scalars['Boolean']>;
  IsVerified?: Maybe<Scalars['Boolean']>;
};

export type EventQuery = {
  __typename?: 'EventQuery';
  Challenges?: Maybe<EventTypeListResponse>;
  Events?: Maybe<EventTypeListResponse>;
  Quizzes?: Maybe<EventTypeListResponse>;
  Revesions?: Maybe<EventTypeListResponse>;
  Sessions?: Maybe<EventTypeListResponse>;
  Surveys?: Maybe<EventTypeListResponse>;
};


export type EventQueryChallengesArgs = {
  criteria?: Maybe<EventCriteria>;
};


export type EventQueryEventsArgs = {
  criteria?: Maybe<EventCriteria>;
};


export type EventQueryQuizzesArgs = {
  criteria?: Maybe<EventCriteria>;
};


export type EventQueryRevesionsArgs = {
  criteria?: Maybe<EventCriteria>;
};


export type EventQuerySessionsArgs = {
  criteria?: Maybe<EventCriteria>;
};


export type EventQuerySurveysArgs = {
  criteria?: Maybe<EventCriteria>;
};

export type EventTypeListResponse = {
  __typename?: 'EventTypeListResponse';
  Result?: Maybe<Array<Maybe<Event>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type TaskQuery = {
  __typename?: 'TaskQuery';
  Task?: Maybe<ActivityTypeResponse>;
};


export type TaskQueryTaskArgs = {
  criteria?: Maybe<ActivityCriteria>;
};

export type ActivityTypeResponse = {
  __typename?: 'ActivityTypeResponse';
  Result?: Maybe<Activity>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type ParentQuery = {
  __typename?: 'ParentQuery';
  Parents?: Maybe<ParentTypeListResponse>;
  SearchParents?: Maybe<ParentTypeListResponse>;
};


export type ParentQueryParentsArgs = {
  criteria?: Maybe<ParentCriteria>;
};


export type ParentQuerySearchParentsArgs = {
  criteria?: Maybe<ParentCriteria>;
};

export type ParentTypeListResponse = {
  __typename?: 'ParentTypeListResponse';
  Result?: Maybe<Array<Maybe<Parent>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type ParentCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  MobileNumber?: Maybe<Scalars['String']>;
  StudentParent?: Maybe<StudentParentCriteria>;
  StudentId?: Maybe<Scalars['Int']>;
  TeacherId?: Maybe<Scalars['Int']>;
};

export type SessionQuery = {
  __typename?: 'SessionQuery';
  OnlineSessionInfo?: Maybe<GetOnlineSessionInfoTypeResponse>;
  SessionAttendanceCode?: Maybe<SessionAttendanceTypeListResponse>;
  Sessions?: Maybe<SessionTypeListResponse>;
  SessionsQuestions?: Maybe<SessionQuestionTypeListResponse>;
};


export type SessionQueryOnlineSessionInfoArgs = {
  sessionId?: Maybe<Scalars['Int']>;
};


export type SessionQuerySessionAttendanceCodeArgs = {
  criteria?: Maybe<SessionAttendanceCriteria>;
};


export type SessionQuerySessionsArgs = {
  criteria?: Maybe<SessionCriteria>;
};


export type SessionQuerySessionsQuestionsArgs = {
  criteria?: Maybe<SessionQuestionCriteria>;
};

export type SessionQuestionTypeListResponse = {
  __typename?: 'SessionQuestionTypeListResponse';
  Result?: Maybe<Array<Maybe<SessionQuestion>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type SessionQuestionCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  SessionId?: Maybe<Scalars['Int']>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  StudentId?: Maybe<Scalars['Int']>;
  IsVerified?: Maybe<Scalars['Boolean']>;
  SessionContentId?: Maybe<Scalars['Int']>;
};

export type SessionAttendanceTypeListResponse = {
  __typename?: 'SessionAttendanceTypeListResponse';
  Result?: Maybe<Array<Maybe<SessionAttendance>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type SessionAttendanceCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  SessionId?: Maybe<Scalars['Int']>;
  StudentId?: Maybe<Scalars['Int']>;
  IsAttended?: Maybe<Scalars['Boolean']>;
  TeacherId?: Maybe<Scalars['Int']>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  CourseId?: Maybe<Scalars['Int']>;
};

export type GetOnlineSessionInfoTypeResponse = {
  __typename?: 'GetOnlineSessionInfoTypeResponse';
  Result?: Maybe<GetOnlineSessionInfo>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type GetOnlineSessionInfo = {
  __typename?: 'GetOnlineSessionInfo';
  APIKey?: Maybe<Scalars['String']>;
  MeetingId?: Maybe<Scalars['String']>;
  MeetingPassword?: Maybe<Scalars['String']>;
  Signature?: Maybe<Scalars['String']>;
};

export type NotificationQuery = {
  __typename?: 'NotificationQuery';
  Notifications?: Maybe<NotificationTypeListResponse>;
};


export type NotificationQueryNotificationsArgs = {
  criteria?: Maybe<NotificationCriteria>;
};

export type NotificationTypeListResponse = {
  __typename?: 'NotificationTypeListResponse';
  Result?: Maybe<Array<Maybe<Notification>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type Notification = {
  __typename?: 'Notification';
  Id?: Maybe<Scalars['Int']>;
  IsReaded?: Maybe<Scalars['Boolean']>;
  Message?: Maybe<Scalars['String']>;
  NotificationClickAction?: Maybe<NotificationClickAction>;
  NotificationData?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
};

export enum NotificationClickAction {
  TEACHER_PROFILE = 'TEACHER_PROFILE',
  STUDENT_PROFILE = 'STUDENT_PROFILE'
}

export type NotificationCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
};

export type LicenceMutation = {
  __typename?: 'LicenceMutation';
  ActiveLicence?: Maybe<LicenceTypeResponse>;
  LicenceInstallmentTypes?: Maybe<LicenceInstallmentTypeTypeListResponse>;
  TotalAssignLicenceCount?: Maybe<IntGraphTypeResponse>;
  TotalRemainingLicenceCount?: Maybe<IntGraphTypeResponse>;
};

export type LicenceTypeResponse = {
  __typename?: 'LicenceTypeResponse';
  Result?: Maybe<LicenceType>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type LicenceType = {
  __typename?: 'LicenceType';
  Cost: Scalars['Float'];
  ExpireInDays?: Maybe<Scalars['Int']>;
  Id: Scalars['Int'];
  Name: Scalars['String'];
  StartDate?: Maybe<Scalars['Date']>;
  SuspendDate?: Maybe<Scalars['Date']>;
};

export type LicenceInstallmentTypeTypeListResponse = {
  __typename?: 'LicenceInstallmentTypeTypeListResponse';
  Result?: Maybe<Array<Maybe<LicenceInstallmentType>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type LicenceInstallmentType = {
  __typename?: 'LicenceInstallmentType';
  Id: Scalars['Int'];
  IsSelected?: Maybe<Scalars['Boolean']>;
  Name: Scalars['String'];
  NumberOfMonthes: Scalars['Int'];
};

export type IntGraphTypeResponse = {
  __typename?: 'IntGraphTypeResponse';
  Result?: Maybe<Scalars['Int']>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type TeacherLicenceQuery = {
  __typename?: 'TeacherLicenceQuery';
  PaymentsDue?: Maybe<TeacherLicenceInstallmentTypeListResponse>;
  TeacherLicenceOrders?: Maybe<TeacherLicenceOrderTypeListResponse>;
  TeacherLicenceOrdersCount?: Maybe<IntGraphTypeResponse>;
  TotalLicenceCount?: Maybe<Scalars['Int']>;
  UnAssignedLicenceCount?: Maybe<Scalars['Int']>;
};


export type TeacherLicenceQueryTeacherLicenceOrdersArgs = {
  criteria?: Maybe<TeacherLicenceCriteriaInput>;
};


export type TeacherLicenceQueryTeacherLicenceOrdersCountArgs = {
  criteria?: Maybe<TeacherLicenceCriteriaInput>;
};

export type TeacherLicenceOrderTypeListResponse = {
  __typename?: 'TeacherLicenceOrderTypeListResponse';
  Result?: Maybe<Array<Maybe<TeacherLicenceOrder>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type TeacherLicenceOrder = {
  __typename?: 'TeacherLicenceOrder';
  AssignedCount?: Maybe<Scalars['Int']>;
  Count?: Maybe<Scalars['Int']>;
  Date?: Maybe<Scalars['Date']>;
  ExpirationDate?: Maybe<Scalars['Date']>;
  Id?: Maybe<Scalars['Int']>;
  LicenceInstallments?: Maybe<Array<Maybe<TeacherLicenceInstallment>>>;
  LicencePrice?: Maybe<Scalars['Float']>;
  LicenceStatus?: Maybe<TeacherLicenceStatus>;
  NumberOfInstallments?: Maybe<Scalars['Int']>;
  NumberOfNotPaidInstallments?: Maybe<Scalars['Int']>;
  NumberOfOrders?: Maybe<Scalars['Int']>;
  NumberOfPaidInstallments?: Maybe<Scalars['Int']>;
  PaymentMethod?: Maybe<TeacherLicencePaymentMethod>;
  PaymentMethodName?: Maybe<Scalars['String']>;
  PaymentStatus?: Maybe<PaymentStatus>;
  PaymentStatusName?: Maybe<Scalars['String']>;
  RemainingCount?: Maybe<Scalars['Int']>;
  RemainingInstallments?: Maybe<Array<Maybe<TeacherLicenceInstallment>>>;
  StudentLicences?: Maybe<Array<Maybe<StudentLicence>>>;
  Teacher?: Maybe<Teacher>;
  TeacherLicencesLog?: Maybe<Array<Maybe<TeacherLicenceLogType>>>;
  TeacherMobile?: Maybe<Scalars['String']>;
  TeacherName?: Maybe<Scalars['String']>;
  TotalPayedPrice?: Maybe<Scalars['Float']>;
  TotalPrice?: Maybe<Scalars['Float']>;
  TotalRemainingPrice?: Maybe<Scalars['Float']>;
};

export enum TeacherLicencePaymentMethod {
  FULL_AMOUNT = 'FULL_AMOUNT',
  INSTALLMENT = 'INSTALLMENT'
}

export enum PaymentStatus {
  NOT_PAYED = 'NOT_PAYED',
  PAYED = 'PAYED',
  PARTIAL_PAYED = 'PARTIAL_PAYED'
}

export enum TeacherLicenceStatus {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  ACTIVE = 'ACTIVE',
  REJECTED = 'REJECTED',
  SUSPENDED = 'SUSPENDED'
}

export type TeacherLicenceInstallment = {
  __typename?: 'TeacherLicenceInstallment';
  Amount?: Maybe<Scalars['Float']>;
  DueDate?: Maybe<Scalars['Date']>;
  Id?: Maybe<Scalars['Int']>;
  IsPayed?: Maybe<Scalars['Boolean']>;
  IsSelected?: Maybe<Scalars['Boolean']>;
};

export type TeacherLicenceLogType = {
  __typename?: 'TeacherLicenceLogType';
  ActionDate?: Maybe<Scalars['String']>;
  ActionType?: Maybe<TeacherLicenceActionType>;
  Id?: Maybe<Scalars['Int']>;
  NewDate?: Maybe<Scalars['Date']>;
  NewStatus?: Maybe<TeacherLicenceStatus>;
  OldDate?: Maybe<Scalars['Date']>;
  OldStatus?: Maybe<TeacherLicenceStatus>;
  TeacherLicenceId?: Maybe<Scalars['Int']>;
  UserName?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['Float']>;
};

export enum TeacherLicenceActionType {
  CREATE_ORDER = 'CREATE_ORDER',
  ACCEPT_ORDER = 'ACCEPT_ORDER',
  REJECT_ORDER = 'REJECT_ORDER',
  SUSPEND_ORDER = 'SUSPEND_ORDER',
  RE_ACTIVATE_ORDER = 'RE_ACTIVATE_ORDER',
  COLLECT_PAYMENT = 'COLLECT_PAYMENT',
  EXTEND_DATE = 'EXTEND_DATE'
}

export type StudentLicence = {
  __typename?: 'StudentLicence';
  CourseName?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['Date']>;
  Id?: Maybe<Scalars['Int']>;
  StudentName?: Maybe<Scalars['String']>;
};

export type TeacherLicenceCriteriaInput = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  TeacherId?: Maybe<Scalars['Int']>;
  RequestedFrom?: Maybe<Scalars['Date']>;
  RequestedTo?: Maybe<Scalars['Date']>;
  ExpiresFrom?: Maybe<Scalars['Date']>;
  ExpiresTo?: Maybe<Scalars['Date']>;
  PaymentStatus?: Maybe<PaymentStatus>;
  ActionStatus?: Maybe<LicenceOrderActionStatus>;
  PaymentMethod?: Maybe<TeacherLicencePaymentMethod>;
  LicenceStatus?: Maybe<TeacherLicenceStatus>;
};

export enum LicenceOrderActionStatus {
  NEED_COLLECT = 'NEED_COLLECT',
  OVER_DUE = 'OVER_DUE'
}

export type TeacherLicenceInstallmentTypeListResponse = {
  __typename?: 'TeacherLicenceInstallmentTypeListResponse';
  Result?: Maybe<Array<Maybe<TeacherLicenceInstallment>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type UserBlockQuery = {
  __typename?: 'UserBlockQuery';
  TeacherBlockedAssistants?: Maybe<UserBlockTypeListResponse>;
  TeacherBlockedParents?: Maybe<UserBlockTypeListResponse>;
  TeacherBlockedStudents?: Maybe<UserBlockTypeListResponse>;
};


export type UserBlockQueryTeacherBlockedAssistantsArgs = {
  criteria?: Maybe<UserBlockCriteria>;
};


export type UserBlockQueryTeacherBlockedParentsArgs = {
  criteria?: Maybe<UserBlockCriteria>;
};


export type UserBlockQueryTeacherBlockedStudentsArgs = {
  criteria?: Maybe<UserBlockCriteria>;
};

export type UserBlockTypeListResponse = {
  __typename?: 'UserBlockTypeListResponse';
  Result?: Maybe<Array<Maybe<UserBlock>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type UserBlock = {
  __typename?: 'UserBlock';
  AssistantId?: Maybe<Scalars['Int']>;
  AssistantName?: Maybe<Scalars['String']>;
  ParentId?: Maybe<Scalars['Int']>;
  ParentName?: Maybe<Scalars['String']>;
  StudentId?: Maybe<Scalars['Int']>;
  StudentName?: Maybe<Scalars['String']>;
  TeacherId?: Maybe<Scalars['Int']>;
  TeacherName?: Maybe<Scalars['String']>;
};

export type UserBlockCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  TeacherId?: Maybe<Scalars['Int']>;
  AssistantId?: Maybe<Scalars['Int']>;
  StudentId?: Maybe<Scalars['Int']>;
  ParentId?: Maybe<Scalars['Int']>;
  BlockUserDirection?: Maybe<BlockUserDirection>;
};

export enum BlockUserDirection {
  FROM_TEACHER_TO_STUDENT = 'FROM_TEACHER_TO_STUDENT',
  FROM_TEACHER_TO_ASSISTANT = 'FROM_TEACHER_TO_ASSISTANT',
  FROM_TEACHER_TO_PARENT = 'FROM_TEACHER_TO_PARENT',
  FROM_ASSISTANT_TO_PARENT = 'FROM_ASSISTANT_TO_PARENT',
  FROM_ASSISTANT_TO_TEACHER = 'FROM_ASSISTANT_TO_TEACHER'
}

export type OnlineContentQuery = {
  __typename?: 'OnlineContentQuery';
  CourseContent?: Maybe<CourseContentQuery>;
  JoinContentRequest?: Maybe<JoinContentRequestQuery>;
  SessionContent?: Maybe<SessionContentQuery>;
  SessionContentPart?: Maybe<SessionContentPartQuery>;
  SessionContentStudent?: Maybe<SessionContentStudentQuery>;
};

export type JoinContentRequestQuery = {
  __typename?: 'JoinContentRequestQuery';
  JoinContentRequests?: Maybe<JoinContentRequestTypeListResponse>;
};


export type JoinContentRequestQueryJoinContentRequestsArgs = {
  criteria?: Maybe<JoinContentRequestCriteria>;
};

export type JoinContentRequestTypeListResponse = {
  __typename?: 'JoinContentRequestTypeListResponse';
  Result?: Maybe<Array<Maybe<JoinContentRequest>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type JoinContentRequest = {
  __typename?: 'JoinContentRequest';
  ActualCreatorImage?: Maybe<Scalars['String']>;
  ActualCreatorName?: Maybe<Scalars['String']>;
  Assistant?: Maybe<Assistant>;
  AssistantId?: Maybe<Scalars['Int']>;
  AssistantName?: Maybe<Scalars['String']>;
  ContentRequestType?: Maybe<ContentRequestType>;
  Description?: Maybe<Scalars['String']>;
  EducationTypeId?: Maybe<Scalars['Int']>;
  EducationTypeName?: Maybe<Scalars['String']>;
  EstimatedLecturePrice?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['Int']>;
  IntroVideoPath?: Maybe<Scalars['String']>;
  IntroVideoPicturePath?: Maybe<Scalars['String']>;
  ProducingWayName?: Maybe<Scalars['String']>;
  RequestStatus?: Maybe<RequestStatus>;
  RequestStatusName?: Maybe<Scalars['String']>;
  SampleLecturePath?: Maybe<Scalars['String']>;
  SampleLecturePicturePath?: Maybe<Scalars['String']>;
  SchoolYearId?: Maybe<Scalars['Int']>;
  SchoolYearName?: Maybe<Scalars['String']>;
  ScientificSubjectId?: Maybe<Scalars['Int']>;
  ScientificSubjectName?: Maybe<Scalars['String']>;
  Subject?: Maybe<Subject>;
  SubjectId?: Maybe<Scalars['Int']>;
  SubjectName?: Maybe<Scalars['String']>;
  Teacher?: Maybe<Teacher>;
  TeacherId?: Maybe<Scalars['Int']>;
  TeacherName?: Maybe<Scalars['String']>;
};

export enum ContentRequestType {
  ONLINE_CONTENT = 'ONLINE_CONTENT',
  LIVE_CONTENT = 'LIVE_CONTENT'
}

export type JoinContentRequestCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  AccountManagerId?: Maybe<Scalars['Int']>;
  TeacherId?: Maybe<Scalars['Int']>;
  ProducingWay?: Maybe<ProducingWay>;
  RequestStatus?: Maybe<RequestStatus>;
  ContentRequestType?: Maybe<ContentRequestType>;
  EducationTypeId?: Maybe<Scalars['Int']>;
  SchoolYearId?: Maybe<Scalars['Int']>;
  ScientificSubjectId?: Maybe<Scalars['Int']>;
};

export enum ProducingWay {
  FULL_CURRICULUM = 'FULL_CURRICULUM',
  SINGLE_LECTURE = 'SINGLE_LECTURE'
}

export type CourseContentQuery = {
  __typename?: 'CourseContentQuery';
  CourseContents?: Maybe<CourseContentTypeListResponse>;
};


export type CourseContentQueryCourseContentsArgs = {
  criteria?: Maybe<CourseContentCriteria>;
};

export type CourseContentTypeListResponse = {
  __typename?: 'CourseContentTypeListResponse';
  Result?: Maybe<Array<Maybe<CourseContent>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type CourseContent = {
  __typename?: 'CourseContent';
  ActualCreatorImage?: Maybe<Scalars['String']>;
  ActualCreatorName?: Maybe<Scalars['String']>;
  Assistant?: Maybe<Assistant>;
  AssistantId?: Maybe<Scalars['Int']>;
  AssistantName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  EducationTypeId?: Maybe<Scalars['Int']>;
  EducationTypeName?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  ImagePath?: Maybe<Scalars['String']>;
  NumOFSessionContent?: Maybe<Scalars['Int']>;
  RequestStatus?: Maybe<RequestStatus>;
  RequestStatusName?: Maybe<Scalars['String']>;
  SchoolYearId?: Maybe<Scalars['Int']>;
  SchoolYearName?: Maybe<Scalars['String']>;
  ScientificSubjectId?: Maybe<Scalars['Int']>;
  ScientificSubjectName?: Maybe<Scalars['String']>;
  SessionContents?: Maybe<Array<Maybe<SessionContent>>>;
  Subject?: Maybe<Subject>;
  SubjectId?: Maybe<Scalars['Int']>;
  SubjectName?: Maybe<Scalars['String']>;
  Teacher?: Maybe<Teacher>;
  TeacherId?: Maybe<Scalars['Int']>;
  TeacherName?: Maybe<Scalars['String']>;
};

export type SessionContent = {
  __typename?: 'SessionContent';
  AutoRecording?: Maybe<Scalars['Boolean']>;
  ChapterId?: Maybe<Scalars['Int']>;
  ChapterName?: Maybe<Scalars['String']>;
  CourseContent?: Maybe<CourseContent>;
  CourseContentId?: Maybe<Scalars['Int']>;
  Description?: Maybe<Scalars['String']>;
  EducationTypeId?: Maybe<Scalars['Int']>;
  EducationTypeName?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  IntroVideoPath?: Maybe<Scalars['String']>;
  IntroVideoPathId?: Maybe<Scalars['String']>;
  IntroVideoPicturePath?: Maybe<Scalars['String']>;
  IsFinished?: Maybe<Scalars['Boolean']>;
  IsPaid?: Maybe<Scalars['Boolean']>;
  PartsCount?: Maybe<Scalars['Int']>;
  Price?: Maybe<Scalars['Float']>;
  PublishDate?: Maybe<Scalars['String']>;
  PurchaseCount?: Maybe<Scalars['Int']>;
  Rate?: Maybe<Scalars['Float']>;
  RemainingCollectedNumber?: Maybe<Scalars['Int']>;
  SchoolYearId?: Maybe<Scalars['Int']>;
  SchoolYearName?: Maybe<Scalars['String']>;
  ScientificSubjectId?: Maybe<Scalars['Int']>;
  ScientificSubjectName?: Maybe<Scalars['String']>;
  Session?: Maybe<Session>;
  SessionContentParts?: Maybe<Array<Maybe<SessionContentPart>>>;
  SessionContentStatus?: Maybe<SessionContentStatus>;
  SessionContentStatusName?: Maybe<Scalars['String']>;
  SessionId?: Maybe<Scalars['Int']>;
  SessionRequestStatus?: Maybe<SessionRequestStatus>;
  SessionRequestStatusName?: Maybe<Scalars['String']>;
  TeacherId?: Maybe<Scalars['Int']>;
  TeacherName?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  TotalAmountToCollect?: Maybe<Scalars['Decimal']>;
  UnitId?: Maybe<Scalars['Int']>;
  UnitName?: Maybe<Scalars['String']>;
};

export enum SessionContentStatus {
  ON_SHOW = 'ON_SHOW',
  OFF_SHOW = 'OFF_SHOW'
}

export enum SessionRequestStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export type SessionContentPart = {
  __typename?: 'SessionContentPart';
  Attachments?: Maybe<Array<Maybe<Attachment>>>;
  Description?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  SessionPartStatus?: Maybe<CurrentState>;
  Title?: Maybe<Scalars['String']>;
  VideoPath?: Maybe<Scalars['String']>;
  VideoPathId?: Maybe<Scalars['String']>;
  VideoPicturePath?: Maybe<Scalars['String']>;
};

export type CourseContentCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  TeacherId?: Maybe<Scalars['Int']>;
  CourseId?: Maybe<Scalars['Int']>;
  RequestStatus?: Maybe<RequestStatus>;
  EducationTypeId?: Maybe<Scalars['Int']>;
  SchoolYearId?: Maybe<Scalars['Int']>;
  ScientificSubjectId?: Maybe<Scalars['Int']>;
  IsSearch?: Maybe<Scalars['Boolean']>;
  CourseType?: Maybe<CourseType>;
};

export enum CourseType {
  OFFLINE_CONTENT = 'OFFLINE_CONTENT',
  ONLINE_CONTENT = 'ONLINE_CONTENT',
  LIVE_CONTENT = 'LIVE_CONTENT'
}

export type SessionContentStudentQuery = {
  __typename?: 'SessionContentStudentQuery';
  EnsurStudentHavePaidSession?: Maybe<BooleanGraphTypeResponse>;
  SessionContentStudents?: Maybe<SessionContentStudentTypeListResponse>;
  SessionContentStudentsReport?: Maybe<SessionContentStudentReportTypeListResponse>;
};


export type SessionContentStudentQuerySessionContentStudentsArgs = {
  criteria?: Maybe<SessionContentStudentCriteria>;
};


export type SessionContentStudentQuerySessionContentStudentsReportArgs = {
  criteria?: Maybe<SessionContentStudentReportCriteria>;
};

export type SessionContentStudentTypeListResponse = {
  __typename?: 'SessionContentStudentTypeListResponse';
  Result?: Maybe<Array<Maybe<SessionContentStudent>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type SessionContentStudent = {
  __typename?: 'SessionContentStudent';
  Chapter?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  IntroVideoPath?: Maybe<Scalars['String']>;
  IsFinished?: Maybe<Scalars['Boolean']>;
  NumOfSessionParts?: Maybe<Scalars['Int']>;
  PaidDate?: Maybe<Scalars['Date']>;
  Price?: Maybe<Scalars['Float']>;
  PublishDate?: Maybe<Scalars['Date']>;
  PurchaseDate?: Maybe<Scalars['Date']>;
  Rate?: Maybe<Scalars['Float']>;
  SessionContent?: Maybe<SessionContent>;
  SessionContentId?: Maybe<Scalars['Int']>;
  SessionContentParts?: Maybe<Array<Maybe<SessionContentPart>>>;
  SessionId?: Maybe<Scalars['Int']>;
  SessionTitle?: Maybe<Scalars['String']>;
  Student?: Maybe<Student>;
  StudentId?: Maybe<Scalars['Int']>;
  StudentName?: Maybe<Scalars['String']>;
  StudentStatus?: Maybe<SessionContentStudentStatus>;
  StudentStatusName?: Maybe<Scalars['String']>;
  Unit?: Maybe<Scalars['String']>;
};

export enum SessionContentStudentStatus {
  STUDENT_PAID = 'STUDENT_PAID',
  STUDENT_NOT_PAID = 'STUDENT_NOT_PAID'
}

export type SessionContentStudentCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  TeacherId?: Maybe<Scalars['Int']>;
  CourseContentId?: Maybe<Scalars['Int']>;
  SessionContentId?: Maybe<Scalars['Int']>;
  StudentId?: Maybe<Scalars['Int']>;
  StudentStatus?: Maybe<SessionContentStudentStatus>;
};

export type BooleanGraphTypeResponse = {
  __typename?: 'BooleanGraphTypeResponse';
  Result?: Maybe<Scalars['Boolean']>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type SessionContentStudentReportTypeListResponse = {
  __typename?: 'SessionContentStudentReportTypeListResponse';
  Result?: Maybe<Array<Maybe<SessionContentStudentReport>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type SessionContentStudentReport = {
  __typename?: 'SessionContentStudentReport';
  Id?: Maybe<Scalars['Int']>;
  IsFinished?: Maybe<Scalars['Boolean']>;
  PaidDate?: Maybe<Scalars['Date']>;
  PaymentType?: Maybe<PaymentType>;
  Price?: Maybe<Scalars['Float']>;
  PublishDate?: Maybe<Scalars['Date']>;
  PurchaseDate?: Maybe<Scalars['Date']>;
  SessionContentId?: Maybe<Scalars['Int']>;
  SessionTitle?: Maybe<Scalars['String']>;
  StudentId?: Maybe<Scalars['Int']>;
  StudentMobile?: Maybe<Scalars['String']>;
  StudentName?: Maybe<Scalars['String']>;
  StudentStatus?: Maybe<SessionContentStudentStatus>;
  StudentStatusName?: Maybe<Scalars['String']>;
  TeacherId?: Maybe<Scalars['Int']>;
  TeacherMobile?: Maybe<Scalars['String']>;
  TeacherName?: Maybe<Scalars['String']>;
};

export enum PaymentType {
  CASH = 'CASH',
  CREDIT_CARD = 'CREDIT_CARD',
  WALLET = 'WALLET',
  CODE = 'CODE'
}

export type SessionContentStudentReportCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  StudentId?: Maybe<Scalars['Int']>;
  TeacherId?: Maybe<Scalars['Int']>;
  SessionContentId?: Maybe<Scalars['Int']>;
  CourseId?: Maybe<Scalars['Int']>;
  StudentStatus?: Maybe<SessionContentStudentStatus>;
};

export type SessionContentQuery = {
  __typename?: 'SessionContentQuery';
  SessionContents?: Maybe<SessionContentTypeListResponse>;
  SessionContentTemps?: Maybe<SessionContentTempTypeListResponse>;
};


export type SessionContentQuerySessionContentsArgs = {
  criteria?: Maybe<SessionContentCriteria>;
};


export type SessionContentQuerySessionContentTempsArgs = {
  criteria?: Maybe<SessionContentTempCriteria>;
};

export type SessionContentTypeListResponse = {
  __typename?: 'SessionContentTypeListResponse';
  Result?: Maybe<Array<Maybe<SessionContent>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type SessionContentCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  AccountManagerId?: Maybe<Scalars['Int']>;
  TeacherId?: Maybe<Scalars['Int']>;
  CourseContentId?: Maybe<Scalars['Int']>;
  SessionRequestStatus?: Maybe<SessionRequestStatus>;
  SessionContentStatus?: Maybe<SessionContentStatus>;
};

export type SessionContentTempTypeListResponse = {
  __typename?: 'SessionContentTempTypeListResponse';
  Result?: Maybe<Array<Maybe<SessionContentTemp>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type SessionContentTemp = {
  __typename?: 'SessionContentTemp';
  ChapterId?: Maybe<Scalars['Int']>;
  Comment?: Maybe<Scalars['String']>;
  CurrentState?: Maybe<CurrentState>;
  Description?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  IntroVideoPath?: Maybe<Scalars['String']>;
  IntroVideoPicturePath?: Maybe<Scalars['String']>;
  Price?: Maybe<Scalars['Float']>;
  SessionContentId?: Maybe<Scalars['Int']>;
  Title?: Maybe<Scalars['String']>;
};

export type SessionContentTempCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  AccountManagerId?: Maybe<Scalars['Int']>;
  CurrentState?: Maybe<CurrentState>;
};

export type SessionContentPartQuery = {
  __typename?: 'SessionContentPartQuery';
  SessionContentParts?: Maybe<SessionContentPartTypeListResponse>;
  SessionContentPartTemps?: Maybe<SessionContentPartTempTypeListResponse>;
};


export type SessionContentPartQuerySessionContentPartsArgs = {
  criteria?: Maybe<SessionContentPartCriteria>;
};


export type SessionContentPartQuerySessionContentPartTempsArgs = {
  criteria?: Maybe<SessionContentPartTempCriteria>;
};

export type SessionContentPartTypeListResponse = {
  __typename?: 'SessionContentPartTypeListResponse';
  Result?: Maybe<Array<Maybe<SessionContentPart>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type SessionContentPartCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  SessionContentId?: Maybe<Scalars['Int']>;
};

export type SessionContentPartTempTypeListResponse = {
  __typename?: 'SessionContentPartTempTypeListResponse';
  Result?: Maybe<Array<Maybe<SessionContentPartTemp>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type SessionContentPartTemp = {
  __typename?: 'SessionContentPartTemp';
  Attachments?: Maybe<Array<Maybe<Attachment>>>;
  Comment?: Maybe<Scalars['String']>;
  CurrentState?: Maybe<CurrentState>;
  Description?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  RejectSessionContentPartReasonId?: Maybe<Scalars['Int']>;
  SessionContentPartId?: Maybe<Scalars['Int']>;
  Title?: Maybe<Scalars['String']>;
  VideoPath?: Maybe<Scalars['String']>;
  VideoPicturePath?: Maybe<Scalars['String']>;
};

export type SessionContentPartTempCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  SessionContentId?: Maybe<Scalars['Int']>;
};

export type PaymentQuery = {
  __typename?: 'PaymentQuery';
  EduraSetup?: Maybe<SessionContentPaymentSetupQuery>;
  StudentBalance?: Maybe<StudentBalanceQuery>;
  TeacherBalance?: Maybe<TeacherBalanceQuery>;
};

export type StudentBalanceQuery = {
  __typename?: 'StudentBalanceQuery';
  GetAllStudentBalances?: Maybe<StudentBalanceTypeListResponse>;
  GetStudentBalance?: Maybe<StudentBalanceTypeResponse>;
  GetStudentWalletTransactions?: Maybe<StudentWalletTransactionsTypeListResponse>;
};


export type StudentBalanceQueryGetAllStudentBalancesArgs = {
  criteria?: Maybe<StudentBalanceCriteria>;
};


export type StudentBalanceQueryGetStudentBalanceArgs = {
  criteria?: Maybe<StudentBalanceCriteria>;
};


export type StudentBalanceQueryGetStudentWalletTransactionsArgs = {
  criteria?: Maybe<StudentWalletTransactionsCriteria>;
};

export type StudentBalanceTypeResponse = {
  __typename?: 'StudentBalanceTypeResponse';
  Result?: Maybe<StudentBalance>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type StudentBalance = {
  __typename?: 'StudentBalance';
  AcademyName?: Maybe<Scalars['String']>;
  Balance?: Maybe<Scalars['Float']>;
  StudentId?: Maybe<Scalars['Int']>;
  StudentName?: Maybe<Scalars['String']>;
};

export type StudentBalanceCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  StudentId?: Maybe<Scalars['Int']>;
  AcademyId?: Maybe<Scalars['Int']>;
  IsReleaseEnvironment: Scalars['Boolean'];
};

export type StudentBalanceTypeListResponse = {
  __typename?: 'StudentBalanceTypeListResponse';
  Result?: Maybe<Array<Maybe<StudentBalance>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type StudentWalletTransactionsTypeListResponse = {
  __typename?: 'StudentWalletTransactionsTypeListResponse';
  Result?: Maybe<Array<Maybe<StudentWalletTransactions>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type StudentWalletTransactions = {
  __typename?: 'StudentWalletTransactions';
  AccountManagerName?: Maybe<Scalars['String']>;
  AddedDate?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['Int']>;
  MerchantRefNumber?: Maybe<Scalars['String']>;
  SessionName?: Maybe<Scalars['String']>;
  StudentName?: Maybe<Scalars['String']>;
  StudentWalletTransactionType?: Maybe<StudentWalletTransactionType>;
  StudentWalletTransactionTypeName?: Maybe<Scalars['String']>;
  TeacherName?: Maybe<Scalars['String']>;
  TeacherPhoneNumber?: Maybe<Scalars['String']>;
  TransactionDirection?: Maybe<TransactionDirection>;
  TransactionDirectionName?: Maybe<Scalars['String']>;
};

export enum StudentWalletTransactionType {
  STUDENT_PAID_FOR_LECTURE = 'STUDENT_PAID_FOR_LECTURE',
  STUDENT_CHARGING = 'STUDENT_CHARGING',
  ACCOUNT_MANAGER_CHARGE_FOR_STUDENT = 'ACCOUNT_MANAGER_CHARGE_FOR_STUDENT',
  ACCOUNT_MANAGER_DEDUCTION = 'ACCOUNT_MANAGER_DEDUCTION',
  STUDENT_CHARGING_BY_CODE = 'STUDENT_CHARGING_BY_CODE'
}

export enum TransactionDirection {
  IN = 'IN',
  OUT = 'OUT'
}

export type StudentWalletTransactionsCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  StudentId?: Maybe<Scalars['Int']>;
};

export type TeacherBalanceQuery = {
  __typename?: 'TeacherBalanceQuery';
  EnsureTeacherHaveBalance?: Maybe<BooleanGraphTypeResponse>;
  GetRecentlyTransactions?: Maybe<TransactionTypeListResponse>;
  GetTeacherBalance?: Maybe<TeacherBalanceTypeResponse>;
};

export type TeacherBalanceTypeResponse = {
  __typename?: 'TeacherBalanceTypeResponse';
  Result?: Maybe<TeacherBalance>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type TeacherBalance = {
  __typename?: 'TeacherBalance';
  BalanceCanCollected?: Maybe<Scalars['Float']>;
  Collected?: Maybe<Scalars['Float']>;
  EduraPercentage?: Maybe<Scalars['Float']>;
  TeacherBalance?: Maybe<Scalars['Float']>;
  TeacherId?: Maybe<Scalars['Int']>;
  TotalBalance?: Maybe<Scalars['Float']>;
};

export type TransactionTypeListResponse = {
  __typename?: 'TransactionTypeListResponse';
  Result?: Maybe<Array<Maybe<Transaction>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  AddedDate: Scalars['Date'];
  TotalAmount: Scalars['String'];
};

export type SessionContentPaymentSetupQuery = {
  __typename?: 'SessionContentPaymentSetupQuery';
  GetEduraPercentage?: Maybe<EduraSessionContenPercentageTypeResponse>;
  GetNumberOfAvilibilityForStudent?: Maybe<EduraSessionContenPercentageTypeResponse>;
};

export type EduraSessionContenPercentageTypeResponse = {
  __typename?: 'EduraSessionContenPercentageTypeResponse';
  Result?: Maybe<EduraSessionContenPercentage>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type EduraSessionContenPercentage = {
  __typename?: 'EduraSessionContenPercentage';
  AvilabilityDaysForStudent?: Maybe<Scalars['Int']>;
  EduraPercentage?: Maybe<Scalars['Float']>;
};

export type ReportsQuery = {
  __typename?: 'ReportsQuery';
  OnlineContentReports?: Maybe<OnlineContentReportQuery>;
  ParentReports?: Maybe<ParentReportsQuery>;
  StudentsAndActivitiesReports?: Maybe<StudentsAndActivitiesReportsQuery>;
};

export type StudentsAndActivitiesReportsQuery = {
  __typename?: 'StudentsAndActivitiesReportsQuery';
  MostAbsentStudents?: Maybe<MostAbsentStudentTypeListResponse>;
  QuizAbsentStudents?: Maybe<QuizStudentsAttendanceTypeListResponse>;
  StudentAssignmentsGrades?: Maybe<StudentAssignmentsGradesTypeListResponse>;
  StudentQuizzesGrades?: Maybe<StudentQuizzesGradesTypeListResponse>;
};


export type StudentsAndActivitiesReportsQueryMostAbsentStudentsArgs = {
  criteria?: Maybe<MostAbsentStudentCriteria>;
};


export type StudentsAndActivitiesReportsQueryQuizAbsentStudentsArgs = {
  criteria?: Maybe<QuizStudentsAttendanceCriteria>;
};


export type StudentsAndActivitiesReportsQueryStudentAssignmentsGradesArgs = {
  criteria?: Maybe<StudentGradesCriteria>;
};


export type StudentsAndActivitiesReportsQueryStudentQuizzesGradesArgs = {
  criteria?: Maybe<StudentGradesCriteria>;
};

export type MostAbsentStudentTypeListResponse = {
  __typename?: 'MostAbsentStudentTypeListResponse';
  Result?: Maybe<Array<Maybe<MostAbsentStudent>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type MostAbsentStudent = {
  __typename?: 'MostAbsentStudent';
  AbsenceCount?: Maybe<Scalars['Int']>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  CourseGroupName?: Maybe<Scalars['String']>;
  JoinDate?: Maybe<Scalars['Date']>;
  SessionsCount?: Maybe<Scalars['Int']>;
  StudentId?: Maybe<Scalars['Int']>;
  StudentName?: Maybe<Scalars['String']>;
};

export type MostAbsentStudentCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  CourseId?: Maybe<Scalars['Int']>;
  TeacherId?: Maybe<Scalars['Int']>;
  AssistantId?: Maybe<Scalars['Int']>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  From?: Maybe<Scalars['Date']>;
  To?: Maybe<Scalars['Date']>;
};

export type QuizStudentsAttendanceTypeListResponse = {
  __typename?: 'QuizStudentsAttendanceTypeListResponse';
  Result?: Maybe<Array<Maybe<QuizStudentsAttendance>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type QuizStudentsAttendance = {
  __typename?: 'QuizStudentsAttendance';
  Attendance?: Maybe<Scalars['String']>;
  GroupId?: Maybe<Scalars['Int']>;
  NumberOfAttendedActivities?: Maybe<Scalars['Int']>;
  StudentId?: Maybe<Scalars['Int']>;
  StudentName?: Maybe<Scalars['String']>;
};

export type QuizStudentsAttendanceCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  CourseId?: Maybe<Scalars['Int']>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  QuizId?: Maybe<Scalars['Int']>;
};

export type StudentQuizzesGradesTypeListResponse = {
  __typename?: 'StudentQuizzesGradesTypeListResponse';
  Result?: Maybe<Array<Maybe<StudentQuizzesGrades>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type StudentQuizzesGrades = {
  __typename?: 'StudentQuizzesGrades';
  IsMarked?: Maybe<Scalars['Boolean']>;
  IsSubmitted?: Maybe<Scalars['Boolean']>;
  QuizGrade?: Maybe<Scalars['Float']>;
  QuizId?: Maybe<Scalars['Int']>;
  QuizName?: Maybe<Scalars['String']>;
  StudentGrade?: Maybe<Scalars['Float']>;
};

export type StudentGradesCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  StudentId: Scalars['Int'];
};

export type StudentAssignmentsGradesTypeListResponse = {
  __typename?: 'StudentAssignmentsGradesTypeListResponse';
  Result?: Maybe<Array<Maybe<StudentAssignmentsGrades>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type StudentAssignmentsGrades = {
  __typename?: 'StudentAssignmentsGrades';
  AssignmentGrade?: Maybe<Scalars['Float']>;
  AssignmentId?: Maybe<Scalars['Int']>;
  AssignmentName?: Maybe<Scalars['String']>;
  IsMarked?: Maybe<Scalars['Boolean']>;
  IsSubmitted?: Maybe<Scalars['Boolean']>;
  StudentGrade?: Maybe<Scalars['Float']>;
};

export type OnlineContentReportQuery = {
  __typename?: 'OnlineContentReportQuery';
  MostSellingCourses?: Maybe<MostSellingCourseTypeListResponse>;
  MostSellingLectures?: Maybe<MostSellingLectureTypeListResponse>;
  StudentAssignmentGrades?: Maybe<StudentAssignmentGradeTypeListResponse>;
};


export type OnlineContentReportQueryMostSellingCoursesArgs = {
  criteria?: Maybe<MostSellingCourseCriteria>;
};


export type OnlineContentReportQueryMostSellingLecturesArgs = {
  criteria?: Maybe<MostSellingLectureCriteria>;
};


export type OnlineContentReportQueryStudentAssignmentGradesArgs = {
  criteria?: Maybe<StudentAssignmentGradeCriteria>;
};

export type MostSellingLectureTypeListResponse = {
  __typename?: 'MostSellingLectureTypeListResponse';
  Result?: Maybe<Array<Maybe<MostSellingLecture>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type MostSellingLecture = {
  __typename?: 'MostSellingLecture';
  CourseId?: Maybe<Scalars['Int']>;
  LectureId?: Maybe<Scalars['Int']>;
  LectureName?: Maybe<Scalars['String']>;
  SellingCount?: Maybe<Scalars['Int']>;
};

export type MostSellingLectureCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  CourseId?: Maybe<Scalars['Int']>;
};

export type MostSellingCourseTypeListResponse = {
  __typename?: 'MostSellingCourseTypeListResponse';
  Result?: Maybe<Array<Maybe<MostSellingCourse>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type MostSellingCourse = {
  __typename?: 'MostSellingCourse';
  CourseId?: Maybe<Scalars['Int']>;
  CourseName?: Maybe<Scalars['String']>;
  PurchaseCount?: Maybe<Scalars['Int']>;
  SessionCount?: Maybe<Scalars['Int']>;
};

export type MostSellingCourseCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  CourseId?: Maybe<Scalars['Int']>;
};

export type StudentAssignmentGradeTypeListResponse = {
  __typename?: 'StudentAssignmentGradeTypeListResponse';
  Result?: Maybe<Array<Maybe<StudentAssignmentGrade>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type StudentAssignmentGrade = {
  __typename?: 'StudentAssignmentGrade';
  StudentGrade?: Maybe<Scalars['Float']>;
  StudentName?: Maybe<Scalars['String']>;
};

export type StudentAssignmentGradeCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  AssignmentId?: Maybe<Scalars['Int']>;
};

export type ParentReportsQuery = {
  __typename?: 'ParentReportsQuery';
  StudentAssignmentQuestionsGrades?: Maybe<StudentAssignmentTypeResponse>;
  StudentAssignmentsGrades?: Maybe<StudentAssignmentsGradesTypeListResponse>;
  StudentAttendance?: Maybe<StudentAttendanceTypeResponse>;
  StudentQuizzesGrades?: Maybe<StudentQuizzesGradesTypeListResponse>;
  StudentQuizzesQuestionsGrades?: Maybe<StudentQuizTypeResponse>;
};


export type ParentReportsQueryStudentAssignmentQuestionsGradesArgs = {
  criteria?: Maybe<StudentAssignmentCriteria>;
};


export type ParentReportsQueryStudentAssignmentsGradesArgs = {
  criteria?: Maybe<StudentGradesCriteria>;
};


export type ParentReportsQueryStudentAttendanceArgs = {
  criteria?: Maybe<StudentAttendanceCriteria>;
};


export type ParentReportsQueryStudentQuizzesGradesArgs = {
  criteria?: Maybe<StudentGradesCriteria>;
};


export type ParentReportsQueryStudentQuizzesQuestionsGradesArgs = {
  criteria?: Maybe<StudentQuizCriteria>;
};

export type StudentAttendanceTypeResponse = {
  __typename?: 'StudentAttendanceTypeResponse';
  Result?: Maybe<StudentAttendance>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type StudentAttendance = {
  __typename?: 'StudentAttendance';
  AbsencePercentage?: Maybe<Scalars['String']>;
  AttendancePercentage?: Maybe<Scalars['String']>;
  NumOfAbsentedLecs?: Maybe<Scalars['Int']>;
  NumOfAttendedLecs?: Maybe<Scalars['Int']>;
  TotalNumOfLectures?: Maybe<Scalars['Int']>;
};

export type StudentAttendanceCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  CourseId?: Maybe<Scalars['Int']>;
  StudentId: Scalars['Int'];
};

export type StudentAssignmentTypeResponse = {
  __typename?: 'StudentAssignmentTypeResponse';
  Result?: Maybe<StudentAssignment>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type StudentAssignment = {
  __typename?: 'StudentAssignment';
  AnsweredQuestionsPercentage?: Maybe<Scalars['String']>;
  NotAnsweredQuestionsPercentage?: Maybe<Scalars['String']>;
  NumOfAnsweredQuestions?: Maybe<Scalars['Int']>;
  StudentAssignmentsGrade?: Maybe<Scalars['Float']>;
  TotalAssignmentsGrade?: Maybe<Scalars['Float']>;
  TotalNumOfQuestions?: Maybe<Scalars['Int']>;
};

export type StudentAssignmentCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  CourseId?: Maybe<Scalars['Int']>;
  StudentId: Scalars['Int'];
};

export type StudentQuizTypeResponse = {
  __typename?: 'StudentQuizTypeResponse';
  Result?: Maybe<StudentQuiz>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type StudentQuiz = {
  __typename?: 'StudentQuiz';
  AnsweredQuestionsPercentage?: Maybe<Scalars['String']>;
  NotAnsweredQuestionsPercentage?: Maybe<Scalars['String']>;
  NumOfAnsweredQuestions?: Maybe<Scalars['Int']>;
  StudentQuizzesGrade?: Maybe<Scalars['Float']>;
  TotalNumOfQuestions?: Maybe<Scalars['Int']>;
  TotalQuizzesGrade?: Maybe<Scalars['Float']>;
};

export type StudentQuizCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  CourseId?: Maybe<Scalars['Int']>;
  StudentId: Scalars['Int'];
};

export type FawryQuery = {
  __typename?: 'FawryQuery';
  GetFawryMerchantRefNumber?: Maybe<FawryMerchantRefNumberTypeResponse>;
};

export type FawryMerchantRefNumberTypeResponse = {
  __typename?: 'FawryMerchantRefNumberTypeResponse';
  Result?: Maybe<FawryMerchantRefNumber>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type FawryMerchantRefNumber = {
  __typename?: 'FawryMerchantRefNumber';
  isMerchmentRefUsedBefore?: Maybe<Scalars['Boolean']>;
  merchantRefNumber?: Maybe<Scalars['String']>;
};

export type AcademyQuery = {
  __typename?: 'AcademyQuery';
  Academies?: Maybe<AcademyTypeListResponse>;
  AcademyResources?: Maybe<AcademyResourceGroupTypeListResponse>;
  AcademyResourceSettings?: Maybe<AcademyResourceGroupSettingTypeListResponse>;
  AcademyResourceSettingsWithValue?: Maybe<AcademyResourceGroupSettingWithValueTypeListResponse>;
  AcademyResourceTemplate?: Maybe<AcademyResourceTemplateTypeListResponse>;
  AcademySlider?: Maybe<AcademySlideTypeListResponse>;
};


export type AcademyQueryAcademiesArgs = {
  criteria?: Maybe<AcademyCriteria>;
};


export type AcademyQueryAcademyResourcesArgs = {
  criteria?: Maybe<AcademyResourceCriteria>;
};


export type AcademyQueryAcademyResourceSettingsArgs = {
  criteria?: Maybe<AcademyResourceGroupSettingCriteria>;
};


export type AcademyQueryAcademyResourceSettingsWithValueArgs = {
  criteria?: Maybe<AcademyResourceGroupSettingWithValueCriteria>;
};


export type AcademyQueryAcademyResourceTemplateArgs = {
  criteria?: Maybe<AcademyResourceTemplateCriteria>;
};


export type AcademyQueryAcademySliderArgs = {
  criteria?: Maybe<AcademySliderCriteria>;
};

export type AcademyTypeListResponse = {
  __typename?: 'AcademyTypeListResponse';
  Result?: Maybe<Array<Maybe<Academy>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type AcademyCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  CountryId?: Maybe<Scalars['Int']>;
  CityId?: Maybe<Scalars['Int']>;
  AreaId?: Maybe<Scalars['Int']>;
};

export type AcademyResourceGroupSettingTypeListResponse = {
  __typename?: 'AcademyResourceGroupSettingTypeListResponse';
  Result?: Maybe<Array<Maybe<AcademyResourceGroupSetting>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type AcademyResourceGroupSetting = {
  __typename?: 'AcademyResourceGroupSetting';
  Id?: Maybe<Scalars['Int']>;
  Name?: Maybe<Scalars['String']>;
  Settings?: Maybe<Array<Maybe<AcademyResourceSetting>>>;
};

export type AcademyResourceSetting = {
  __typename?: 'AcademyResourceSetting';
  DefaultValue?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  ImagePath?: Maybe<Scalars['String']>;
  Key?: Maybe<Scalars['String']>;
  ValueType?: Maybe<AcademyResourceValueTypes>;
};

export enum AcademyResourceValueTypes {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
  HTML = 'HTML',
  COLOR = 'COLOR',
  IMAGE = 'IMAGE',
  CUSTOM_VALUES = 'CUSTOM_VALUES'
}

export type AcademyResourceGroupSettingCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  AcademyResourceTemplateId?: Maybe<Scalars['Int']>;
};

export type AcademyResourceGroupTypeListResponse = {
  __typename?: 'AcademyResourceGroupTypeListResponse';
  Result?: Maybe<Array<Maybe<AcademyResourceGroup>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type AcademyResourceGroup = {
  __typename?: 'AcademyResourceGroup';
  Id?: Maybe<Scalars['Int']>;
  Name?: Maybe<Scalars['String']>;
  Resources?: Maybe<Array<Maybe<AcademyResource>>>;
};

export type AcademyResource = {
  __typename?: 'AcademyResource';
  ImagePath?: Maybe<Scalars['String']>;
  Key?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
  ValueType?: Maybe<AcademyResourceValueTypes>;
};

export type AcademyResourceCriteria = {
  GroupId?: Maybe<Scalars['Int']>;
};

export type AcademyResourceGroupSettingWithValueTypeListResponse = {
  __typename?: 'AcademyResourceGroupSettingWithValueTypeListResponse';
  Result?: Maybe<Array<Maybe<AcademyResourceGroupSettingWithValue>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type AcademyResourceGroupSettingWithValue = {
  __typename?: 'AcademyResourceGroupSettingWithValue';
  Id?: Maybe<Scalars['Int']>;
  Name?: Maybe<Scalars['String']>;
  Settings?: Maybe<Array<Maybe<AcademyResourceSettingWithValue>>>;
};

export type AcademyResourceSettingWithValue = {
  __typename?: 'AcademyResourceSettingWithValue';
  Id?: Maybe<Scalars['Int']>;
  ImagePath?: Maybe<Scalars['String']>;
  Key?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
  ValueType?: Maybe<AcademyResourceValueTypes>;
};

export type AcademyResourceGroupSettingWithValueCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  AcademyId?: Maybe<Scalars['Int']>;
  AcademyResourceTemplateId?: Maybe<Scalars['Int']>;
};

export type AcademyResourceTemplateTypeListResponse = {
  __typename?: 'AcademyResourceTemplateTypeListResponse';
  Result?: Maybe<Array<Maybe<AcademyResourceTemplate>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type AcademyResourceTemplate = {
  __typename?: 'AcademyResourceTemplate';
  Id?: Maybe<Scalars['Int']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  Name?: Maybe<Scalars['String']>;
};

export type AcademyResourceTemplateCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  IsDefault?: Maybe<Scalars['Boolean']>;
};

export type AcademySlideTypeListResponse = {
  __typename?: 'AcademySlideTypeListResponse';
  Result?: Maybe<Array<Maybe<AcademySlide>>>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type AcademySlide = {
  __typename?: 'AcademySlide';
  AcademyId?: Maybe<Scalars['Int']>;
  AcademyName?: Maybe<Scalars['String']>;
  Action?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  ImagePath?: Maybe<Scalars['String']>;
  Variables?: Maybe<Array<Maybe<AcademySlideVariable>>>;
};

export type AcademySlideVariable = {
  __typename?: 'AcademySlideVariable';
  Key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type AcademySliderCriteria = {
  Id?: Maybe<Scalars['Int']>;
  Ids?: Maybe<Array<Scalars['Int']>>;
  PageNumber?: Maybe<Scalars['Int']>;
  PageSize?: Maybe<Scalars['Int']>;
  PagingEnabled?: Maybe<Scalars['Boolean']>;
  SearchText?: Maybe<Scalars['String']>;
  OrderByColumn?: Maybe<Scalars['String']>;
  OrderDirection?: Maybe<OrderDirection>;
  AcademyId?: Maybe<Scalars['Int']>;
  IsForWeb?: Maybe<Scalars['Boolean']>;
};

export type RootMutation = {
  __typename?: 'RootMutation';
  AcademyMutation?: Maybe<AcademyMutation>;
  Account?: Maybe<AccountMutations>;
  Activity?: Maybe<ActivityMutations>;
  ActivityQuestion?: Maybe<ActivityQuestionMutations>;
  Common?: Maybe<CommonMutation>;
  Course?: Maybe<CourseMutations>;
  CourseGroup?: Maybe<CourseGroupMutations>;
  FawryMutation?: Maybe<FawryMutations>;
  Licence?: Maybe<LicenceMutations>;
  Lookups?: Maybe<LookupsMutations>;
  Marketing?: Maybe<MarketingMutations>;
  Notification?: Maybe<NotificationMutations>;
  OnlineContentMutation?: Maybe<OnlineContentMutation>;
  Parent?: Maybe<ParentMutations>;
  PaymentMutation?: Maybe<PaymentMutation>;
  Request?: Maybe<RequestMutations>;
  Session?: Maybe<SessionMutations>;
  Student?: Maybe<StudentMutations>;
  StudentLicence?: Maybe<StudentLicenceMutation>;
  Teacher?: Maybe<TeacherMutations>;
  TeacherLicence?: Maybe<TeacherLicenceMutation>;
  UserBlock?: Maybe<UserBlockMutation>;
};

export type CommonMutation = {
  __typename?: 'CommonMutation';
  ContactUs?: Maybe<ContactUsTypeResponse>;
};


export type CommonMutationContactUsArgs = {
  model?: Maybe<ContactUsInput>;
};

export type ContactUsTypeResponse = {
  __typename?: 'ContactUsTypeResponse';
  Result?: Maybe<ContactUs>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type ContactUs = {
  __typename?: 'ContactUs';
  Content?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  Mobile?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type ContactUsInput = {
  Name: Scalars['String'];
  Email: Scalars['String'];
  Content: Scalars['String'];
  Mobile: Scalars['String'];
};

export type LookupsMutations = {
  __typename?: 'LookupsMutations';
  CreateActionReason?: Maybe<ActionReasonTypeResponse>;
  CreateArea?: Maybe<AreaTypeResponse>;
  CreateCity?: Maybe<CityTypeResponse>;
  CreateCountry?: Maybe<CountryTypeResponse>;
  CreateEducationStage?: Maybe<EducationStageTypeResponse>;
  CreateEducationType?: Maybe<EducationTypeTypeResponse>;
  CreateSchoolYear?: Maybe<SchoolYearTypeResponse>;
  CreateScientificSubject?: Maybe<ScientificSubjectTypeResponse>;
  CreateTeacherBlockAssistantReason?: Maybe<ActionReasonTypeResponse>;
  CreateTeacherBlockStudentReason?: Maybe<ActionReasonTypeResponse>;
  CreateTeacherRemoveAssistantReason?: Maybe<ActionReasonTypeResponse>;
  CreateTeacherRemoveStudentReason?: Maybe<ActionReasonTypeResponse>;
  CreateTeacherSuspendAssistantReason?: Maybe<ActionReasonTypeResponse>;
  CreateTeacherSuspendStudentReason?: Maybe<ActionReasonTypeResponse>;
  DeleteActionReason?: Maybe<BooleanGraphTypeResponse>;
  DeleteArea?: Maybe<BooleanGraphTypeResponse>;
  DeleteCity?: Maybe<BooleanGraphTypeResponse>;
  DeleteCountry?: Maybe<BooleanGraphTypeResponse>;
  DeleteEducationStage?: Maybe<BooleanGraphTypeResponse>;
  DeleteEducationType?: Maybe<BooleanGraphTypeResponse>;
  DeleteSchoolYear?: Maybe<BooleanGraphTypeResponse>;
  DeleteScientificSubject?: Maybe<BooleanGraphTypeResponse>;
  UpdateActionReason?: Maybe<ActionReasonTypeResponse>;
  UpdateAppResource?: Maybe<AppResourcesTypeResponse>;
  UpdateArea?: Maybe<AreaTypeResponse>;
  UpdateCity?: Maybe<CityTypeResponse>;
  UpdateCountry?: Maybe<CountryTypeResponse>;
  UpdateEducationStage?: Maybe<EducationStageTypeResponse>;
  UpdateEducationType?: Maybe<EducationTypeTypeResponse>;
  UpdateMessageTemplate?: Maybe<MessageTemplateTypeResponse>;
  UpdateSchoolYear?: Maybe<SchoolYearTypeResponse>;
  UpdateScientificSubject?: Maybe<ScientificSubjectTypeResponse>;
};


export type LookupsMutationsCreateActionReasonArgs = {
  model?: Maybe<ActionReasonInput>;
};


export type LookupsMutationsCreateAreaArgs = {
  model?: Maybe<AreaInput>;
};


export type LookupsMutationsCreateCityArgs = {
  model?: Maybe<CityInput>;
};


export type LookupsMutationsCreateCountryArgs = {
  model?: Maybe<CountryInput>;
};


export type LookupsMutationsCreateEducationStageArgs = {
  model?: Maybe<EducationStageInput>;
};


export type LookupsMutationsCreateEducationTypeArgs = {
  model?: Maybe<EducationTypeInput>;
};


export type LookupsMutationsCreateSchoolYearArgs = {
  model?: Maybe<SchoolYearInput>;
};


export type LookupsMutationsCreateScientificSubjectArgs = {
  model?: Maybe<ScientificSubjectInput>;
};


export type LookupsMutationsCreateTeacherBlockAssistantReasonArgs = {
  model?: Maybe<ActionReasonInput>;
};


export type LookupsMutationsCreateTeacherBlockStudentReasonArgs = {
  model?: Maybe<ActionReasonInput>;
};


export type LookupsMutationsCreateTeacherRemoveAssistantReasonArgs = {
  model?: Maybe<ActionReasonInput>;
};


export type LookupsMutationsCreateTeacherRemoveStudentReasonArgs = {
  model?: Maybe<ActionReasonInput>;
};


export type LookupsMutationsCreateTeacherSuspendAssistantReasonArgs = {
  model?: Maybe<ActionReasonInput>;
};


export type LookupsMutationsCreateTeacherSuspendStudentReasonArgs = {
  model?: Maybe<ActionReasonInput>;
};


export type LookupsMutationsDeleteActionReasonArgs = {
  actionReasonId?: Maybe<Scalars['Int']>;
};


export type LookupsMutationsDeleteAreaArgs = {
  areaId?: Maybe<Scalars['Int']>;
};


export type LookupsMutationsDeleteCityArgs = {
  cityId?: Maybe<Scalars['Int']>;
};


export type LookupsMutationsDeleteCountryArgs = {
  countryId?: Maybe<Scalars['Int']>;
};


export type LookupsMutationsDeleteEducationStageArgs = {
  educationStageId?: Maybe<Scalars['Int']>;
};


export type LookupsMutationsDeleteEducationTypeArgs = {
  educationTypeId?: Maybe<Scalars['Int']>;
};


export type LookupsMutationsDeleteSchoolYearArgs = {
  schoolYearId?: Maybe<Scalars['Int']>;
};


export type LookupsMutationsDeleteScientificSubjectArgs = {
  scientificSubjectId?: Maybe<Scalars['Int']>;
};


export type LookupsMutationsUpdateActionReasonArgs = {
  model?: Maybe<ActionReasonInput>;
};


export type LookupsMutationsUpdateAppResourceArgs = {
  model?: Maybe<AppResourceInput>;
};


export type LookupsMutationsUpdateAreaArgs = {
  model?: Maybe<AreaInput>;
};


export type LookupsMutationsUpdateCityArgs = {
  model?: Maybe<CityInput>;
};


export type LookupsMutationsUpdateCountryArgs = {
  model?: Maybe<CountryInput>;
};


export type LookupsMutationsUpdateEducationStageArgs = {
  model?: Maybe<EducationStageInput>;
};


export type LookupsMutationsUpdateEducationTypeArgs = {
  model?: Maybe<EducationTypeInput>;
};


export type LookupsMutationsUpdateMessageTemplateArgs = {
  model?: Maybe<MessageTemplateInput>;
};


export type LookupsMutationsUpdateSchoolYearArgs = {
  model?: Maybe<SchoolYearInput>;
};


export type LookupsMutationsUpdateScientificSubjectArgs = {
  model?: Maybe<ScientificSubjectInput>;
};

export type CountryTypeResponse = {
  __typename?: 'CountryTypeResponse';
  Result?: Maybe<Country>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type CountryInput = {
  Id?: Maybe<Scalars['Int']>;
  NameEn: Scalars['String'];
  NameAr: Scalars['String'];
  CountryCode: Scalars['String'];
  Code: Scalars['String'];
  AcademyIds: Array<Scalars['Int']>;
};

export type CityTypeResponse = {
  __typename?: 'CityTypeResponse';
  Result?: Maybe<City>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type CityInput = {
  Id?: Maybe<Scalars['Int']>;
  NameEn: Scalars['String'];
  NameAr: Scalars['String'];
  Code: Scalars['String'];
  CountryId: Scalars['Int'];
};

export type AreaTypeResponse = {
  __typename?: 'AreaTypeResponse';
  Result?: Maybe<Area>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type AreaInput = {
  Id?: Maybe<Scalars['Int']>;
  NameEn: Scalars['String'];
  NameAr: Scalars['String'];
  Code: Scalars['String'];
  CityId: Scalars['Int'];
};

export type EducationTypeTypeResponse = {
  __typename?: 'EducationTypeTypeResponse';
  Result?: Maybe<EducationType>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type EducationTypeInput = {
  Id?: Maybe<Scalars['Int']>;
  NameEn: Scalars['String'];
  NameAr: Scalars['String'];
  AcademyIds: Array<Scalars['Int']>;
  DescriptionEn?: Maybe<Scalars['String']>;
  DescriptionAr?: Maybe<Scalars['String']>;
};

export type EducationStageTypeResponse = {
  __typename?: 'EducationStageTypeResponse';
  Result?: Maybe<EducationStage>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type EducationStageInput = {
  Id?: Maybe<Scalars['Int']>;
  NameEn: Scalars['String'];
  NameAr: Scalars['String'];
  AcademyIds: Array<Scalars['Int']>;
  DescriptionEn?: Maybe<Scalars['String']>;
  DescriptionAr?: Maybe<Scalars['String']>;
};

export type SchoolYearTypeResponse = {
  __typename?: 'SchoolYearTypeResponse';
  Result?: Maybe<SchoolYear>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type SchoolYearInput = {
  Id?: Maybe<Scalars['Int']>;
  NameEn: Scalars['String'];
  NameAr: Scalars['String'];
  DescriptionEn?: Maybe<Scalars['String']>;
  DescriptionAr?: Maybe<Scalars['String']>;
  EducationStageId: Scalars['Int'];
  ScientificSubjectIds: Array<Scalars['Int']>;
  EducationTypeIds: Array<Scalars['Int']>;
  AcademyIds: Array<Scalars['Int']>;
};

export type ScientificSubjectTypeResponse = {
  __typename?: 'ScientificSubjectTypeResponse';
  Result?: Maybe<ScientificSubject>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type ScientificSubjectInput = {
  Id?: Maybe<Scalars['Int']>;
  NameEn: Scalars['String'];
  NameAr: Scalars['String'];
  DescriptionEn?: Maybe<Scalars['String']>;
  DescriptionAr?: Maybe<Scalars['String']>;
  ImagePath?: Maybe<Scalars['String']>;
  AcademyIds: Array<Scalars['Int']>;
};

export type ActionReasonTypeResponse = {
  __typename?: 'ActionReasonTypeResponse';
  Result?: Maybe<ActionReason>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type ActionReasonInput = {
  Id?: Maybe<Scalars['Int']>;
  NameEn: Scalars['String'];
  NameAr: Scalars['String'];
  ResonType?: Maybe<ActionReasonType>;
};

export type AppResourcesTypeResponse = {
  __typename?: 'AppResourcesTypeResponse';
  Result?: Maybe<AppResources>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type AppResourceInput = {
  Key: Scalars['String'];
  ValueEn?: Maybe<Scalars['String']>;
  ValueAr?: Maybe<Scalars['String']>;
  AcademyId?: Maybe<Scalars['Int']>;
  ValueType?: Maybe<ResourceValueType>;
};

export type MessageTemplateInput = {
  Id?: Maybe<Scalars['Int']>;
  AcademyId?: Maybe<Scalars['Int']>;
  TitleEn?: Maybe<Scalars['String']>;
  TitleAr?: Maybe<Scalars['String']>;
  SmsTemplateEn?: Maybe<Scalars['String']>;
  SmsTemplateAr?: Maybe<Scalars['String']>;
  EmailTemplateEn?: Maybe<Scalars['String']>;
  EmailTemplateAr?: Maybe<Scalars['String']>;
  NotificationTemplateEn?: Maybe<Scalars['String']>;
  NotificationTemplateAr?: Maybe<Scalars['String']>;
  EmailEnabled?: Maybe<Scalars['Boolean']>;
  SmsEnabled?: Maybe<Scalars['Boolean']>;
  NotificationEnabled?: Maybe<Scalars['Boolean']>;
  TemplateType?: Maybe<MessageTemplateType>;
};

/** Contains mutations for account management such as (register, login, verify mobile,...). */
export type AccountMutations = {
  __typename?: 'AccountMutations';
  /** Used to change the password given the old and new password. */
  ChangePassword?: Maybe<ChangePasswordTypeResponse>;
  ChangeUserPasswordForAdmin?: Maybe<BooleanGraphTypeResponse>;
  /** Used to check if the mobile or email assosiated to any user. */
  CheckEmailAndMobileNumber?: Maybe<CheckEmailAndMobileNumberTypeResponse>;
  DeleteMyAccount?: Maybe<BooleanGraphTypeResponse>;
  DeleteStudentTokens?: Maybe<BooleanGraphTypeResponse>;
  /** Used to generate a reset password and send it to the user. */
  ForgetPassword?: Maybe<ForgetPasswordTypeResponse>;
  /** Used to authenticate the user and get access token. */
  Login?: Maybe<LoginTypeResponse>;
  LogoutFromAllDevices?: Maybe<BooleanGraphTypeResponse>;
  LogOutFromCurrentDevice?: Maybe<BooleanGraphTypeResponse>;
  /** Used to Register a new user (student, parent, teacher, assistant). */
  Register?: Maybe<RegisterTypeResponse>;
  /** Used to refresh the access token. */
  RereshToken?: Maybe<TokenTypeResponse>;
  /** Used to resend the verification code to the user. */
  ResendVerificationCode?: Maybe<ResendVerificationCodeTypeResponse>;
  /** Used to reset the password given the reset password code. */
  ResetPassword?: Maybe<ResetPasswordTypeResponse>;
  UpdateConfirmationStatus?: Maybe<BooleanGraphTypeResponse>;
  UpdateMemberMobileNumber?: Maybe<BooleanGraphTypeResponse>;
  UpdateMemberStatus?: Maybe<BooleanGraphTypeResponse>;
  /** Used to update the user profile data. */
  UpdateUserProfile?: Maybe<UserProfileTypeResponse>;
  UpdateUserToken?: Maybe<BooleanGraphTypeResponse>;
  /** Used to check if the given code vaild to reset password for the user. */
  ValidateResetPasswordCode?: Maybe<ValidateResetPasswordCodeTypeResponse>;
  /** Used to verify the mobile number with the given code. */
  VerifyMobile?: Maybe<VerifyMobileTypeResponse>;
};


/** Contains mutations for account management such as (register, login, verify mobile,...). */
export type AccountMutationsChangePasswordArgs = {
  model: ChangePasswordInput;
};


/** Contains mutations for account management such as (register, login, verify mobile,...). */
export type AccountMutationsChangeUserPasswordForAdminArgs = {
  model?: Maybe<ChangePasswordForAdminInput>;
};


/** Contains mutations for account management such as (register, login, verify mobile,...). */
export type AccountMutationsCheckEmailAndMobileNumberArgs = {
  model: CheckEmailAndMobileNumberInput;
};


/** Contains mutations for account management such as (register, login, verify mobile,...). */
export type AccountMutationsDeleteStudentTokensArgs = {
  model?: Maybe<DeleteStudentTokensInput>;
};


/** Contains mutations for account management such as (register, login, verify mobile,...). */
export type AccountMutationsForgetPasswordArgs = {
  model: ForgetPasswordInput;
};


/** Contains mutations for account management such as (register, login, verify mobile,...). */
export type AccountMutationsLoginArgs = {
  model: LoginInput;
};


/** Contains mutations for account management such as (register, login, verify mobile,...). */
export type AccountMutationsRegisterArgs = {
  model: RegisterInput;
};


/** Contains mutations for account management such as (register, login, verify mobile,...). */
export type AccountMutationsRereshTokenArgs = {
  model: RefreshTokenInput;
};


/** Contains mutations for account management such as (register, login, verify mobile,...). */
export type AccountMutationsResendVerificationCodeArgs = {
  model: ResendVerificationCodeInput;
};


/** Contains mutations for account management such as (register, login, verify mobile,...). */
export type AccountMutationsResetPasswordArgs = {
  model: ResetPasswordInput;
};


/** Contains mutations for account management such as (register, login, verify mobile,...). */
export type AccountMutationsUpdateConfirmationStatusArgs = {
  model?: Maybe<UpdateConfirmationStatusInput>;
};


/** Contains mutations for account management such as (register, login, verify mobile,...). */
export type AccountMutationsUpdateMemberMobileNumberArgs = {
  model?: Maybe<UpdateMemberMobileNumberInput>;
};


/** Contains mutations for account management such as (register, login, verify mobile,...). */
export type AccountMutationsUpdateMemberStatusArgs = {
  model?: Maybe<UpdateUserStatusInput>;
};


/** Contains mutations for account management such as (register, login, verify mobile,...). */
export type AccountMutationsUpdateUserProfileArgs = {
  model?: Maybe<UpdateUserProfileInput>;
};


/** Contains mutations for account management such as (register, login, verify mobile,...). */
export type AccountMutationsUpdateUserTokenArgs = {
  model?: Maybe<UserTokenInput>;
};


/** Contains mutations for account management such as (register, login, verify mobile,...). */
export type AccountMutationsValidateResetPasswordCodeArgs = {
  model: ValidateResetPasswordCodeInput;
};


/** Contains mutations for account management such as (register, login, verify mobile,...). */
export type AccountMutationsVerifyMobileArgs = {
  model: VerifyMobileInput;
};

export type LoginTypeResponse = {
  __typename?: 'LoginTypeResponse';
  Result?: Maybe<LoginOutput>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type LoginOutput = {
  __typename?: 'LoginOutput';
  AssistantId?: Maybe<Scalars['Int']>;
  EducationTypeId?: Maybe<Scalars['Int']>;
  ImagePath?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  ParentId?: Maybe<Scalars['Int']>;
  PhoneNumber: Scalars['String'];
  RefreshToken: Scalars['String'];
  StudentId?: Maybe<Scalars['Int']>;
  TeacherId?: Maybe<Scalars['Int']>;
  Token: Scalars['String'];
  TokenExpirationDate: Scalars['Date'];
  UserId: Scalars['Int'];
  UserName: Scalars['String'];
  UserType?: Maybe<ApplicationUserType>;
};

/** Represents a login model. */
export type LoginInput = {
  /** this is a username */
  UserName?: Maybe<Scalars['String']>;
  Password: Scalars['String'];
  AccessToken?: Maybe<Scalars['String']>;
};

export type DeleteStudentTokensInput = {
  UserId: Scalars['Int'];
};

export type TokenTypeResponse = {
  __typename?: 'TokenTypeResponse';
  Result?: Maybe<Token>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type Token = {
  __typename?: 'Token';
  RefreshToken: Scalars['String'];
  Token: Scalars['String'];
  TokenExpirationDate: Scalars['Date'];
};

export type RefreshTokenInput = {
  Token: Scalars['String'];
  RefreshToken: Scalars['String'];
};

export type RegisterTypeResponse = {
  __typename?: 'RegisterTypeResponse';
  Result?: Maybe<RegisterOutput>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type RegisterOutput = {
  __typename?: 'RegisterOutput';
  AssistantId?: Maybe<Scalars['Int']>;
  Code?: Maybe<Scalars['String']>;
  ImagePath?: Maybe<Scalars['String']>;
  ParentId?: Maybe<Scalars['Int']>;
  StudentId?: Maybe<Scalars['Int']>;
  TeacherId?: Maybe<Scalars['Int']>;
  UserId: Scalars['Int'];
};

export type RegisterInput = {
  AreaId: Scalars['Int'];
  Password: Scalars['String'];
  CityId: Scalars['Int'];
  CountryId: Scalars['Int'];
  Email?: Maybe<Scalars['String']>;
  EducationTypeId?: Maybe<Scalars['Int']>;
  SchoolYearId?: Maybe<Scalars['Int']>;
  CountryCode: Scalars['String'];
  Gender?: Maybe<Gender>;
  IdentityNumber?: Maybe<Scalars['String']>;
  MobileNumber: Scalars['String'];
  Name: Scalars['String'];
  IsFromWhiteLabel?: Maybe<Scalars['Boolean']>;
  UserType?: Maybe<ApplicationUserType>;
  SubjectIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type ResendVerificationCodeTypeResponse = {
  __typename?: 'ResendVerificationCodeTypeResponse';
  Result?: Maybe<ResendVerificationCodeOutput>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type ResendVerificationCodeOutput = {
  __typename?: 'ResendVerificationCodeOutput';
  Code?: Maybe<Scalars['String']>;
  UserId: Scalars['Int'];
};

export type ResendVerificationCodeInput = {
  MobileNumber: Scalars['String'];
};

export type VerifyMobileTypeResponse = {
  __typename?: 'VerifyMobileTypeResponse';
  Result?: Maybe<VerifyMobileOutput>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type VerifyMobileOutput = {
  __typename?: 'VerifyMobileOutput';
  AssistantId?: Maybe<Scalars['Int']>;
  ImagePath?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  ParentId?: Maybe<Scalars['Int']>;
  RefreshToken?: Maybe<Scalars['String']>;
  StudentId?: Maybe<Scalars['Int']>;
  TeacherId?: Maybe<Scalars['Int']>;
  Token?: Maybe<Scalars['String']>;
  TokenExpirationDate?: Maybe<Scalars['Date']>;
  UserId: Scalars['Int'];
  UserName?: Maybe<Scalars['String']>;
};

export type VerifyMobileInput = {
  MobileNumber: Scalars['String'];
  Code: Scalars['String'];
  Password?: Maybe<Scalars['String']>;
};

export type ForgetPasswordTypeResponse = {
  __typename?: 'ForgetPasswordTypeResponse';
  Result?: Maybe<ForgetPasswordOutput>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type ForgetPasswordOutput = {
  __typename?: 'ForgetPasswordOutput';
  Code: Scalars['String'];
  UserId: Scalars['Int'];
};

export type ForgetPasswordInput = {
  UserName: Scalars['String'];
};

export type ValidateResetPasswordCodeTypeResponse = {
  __typename?: 'ValidateResetPasswordCodeTypeResponse';
  Result?: Maybe<ValidateResetPasswordCodeOutput>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type ValidateResetPasswordCodeOutput = {
  __typename?: 'ValidateResetPasswordCodeOutput';
  Code: Scalars['String'];
  IsValid: Scalars['Boolean'];
  UserId: Scalars['Int'];
};

export type ValidateResetPasswordCodeInput = {
  UserName: Scalars['String'];
  Code: Scalars['String'];
};

export type ResetPasswordTypeResponse = {
  __typename?: 'ResetPasswordTypeResponse';
  Result?: Maybe<ResetPasswordOutput>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type ResetPasswordOutput = {
  __typename?: 'ResetPasswordOutput';
  AssistantId?: Maybe<Scalars['Int']>;
  ImagePath?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  ParentId?: Maybe<Scalars['Int']>;
  RefreshToken?: Maybe<Scalars['String']>;
  StudentId?: Maybe<Scalars['Int']>;
  TeacherId?: Maybe<Scalars['Int']>;
  Token?: Maybe<Scalars['String']>;
  TokenExpirationDate?: Maybe<Scalars['Date']>;
  UserId: Scalars['Int'];
  UserName?: Maybe<Scalars['String']>;
};

export type ResetPasswordInput = {
  UserName: Scalars['String'];
  Code: Scalars['String'];
  NewPassword: Scalars['String'];
};

export type ChangePasswordTypeResponse = {
  __typename?: 'ChangePasswordTypeResponse';
  Result?: Maybe<ChangePasswordOutput>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type ChangePasswordOutput = {
  __typename?: 'ChangePasswordOutput';
  AssistantId?: Maybe<Scalars['Int']>;
  ImagePath?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  ParentId?: Maybe<Scalars['Int']>;
  RefreshToken?: Maybe<Scalars['String']>;
  StudentId?: Maybe<Scalars['Int']>;
  TeacherId?: Maybe<Scalars['Int']>;
  Token?: Maybe<Scalars['String']>;
  TokenExpirationDate?: Maybe<Scalars['Date']>;
  UserId: Scalars['Int'];
  UserName?: Maybe<Scalars['String']>;
};

export type ChangePasswordInput = {
  UserName?: Maybe<Scalars['String']>;
  OldPassword: Scalars['String'];
  NewPassword: Scalars['String'];
};

export type CheckEmailAndMobileNumberTypeResponse = {
  __typename?: 'CheckEmailAndMobileNumberTypeResponse';
  Result?: Maybe<CheckEmailAndMobileNumberOutput>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type CheckEmailAndMobileNumberOutput = {
  __typename?: 'CheckEmailAndMobileNumberOutput';
  IsValid: Scalars['Boolean'];
};

export type CheckEmailAndMobileNumberInput = {
  Email?: Maybe<Scalars['String']>;
  Mobile?: Maybe<Scalars['String']>;
};

export type UpdateUserProfileInput = {
  Name: Scalars['String'];
  Email?: Maybe<Scalars['String']>;
  Slogan?: Maybe<Scalars['String']>;
  ImagePath?: Maybe<Scalars['String']>;
  Gender?: Maybe<Gender>;
  EducationTypeId?: Maybe<Scalars['Int']>;
  SchoolYearId?: Maybe<Scalars['Int']>;
  Bio?: Maybe<Scalars['String']>;
  VideoPathId?: Maybe<Scalars['String']>;
};

export type UserTokenInput = {
  DeviceId: Scalars['String'];
  DeviceType?: Maybe<DeviceType>;
  Token: Scalars['String'];
  UserId?: Maybe<Scalars['Int']>;
};

export enum DeviceType {
  ANDROID = 'ANDROID',
  IOS = 'IOS'
}

export type UpdateUserStatusInput = {
  UserId: Scalars['Int'];
  Status?: Maybe<ResourceStatus>;
};

export type ChangePasswordForAdminInput = {
  UserName: Scalars['String'];
  NewPassword: Scalars['String'];
};

export type UpdateMemberMobileNumberInput = {
  UserId: Scalars['Int'];
  NewMobileNumber: Scalars['String'];
};

export type UpdateConfirmationStatusInput = {
  UserId: Scalars['Int'];
  IsConfirmed?: Maybe<Scalars['Boolean']>;
};

export type RequestMutations = {
  __typename?: 'RequestMutations';
  AssistantRequests?: Maybe<AssistantRequestMutation>;
  CancelRequest?: Maybe<BooleanGraphTypeResponse>;
  ParentRequests?: Maybe<ParentRequestMutations>;
  StudentRequests?: Maybe<StudentRequestMutations>;
  TeacherRequests?: Maybe<TeacherRequestMutations>;
};


export type RequestMutationsCancelRequestArgs = {
  model: RequestInput;
};

export type StudentRequestMutations = {
  __typename?: 'StudentRequestMutations';
  Absent?: Maybe<RequestTypeResponse>;
  ChangeCourseGroup?: Maybe<RequestTypeResponse>;
  CreateAddParent?: Maybe<RequestTypeResponse>;
  JoinCourseGroup?: Maybe<RequestTypeResponse>;
  ManageAddSonRequest?: Maybe<BooleanGraphTypeResponse>;
  ManageAddToCourseGroupRequest?: Maybe<BooleanGraphTypeResponse>;
};


export type StudentRequestMutationsAbsentArgs = {
  model: AbsentInput;
};


export type StudentRequestMutationsChangeCourseGroupArgs = {
  model: ChangeCourseGroupInput;
};


export type StudentRequestMutationsCreateAddParentArgs = {
  model: AddParentInput;
};


export type StudentRequestMutationsJoinCourseGroupArgs = {
  model: JoinCourseGroupInput;
};


export type StudentRequestMutationsManageAddSonRequestArgs = {
  model: ManageRequestInput;
};


export type StudentRequestMutationsManageAddToCourseGroupRequestArgs = {
  model: ManageRequestInput;
};

export type RequestTypeResponse = {
  __typename?: 'RequestTypeResponse';
  Result?: Maybe<Request>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type JoinCourseGroupInput = {
  CourseGroupId?: Maybe<Scalars['Int']>;
  StudentId?: Maybe<Scalars['Int']>;
};

export type AbsentInput = {
  CourseGroupId?: Maybe<Scalars['Int']>;
  StudentId?: Maybe<Scalars['Int']>;
  SessionId?: Maybe<Scalars['Int']>;
  ActionReasonId?: Maybe<Scalars['Int']>;
  Comment?: Maybe<Scalars['String']>;
};

export type ChangeCourseGroupInput = {
  CourseGroupId?: Maybe<Scalars['Int']>;
  AlternativeCourseGroupId?: Maybe<Scalars['Int']>;
  AlternativeSessionId?: Maybe<Scalars['Int']>;
  StudentId?: Maybe<Scalars['Int']>;
  SessionId?: Maybe<Scalars['Int']>;
  ChangeGroupType?: Maybe<ChangeGroupType>;
};

export enum ChangeGroupType {
  TEMPORARY = 'TEMPORARY',
  PERMANENT = 'PERMANENT'
}

export type AddParentInput = {
  ParentId?: Maybe<Scalars['Int']>;
};

export type ManageRequestInput = {
  ActionReasonId?: Maybe<Scalars['Int']>;
  Comment?: Maybe<Scalars['String']>;
  RequestId: Scalars['Int'];
  RequestStatus?: Maybe<RequestStatus>;
  RequestStatusName?: Maybe<Scalars['String']>;
};

export type TeacherRequestMutations = {
  __typename?: 'TeacherRequestMutations';
  AddAssistantToCourseGroups?: Maybe<RequestTypeListResponse>;
  AddStudentToCourseGroup?: Maybe<RequestTypeResponse>;
  ManageAbsentRequest?: Maybe<BooleanGraphTypeResponse>;
  ManageChangeGroupRequest?: Maybe<BooleanGraphTypeResponse>;
  ManageJoinCourseGroupRequest?: Maybe<BooleanGraphTypeResponse>;
  ManageJoinCourseGroupRequestList?: Maybe<BooleanGraphTypeResponse>;
  ManageJoinTeacherRequest?: Maybe<BooleanGraphTypeResponse>;
};


export type TeacherRequestMutationsAddAssistantToCourseGroupsArgs = {
  model: AddAssistantToCourseGroupsInput;
};


export type TeacherRequestMutationsAddStudentToCourseGroupArgs = {
  model: AddToCourseGroupByTeacherInput;
};


export type TeacherRequestMutationsManageAbsentRequestArgs = {
  model: ManageRequestInput;
};


export type TeacherRequestMutationsManageChangeGroupRequestArgs = {
  model: ManageRequestInput;
};


export type TeacherRequestMutationsManageJoinCourseGroupRequestArgs = {
  model: ManageRequestInput;
};


export type TeacherRequestMutationsManageJoinCourseGroupRequestListArgs = {
  model: ManageRequestListInput;
};


export type TeacherRequestMutationsManageJoinTeacherRequestArgs = {
  model: ManageRequestInput;
};

export type AddToCourseGroupByTeacherInput = {
  StudentId?: Maybe<Scalars['Int']>;
  CourseGroupId?: Maybe<Scalars['Int']>;
};

export type AddAssistantToCourseGroupsInput = {
  AssistantId?: Maybe<Scalars['Int']>;
  CourseGroupIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type ManageRequestListInput = {
  RequestIds: Array<Scalars['Int']>;
  RequestStatus?: Maybe<RequestStatus>;
  RequestStatusName?: Maybe<Scalars['String']>;
};

export type AssistantRequestMutation = {
  __typename?: 'AssistantRequestMutation';
  JoinTeacher?: Maybe<RequestTypeResponse>;
  ManageAddAssistantToCourseRequest?: Maybe<BooleanGraphTypeResponse>;
};


export type AssistantRequestMutationJoinTeacherArgs = {
  model: JoinTeacherInput;
};


export type AssistantRequestMutationManageAddAssistantToCourseRequestArgs = {
  model: ManageRequestInput;
};

export type JoinTeacherInput = {
  TeacherId?: Maybe<Scalars['Int']>;
};

export type ParentRequestMutations = {
  __typename?: 'ParentRequestMutations';
  AddSonRequest?: Maybe<RequestTypeResponse>;
  ManageAddParentRequest?: Maybe<BooleanGraphTypeResponse>;
};


export type ParentRequestMutationsAddSonRequestArgs = {
  model: AddSonInput;
};


export type ParentRequestMutationsManageAddParentRequestArgs = {
  model: ManageRequestInput;
};

export type AddSonInput = {
  StudentId?: Maybe<Scalars['Int']>;
};

export type RequestInput = {
  Id?: Maybe<Scalars['Int']>;
  ActionReasonId?: Maybe<Scalars['Int']>;
  Comment?: Maybe<Scalars['String']>;
};

export type TeacherMutations = {
  __typename?: 'TeacherMutations';
  AssignAssistantCourseGroupRoles?: Maybe<AssignAssistantRolesTypeResponse>;
  AssignAssistantPublicRoles?: Maybe<AssignAssistantRolesTypeResponse>;
  ConfirmStudentAttendance?: Maybe<SessionAttendanceTypeResponse>;
  CreatePreviousExam?: Maybe<PreviousExamTypeResponse>;
  CreateTeacherQuestion?: Maybe<TeacherQuestionTypeResponse>;
  CreateTeacherSubjectAndCourse?: Maybe<CourseTypeResponse>;
  DeleteAssistant?: Maybe<BooleanGraphTypeResponse>;
  DeletePreviousExam?: Maybe<BooleanGraphTypeResponse>;
  DeleteStudent?: Maybe<BooleanGraphTypeResponse>;
  DeleteTeacherQuestion?: Maybe<BooleanGraphTypeResponse>;
  EditPreviousExam?: Maybe<BooleanGraphTypeResponse>;
  EditTeacherQuestion?: Maybe<TeacherQuestionTypeResponse>;
  FollowTeacher?: Maybe<TeacherFollowerTypeResponse>;
  MarkActivityOffline?: Maybe<BooleanGraphTypeResponse>;
  MarkStudentAnswerOnline?: Maybe<BooleanGraphTypeResponse>;
  RemoveParent?: Maybe<BooleanGraphTypeResponse>;
  RemoveTeacherForTester?: Maybe<TeacherTypeResponse>;
  SuspendAndActiveAssistant?: Maybe<BooleanGraphTypeResponse>;
  SuspendAndActiveStudent?: Maybe<BooleanGraphTypeResponse>;
  UnFollowTeacher?: Maybe<TeacherFollowerTypeResponse>;
  UpdateAndAddToQuestionBank?: Maybe<BooleanGraphTypeResponse>;
  UpdateTeacherActivities?: Maybe<BooleanGraphTypeResponse>;
};


export type TeacherMutationsAssignAssistantCourseGroupRolesArgs = {
  model?: Maybe<AssignAssistantRolesInput>;
};


export type TeacherMutationsAssignAssistantPublicRolesArgs = {
  model?: Maybe<AssignAssistantRolesInput>;
};


export type TeacherMutationsConfirmStudentAttendanceArgs = {
  model?: Maybe<ConfirmSessionAttendanceInput>;
};


export type TeacherMutationsCreatePreviousExamArgs = {
  model?: Maybe<AddPreviousExamInput>;
};


export type TeacherMutationsCreateTeacherQuestionArgs = {
  model?: Maybe<TeacherQuestionInput>;
};


export type TeacherMutationsCreateTeacherSubjectAndCourseArgs = {
  model?: Maybe<TeacherSubjectInput>;
};


export type TeacherMutationsDeleteAssistantArgs = {
  model?: Maybe<ManageAssistantInput>;
};


export type TeacherMutationsDeletePreviousExamArgs = {
  previousExamId?: Maybe<Scalars['Int']>;
};


export type TeacherMutationsDeleteStudentArgs = {
  model?: Maybe<ManageStudentInput>;
};


export type TeacherMutationsDeleteTeacherQuestionArgs = {
  questionId?: Maybe<Scalars['Int']>;
};


export type TeacherMutationsEditPreviousExamArgs = {
  model?: Maybe<EditPreviousExamInput>;
};


export type TeacherMutationsEditTeacherQuestionArgs = {
  model?: Maybe<TeacherQuestionInput>;
};


export type TeacherMutationsFollowTeacherArgs = {
  model?: Maybe<TeacherFollowerInput>;
};


export type TeacherMutationsMarkActivityOfflineArgs = {
  model?: Maybe<MarkActivityInput>;
};


export type TeacherMutationsMarkStudentAnswerOnlineArgs = {
  model?: Maybe<MarkStudentAnswerOnlineInput>;
};


export type TeacherMutationsRemoveParentArgs = {
  model?: Maybe<RemoveParentInput>;
};


export type TeacherMutationsRemoveTeacherForTesterArgs = {
  model: TeacherInput;
};


export type TeacherMutationsSuspendAndActiveAssistantArgs = {
  model?: Maybe<ManageAssistantInput>;
};


export type TeacherMutationsSuspendAndActiveStudentArgs = {
  model?: Maybe<ManageStudentInput>;
};


export type TeacherMutationsUnFollowTeacherArgs = {
  model?: Maybe<TeacherFollowerInput>;
};


export type TeacherMutationsUpdateAndAddToQuestionBankArgs = {
  model?: Maybe<UpdateCourseQuestionInput>;
};


export type TeacherMutationsUpdateTeacherActivitiesArgs = {
  model?: Maybe<UpdateTeacherActivitiesInputType>;
};

export type TeacherFollowerTypeResponse = {
  __typename?: 'TeacherFollowerTypeResponse';
  Result?: Maybe<TeacherFollower>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type TeacherFollower = {
  __typename?: 'TeacherFollower';
  ParentId?: Maybe<Scalars['Int']>;
  StudentId?: Maybe<Scalars['Int']>;
  TeacherId: Scalars['Int'];
};

export type TeacherFollowerInput = {
  TeacherId: Scalars['Int'];
  StudentId?: Maybe<Scalars['Int']>;
  ParentId?: Maybe<Scalars['Int']>;
};

export type ManageStudentInput = {
  TeacherId?: Maybe<Scalars['Int']>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  StudentIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  StudentStatus?: Maybe<CourseGroupStudentStatus>;
  ReasonId?: Maybe<Scalars['Int']>;
  Comment?: Maybe<Scalars['String']>;
};

export type ManageAssistantInput = {
  TeacherId?: Maybe<Scalars['Int']>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  AssistantIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  AssistantStatus?: Maybe<CourseGroupAssistantStatus>;
  ReasonId?: Maybe<Scalars['Int']>;
  Comment?: Maybe<Scalars['String']>;
};

export type RemoveParentInput = {
  ParentId: Scalars['Int'];
  CourseGroupIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type TeacherQuestionTypeResponse = {
  __typename?: 'TeacherQuestionTypeResponse';
  Result?: Maybe<TeacherQuestion>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type TeacherQuestionInput = {
  Id?: Maybe<Scalars['Int']>;
  TeacherId?: Maybe<Scalars['Int']>;
  SubjectId: Scalars['Int'];
  QuestionText: Scalars['String'];
  Answer?: Maybe<Scalars['String']>;
  ChoiceOne?: Maybe<Scalars['String']>;
  ChoiceTwo?: Maybe<Scalars['String']>;
  ChoiceThree?: Maybe<Scalars['String']>;
  Tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  ChoiceFour?: Maybe<Scalars['String']>;
  Level?: Maybe<QuestionLevel>;
  QuestionType?: Maybe<QuestionType>;
  AnswerAttachs?: Maybe<Array<Maybe<Scalars['String']>>>;
  QuestionAttachs?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateCourseQuestionInput = {
  Id: Scalars['Int'];
  IsAccepted: Scalars['Boolean'];
};

export type PreviousExamTypeResponse = {
  __typename?: 'PreviousExamTypeResponse';
  Result?: Maybe<PreviousExam>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type AddPreviousExamInput = {
  TeacherId?: Maybe<Scalars['Int']>;
  AssistantId?: Maybe<Scalars['Int']>;
  CourseId: Scalars['Int'];
  Title: Scalars['String'];
  ExamYear: Scalars['String'];
  AttachmentPath?: Maybe<Scalars['String']>;
  Attachments: Array<Maybe<Scalars['String']>>;
};

export type EditPreviousExamInput = {
  Id: Scalars['Int'];
  CourseId: Scalars['Int'];
  Title: Scalars['String'];
  ExamYear: Scalars['String'];
  AttachmentPath?: Maybe<Scalars['String']>;
  Attachments: Array<Maybe<Scalars['String']>>;
};

export type MarkActivityInput = {
  ActivityId: Scalars['Int'];
  StudentId: Scalars['Int'];
  TeacherId?: Maybe<Scalars['Int']>;
  AssistantId?: Maybe<Scalars['Int']>;
  Grade: Scalars['Float'];
};

export type MarkStudentAnswerOnlineInput = {
  TeacherId?: Maybe<Scalars['Int']>;
  AssistantId?: Maybe<Scalars['Int']>;
  StudentId: Scalars['Int'];
  AnswerId: Scalars['Int'];
  Grade: Scalars['Float'];
};

export type AssignAssistantRolesTypeResponse = {
  __typename?: 'AssignAssistantRolesTypeResponse';
  Result?: Maybe<AssignAssistantRolesType>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type AssignAssistantRolesType = {
  __typename?: 'AssignAssistantRolesType';
  AssistantId?: Maybe<Scalars['Int']>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  TeacherId?: Maybe<Scalars['Int']>;
};

export type AssignAssistantRolesInput = {
  AssistantId: Scalars['Int'];
  CourseGroupId?: Maybe<Scalars['Int']>;
  Roles?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type CourseTypeResponse = {
  __typename?: 'CourseTypeResponse';
  Result?: Maybe<Course>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type TeacherSubjectInput = {
  SchoolYearId: Scalars['Int'];
  SemesterId: Scalars['Int'];
  EducationTypeId: Scalars['Int'];
  ScientificSubjectId: Scalars['Int'];
  IsActive: Scalars['Boolean'];
  NumOfCourseGroups?: Maybe<Scalars['Int']>;
  ImagePath?: Maybe<Scalars['String']>;
};

export type SessionAttendanceTypeResponse = {
  __typename?: 'SessionAttendanceTypeResponse';
  Result?: Maybe<SessionAttendance>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type ConfirmSessionAttendanceInput = {
  SessionId: Scalars['Int'];
  StudentIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type TeacherTypeResponse = {
  __typename?: 'TeacherTypeResponse';
  Result?: Maybe<Teacher>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type TeacherInput = {
  Id?: Maybe<Scalars['Int']>;
  MobileNumber?: Maybe<Scalars['String']>;
};

export type UpdateTeacherActivitiesInputType = {
  Features?: Maybe<Array<Maybe<TeacherFeatureInputType>>>;
  CallHours?: Maybe<Array<Maybe<TeacherHoursInputType>>>;
  VisitHours?: Maybe<Array<Maybe<TeacherHoursInputType>>>;
};

export type TeacherFeatureInputType = {
  Id?: Maybe<Scalars['Int']>;
  FeatureId?: Maybe<Scalars['Int']>;
  RowState?: Maybe<RowState>;
};

export enum RowState {
  NOT_CHANGED = 'NOT_CHANGED',
  ADDED = 'ADDED',
  UPDATD = 'UPDATD',
  DELETED = 'DELETED'
}

export type TeacherHoursInputType = {
  Id?: Maybe<Scalars['Int']>;
  Day?: Maybe<Day>;
  AvailablityStatus?: Maybe<AvailablityStatus>;
  AvailabilityType?: Maybe<AvailabilityType>;
  RowState?: Maybe<RowState>;
  From?: Maybe<Scalars['String']>;
  To?: Maybe<Scalars['String']>;
};

export type StudentMutations = {
  __typename?: 'StudentMutations';
  ApplyActivityOnline?: Maybe<UserActivityTypeResponse>;
  ManageSuggestedQuestion?: Maybe<BooleanGraphTypeResponse>;
  RemoveParent?: Maybe<BooleanGraphTypeResponse>;
  RemoveStudentForTester?: Maybe<StudentTypeResponse>;
  SubmitCourseGroupRate?: Maybe<CourseGroupStudentsTypeResponse>;
  SubmitCourseGroupReport?: Maybe<CourseGroupStudentsTypeResponse>;
  SuggestQuestion?: Maybe<CourseQuestionTypeResponse>;
  ViewRevision?: Maybe<ViewRevisionTypeResponse>;
};


export type StudentMutationsApplyActivityOnlineArgs = {
  model?: Maybe<ApplyActivityOnlineInput>;
};


export type StudentMutationsManageSuggestedQuestionArgs = {
  model?: Maybe<UpdateCourseQuestionInput>;
};


export type StudentMutationsRemoveParentArgs = {
  model?: Maybe<StudentParentInput>;
};


export type StudentMutationsRemoveStudentForTesterArgs = {
  model: StudentInput;
};


export type StudentMutationsSubmitCourseGroupRateArgs = {
  model?: Maybe<CourseGroupRateInput>;
};


export type StudentMutationsSubmitCourseGroupReportArgs = {
  model?: Maybe<CourseGroupReportInput>;
};


export type StudentMutationsSuggestQuestionArgs = {
  model?: Maybe<SuggestQuestionInput>;
};


export type StudentMutationsViewRevisionArgs = {
  model: ViewRevisionInput;
};

export type StudentParentInput = {
  StudentId?: Maybe<Scalars['Int']>;
  ParentId?: Maybe<Scalars['Int']>;
};

export type StudentTypeResponse = {
  __typename?: 'StudentTypeResponse';
  Result?: Maybe<Student>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type StudentInput = {
  Id?: Maybe<Scalars['Int']>;
  MobileNumber?: Maybe<Scalars['String']>;
};

export type UserActivityTypeResponse = {
  __typename?: 'UserActivityTypeResponse';
  Result?: Maybe<StudentActivity>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type ApplyActivityOnlineInput = {
  ActivityId: Scalars['Int'];
  StudentId: Scalars['Int'];
  CourseGroupId?: Maybe<Scalars['Int']>;
  StudentAnswers?: Maybe<Array<Maybe<StudentAnswerInput>>>;
};

export type StudentAnswerInput = {
  ActivityQuestionId: Scalars['Int'];
  Answer?: Maybe<Scalars['String']>;
  AnswerComment?: Maybe<Scalars['String']>;
};

export type CourseQuestionTypeResponse = {
  __typename?: 'CourseQuestionTypeResponse';
  Result?: Maybe<CourseQuestion>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type SuggestQuestionInput = {
  StudentId?: Maybe<Scalars['Int']>;
  CourseGroupId: Scalars['Int'];
  QuestionText?: Maybe<Scalars['String']>;
  QuestionPath?: Maybe<Scalars['String']>;
  Answer?: Maybe<Scalars['String']>;
  AnswerPath?: Maybe<Scalars['String']>;
  AnswerAttachs?: Maybe<Array<Maybe<AttachmentInput>>>;
  QuestionAttachs?: Maybe<Array<Maybe<AttachmentInput>>>;
};

export type AttachmentInput = {
  AttachmentPath: Scalars['String'];
  MainImage?: Maybe<Scalars['Boolean']>;
  RowState?: Maybe<RowState>;
};

export type CourseGroupStudentsTypeResponse = {
  __typename?: 'CourseGroupStudentsTypeResponse';
  Result?: Maybe<CourseGroupStudents>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type CourseGroupRateInput = {
  CourseGroupId: Scalars['Int'];
  StudentId?: Maybe<Scalars['Int']>;
  Rate?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['String']>;
};

export type CourseGroupReportInput = {
  CourseGroupId: Scalars['Int'];
  StudentId?: Maybe<Scalars['Int']>;
  Report?: Maybe<Scalars['String']>;
};

export type ViewRevisionTypeResponse = {
  __typename?: 'ViewRevisionTypeResponse';
  Result?: Maybe<ViewRevision>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type ViewRevision = {
  __typename?: 'ViewRevision';
  ActivityId?: Maybe<Scalars['Int']>;
  IsView?: Maybe<Scalars['Boolean']>;
  StudentId?: Maybe<Scalars['Int']>;
};

export type ViewRevisionInput = {
  ActivityId: Scalars['Int'];
  StudentId: Scalars['Int'];
};

export type CourseMutations = {
  __typename?: 'CourseMutations';
  CreateCourse?: Maybe<CourseTypeResponse>;
};


export type CourseMutationsCreateCourseArgs = {
  model?: Maybe<CourseInput>;
};

export type CourseInput = {
  SubjectId: Scalars['Int'];
  StartDate?: Maybe<Scalars['Date']>;
  EndDate?: Maybe<Scalars['Date']>;
  Cost?: Maybe<Scalars['Int']>;
  IsActive: Scalars['Boolean'];
  ImagePath?: Maybe<Scalars['String']>;
  NumOfCourseGroups?: Maybe<Scalars['Int']>;
};

export type ActivityMutations = {
  __typename?: 'ActivityMutations';
  ApplyActivityOffline?: Maybe<ApplyActivityOfflineTypeResponse>;
  ApplyComment?: Maybe<ApplyCommentTypeResponse>;
  CreateActivity?: Maybe<ActivityTypeResponse>;
  CreateAssignment?: Maybe<ActivityTypeListResponse>;
  CreateChallenge?: Maybe<ActivityTypeListResponse>;
  CreatePost?: Maybe<ActivityTypeListResponse>;
  CreateQuiz?: Maybe<ActivityTypeListResponse>;
  CreateRevision?: Maybe<ActivityTypeListResponse>;
  CreateSurvey?: Maybe<ActivityTypeListResponse>;
  DeleteActivtiy?: Maybe<BooleanGraphTypeResponse>;
  JoinActivity?: Maybe<ActivityTypeResponse>;
  UpdateAssignment?: Maybe<ActivityTypeResponse>;
  UpdateChallenge?: Maybe<ActivityTypeResponse>;
  UpdatePost?: Maybe<ActivityTypeResponse>;
  UpdateQuiz?: Maybe<ActivityTypeResponse>;
  UpdateRevision?: Maybe<ActivityTypeResponse>;
  UpdateSurvey?: Maybe<ActivityTypeResponse>;
};


export type ActivityMutationsApplyActivityOfflineArgs = {
  model?: Maybe<ApplyActivityOfflineInput>;
};


export type ActivityMutationsApplyCommentArgs = {
  model?: Maybe<ApplyCommentInput>;
};


export type ActivityMutationsCreateActivityArgs = {
  model?: Maybe<ActivityInput>;
};


export type ActivityMutationsCreateAssignmentArgs = {
  model?: Maybe<AssignmentInput>;
};


export type ActivityMutationsCreateChallengeArgs = {
  model?: Maybe<ChallengeInput>;
};


export type ActivityMutationsCreatePostArgs = {
  model?: Maybe<PostInput>;
};


export type ActivityMutationsCreateQuizArgs = {
  model?: Maybe<QuizInput>;
};


export type ActivityMutationsCreateRevisionArgs = {
  model?: Maybe<RevisionInput>;
};


export type ActivityMutationsCreateSurveyArgs = {
  model?: Maybe<SurveyInput>;
};


export type ActivityMutationsDeleteActivtiyArgs = {
  activtiyId?: Maybe<Scalars['Int']>;
};


export type ActivityMutationsJoinActivityArgs = {
  model?: Maybe<ActivityInput>;
};


export type ActivityMutationsUpdateAssignmentArgs = {
  model?: Maybe<AssignmentInput>;
};


export type ActivityMutationsUpdateChallengeArgs = {
  model?: Maybe<ChallengeInput>;
};


export type ActivityMutationsUpdatePostArgs = {
  model?: Maybe<PostInput>;
};


export type ActivityMutationsUpdateQuizArgs = {
  model?: Maybe<QuizInput>;
};


export type ActivityMutationsUpdateRevisionArgs = {
  model?: Maybe<RevisionInput>;
};


export type ActivityMutationsUpdateSurveyArgs = {
  model?: Maybe<SurveyInput>;
};

export type ActivityInput = {
  ActivityType?: Maybe<ActivityType>;
  ActivitySource?: Maybe<ActivitySource>;
  StudentId?: Maybe<Scalars['Int']>;
  TeacherId?: Maybe<Scalars['Int']>;
  SessionId?: Maybe<Scalars['Int']>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  CourseId?: Maybe<Scalars['Int']>;
  Title?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  Description?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  CanResubmite?: Maybe<Scalars['Boolean']>;
  CanSeeAnswer?: Maybe<Scalars['Boolean']>;
  IsOnline?: Maybe<Scalars['Boolean']>;
  StartDate?: Maybe<Scalars['Date']>;
  Date?: Maybe<Scalars['Date']>;
  DeadLine?: Maybe<Scalars['Date']>;
  ActivityDuration?: Maybe<Scalars['Seconds']>;
  Grade?: Maybe<Scalars['Float']>;
  AppliedCount?: Maybe<Scalars['Int']>;
  NotAppliedCount?: Maybe<Scalars['Int']>;
  SuccededCount?: Maybe<Scalars['Int']>;
  FailedCount?: Maybe<Scalars['Int']>;
};

export type ApplyActivityOfflineTypeResponse = {
  __typename?: 'ApplyActivityOfflineTypeResponse';
  Result?: Maybe<ApplyActivityOffline>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type ApplyActivityOffline = {
  __typename?: 'ApplyActivityOffline';
  ActivityId?: Maybe<Scalars['Int']>;
  Attachments?: Maybe<Array<Maybe<Scalars['String']>>>;
  Id?: Maybe<Scalars['Int']>;
  StudentId?: Maybe<Scalars['Int']>;
};

export type ApplyActivityOfflineInput = {
  ActivityId: Scalars['Int'];
  StudentId: Scalars['Int'];
  Attachments?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ApplyCommentTypeResponse = {
  __typename?: 'ApplyCommentTypeResponse';
  Result?: Maybe<ApplyComment>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type ApplyComment = {
  __typename?: 'ApplyComment';
  ActivityId?: Maybe<Scalars['Int']>;
  AssistantId?: Maybe<Scalars['Int']>;
  Comment?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  ParentUserActivityId?: Maybe<Scalars['Int']>;
  StudentId?: Maybe<Scalars['Int']>;
  TeacherId?: Maybe<Scalars['Int']>;
};

export type ApplyCommentInput = {
  ActivityId: Scalars['Int'];
  StudentId?: Maybe<Scalars['Int']>;
  TeacherId?: Maybe<Scalars['Int']>;
  AssistantId?: Maybe<Scalars['Int']>;
  Comment?: Maybe<Scalars['String']>;
  ParentUserActivityId?: Maybe<Scalars['Int']>;
};

export type PostInput = {
  Id?: Maybe<Scalars['Int']>;
  CourseGroupIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  Content?: Maybe<Scalars['String']>;
  IsDarft?: Maybe<Scalars['Boolean']>;
  PublishNow?: Maybe<Scalars['Boolean']>;
  IsScheduled?: Maybe<Scalars['Boolean']>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  Title: Scalars['String'];
  ActivitySource?: Maybe<ActivitySource>;
  StartDate?: Maybe<Scalars['DateTime']>;
  Attachments?: Maybe<Array<Maybe<Scalars['String']>>>;
  VideoPathId?: Maybe<Scalars['String']>;
  HideFromRecording?: Maybe<Scalars['Boolean']>;
};

export type AssignmentInput = {
  Id?: Maybe<Scalars['Int']>;
  Title: Scalars['String'];
  Content?: Maybe<Scalars['String']>;
  TeacherId?: Maybe<Scalars['Int']>;
  CourseGroupIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  Attachments?: Maybe<Array<Maybe<Scalars['String']>>>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  SessionId?: Maybe<Scalars['Int']>;
  CanSeeAnswer?: Maybe<Scalars['Boolean']>;
  CanResubmite?: Maybe<Scalars['Boolean']>;
  SessionContentId?: Maybe<Scalars['Int']>;
  IsOnline?: Maybe<Scalars['Boolean']>;
  Grade?: Maybe<Scalars['Float']>;
  IsDarft?: Maybe<Scalars['Boolean']>;
  PublishNow?: Maybe<Scalars['Boolean']>;
  IsScheduled?: Maybe<Scalars['Boolean']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  DeadLine?: Maybe<Scalars['DateTime']>;
  ActivityQuestions?: Maybe<Array<Maybe<AssignmentQuestionInput>>>;
};

export type AssignmentQuestionInput = {
  Id?: Maybe<Scalars['Int']>;
  ActivityId?: Maybe<Scalars['Int']>;
  Description?: Maybe<Scalars['String']>;
  QuestionText: Scalars['String'];
  ChoiceOne?: Maybe<Scalars['String']>;
  ChoiceTwo?: Maybe<Scalars['String']>;
  ChoiceThree?: Maybe<Scalars['String']>;
  ChoiceFour?: Maybe<Scalars['String']>;
  Grade?: Maybe<Scalars['Float']>;
  Answer?: Maybe<Scalars['String']>;
  AnswerAttachs?: Maybe<Array<Maybe<Scalars['String']>>>;
  QuestionAttachs?: Maybe<Array<Maybe<Scalars['String']>>>;
  Level?: Maybe<QuestionLevel>;
  QuestionType?: Maybe<QuestionType>;
  RowState?: Maybe<RowState>;
};

export type QuizInput = {
  Id?: Maybe<Scalars['Int']>;
  Title: Scalars['String'];
  ShuffledQuestions?: Maybe<Scalars['Boolean']>;
  Content?: Maybe<Scalars['String']>;
  CourseGroupIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  Attachments?: Maybe<Array<Maybe<Scalars['String']>>>;
  ActivityDuration?: Maybe<Scalars['Int']>;
  Grade?: Maybe<Scalars['Float']>;
  IsOnline?: Maybe<Scalars['Boolean']>;
  CanResubmite?: Maybe<Scalars['Boolean']>;
  CanSeeAnswer?: Maybe<Scalars['Boolean']>;
  IsDarft?: Maybe<Scalars['Boolean']>;
  PublishNow?: Maybe<Scalars['Boolean']>;
  IsScheduled?: Maybe<Scalars['Boolean']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  DeadLine?: Maybe<Scalars['DateTime']>;
  ActivityQuestions?: Maybe<Array<Maybe<AssignmentQuestionInput>>>;
};

export type RevisionInput = {
  Id?: Maybe<Scalars['Int']>;
  Title?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  TeacherId?: Maybe<Scalars['Int']>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  CourseGroupIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  Attachments?: Maybe<Array<Maybe<Scalars['String']>>>;
  SessionId?: Maybe<Scalars['Int']>;
  IsOnline?: Maybe<Scalars['Boolean']>;
  IsDarft?: Maybe<Scalars['Boolean']>;
  PublishNow?: Maybe<Scalars['Boolean']>;
  IsScheduled?: Maybe<Scalars['Boolean']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  DeadLine?: Maybe<Scalars['DateTime']>;
  ActivityQuestions?: Maybe<Array<Maybe<AssignmentQuestionInput>>>;
};

export type ChallengeInput = {
  Id?: Maybe<Scalars['Int']>;
  Title?: Maybe<Scalars['String']>;
  Grade?: Maybe<Scalars['Float']>;
  ActivityDuration?: Maybe<Scalars['Seconds']>;
  TeacherId?: Maybe<Scalars['Int']>;
  StartTime?: Maybe<Scalars['String']>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  CourseGroupIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  SessionId?: Maybe<Scalars['Int']>;
  IsOnline?: Maybe<Scalars['Boolean']>;
  IsDarft?: Maybe<Scalars['Boolean']>;
  PublishNow?: Maybe<Scalars['Boolean']>;
  IsScheduled?: Maybe<Scalars['Boolean']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  DeadLine?: Maybe<Scalars['DateTime']>;
  ActivityQuestions?: Maybe<Array<Maybe<AssignmentQuestionInput>>>;
};

export type SurveyInput = {
  Id?: Maybe<Scalars['Int']>;
  Title?: Maybe<Scalars['String']>;
  TeacherId?: Maybe<Scalars['Int']>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  CourseGroupIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  SessionId?: Maybe<Scalars['Int']>;
  IsOnline?: Maybe<Scalars['Boolean']>;
  IsDarft?: Maybe<Scalars['Boolean']>;
  PublishNow?: Maybe<Scalars['Boolean']>;
  IsScheduled?: Maybe<Scalars['Boolean']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  DeadLine?: Maybe<Scalars['DateTime']>;
  ActivityQuestions?: Maybe<Array<Maybe<AssignmentQuestionInput>>>;
};

export type MarketingMutations = {
  __typename?: 'MarketingMutations';
  ChangeVoucherDetailStatus?: Maybe<BooleanGraphTypeResponse>;
  ChangeVoucherMasterStatus?: Maybe<BooleanGraphTypeResponse>;
  ChargeWalletByCode?: Maybe<ChargeWalletByCodeTypeResponse>;
  CreateVoucher?: Maybe<VoucherMasterTypeResponse>;
  CreateVoucherDetails?: Maybe<BooleanGraphTypeResponse>;
};


export type MarketingMutationsChangeVoucherDetailStatusArgs = {
  model?: Maybe<SuspendVoucherInput>;
};


export type MarketingMutationsChangeVoucherMasterStatusArgs = {
  model?: Maybe<SuspendVoucherInput>;
};


export type MarketingMutationsChargeWalletByCodeArgs = {
  model?: Maybe<ChargeWalletByCodeInput>;
};


export type MarketingMutationsCreateVoucherArgs = {
  model?: Maybe<VoucherInput>;
};


export type MarketingMutationsCreateVoucherDetailsArgs = {
  model?: Maybe<VoucherDetailInput>;
};

export type VoucherMasterTypeResponse = {
  __typename?: 'VoucherMasterTypeResponse';
  Result?: Maybe<VoucherMaster>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type VoucherInput = {
  Id?: Maybe<Scalars['Int']>;
  Title: Scalars['String'];
  DetailsCount: Scalars['Int'];
  Cost: Scalars['Decimal'];
  Value: Scalars['Decimal'];
  AcademyId: Scalars['Int'];
  ExpirationDate: Scalars['Date'];
};

export type VoucherDetailInput = {
  VoucherMasterId: Scalars['Int'];
  CodesCount: Scalars['Int'];
};

export type ChargeWalletByCodeTypeResponse = {
  __typename?: 'ChargeWalletByCodeTypeResponse';
  Result?: Maybe<ChargeWalletByCode>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type ChargeWalletByCode = {
  __typename?: 'ChargeWalletByCode';
  Amount?: Maybe<Scalars['Decimal']>;
  Id?: Maybe<Scalars['Int']>;
  StudentBalance?: Maybe<Scalars['Decimal']>;
  TransactionId?: Maybe<Scalars['Int']>;
};

export type ChargeWalletByCodeInput = {
  Code?: Maybe<Scalars['String']>;
};

export type SuspendVoucherInput = {
  Id: Scalars['Int'];
  Status?: Maybe<ResourceStatus>;
};

export type ActivityQuestionMutations = {
  __typename?: 'ActivityQuestionMutations';
  DeleteActivityQuestion?: Maybe<BooleanGraphTypeResponse>;
};


export type ActivityQuestionMutationsDeleteActivityQuestionArgs = {
  activityQuestionId?: Maybe<Scalars['Int']>;
};

export type SessionMutations = {
  __typename?: 'SessionMutations';
  AskSessionQuestion?: Maybe<SessionQuestionTypeResponse>;
  AttendSessionWithQRCode?: Maybe<SessionAttendanceQrCodeTypeResponse>;
  CreateSession?: Maybe<SessionTypeResponse>;
  DeleteSession?: Maybe<BooleanGraphTypeResponse>;
  EndAllMeeting?: Maybe<BooleanGraphTypeResponse>;
  EndMeeting?: Maybe<BooleanGraphTypeResponse>;
  GenerateQRCode?: Maybe<StringGraphTypeResponse>;
  SessionQuestion?: Maybe<SessionQuestionMutations>;
  StartOnlineSession?: Maybe<StartOnlineSessionTypeResponse>;
  SubmitSessionRate?: Maybe<SessionRateTypeResponse>;
  UpdateSession?: Maybe<SessionTypeResponse>;
};


export type SessionMutationsAskSessionQuestionArgs = {
  model?: Maybe<SessionQuestionInput>;
};


export type SessionMutationsAttendSessionWithQrCodeArgs = {
  model?: Maybe<SessionAttendanceQrCodeInput>;
};


export type SessionMutationsCreateSessionArgs = {
  model?: Maybe<SessionInput>;
};


export type SessionMutationsDeleteSessionArgs = {
  sessionId?: Maybe<Scalars['Int']>;
};


export type SessionMutationsEndMeetingArgs = {
  meetingId?: Maybe<Scalars['String']>;
};


export type SessionMutationsGenerateQrCodeArgs = {
  sessionId?: Maybe<Scalars['Int']>;
};


export type SessionMutationsStartOnlineSessionArgs = {
  model?: Maybe<StartOnlineSessionInput>;
};


export type SessionMutationsSubmitSessionRateArgs = {
  model?: Maybe<SessionRateInput>;
};


export type SessionMutationsUpdateSessionArgs = {
  model?: Maybe<SessionInput>;
};

export type SessionQuestionMutations = {
  __typename?: 'SessionQuestionMutations';
  AnswerSessionQuestion?: Maybe<SessionQuestionTypeResponse>;
  DeleteSessionQuestion?: Maybe<BooleanGraphTypeResponse>;
};


export type SessionQuestionMutationsAnswerSessionQuestionArgs = {
  model?: Maybe<AnswerSessionQusetionInput>;
};


export type SessionQuestionMutationsDeleteSessionQuestionArgs = {
  model?: Maybe<DeleteSessionQusetionInput>;
};

export type SessionQuestionTypeResponse = {
  __typename?: 'SessionQuestionTypeResponse';
  Result?: Maybe<SessionQuestion>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type AnswerSessionQusetionInput = {
  SessionQuestionId: Scalars['Int'];
  Answer?: Maybe<Scalars['String']>;
  AttachmentPath?: Maybe<Scalars['String']>;
  AddToQuestionBank: Scalars['Boolean'];
  Attachments?: Maybe<Array<Maybe<AttachmentInput>>>;
};

export type DeleteSessionQusetionInput = {
  SessionQuestionId: Scalars['Int'];
};

export type SessionTypeResponse = {
  __typename?: 'SessionTypeResponse';
  Result?: Maybe<Session>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type SessionInput = {
  Id?: Maybe<Scalars['Int']>;
  VideoPathIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  SessionType?: Maybe<SessionType>;
  SessionAttendType?: Maybe<SessionAttendType>;
  SessionSettings?: Maybe<ZoomMeetingSettingsInput>;
  Duration?: Maybe<Scalars['Int']>;
  HasQRCode?: Maybe<Scalars['Boolean']>;
  StartTime: Scalars['String'];
  EndTime: Scalars['String'];
  CourseGroupIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  Attatchments?: Maybe<Array<Maybe<Scalars['String']>>>;
  Date: Scalars['Date'];
  Title: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  AbsentCount?: Maybe<Scalars['Int']>;
  AttendCount?: Maybe<Scalars['Int']>;
  IsMoreThanOneGroup?: Maybe<Scalars['Boolean']>;
};

export type ZoomMeetingSettingsInput = {
  Topic?: Maybe<Scalars['String']>;
  Agenda?: Maybe<Scalars['String']>;
  AutoRecording?: Maybe<Scalars['Boolean']>;
};

export type SessionRateTypeResponse = {
  __typename?: 'SessionRateTypeResponse';
  Result?: Maybe<SessionRate>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type SessionRate = {
  __typename?: 'SessionRate';
  Comment?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  Rate?: Maybe<Scalars['Float']>;
  SessionId?: Maybe<Scalars['Int']>;
  StudentId?: Maybe<Scalars['Int']>;
};

export type SessionRateInput = {
  SessionId: Scalars['Int'];
  StudentId?: Maybe<Scalars['Int']>;
  Rate?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['String']>;
};

export type SessionAttendanceQrCodeTypeResponse = {
  __typename?: 'SessionAttendanceQRCodeTypeResponse';
  Result?: Maybe<SessionAttendanceQrCode>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type SessionAttendanceQrCode = {
  __typename?: 'SessionAttendanceQRCode';
  Id?: Maybe<Scalars['Int']>;
  IsAttended?: Maybe<Scalars['Boolean']>;
  SessionId?: Maybe<Scalars['Int']>;
  StudentId?: Maybe<Scalars['Int']>;
  StudentQRCode?: Maybe<Scalars['String']>;
};

export type SessionAttendanceQrCodeInput = {
  SessionId: Scalars['Int'];
  StudentId?: Maybe<Scalars['Int']>;
  StudentQRCode?: Maybe<Scalars['String']>;
};

export type SessionQuestionInput = {
  SessionId?: Maybe<Scalars['Int']>;
  SessionContentId?: Maybe<Scalars['Int']>;
  StudentId: Scalars['Int'];
  Question: Scalars['String'];
  CourseGroupId?: Maybe<Scalars['Int']>;
  Attachments?: Maybe<Array<Maybe<AttachmentInput>>>;
};

export type StringGraphTypeResponse = {
  __typename?: 'StringGraphTypeResponse';
  Result?: Maybe<Scalars['String']>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type StartOnlineSessionTypeResponse = {
  __typename?: 'StartOnlineSessionTypeResponse';
  Result?: Maybe<StartOnlineSession>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type StartOnlineSession = {
  __typename?: 'StartOnlineSession';
  APIKey?: Maybe<Scalars['String']>;
  APISecret?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  MeetingId?: Maybe<Scalars['String']>;
  MeetingPassword?: Maybe<Scalars['String']>;
  Signature?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  UserId?: Maybe<Scalars['String']>;
  ZAK?: Maybe<Scalars['String']>;
};

export type StartOnlineSessionInput = {
  Id?: Maybe<Scalars['Int']>;
};

export type NotificationMutations = {
  __typename?: 'NotificationMutations';
  DeleteNotification?: Maybe<BooleanGraphTypeResponse>;
  ReadNotification?: Maybe<BooleanGraphTypeResponse>;
};


export type NotificationMutationsDeleteNotificationArgs = {
  notificationId?: Maybe<Scalars['Int']>;
};


export type NotificationMutationsReadNotificationArgs = {
  notificationId?: Maybe<Scalars['Int']>;
};

export type CourseGroupMutations = {
  __typename?: 'CourseGroupMutations';
  CreateCourseGroup?: Maybe<CourseGroupTypeResponse>;
  DeleteCourseGroup?: Maybe<BooleanGraphTypeResponse>;
  TransfareToCourseGroup?: Maybe<BooleanGraphTypeResponse>;
  UpdateCourseGroup?: Maybe<CourseGroupTypeResponse>;
};


export type CourseGroupMutationsCreateCourseGroupArgs = {
  model: CourseGroupInput;
};


export type CourseGroupMutationsDeleteCourseGroupArgs = {
  model: DeleteCourseGroupInput;
};


export type CourseGroupMutationsTransfareToCourseGroupArgs = {
  model: TransfareToCourseGroupInput;
};


export type CourseGroupMutationsUpdateCourseGroupArgs = {
  model: CourseGroupInput;
};

export type CourseGroupTypeResponse = {
  __typename?: 'CourseGroupTypeResponse';
  Result?: Maybe<CourseGroup>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type CourseGroupInput = {
  AllowAbsentRequest: Scalars['Boolean'];
  AllowChangeGroupRequest: Scalars['Boolean'];
  AllowNewRequests: Scalars['Boolean'];
  IsActive: Scalars['Boolean'];
  IsLimited?: Maybe<Scalars['Boolean']>;
  LimitationPeriodInDays?: Maybe<Scalars['Int']>;
  Name: Scalars['String'];
  Id?: Maybe<Scalars['Int']>;
  CourseId?: Maybe<Scalars['Int']>;
  NumberOfStudents: Scalars['Int'];
  CourseGroupHours?: Maybe<Array<Maybe<CourseGroupHourInput>>>;
  GroupAssistants?: Maybe<Array<Maybe<CourseGroupAssistantInput>>>;
};

export type CourseGroupHourInput = {
  CourseGroupId?: Maybe<Scalars['Int']>;
  Day?: Maybe<Day>;
  DayStatus?: Maybe<AvailablityStatus>;
  StartTime?: Maybe<Scalars['String']>;
  EndTime?: Maybe<Scalars['String']>;
  RowState?: Maybe<RowState>;
};

export type CourseGroupAssistantInput = {
  AssistantId?: Maybe<Scalars['Int']>;
  AssistantName?: Maybe<Scalars['String']>;
  RowState?: Maybe<RowState>;
  Roles?: Maybe<Array<Maybe<AssistantRoleInput>>>;
};

export type AssistantRoleInput = {
  AssistantId?: Maybe<Scalars['Int']>;
  CourseGroupId?: Maybe<Scalars['Int']>;
  RoleName?: Maybe<Scalars['String']>;
  RoleId: Scalars['Int'];
  RowState?: Maybe<RowState>;
  TeacherId?: Maybe<Scalars['Int']>;
};

export type DeleteCourseGroupInput = {
  Id: Scalars['Int'];
};

export type TransfareToCourseGroupInput = {
  CourseGroupId: Scalars['Int'];
  TransfaredGroupId: Scalars['Int'];
  StudentIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type TeacherLicenceMutation = {
  __typename?: 'TeacherLicenceMutation';
  CreateTeacherNote?: Maybe<NoteTypeResponse>;
  ExtendTeacherLicence?: Maybe<BooleanGraphTypeResponse>;
  PayTeacherLicence?: Maybe<BooleanGraphTypeResponse>;
  RequestTeacherLicence?: Maybe<RequestTeacherLicenceTypeResponse>;
  UpdateTeacherLicenceStatus?: Maybe<BooleanGraphTypeResponse>;
};


export type TeacherLicenceMutationCreateTeacherNoteArgs = {
  model?: Maybe<NoteInput>;
};


export type TeacherLicenceMutationExtendTeacherLicenceArgs = {
  model?: Maybe<ExtendTeacherLicenceInput>;
};


export type TeacherLicenceMutationPayTeacherLicenceArgs = {
  model?: Maybe<PayLicenceInput>;
};


export type TeacherLicenceMutationRequestTeacherLicenceArgs = {
  model: RequestTeacherLicenceInput;
};


export type TeacherLicenceMutationUpdateTeacherLicenceStatusArgs = {
  model?: Maybe<ManageTeacherLicenceInput>;
};

export type RequestTeacherLicenceTypeResponse = {
  __typename?: 'RequestTeacherLicenceTypeResponse';
  Result?: Maybe<RequestTeacherLicence>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type RequestTeacherLicence = {
  __typename?: 'RequestTeacherLicence';
  CourseId?: Maybe<Scalars['Int']>;
  LicenceInstallmentTypeId?: Maybe<Scalars['Int']>;
  NumberOfLicences?: Maybe<Scalars['Int']>;
  PaymentMethod?: Maybe<TeacherLicencePaymentMethod>;
  PaymentType?: Maybe<PaymentType>;
};

export type RequestTeacherLicenceInput = {
  CourseId?: Maybe<Scalars['Int']>;
  LicenceInstallmentTypeId?: Maybe<Scalars['Int']>;
  NumberOfLicences: Scalars['Int'];
  PaymentMethod?: Maybe<TeacherLicencePaymentMethod>;
  PaymentType?: Maybe<PaymentType>;
};

export type ManageTeacherLicenceInput = {
  TeacherLicenceId: Scalars['Int'];
  TeacherLicenceStatus?: Maybe<TeacherLicenceStatus>;
};

export type ExtendTeacherLicenceInput = {
  TeacherLicenceId: Scalars['Int'];
  NumberOfDays: Scalars['Int'];
};

export type PayLicenceInput = {
  TeacherLicenceId: Scalars['Int'];
  Amount?: Maybe<Scalars['Float']>;
  TeacherLicenceInstallmentIds?: Maybe<Array<Scalars['Int']>>;
  PaymentType?: Maybe<PaymentType>;
};

export type NoteTypeResponse = {
  __typename?: 'NoteTypeResponse';
  Result?: Maybe<Note>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type NoteInput = {
  TeacherId?: Maybe<Scalars['Int']>;
  AccountManagerId?: Maybe<Scalars['Int']>;
  NoteContent?: Maybe<Scalars['String']>;
  NoteDirection?: Maybe<NoteDirection>;
  NoteType?: Maybe<NoteType>;
};

export type StudentLicenceMutation = {
  __typename?: 'StudentLicenceMutation';
  AssignLicenceToStudent?: Maybe<StudentLicenceTypeResponse>;
};


export type StudentLicenceMutationAssignLicenceToStudentArgs = {
  model: AssignLicenceToStudentInput;
};

export type StudentLicenceTypeResponse = {
  __typename?: 'StudentLicenceTypeResponse';
  Result?: Maybe<StudentLicence>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type AssignLicenceToStudentInput = {
  CourseGroupId: Scalars['Int'];
  StudentId: Scalars['Int'];
};

export type UserBlockMutation = {
  __typename?: 'UserBlockMutation';
  ActiveParent?: Maybe<BooleanGraphTypeResponse>;
  BlockAssistant?: Maybe<BooleanGraphTypeResponse>;
  BlockParent?: Maybe<BooleanGraphTypeResponse>;
  BlockStudent?: Maybe<BooleanGraphTypeResponse>;
  UnBlockAssistant?: Maybe<BooleanGraphTypeResponse>;
  UnBlockStudent?: Maybe<BooleanGraphTypeResponse>;
};


export type UserBlockMutationActiveParentArgs = {
  model?: Maybe<BlockParentInput>;
};


export type UserBlockMutationBlockAssistantArgs = {
  model?: Maybe<ManageAssistantInput>;
};


export type UserBlockMutationBlockParentArgs = {
  model?: Maybe<BlockParentInput>;
};


export type UserBlockMutationBlockStudentArgs = {
  model?: Maybe<ManageStudentInput>;
};


export type UserBlockMutationUnBlockAssistantArgs = {
  model?: Maybe<UnBlockAssistantInput>;
};


export type UserBlockMutationUnBlockStudentArgs = {
  model?: Maybe<UnBlockStudentInput>;
};

export type UnBlockStudentInput = {
  StudentIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type UnBlockAssistantInput = {
  AssistantIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type BlockParentInput = {
  TeacherId: Scalars['Int'];
  ParentIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type LicenceMutations = {
  __typename?: 'LicenceMutations';
  UpdateLicence?: Maybe<BooleanGraphTypeResponse>;
  UpdateLicenceInstallmentTypes?: Maybe<BooleanGraphTypeResponse>;
};


export type LicenceMutationsUpdateLicenceArgs = {
  model: LicenceInput;
};


export type LicenceMutationsUpdateLicenceInstallmentTypesArgs = {
  types: Array<Maybe<LicenceInstallmentTypeInput>>;
};

export type LicenceInput = {
  ExpireInDays: Scalars['Int'];
  Cost: Scalars['Float'];
  AcademyId: Scalars['Int'];
};

export type LicenceInstallmentTypeInput = {
  Id?: Maybe<Scalars['Int']>;
  AcademyId: Scalars['Int'];
  NumberOfMonthes: Scalars['Int'];
};

export type ParentMutations = {
  __typename?: 'ParentMutations';
  RemoveChild?: Maybe<BooleanGraphTypeResponse>;
};


export type ParentMutationsRemoveChildArgs = {
  model?: Maybe<StudentParentInput>;
};

export type OnlineContentMutation = {
  __typename?: 'OnlineContentMutation';
  CourseContent?: Maybe<CourseContentMutations>;
  JoinContentRequest?: Maybe<JoinContentRequestMutations>;
  SessionContent?: Maybe<SessionContentMutations>;
  SessionContentStudent?: Maybe<SessionContentStudentMutation>;
  SessionPartContent?: Maybe<SessionContentPartMutation>;
};

export type JoinContentRequestMutations = {
  __typename?: 'JoinContentRequestMutations';
  CancelJoinContentRequest?: Maybe<BooleanGraphTypeResponse>;
  ManageJoinContentRequest?: Maybe<BooleanGraphTypeResponse>;
  SendJoinContentRequest?: Maybe<JoinContentRequestTypeResponse>;
};


export type JoinContentRequestMutationsCancelJoinContentRequestArgs = {
  model?: Maybe<CancelJoinContentRequestInput>;
};


export type JoinContentRequestMutationsManageJoinContentRequestArgs = {
  model?: Maybe<ManageJoinContentRequestInput>;
};


export type JoinContentRequestMutationsSendJoinContentRequestArgs = {
  model?: Maybe<JoinContentRequestInput>;
};

export type JoinContentRequestTypeResponse = {
  __typename?: 'JoinContentRequestTypeResponse';
  Result?: Maybe<JoinContentRequest>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type JoinContentRequestInput = {
  EducationTypeId: Scalars['Int'];
  SchoolYearId: Scalars['Int'];
  ScientificSubjectId: Scalars['Int'];
  ProducingWay?: Maybe<ProducingWay>;
  Description?: Maybe<Scalars['String']>;
  EstimatedLecturePrice: Scalars['Float'];
  IntroVideoPathId?: Maybe<Scalars['String']>;
  SampleLecturePathId: Scalars['String'];
  ContentRequestType?: Maybe<ContentRequestType>;
};

export type CancelJoinContentRequestInput = {
  JoinContentRequestId: Scalars['Int'];
};

export type ManageJoinContentRequestInput = {
  JoinContentRequestId: Scalars['Int'];
  RequestStatus?: Maybe<RequestStatus>;
};

export type CourseContentMutations = {
  __typename?: 'CourseContentMutations';
  CancelCourseContent?: Maybe<BooleanGraphTypeResponse>;
  CreateCourseContent?: Maybe<CourseContentTypeResponse>;
  DeleteCourseContent?: Maybe<BooleanGraphTypeResponse>;
  ManageCourseContent?: Maybe<BooleanGraphTypeResponse>;
  UpdateCourseContent?: Maybe<BooleanGraphTypeResponse>;
};


export type CourseContentMutationsCancelCourseContentArgs = {
  model?: Maybe<CancelCourseContentInput>;
};


export type CourseContentMutationsCreateCourseContentArgs = {
  model?: Maybe<CourseContentInput>;
};


export type CourseContentMutationsDeleteCourseContentArgs = {
  courseContentId?: Maybe<Scalars['Int']>;
};


export type CourseContentMutationsManageCourseContentArgs = {
  model?: Maybe<ManageCourseContentInput>;
};


export type CourseContentMutationsUpdateCourseContentArgs = {
  model?: Maybe<CourseContentInput>;
};

export type CourseContentTypeResponse = {
  __typename?: 'CourseContentTypeResponse';
  Result?: Maybe<CourseContent>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type CourseContentInput = {
  Id?: Maybe<Scalars['Int']>;
  EducationTypeId: Scalars['Int'];
  SchoolYearId: Scalars['Int'];
  ScientificSubjectId: Scalars['Int'];
  Description?: Maybe<Scalars['String']>;
  ImagePath?: Maybe<Scalars['String']>;
  CourseType?: Maybe<CourseType>;
};

export type CancelCourseContentInput = {
  CourseContentId: Scalars['Int'];
};

export type ManageCourseContentInput = {
  CourseContentId: Scalars['Int'];
  ActionComment?: Maybe<Scalars['String']>;
  ActionReasonId?: Maybe<Scalars['Int']>;
  RequestStatus?: Maybe<RequestStatus>;
};

export type SessionContentMutations = {
  __typename?: 'SessionContentMutations';
  CreateSessionContent?: Maybe<SessionContentTypeResponse>;
  DeleteSessionContent?: Maybe<BooleanGraphTypeResponse>;
  ManageUpdateSessionContent?: Maybe<SessionContentTypeResponse>;
  SwitchSessionContentStatus?: Maybe<BooleanGraphTypeResponse>;
  UpdateSessionContent?: Maybe<SessionContentTypeResponse>;
};


export type SessionContentMutationsCreateSessionContentArgs = {
  model?: Maybe<SessionContentInput>;
};


export type SessionContentMutationsDeleteSessionContentArgs = {
  sessionContentId?: Maybe<Scalars['Int']>;
};


export type SessionContentMutationsManageUpdateSessionContentArgs = {
  model?: Maybe<ManageUpdateSessionContentInput>;
};


export type SessionContentMutationsSwitchSessionContentStatusArgs = {
  sessionContentId?: Maybe<Scalars['Int']>;
};


export type SessionContentMutationsUpdateSessionContentArgs = {
  model?: Maybe<SessionContentInput>;
};

export type SessionContentTypeResponse = {
  __typename?: 'SessionContentTypeResponse';
  Result?: Maybe<SessionContent>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type SessionContentInput = {
  Id?: Maybe<Scalars['Int']>;
  ChapterId?: Maybe<Scalars['Int']>;
  ChapterName?: Maybe<Scalars['String']>;
  CourseContentId?: Maybe<Scalars['Int']>;
  Description?: Maybe<Scalars['String']>;
  Price: Scalars['Float'];
  Title: Scalars['String'];
  UnitId?: Maybe<Scalars['Int']>;
  UnitName?: Maybe<Scalars['String']>;
  Rate?: Maybe<Scalars['Float']>;
  PublishDate?: Maybe<Scalars['DateTime']>;
  IntroVideoPathId?: Maybe<Scalars['String']>;
  CourseType?: Maybe<CourseType>;
  SessionSettings?: Maybe<ZoomMeetingSettingsInput>;
};

export type ManageUpdateSessionContentInput = {
  RequestReaction?: Maybe<RequestStatus>;
  SessionContentTempId?: Maybe<Scalars['Int']>;
  Comment?: Maybe<Scalars['String']>;
  RejectSessionContentReasonId?: Maybe<Scalars['Int']>;
};

export type SessionContentPartMutation = {
  __typename?: 'SessionContentPartMutation';
  CreateSessionContentPart?: Maybe<SessionContentPartTypeResponse>;
  ManageUpdateSessionContentPart?: Maybe<SessionContentPartTypeResponse>;
  UpdateSessionContentPart?: Maybe<SessionContentPartTypeResponse>;
};


export type SessionContentPartMutationCreateSessionContentPartArgs = {
  model?: Maybe<SessionContentPartInput>;
};


export type SessionContentPartMutationManageUpdateSessionContentPartArgs = {
  model?: Maybe<ManageUpdateSessionContentPartInput>;
};


export type SessionContentPartMutationUpdateSessionContentPartArgs = {
  model?: Maybe<SessionContentPartInput>;
};

export type SessionContentPartTypeResponse = {
  __typename?: 'SessionContentPartTypeResponse';
  Result?: Maybe<SessionContentPart>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type SessionContentPartInput = {
  SessionContentId?: Maybe<Scalars['Int']>;
  Id?: Maybe<Scalars['Int']>;
  Title: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  VideoPathId?: Maybe<Scalars['String']>;
  Attachments?: Maybe<Array<Maybe<AttachmentInput>>>;
};

export type ManageUpdateSessionContentPartInput = {
  RequestReaction?: Maybe<RequestStatus>;
  SessionContentPartTempId?: Maybe<Scalars['Int']>;
  Comment?: Maybe<Scalars['String']>;
  RejectSessionContentPartReasonId?: Maybe<Scalars['Int']>;
};

export type SessionContentStudentMutation = {
  __typename?: 'SessionContentStudentMutation';
  BuySessionContent?: Maybe<SessionContentStudentTypeResponse>;
  BuySessionContentByCode?: Maybe<SessionContentStudentTypeResponse>;
  CancelBuySessionContent?: Maybe<BooleanGraphTypeResponse>;
  RateSessionContent?: Maybe<SessionContentStudentTypeResponse>;
  ReBuySessionContent?: Maybe<SessionContentStudentTypeResponse>;
};


export type SessionContentStudentMutationBuySessionContentArgs = {
  model?: Maybe<BuySessionContentInput>;
};


export type SessionContentStudentMutationBuySessionContentByCodeArgs = {
  model?: Maybe<BuySessionContentInput>;
};


export type SessionContentStudentMutationCancelBuySessionContentArgs = {
  model?: Maybe<CancelBuySessionContentInput>;
};


export type SessionContentStudentMutationRateSessionContentArgs = {
  model?: Maybe<RateSessionContentInput>;
};


export type SessionContentStudentMutationReBuySessionContentArgs = {
  model?: Maybe<BuySessionContentInput>;
};

export type SessionContentStudentTypeResponse = {
  __typename?: 'SessionContentStudentTypeResponse';
  Result?: Maybe<SessionContentStudent>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type RateSessionContentInput = {
  SessionContentId: Scalars['Int'];
  Rate: Scalars['Float'];
};

export type BuySessionContentInput = {
  SessionContentId: Scalars['Int'];
  Code?: Maybe<Scalars['String']>;
};

export type CancelBuySessionContentInput = {
  SessionContentId: Scalars['Int'];
};

export type PaymentMutation = {
  __typename?: 'PaymentMutation';
  StudentBalance?: Maybe<StudentBalanceMutation>;
  TeacherBalance?: Maybe<TeacherBalanceMutation>;
};

export type StudentBalanceMutation = {
  __typename?: 'StudentBalanceMutation';
  CreateStudentWallets?: Maybe<BooleanGraphTypeResponse>;
  ManageStudentBalanceByAdmin?: Maybe<BooleanGraphTypeResponse>;
  RechargeStudentWallet?: Maybe<StudentBalanceTypeResponse>;
};


export type StudentBalanceMutationManageStudentBalanceByAdminArgs = {
  model?: Maybe<UpdateStudentBalanceInput>;
};


export type StudentBalanceMutationRechargeStudentWalletArgs = {
  model?: Maybe<RechargeStudentWalletInput>;
};

export type RechargeStudentWalletInput = {
  Amount: Scalars['Float'];
};

export type UpdateStudentBalanceInput = {
  StudentId: Scalars['Int'];
  Amount: Scalars['Float'];
  SessionContentId?: Maybe<Scalars['Int']>;
  WalletTransactionDirection?: Maybe<TransactionDirection>;
  StudentWalletTransactionType?: Maybe<StudentWalletTransactionType>;
};

export type TeacherBalanceMutation = {
  __typename?: 'TeacherBalanceMutation';
  CollectTeacherMoney?: Maybe<BooleanGraphTypeResponse>;
  CreateTeachertWallets?: Maybe<BooleanGraphTypeResponse>;
};

export type FawryMutations = {
  __typename?: 'FawryMutations';
  ReChargeStudentWalletWithFawry?: Maybe<FawryPaymentResponseTypeResponse>;
};


export type FawryMutationsReChargeStudentWalletWithFawryArgs = {
  model?: Maybe<ChargeStudentWalletFawryInput>;
};

export type FawryPaymentResponseTypeResponse = {
  __typename?: 'FawryPaymentResponseTypeResponse';
  Result?: Maybe<FawryPaymentResponse>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type FawryPaymentResponse = {
  __typename?: 'FawryPaymentResponse';
  customerMail?: Maybe<Scalars['String']>;
  customerMerchantId?: Maybe<Scalars['String']>;
  customerMobile?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  fawryFees?: Maybe<Scalars['Decimal']>;
  fawryRefNumber?: Maybe<Scalars['String']>;
  merchantRefNumber?: Maybe<Scalars['String']>;
  orderAmount?: Maybe<Scalars['Decimal']>;
  orderExpiryDate?: Maybe<Scalars['Decimal']>;
  orderExpiryDateFormat?: Maybe<Scalars['String']>;
  orderStatus?: Maybe<Scalars['String']>;
  paymentAmount?: Maybe<Scalars['Decimal']>;
  paymentMethod?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['String']>;
};

export type ChargeStudentWalletFawryInput = {
  StudentId: Scalars['Int'];
  MerchantRefNumber: Scalars['String'];
  TrxId: Scalars['String'];
  IsReleaseEnvironment: Scalars['Boolean'];
};

export type AcademyMutation = {
  __typename?: 'AcademyMutation';
  CreateAcademy?: Maybe<AcademyTypeResponse>;
  CreateAcademyForTeacher?: Maybe<AcademyTypeResponse>;
  CreateResourceGroupSetting?: Maybe<AcademyResourceGroupSettingTypeResponse>;
  CreateResourceTemplate?: Maybe<AcademyResourceTemplateTypeResponse>;
  CreateSlide?: Maybe<AcademySlideTypeResponse>;
  DeleteResourceGroupSetting?: Maybe<BooleanGraphTypeResponse>;
  DeleteResourceTemplate?: Maybe<BooleanGraphTypeResponse>;
  DeleteSlide?: Maybe<BooleanGraphTypeResponse>;
  UpdateAcademyResources?: Maybe<BooleanGraphTypeResponse>;
  UpdateAcademyStatus?: Maybe<BooleanGraphTypeResponse>;
  UpdateResourceGroupSetting?: Maybe<AcademyResourceGroupSettingTypeResponse>;
  UpdateResourceTemplate?: Maybe<AcademyResourceTemplateTypeResponse>;
  UpdateSlide?: Maybe<BooleanGraphTypeResponse>;
};


export type AcademyMutationCreateAcademyArgs = {
  model?: Maybe<AcademyInput>;
};


export type AcademyMutationCreateAcademyForTeacherArgs = {
  model?: Maybe<AcademyForTeacherInput>;
};


export type AcademyMutationCreateResourceGroupSettingArgs = {
  model?: Maybe<AcademyResourceGroupSettingInput>;
};


export type AcademyMutationCreateResourceTemplateArgs = {
  model?: Maybe<AcademyResourceTemplateInput>;
};


export type AcademyMutationCreateSlideArgs = {
  model?: Maybe<AcademySlideInput>;
};


export type AcademyMutationDeleteResourceGroupSettingArgs = {
  groupId?: Maybe<Scalars['Int']>;
};


export type AcademyMutationDeleteResourceTemplateArgs = {
  templateId?: Maybe<Scalars['Int']>;
};


export type AcademyMutationDeleteSlideArgs = {
  slideId?: Maybe<Scalars['Int']>;
};


export type AcademyMutationUpdateAcademyResourcesArgs = {
  model?: Maybe<UpdateAcademyResourcesInput>;
};


export type AcademyMutationUpdateAcademyStatusArgs = {
  model?: Maybe<UpdateAcademyStatusInput>;
};


export type AcademyMutationUpdateResourceGroupSettingArgs = {
  model?: Maybe<AcademyResourceGroupSettingInput>;
};


export type AcademyMutationUpdateResourceTemplateArgs = {
  model?: Maybe<AcademyResourceTemplateInput>;
};


export type AcademyMutationUpdateSlideArgs = {
  model?: Maybe<AcademySlideInput>;
};

export type AcademyTypeResponse = {
  __typename?: 'AcademyTypeResponse';
  Result?: Maybe<Academy>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type AcademyInput = {
  Id?: Maybe<Scalars['Int']>;
  Code: Scalars['String'];
  NameEn: Scalars['String'];
  NameAr: Scalars['String'];
  AddressEn: Scalars['String'];
  AddressAr: Scalars['String'];
  ManagerFirstName: Scalars['String'];
  ManagerLastName: Scalars['String'];
  ManagerEmail: Scalars['String'];
  ManagerMobileNumber: Scalars['String'];
  Password: Scalars['String'];
  CountryCode: Scalars['String'];
  CountryId?: Maybe<Scalars['Int']>;
  CityId?: Maybe<Scalars['Int']>;
  AreaId?: Maybe<Scalars['Int']>;
  IdentityNumber?: Maybe<Scalars['String']>;
  Gender?: Maybe<Gender>;
  SubjectIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  EducationTypeId?: Maybe<Scalars['Int']>;
  SchoolYearId?: Maybe<Scalars['Int']>;
};

export type AcademyForTeacherInput = {
  Id?: Maybe<Scalars['Int']>;
  Code: Scalars['String'];
  NameEn: Scalars['String'];
  NameAr: Scalars['String'];
  AddressEn: Scalars['String'];
  AddressAr: Scalars['String'];
  CountryId?: Maybe<Scalars['Int']>;
  CityId?: Maybe<Scalars['Int']>;
  AreaId?: Maybe<Scalars['Int']>;
  TeacherId?: Maybe<Scalars['Int']>;
};

export type AcademyResourceGroupSettingTypeResponse = {
  __typename?: 'AcademyResourceGroupSettingTypeResponse';
  Result?: Maybe<AcademyResourceGroupSetting>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type AcademyResourceGroupSettingInput = {
  Id?: Maybe<Scalars['Int']>;
  Name: Scalars['String'];
  AcademyResourceTemplateId: Scalars['Int'];
  Settings?: Maybe<Array<Maybe<AcademyResourceSettingInput>>>;
};

export type AcademyResourceSettingInput = {
  Id?: Maybe<Scalars['Int']>;
  Key: Scalars['String'];
  DefaultValue: Scalars['String'];
  ValueType?: Maybe<AcademyResourceValueTypes>;
};

export type UpdateAcademyResourcesInput = {
  AcademyId: Scalars['Int'];
  TemplateId?: Maybe<Scalars['Int']>;
  IsNewTemplate?: Maybe<Scalars['Boolean']>;
  TemplateName?: Maybe<Scalars['String']>;
  Resources?: Maybe<Array<Maybe<UpdateAcademyResourceItemInput>>>;
};

export type UpdateAcademyResourceItemInput = {
  ResourceSettingId: Scalars['Int'];
  Value?: Maybe<Scalars['String']>;
};

export type AcademyResourceTemplateTypeResponse = {
  __typename?: 'AcademyResourceTemplateTypeResponse';
  Result?: Maybe<AcademyResourceTemplate>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type AcademyResourceTemplateInput = {
  Id?: Maybe<Scalars['Int']>;
  Name: Scalars['String'];
};

export type UpdateAcademyStatusInput = {
  AcademyId: Scalars['Int'];
  Status?: Maybe<ResourceStatus>;
};

export type AcademySlideTypeResponse = {
  __typename?: 'AcademySlideTypeResponse';
  Result?: Maybe<AcademySlide>;
  TotalCount?: Maybe<Scalars['Int']>;
};

export type AcademySlideInput = {
  Id?: Maybe<Scalars['Int']>;
  Action?: Maybe<Scalars['String']>;
  AcademyId: Scalars['Int'];
  Content?: Maybe<Scalars['String']>;
  ImagePath: Scalars['String'];
  Variables?: Maybe<Array<Maybe<AcademySlideVariableInput>>>;
};

export type AcademySlideVariableInput = {
  Key?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
};

export namespace RequestForAbsent {
  export type Variables = RequestForAbsentMutationVariables;
  export type Mutation = RequestForAbsentMutation;
  export type Request = (NonNullable<RequestForAbsentMutation['Request']>);
  export type StudentRequests = (NonNullable<(NonNullable<RequestForAbsentMutation['Request']>)['StudentRequests']>);
  export type Absent = (NonNullable<(NonNullable<(NonNullable<RequestForAbsentMutation['Request']>)['StudentRequests']>)['Absent']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<(NonNullable<RequestForAbsentMutation['Request']>)['StudentRequests']>)['Absent']>)['Result']>);
}

export namespace ApplyActivityOffline {
  export type Variables = ApplyActivityOfflineMutationVariables;
  export type Mutation = ApplyActivityOfflineMutation;
  export type Activity = (NonNullable<ApplyActivityOfflineMutation['Activity']>);
  export type ApplyActivityOffline = (NonNullable<(NonNullable<ApplyActivityOfflineMutation['Activity']>)['ApplyActivityOffline']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<ApplyActivityOfflineMutation['Activity']>)['ApplyActivityOffline']>)['Result']>);
}

export namespace ApplyActivityOnline {
  export type Variables = ApplyActivityOnlineMutationVariables;
  export type Mutation = ApplyActivityOnlineMutation;
  export type Student = (NonNullable<ApplyActivityOnlineMutation['Student']>);
  export type ApplyActivityOnline = (NonNullable<(NonNullable<ApplyActivityOnlineMutation['Student']>)['ApplyActivityOnline']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<ApplyActivityOnlineMutation['Student']>)['ApplyActivityOnline']>)['Result']>);
}

export namespace AskSessionQuestion {
  export type Variables = AskSessionQuestionMutationVariables;
  export type Mutation = AskSessionQuestionMutation;
  export type Session = (NonNullable<AskSessionQuestionMutation['Session']>);
  export type AskSessionQuestion = (NonNullable<(NonNullable<AskSessionQuestionMutation['Session']>)['AskSessionQuestion']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<AskSessionQuestionMutation['Session']>)['AskSessionQuestion']>)['Result']>);
}

export namespace BlockStudent {
  export type Variables = BlockStudentMutationVariables;
  export type Mutation = BlockStudentMutation;
  export type UserBlock = (NonNullable<BlockStudentMutation['UserBlock']>);
  export type BlockStudent = (NonNullable<(NonNullable<BlockStudentMutation['UserBlock']>)['BlockStudent']>);
}

export namespace CancelRequest {
  export type Variables = CancelRequestMutationVariables;
  export type Mutation = CancelRequestMutation;
  export type Request = (NonNullable<CancelRequestMutation['Request']>);
  export type CancelRequest = (NonNullable<(NonNullable<CancelRequestMutation['Request']>)['CancelRequest']>);
}

export namespace ChangeCourseGroupPermanent {
  export type Variables = ChangeCourseGroupPermanentMutationVariables;
  export type Mutation = ChangeCourseGroupPermanentMutation;
  export type Request = (NonNullable<ChangeCourseGroupPermanentMutation['Request']>);
  export type StudentRequests = (NonNullable<(NonNullable<ChangeCourseGroupPermanentMutation['Request']>)['StudentRequests']>);
  export type ChangeCourseGroup = (NonNullable<(NonNullable<(NonNullable<ChangeCourseGroupPermanentMutation['Request']>)['StudentRequests']>)['ChangeCourseGroup']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<(NonNullable<ChangeCourseGroupPermanentMutation['Request']>)['StudentRequests']>)['ChangeCourseGroup']>)['Result']>);
}

export namespace ChangePassword {
  export type Variables = ChangePasswordMutationVariables;
  export type Mutation = ChangePasswordMutation;
  export type Account = (NonNullable<ChangePasswordMutation['Account']>);
  export type ChangePassword = (NonNullable<(NonNullable<ChangePasswordMutation['Account']>)['ChangePassword']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<ChangePasswordMutation['Account']>)['ChangePassword']>)['Result']>);
}

export namespace CheckEmailAndMobileNumber {
  export type Variables = CheckEmailAndMobileNumberMutationVariables;
  export type Mutation = CheckEmailAndMobileNumberMutation;
  export type Account = (NonNullable<CheckEmailAndMobileNumberMutation['Account']>);
  export type CheckEmailAndMobileNumber = (NonNullable<(NonNullable<CheckEmailAndMobileNumberMutation['Account']>)['CheckEmailAndMobileNumber']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<CheckEmailAndMobileNumberMutation['Account']>)['CheckEmailAndMobileNumber']>)['Result']>);
}

export namespace ContactUs {
  export type Variables = ContactUsMutationVariables;
  export type Mutation = ContactUsMutation;
  export type Common = (NonNullable<ContactUsMutation['Common']>);
  export type ContactUs = (NonNullable<(NonNullable<ContactUsMutation['Common']>)['ContactUs']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<ContactUsMutation['Common']>)['ContactUs']>)['Result']>);
}

export namespace CreateAssignment {
  export type Variables = CreateAssignmentMutationVariables;
  export type Mutation = CreateAssignmentMutation;
  export type Activity = (NonNullable<CreateAssignmentMutation['Activity']>);
  export type CreateAssignment = (NonNullable<(NonNullable<CreateAssignmentMutation['Activity']>)['CreateAssignment']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<CreateAssignmentMutation['Activity']>)['CreateAssignment']>)['Result']>)[number]>;
}

export namespace CreateCourseGroup {
  export type Variables = CreateCourseGroupMutationVariables;
  export type Mutation = CreateCourseGroupMutation;
  export type CourseGroup = (NonNullable<CreateCourseGroupMutation['CourseGroup']>);
  export type CreateCourseGroup = (NonNullable<(NonNullable<CreateCourseGroupMutation['CourseGroup']>)['CreateCourseGroup']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<CreateCourseGroupMutation['CourseGroup']>)['CreateCourseGroup']>)['Result']>);
}

export namespace CreateCourse {
  export type Variables = CreateCourseMutationVariables;
  export type Mutation = CreateCourseMutation;
  export type Course = (NonNullable<CreateCourseMutation['Course']>);
  export type CreateCourse = (NonNullable<(NonNullable<CreateCourseMutation['Course']>)['CreateCourse']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<CreateCourseMutation['Course']>)['CreateCourse']>)['Result']>);
}

export namespace CreatePost {
  export type Variables = CreatePostMutationVariables;
  export type Mutation = CreatePostMutation;
  export type Activity = (NonNullable<CreatePostMutation['Activity']>);
  export type CreatePost = (NonNullable<(NonNullable<CreatePostMutation['Activity']>)['CreatePost']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<CreatePostMutation['Activity']>)['CreatePost']>)['Result']>)[number]>;
}

export namespace CreatePreviouExam {
  export type Variables = CreatePreviouExamMutationVariables;
  export type Mutation = CreatePreviouExamMutation;
  export type Teacher = (NonNullable<CreatePreviouExamMutation['Teacher']>);
  export type CreatePreviousExam = (NonNullable<(NonNullable<CreatePreviouExamMutation['Teacher']>)['CreatePreviousExam']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<CreatePreviouExamMutation['Teacher']>)['CreatePreviousExam']>)['Result']>);
}

export namespace CreateQuiz {
  export type Variables = CreateQuizMutationVariables;
  export type Mutation = CreateQuizMutation;
  export type Activity = (NonNullable<CreateQuizMutation['Activity']>);
  export type CreateQuiz = (NonNullable<(NonNullable<CreateQuizMutation['Activity']>)['CreateQuiz']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<CreateQuizMutation['Activity']>)['CreateQuiz']>)['Result']>)[number]>;
}

export namespace CreateRevision {
  export type Variables = CreateRevisionMutationVariables;
  export type Mutation = CreateRevisionMutation;
  export type Activity = (NonNullable<CreateRevisionMutation['Activity']>);
  export type CreateRevision = (NonNullable<(NonNullable<CreateRevisionMutation['Activity']>)['CreateRevision']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<CreateRevisionMutation['Activity']>)['CreateRevision']>)['Result']>)[number]>;
}

export namespace CreateSession {
  export type Variables = CreateSessionMutationVariables;
  export type Mutation = CreateSessionMutation;
  export type Session = (NonNullable<CreateSessionMutation['Session']>);
  export type CreateSession = (NonNullable<(NonNullable<CreateSessionMutation['Session']>)['CreateSession']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<CreateSessionMutation['Session']>)['CreateSession']>)['Result']>);
}

export namespace CreateTeacherQuestionBank {
  export type Variables = CreateTeacherQuestionBankMutationVariables;
  export type Mutation = CreateTeacherQuestionBankMutation;
  export type Teacher = (NonNullable<CreateTeacherQuestionBankMutation['Teacher']>);
  export type CreateTeacherQuestion = (NonNullable<(NonNullable<CreateTeacherQuestionBankMutation['Teacher']>)['CreateTeacherQuestion']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<CreateTeacherQuestionBankMutation['Teacher']>)['CreateTeacherQuestion']>)['Result']>);
}

export namespace DeleteCourseGroup {
  export type Variables = DeleteCourseGroupMutationVariables;
  export type Mutation = DeleteCourseGroupMutation;
  export type CourseGroup = (NonNullable<DeleteCourseGroupMutation['CourseGroup']>);
  export type DeleteCourseGroup = (NonNullable<(NonNullable<DeleteCourseGroupMutation['CourseGroup']>)['DeleteCourseGroup']>);
}

export namespace FollowTeacher {
  export type Variables = FollowTeacherMutationVariables;
  export type Mutation = FollowTeacherMutation;
  export type Teacher = (NonNullable<FollowTeacherMutation['Teacher']>);
  export type FollowTeacher = (NonNullable<(NonNullable<FollowTeacherMutation['Teacher']>)['FollowTeacher']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<FollowTeacherMutation['Teacher']>)['FollowTeacher']>)['Result']>);
}

export namespace ForgetPassword {
  export type Variables = ForgetPasswordMutationVariables;
  export type Mutation = ForgetPasswordMutation;
  export type Account = (NonNullable<ForgetPasswordMutation['Account']>);
  export type ForgetPassword = (NonNullable<(NonNullable<ForgetPasswordMutation['Account']>)['ForgetPassword']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<ForgetPasswordMutation['Account']>)['ForgetPassword']>)['Result']>);
}

export namespace GenerateQrCode {
  export type Variables = GenerateQrCodeMutationVariables;
  export type Mutation = GenerateQrCodeMutation;
  export type Session = (NonNullable<GenerateQrCodeMutation['Session']>);
  export type GenerateQrCode = (NonNullable<(NonNullable<GenerateQrCodeMutation['Session']>)['GenerateQRCode']>);
}

export namespace JoinCourseGroup {
  export type Variables = JoinCourseGroupMutationVariables;
  export type Mutation = JoinCourseGroupMutation;
  export type Request = (NonNullable<JoinCourseGroupMutation['Request']>);
  export type StudentRequests = (NonNullable<(NonNullable<JoinCourseGroupMutation['Request']>)['StudentRequests']>);
  export type JoinCourseGroup = (NonNullable<(NonNullable<(NonNullable<JoinCourseGroupMutation['Request']>)['StudentRequests']>)['JoinCourseGroup']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<(NonNullable<JoinCourseGroupMutation['Request']>)['StudentRequests']>)['JoinCourseGroup']>)['Result']>);
}

export namespace Login {
  export type Variables = LoginMutationVariables;
  export type Mutation = LoginMutation;
  export type Account = (NonNullable<LoginMutation['Account']>);
  export type Login = (NonNullable<(NonNullable<LoginMutation['Account']>)['Login']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<LoginMutation['Account']>)['Login']>)['Result']>);
}

export namespace ManageAddSonRequest {
  export type Variables = ManageAddSonRequestMutationVariables;
  export type Mutation = ManageAddSonRequestMutation;
  export type Request = (NonNullable<ManageAddSonRequestMutation['Request']>);
  export type StudentRequests = (NonNullable<(NonNullable<ManageAddSonRequestMutation['Request']>)['StudentRequests']>);
  export type ManageAddSonRequest = (NonNullable<(NonNullable<(NonNullable<ManageAddSonRequestMutation['Request']>)['StudentRequests']>)['ManageAddSonRequest']>);
}

export namespace ManageAddToCourseGroupRequest {
  export type Variables = ManageAddToCourseGroupRequestMutationVariables;
  export type Mutation = ManageAddToCourseGroupRequestMutation;
  export type Request = (NonNullable<ManageAddToCourseGroupRequestMutation['Request']>);
  export type StudentRequests = (NonNullable<(NonNullable<ManageAddToCourseGroupRequestMutation['Request']>)['StudentRequests']>);
  export type ManageAddToCourseGroupRequest = (NonNullable<(NonNullable<(NonNullable<ManageAddToCourseGroupRequestMutation['Request']>)['StudentRequests']>)['ManageAddToCourseGroupRequest']>);
}

export namespace RefreshToken {
  export type Variables = RefreshTokenMutationVariables;
  export type Mutation = RefreshTokenMutation;
  export type Account = (NonNullable<RefreshTokenMutation['Account']>);
  export type RereshToken = (NonNullable<(NonNullable<RefreshTokenMutation['Account']>)['RereshToken']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<RefreshTokenMutation['Account']>)['RereshToken']>)['Result']>);
}

export namespace Register {
  export type Variables = RegisterMutationVariables;
  export type Mutation = RegisterMutation;
  export type Account = (NonNullable<RegisterMutation['Account']>);
  export type Register = (NonNullable<(NonNullable<RegisterMutation['Account']>)['Register']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<RegisterMutation['Account']>)['Register']>)['Result']>);
}

export namespace RemoveAssignment {
  export type Variables = RemoveAssignmentMutationVariables;
  export type Mutation = RemoveAssignmentMutation;
  export type Activity = (NonNullable<RemoveAssignmentMutation['Activity']>);
  export type DeleteActivtiy = (NonNullable<(NonNullable<RemoveAssignmentMutation['Activity']>)['DeleteActivtiy']>);
}

export namespace RemovePost {
  export type Variables = RemovePostMutationVariables;
  export type Mutation = RemovePostMutation;
  export type Activity = (NonNullable<RemovePostMutation['Activity']>);
  export type DeleteActivtiy = (NonNullable<(NonNullable<RemovePostMutation['Activity']>)['DeleteActivtiy']>);
}

export namespace RemovePreviousExam {
  export type Variables = RemovePreviousExamMutationVariables;
  export type Mutation = RemovePreviousExamMutation;
  export type Teacher = (NonNullable<RemovePreviousExamMutation['Teacher']>);
  export type DeletePreviousExam = (NonNullable<(NonNullable<RemovePreviousExamMutation['Teacher']>)['DeletePreviousExam']>);
}

export namespace RemoveQuiz {
  export type Variables = RemoveQuizMutationVariables;
  export type Mutation = RemoveQuizMutation;
  export type Activity = (NonNullable<RemoveQuizMutation['Activity']>);
  export type DeleteActivtiy = (NonNullable<(NonNullable<RemoveQuizMutation['Activity']>)['DeleteActivtiy']>);
}

export namespace RemoveRevision {
  export type Variables = RemoveRevisionMutationVariables;
  export type Mutation = RemoveRevisionMutation;
  export type Activity = (NonNullable<RemoveRevisionMutation['Activity']>);
  export type DeleteActivtiy = (NonNullable<(NonNullable<RemoveRevisionMutation['Activity']>)['DeleteActivtiy']>);
}

export namespace RemoveSession {
  export type Variables = RemoveSessionMutationVariables;
  export type Mutation = RemoveSessionMutation;
  export type Session = (NonNullable<RemoveSessionMutation['Session']>);
  export type DeleteSession = (NonNullable<(NonNullable<RemoveSessionMutation['Session']>)['DeleteSession']>);
}

export namespace RemoveStudents {
  export type Variables = RemoveStudentsMutationVariables;
  export type Mutation = RemoveStudentsMutation;
  export type Teacher = (NonNullable<RemoveStudentsMutation['Teacher']>);
  export type DeleteStudent = (NonNullable<(NonNullable<RemoveStudentsMutation['Teacher']>)['DeleteStudent']>);
}

export namespace RemoveTeacherQuestionbank {
  export type Variables = RemoveTeacherQuestionbankMutationVariables;
  export type Mutation = RemoveTeacherQuestionbankMutation;
  export type Teacher = (NonNullable<RemoveTeacherQuestionbankMutation['Teacher']>);
  export type DeleteTeacherQuestion = (NonNullable<(NonNullable<RemoveTeacherQuestionbankMutation['Teacher']>)['DeleteTeacherQuestion']>);
}

export namespace ResendVerificationCode {
  export type Variables = ResendVerificationCodeMutationVariables;
  export type Mutation = ResendVerificationCodeMutation;
  export type Account = (NonNullable<ResendVerificationCodeMutation['Account']>);
  export type ResendVerificationCode = (NonNullable<(NonNullable<ResendVerificationCodeMutation['Account']>)['ResendVerificationCode']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<ResendVerificationCodeMutation['Account']>)['ResendVerificationCode']>)['Result']>);
}

export namespace ResetPassword {
  export type Variables = ResetPasswordMutationVariables;
  export type Mutation = ResetPasswordMutation;
  export type Account = (NonNullable<ResetPasswordMutation['Account']>);
  export type ResetPassword = (NonNullable<(NonNullable<ResetPasswordMutation['Account']>)['ResetPassword']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<ResetPasswordMutation['Account']>)['ResetPassword']>)['Result']>);
}

export namespace SubmitCourseGroupRate {
  export type Variables = SubmitCourseGroupRateMutationVariables;
  export type Mutation = SubmitCourseGroupRateMutation;
  export type Student = (NonNullable<SubmitCourseGroupRateMutation['Student']>);
  export type SubmitCourseGroupRate = (NonNullable<(NonNullable<SubmitCourseGroupRateMutation['Student']>)['SubmitCourseGroupRate']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<SubmitCourseGroupRateMutation['Student']>)['SubmitCourseGroupRate']>)['Result']>);
}

export namespace SubmitCourseGroupReport {
  export type Variables = SubmitCourseGroupReportMutationVariables;
  export type Mutation = SubmitCourseGroupReportMutation;
  export type Student = (NonNullable<SubmitCourseGroupReportMutation['Student']>);
  export type SubmitCourseGroupReport = (NonNullable<(NonNullable<SubmitCourseGroupReportMutation['Student']>)['SubmitCourseGroupReport']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<SubmitCourseGroupReportMutation['Student']>)['SubmitCourseGroupReport']>)['Result']>);
}

export namespace SubmitSessionRate {
  export type Variables = SubmitSessionRateMutationVariables;
  export type Mutation = SubmitSessionRateMutation;
  export type Session = (NonNullable<SubmitSessionRateMutation['Session']>);
  export type SubmitSessionRate = (NonNullable<(NonNullable<SubmitSessionRateMutation['Session']>)['SubmitSessionRate']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<SubmitSessionRateMutation['Session']>)['SubmitSessionRate']>)['Result']>);
}

export namespace SuggestQuestion {
  export type Variables = SuggestQuestionMutationVariables;
  export type Mutation = SuggestQuestionMutation;
  export type Student = (NonNullable<SuggestQuestionMutation['Student']>);
  export type SuggestQuestion = (NonNullable<(NonNullable<SuggestQuestionMutation['Student']>)['SuggestQuestion']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<SuggestQuestionMutation['Student']>)['SuggestQuestion']>)['Result']>);
}

export namespace SuspendStudent {
  export type Variables = SuspendStudentMutationVariables;
  export type Mutation = SuspendStudentMutation;
  export type Teacher = (NonNullable<SuspendStudentMutation['Teacher']>);
  export type SuspendAndActiveStudent = (NonNullable<(NonNullable<SuspendStudentMutation['Teacher']>)['SuspendAndActiveStudent']>);
}

export namespace UnFollowTeacher {
  export type Variables = UnFollowTeacherMutationVariables;
  export type Mutation = UnFollowTeacherMutation;
  export type Teacher = (NonNullable<UnFollowTeacherMutation['Teacher']>);
  export type UnFollowTeacher = (NonNullable<(NonNullable<UnFollowTeacherMutation['Teacher']>)['UnFollowTeacher']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<UnFollowTeacherMutation['Teacher']>)['UnFollowTeacher']>)['Result']>);
}

export namespace UpdateAssignment {
  export type Variables = UpdateAssignmentMutationVariables;
  export type Mutation = UpdateAssignmentMutation;
  export type Activity = (NonNullable<UpdateAssignmentMutation['Activity']>);
  export type UpdateAssignment = (NonNullable<(NonNullable<UpdateAssignmentMutation['Activity']>)['UpdateAssignment']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<UpdateAssignmentMutation['Activity']>)['UpdateAssignment']>)['Result']>);
}

export namespace UpdateCourseGroup {
  export type Variables = UpdateCourseGroupMutationVariables;
  export type Mutation = UpdateCourseGroupMutation;
  export type CourseGroup = (NonNullable<UpdateCourseGroupMutation['CourseGroup']>);
  export type UpdateCourseGroup = (NonNullable<(NonNullable<UpdateCourseGroupMutation['CourseGroup']>)['UpdateCourseGroup']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<UpdateCourseGroupMutation['CourseGroup']>)['UpdateCourseGroup']>)['Result']>);
}

export namespace UpdatePost {
  export type Variables = UpdatePostMutationVariables;
  export type Mutation = UpdatePostMutation;
  export type Activity = (NonNullable<UpdatePostMutation['Activity']>);
  export type UpdatePost = (NonNullable<(NonNullable<UpdatePostMutation['Activity']>)['UpdatePost']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<UpdatePostMutation['Activity']>)['UpdatePost']>)['Result']>);
}

export namespace UpdatePreviouExam {
  export type Variables = UpdatePreviouExamMutationVariables;
  export type Mutation = UpdatePreviouExamMutation;
  export type Teacher = (NonNullable<UpdatePreviouExamMutation['Teacher']>);
  export type EditPreviousExam = (NonNullable<(NonNullable<UpdatePreviouExamMutation['Teacher']>)['EditPreviousExam']>);
}

export namespace UpdateRevision {
  export type Variables = UpdateRevisionMutationVariables;
  export type Mutation = UpdateRevisionMutation;
  export type Activity = (NonNullable<UpdateRevisionMutation['Activity']>);
  export type UpdateRevision = (NonNullable<(NonNullable<UpdateRevisionMutation['Activity']>)['UpdateRevision']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<UpdateRevisionMutation['Activity']>)['UpdateRevision']>)['Result']>);
}

export namespace UpdateSession {
  export type Variables = UpdateSessionMutationVariables;
  export type Mutation = UpdateSessionMutation;
  export type Session = (NonNullable<UpdateSessionMutation['Session']>);
  export type UpdateSession = (NonNullable<(NonNullable<UpdateSessionMutation['Session']>)['UpdateSession']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<UpdateSessionMutation['Session']>)['UpdateSession']>)['Result']>);
}

export namespace UpdateTeacherQuestionbank {
  export type Variables = UpdateTeacherQuestionbankMutationVariables;
  export type Mutation = UpdateTeacherQuestionbankMutation;
  export type Teacher = (NonNullable<UpdateTeacherQuestionbankMutation['Teacher']>);
  export type EditTeacherQuestion = (NonNullable<(NonNullable<UpdateTeacherQuestionbankMutation['Teacher']>)['EditTeacherQuestion']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<UpdateTeacherQuestionbankMutation['Teacher']>)['EditTeacherQuestion']>)['Result']>);
}

export namespace UpdateUserProfile {
  export type Variables = UpdateUserProfileMutationVariables;
  export type Mutation = UpdateUserProfileMutation;
  export type Account = (NonNullable<UpdateUserProfileMutation['Account']>);
  export type UpdateUserProfile = (NonNullable<(NonNullable<UpdateUserProfileMutation['Account']>)['UpdateUserProfile']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<UpdateUserProfileMutation['Account']>)['UpdateUserProfile']>)['Result']>);
}

export namespace ValidateResetPasswordCode {
  export type Variables = ValidateResetPasswordCodeMutationVariables;
  export type Mutation = ValidateResetPasswordCodeMutation;
  export type Account = (NonNullable<ValidateResetPasswordCodeMutation['Account']>);
  export type ValidateResetPasswordCode = (NonNullable<(NonNullable<ValidateResetPasswordCodeMutation['Account']>)['ValidateResetPasswordCode']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<ValidateResetPasswordCodeMutation['Account']>)['ValidateResetPasswordCode']>)['Result']>);
}

export namespace VerifyMobile {
  export type Variables = VerifyMobileMutationVariables;
  export type Mutation = VerifyMobileMutation;
  export type Account = (NonNullable<VerifyMobileMutation['Account']>);
  export type VerifyMobile = (NonNullable<(NonNullable<VerifyMobileMutation['Account']>)['VerifyMobile']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<VerifyMobileMutation['Account']>)['VerifyMobile']>)['Result']>);
}

export namespace ViewRevision {
  export type Variables = ViewRevisionMutationVariables;
  export type Mutation = ViewRevisionMutation;
  export type Student = (NonNullable<ViewRevisionMutation['Student']>);
  export type ViewRevision = (NonNullable<(NonNullable<ViewRevisionMutation['Student']>)['ViewRevision']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<ViewRevisionMutation['Student']>)['ViewRevision']>)['Result']>);
}

export namespace ActivityQuestionsWithAnswers {
  export type Variables = ActivityQuestionsWithAnswersQueryVariables;
  export type Query = ActivityQuestionsWithAnswersQuery;
  export type ActivityQuery = (NonNullable<ActivityQuestionsWithAnswersQuery['ActivityQuery']>);
  export type ActivityQuestions = (NonNullable<(NonNullable<ActivityQuestionsWithAnswersQuery['ActivityQuery']>)['ActivityQuestions']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<ActivityQuestionsWithAnswersQuery['ActivityQuery']>)['ActivityQuestions']>)['Result']>)[number]>;
  export type QuestionAttachments = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<ActivityQuestionsWithAnswersQuery['ActivityQuery']>)['ActivityQuestions']>)['Result']>)[number]>['QuestionAttachments']>)[number]>;
  export type StudentAnswer = (NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<ActivityQuestionsWithAnswersQuery['ActivityQuery']>)['ActivityQuestions']>)['Result']>)[number]>['StudentAnswer']>);
  export type Activity = (NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<ActivityQuestionsWithAnswersQuery['ActivityQuery']>)['ActivityQuestions']>)['Result']>)[number]>['Activity']>);
}

export namespace ActivityQuestions {
  export type Variables = ActivityQuestionsQueryVariables;
  export type Query = ActivityQuestionsQuery;
  export type ActivityQuery = (NonNullable<ActivityQuestionsQuery['ActivityQuery']>);
  export type Activities = (NonNullable<(NonNullable<ActivityQuestionsQuery['ActivityQuery']>)['Activities']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<ActivityQuestionsQuery['ActivityQuery']>)['Activities']>)['Result']>)[number]>;
  export type ActivityQuestions = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<ActivityQuestionsQuery['ActivityQuery']>)['Activities']>)['Result']>)[number]>['ActivityQuestions']>)[number]>;
  export type QuestionAttachments = NonNullable<(NonNullable<NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<ActivityQuestionsQuery['ActivityQuery']>)['Activities']>)['Result']>)[number]>['ActivityQuestions']>)[number]>['QuestionAttachments']>)[number]>;
}

export namespace Areas {
  export type Variables = AreasQueryVariables;
  export type Query = AreasQuery;
  export type LookupQuery = (NonNullable<AreasQuery['LookupQuery']>);
  export type Areas = (NonNullable<(NonNullable<AreasQuery['LookupQuery']>)['Areas']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<AreasQuery['LookupQuery']>)['Areas']>)['Result']>)[number]>;
}

export namespace Assignments {
  export type Variables = AssignmentsQueryVariables;
  export type Query = AssignmentsQuery;
  export type ActivityQuery = (NonNullable<AssignmentsQuery['ActivityQuery']>);
  export type Assignments = (NonNullable<(NonNullable<AssignmentsQuery['ActivityQuery']>)['Assignments']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<AssignmentsQuery['ActivityQuery']>)['Assignments']>)['Result']>)[number]>;
  export type CourseGroup = (NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<AssignmentsQuery['ActivityQuery']>)['Assignments']>)['Result']>)[number]>['CourseGroup']>);
  export type Session = (NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<AssignmentsQuery['ActivityQuery']>)['Assignments']>)['Result']>)[number]>['Session']>);
  export type GroupedSessionGroups = NonNullable<(NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<AssignmentsQuery['ActivityQuery']>)['Assignments']>)['Result']>)[number]>['Session']>)['GroupedSessionGroups']>)[number]>;
  export type Attatchments = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<AssignmentsQuery['ActivityQuery']>)['Assignments']>)['Result']>)[number]>['Attatchments']>)[number]>;
  export type ActivityQuestions = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<AssignmentsQuery['ActivityQuery']>)['Assignments']>)['Result']>)[number]>['ActivityQuestions']>)[number]>;
  export type QuestionAttachments = NonNullable<(NonNullable<NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<AssignmentsQuery['ActivityQuery']>)['Assignments']>)['Result']>)[number]>['ActivityQuestions']>)[number]>['QuestionAttachments']>)[number]>;
  export type QuestionAnswerAttachments = NonNullable<(NonNullable<NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<AssignmentsQuery['ActivityQuery']>)['Assignments']>)['Result']>)[number]>['ActivityQuestions']>)[number]>['QuestionAnswerAttachments']>)[number]>;
  export type StudentAnswer = (NonNullable<NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<AssignmentsQuery['ActivityQuery']>)['Assignments']>)['Result']>)[number]>['ActivityQuestions']>)[number]>['StudentAnswer']>);
  export type UserActivities = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<AssignmentsQuery['ActivityQuery']>)['Assignments']>)['Result']>)[number]>['UserActivities']>)[number]>;
  export type Attachments = NonNullable<(NonNullable<NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<AssignmentsQuery['ActivityQuery']>)['Assignments']>)['Result']>)[number]>['UserActivities']>)[number]>['Attachments']>)[number]>;
}

export namespace Assistants {
  export type Variables = AssistantsQueryVariables;
  export type Query = AssistantsQuery;
  export type AssistantQuery = (NonNullable<AssistantsQuery['AssistantQuery']>);
  export type Assistants = (NonNullable<(NonNullable<AssistantsQuery['AssistantQuery']>)['Assistants']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<AssistantsQuery['AssistantQuery']>)['Assistants']>)['Result']>)[number]>;
  export type CourseGroups = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<AssistantsQuery['AssistantQuery']>)['Assistants']>)['Result']>)[number]>['CourseGroups']>)[number]>;
}

export namespace AssistantRoles {
  export type Variables = AssistantRolesQueryVariables;
  export type Query = AssistantRolesQuery;
  export type AssistantQuery = (NonNullable<AssistantRolesQuery['AssistantQuery']>);
  export type AssistantRoles = (NonNullable<(NonNullable<AssistantRolesQuery['AssistantQuery']>)['AssistantRoles']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<AssistantRolesQuery['AssistantQuery']>)['AssistantRoles']>)['Result']>)[number]>;
}

export namespace AssignAssistantCourseGroupRoles {
  export type Variables = AssignAssistantCourseGroupRolesMutationVariables;
  export type Mutation = AssignAssistantCourseGroupRolesMutation;
  export type Teacher = (NonNullable<AssignAssistantCourseGroupRolesMutation['Teacher']>);
  export type AssignAssistantCourseGroupRoles = (NonNullable<(NonNullable<AssignAssistantCourseGroupRolesMutation['Teacher']>)['AssignAssistantCourseGroupRoles']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<AssignAssistantCourseGroupRolesMutation['Teacher']>)['AssignAssistantCourseGroupRoles']>)['Result']>);
}

export namespace AssignAssistantGeneralRoles {
  export type Variables = AssignAssistantGeneralRolesMutationVariables;
  export type Mutation = AssignAssistantGeneralRolesMutation;
  export type Teacher = (NonNullable<AssignAssistantGeneralRolesMutation['Teacher']>);
  export type AssignAssistantPublicRoles = (NonNullable<(NonNullable<AssignAssistantGeneralRolesMutation['Teacher']>)['AssignAssistantPublicRoles']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<AssignAssistantGeneralRolesMutation['Teacher']>)['AssignAssistantPublicRoles']>)['Result']>);
}

export namespace DeleteAssistant {
  export type Variables = DeleteAssistantMutationVariables;
  export type Mutation = DeleteAssistantMutation;
  export type Teacher = (NonNullable<DeleteAssistantMutation['Teacher']>);
  export type DeleteAssistant = (NonNullable<(NonNullable<DeleteAssistantMutation['Teacher']>)['DeleteAssistant']>);
}

export namespace CheckTeacherFollower {
  export type Variables = CheckTeacherFollowerQueryVariables;
  export type Query = CheckTeacherFollowerQuery;
  export type StudentQuery = (NonNullable<CheckTeacherFollowerQuery['StudentQuery']>);
  export type CheckTeacherFollower = (NonNullable<(NonNullable<CheckTeacherFollowerQuery['StudentQuery']>)['CheckTeacherFollower']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<CheckTeacherFollowerQuery['StudentQuery']>)['CheckTeacherFollower']>)['Result']>);
}

export namespace Cities {
  export type Variables = CitiesQueryVariables;
  export type Query = CitiesQuery;
  export type LookupQuery = (NonNullable<CitiesQuery['LookupQuery']>);
  export type Cities = (NonNullable<(NonNullable<CitiesQuery['LookupQuery']>)['Cities']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<CitiesQuery['LookupQuery']>)['Cities']>)['Result']>)[number]>;
}

export namespace Countries {
  export type Variables = CountriesQueryVariables;
  export type Query = CountriesQuery;
  export type LookupQuery = (NonNullable<CountriesQuery['LookupQuery']>);
  export type Countries = (NonNullable<(NonNullable<CountriesQuery['LookupQuery']>)['Countries']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<CountriesQuery['LookupQuery']>)['Countries']>)['Result']>)[number]>;
}

export namespace CourseGroupStudents {
  export type Variables = CourseGroupStudentsQueryVariables;
  export type Query = CourseGroupStudentsQuery;
  export type CourseGroupQuery = (NonNullable<CourseGroupStudentsQuery['CourseGroupQuery']>);
  export type CourseGroupStudents = (NonNullable<(NonNullable<CourseGroupStudentsQuery['CourseGroupQuery']>)['CourseGroupStudents']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<CourseGroupStudentsQuery['CourseGroupQuery']>)['CourseGroupStudents']>)['Result']>)[number]>;
  export type CourseGroup = (NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<CourseGroupStudentsQuery['CourseGroupQuery']>)['CourseGroupStudents']>)['Result']>)[number]>['CourseGroup']>);
  export type CourseGroupHours = NonNullable<(NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<CourseGroupStudentsQuery['CourseGroupQuery']>)['CourseGroupStudents']>)['Result']>)[number]>['CourseGroup']>)['CourseGroupHours']>)[number]>;
}

export namespace CourseGroups {
  export type Variables = CourseGroupsQueryVariables;
  export type Query = CourseGroupsQuery;
  export type CourseGroupQuery = (NonNullable<CourseGroupsQuery['CourseGroupQuery']>);
  export type CourseGroups = (NonNullable<(NonNullable<CourseGroupsQuery['CourseGroupQuery']>)['CourseGroups']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<CourseGroupsQuery['CourseGroupQuery']>)['CourseGroups']>)['Result']>)[number]>;
  export type CourseGroupAssistants = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<CourseGroupsQuery['CourseGroupQuery']>)['CourseGroups']>)['Result']>)[number]>['CourseGroupAssistants']>)[number]>;
  export type AssistantRoles = NonNullable<(NonNullable<NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<CourseGroupsQuery['CourseGroupQuery']>)['CourseGroups']>)['Result']>)[number]>['CourseGroupAssistants']>)[number]>['AssistantRoles']>)[number]>;
  export type CourseGroupHours = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<CourseGroupsQuery['CourseGroupQuery']>)['CourseGroups']>)['Result']>)[number]>['CourseGroupHours']>)[number]>;
  export type Course = (NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<CourseGroupsQuery['CourseGroupQuery']>)['CourseGroups']>)['Result']>)[number]>['Course']>);
  export type CourseGroupStudent = (NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<CourseGroupsQuery['CourseGroupQuery']>)['CourseGroups']>)['Result']>)[number]>['CourseGroupStudent']>);
  export type Teacher = (NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<CourseGroupsQuery['CourseGroupQuery']>)['CourseGroups']>)['Result']>)[number]>['Teacher']>);
}

export namespace Posts {
  export type Variables = PostsQueryVariables;
  export type Query = PostsQuery;
  export type ActivityQuery = (NonNullable<PostsQuery['ActivityQuery']>);
  export type Posts = (NonNullable<(NonNullable<PostsQuery['ActivityQuery']>)['Posts']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<PostsQuery['ActivityQuery']>)['Posts']>)['Result']>)[number]>;
  export type Attachments = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<PostsQuery['ActivityQuery']>)['Posts']>)['Result']>)[number]>['Attachments']>)[number]>;
  export type CourseGroup = (NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<PostsQuery['ActivityQuery']>)['Posts']>)['Result']>)[number]>['CourseGroup']>);
}

export namespace PreviousExams {
  export type Variables = PreviousExamsQueryVariables;
  export type Query = PreviousExamsQuery;
  export type TeacherQuery = (NonNullable<PreviousExamsQuery['TeacherQuery']>);
  export type PreviousExams = (NonNullable<(NonNullable<PreviousExamsQuery['TeacherQuery']>)['PreviousExams']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<PreviousExamsQuery['TeacherQuery']>)['PreviousExams']>)['Result']>)[number]>;
  export type Attachments = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<PreviousExamsQuery['TeacherQuery']>)['PreviousExams']>)['Result']>)[number]>['Attachments']>)[number]>;
}

export namespace Quizzes {
  export type Variables = QuizzesQueryVariables;
  export type Query = QuizzesQuery;
  export type ActivityQuery = (NonNullable<QuizzesQuery['ActivityQuery']>);
  export type Quizzes = (NonNullable<(NonNullable<QuizzesQuery['ActivityQuery']>)['Quizzes']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<QuizzesQuery['ActivityQuery']>)['Quizzes']>)['Result']>)[number]>;
  export type Attachments = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<QuizzesQuery['ActivityQuery']>)['Quizzes']>)['Result']>)[number]>['Attachments']>)[number]>;
  export type QuizQuestions = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<QuizzesQuery['ActivityQuery']>)['Quizzes']>)['Result']>)[number]>['QuizQuestions']>)[number]>;
  export type StudentAnswer = (NonNullable<NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<QuizzesQuery['ActivityQuery']>)['Quizzes']>)['Result']>)[number]>['QuizQuestions']>)[number]>['StudentAnswer']>);
  export type QuestionAttachments = NonNullable<(NonNullable<NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<QuizzesQuery['ActivityQuery']>)['Quizzes']>)['Result']>)[number]>['QuizQuestions']>)[number]>['QuestionAttachments']>)[number]>;
  export type QuestionAnswerAttachments = NonNullable<(NonNullable<NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<QuizzesQuery['ActivityQuery']>)['Quizzes']>)['Result']>)[number]>['QuizQuestions']>)[number]>['QuestionAnswerAttachments']>)[number]>;
  export type UserActivities = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<QuizzesQuery['ActivityQuery']>)['Quizzes']>)['Result']>)[number]>['UserActivities']>)[number]>;
  export type _Attachments = NonNullable<(NonNullable<NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<QuizzesQuery['ActivityQuery']>)['Quizzes']>)['Result']>)[number]>['UserActivities']>)[number]>['Attachments']>)[number]>;
}

export namespace QuizzesForTable {
  export type Variables = QuizzesForTableQueryVariables;
  export type Query = QuizzesForTableQuery;
  export type ActivityQuery = (NonNullable<QuizzesForTableQuery['ActivityQuery']>);
  export type Quizzes = (NonNullable<(NonNullable<QuizzesForTableQuery['ActivityQuery']>)['Quizzes']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<QuizzesForTableQuery['ActivityQuery']>)['Quizzes']>)['Result']>)[number]>;
  export type Attachments = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<QuizzesForTableQuery['ActivityQuery']>)['Quizzes']>)['Result']>)[number]>['Attachments']>)[number]>;
}

export namespace Revisions {
  export type Variables = RevisionsQueryVariables;
  export type Query = RevisionsQuery;
  export type ActivityQuery = (NonNullable<RevisionsQuery['ActivityQuery']>);
  export type Revisions = (NonNullable<(NonNullable<RevisionsQuery['ActivityQuery']>)['Revisions']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<RevisionsQuery['ActivityQuery']>)['Revisions']>)['Result']>)[number]>;
  export type Attachments = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<RevisionsQuery['ActivityQuery']>)['Revisions']>)['Result']>)[number]>['Attachments']>)[number]>;
  export type Comments = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<RevisionsQuery['ActivityQuery']>)['Revisions']>)['Result']>)[number]>['Comments']>)[number]>;
  export type Replies = NonNullable<(NonNullable<NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<RevisionsQuery['ActivityQuery']>)['Revisions']>)['Result']>)[number]>['Comments']>)[number]>['Replies']>)[number]>;
}

export namespace GetBasicRoles {
  export type Variables = GetBasicRolesQueryVariables;
  export type Query = GetBasicRolesQuery;
  export type LookupQuery = (NonNullable<GetBasicRolesQuery['LookupQuery']>);
  export type BasicRoles = (NonNullable<(NonNullable<GetBasicRolesQuery['LookupQuery']>)['BasicRoles']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<GetBasicRolesQuery['LookupQuery']>)['BasicRoles']>)['Result']>)[number]>;
  export type Permissions = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<GetBasicRolesQuery['LookupQuery']>)['BasicRoles']>)['Result']>)[number]>['Permissions']>)[number]>;
}

export namespace SchoolYears {
  export type Variables = SchoolYearsQueryVariables;
  export type Query = SchoolYearsQuery;
  export type LookupQuery = (NonNullable<SchoolYearsQuery['LookupQuery']>);
  export type SchoolYears = (NonNullable<(NonNullable<SchoolYearsQuery['LookupQuery']>)['SchoolYears']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<SchoolYearsQuery['LookupQuery']>)['SchoolYears']>)['Result']>)[number]>;
  export type Subjects = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<SchoolYearsQuery['LookupQuery']>)['SchoolYears']>)['Result']>)[number]>['Subjects']>)[number]>;
}

export namespace ScientificSubjects {
  export type Variables = ScientificSubjectsQueryVariables;
  export type Query = ScientificSubjectsQuery;
  export type LookupQuery = (NonNullable<ScientificSubjectsQuery['LookupQuery']>);
  export type ScientificSubjects = (NonNullable<(NonNullable<ScientificSubjectsQuery['LookupQuery']>)['ScientificSubjects']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<ScientificSubjectsQuery['LookupQuery']>)['ScientificSubjects']>)['Result']>)[number]>;
}

export namespace SessionQuestions {
  export type Variables = SessionQuestionsQueryVariables;
  export type Query = SessionQuestionsQuery;
  export type SessionQuery = (NonNullable<SessionQuestionsQuery['SessionQuery']>);
  export type SessionsQuestions = (NonNullable<(NonNullable<SessionQuestionsQuery['SessionQuery']>)['SessionsQuestions']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<SessionQuestionsQuery['SessionQuery']>)['SessionsQuestions']>)['Result']>)[number]>;
  export type QuestionAttachments = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<SessionQuestionsQuery['SessionQuery']>)['SessionsQuestions']>)['Result']>)[number]>['QuestionAttachments']>)[number]>;
  export type AnswerAttachments = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<SessionQuestionsQuery['SessionQuery']>)['SessionsQuestions']>)['Result']>)[number]>['AnswerAttachments']>)[number]>;
}

export namespace Sessions {
  export type Variables = SessionsQueryVariables;
  export type Query = SessionsQuery;
  export type SessionQuery = (NonNullable<SessionsQuery['SessionQuery']>);
  export type Sessions = (NonNullable<(NonNullable<SessionsQuery['SessionQuery']>)['Sessions']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<SessionsQuery['SessionQuery']>)['Sessions']>)['Result']>)[number]>;
  export type CourseGroup = (NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<SessionsQuery['SessionQuery']>)['Sessions']>)['Result']>)[number]>['CourseGroup']>);
  export type CourseGroupHours = NonNullable<(NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<SessionsQuery['SessionQuery']>)['Sessions']>)['Result']>)[number]>['CourseGroup']>)['CourseGroupHours']>)[number]>;
  export type ZoomMeetingSettings = (NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<SessionsQuery['SessionQuery']>)['Sessions']>)['Result']>)[number]>['ZoomMeetingSettings']>);
  export type SessionsAttendance = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<SessionsQuery['SessionQuery']>)['Sessions']>)['Result']>)[number]>['SessionsAttendance']>)[number]>;
  export type StudentSessionAttendance = (NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<SessionsQuery['SessionQuery']>)['Sessions']>)['Result']>)[number]>['StudentSessionAttendance']>);
  export type Assignments = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<SessionsQuery['SessionQuery']>)['Sessions']>)['Result']>)[number]>['Assignments']>)[number]>;
  export type Attachments = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<SessionsQuery['SessionQuery']>)['Sessions']>)['Result']>)[number]>['Attachments']>)[number]>;
}

export namespace StudentRequests {
  export type Variables = StudentRequestsQueryVariables;
  export type Query = StudentRequestsQuery;
  export type RequestQuery = (NonNullable<StudentRequestsQuery['RequestQuery']>);
  export type StudentRequests = (NonNullable<(NonNullable<StudentRequestsQuery['RequestQuery']>)['StudentRequests']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<StudentRequestsQuery['RequestQuery']>)['StudentRequests']>)['Result']>)[number]>;
}

export namespace GetStudentBalance {
  export type Variables = GetStudentBalanceQueryVariables;
  export type Query = GetStudentBalanceQuery;
  export type PaymentQuery = (NonNullable<GetStudentBalanceQuery['PaymentQuery']>);
  export type StudentBalance = (NonNullable<(NonNullable<GetStudentBalanceQuery['PaymentQuery']>)['StudentBalance']>);
  export type GetStudentBalance = (NonNullable<(NonNullable<(NonNullable<GetStudentBalanceQuery['PaymentQuery']>)['StudentBalance']>)['GetStudentBalance']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<(NonNullable<GetStudentBalanceQuery['PaymentQuery']>)['StudentBalance']>)['GetStudentBalance']>)['Result']>);
}

export namespace GetSessionContentStudents {
  export type Variables = GetSessionContentStudentsQueryVariables;
  export type Query = GetSessionContentStudentsQuery;
  export type OnlineContentQuery = (NonNullable<GetSessionContentStudentsQuery['OnlineContentQuery']>);
  export type SessionContentStudent = (NonNullable<(NonNullable<GetSessionContentStudentsQuery['OnlineContentQuery']>)['SessionContentStudent']>);
  export type SessionContentStudents = (NonNullable<(NonNullable<(NonNullable<GetSessionContentStudentsQuery['OnlineContentQuery']>)['SessionContentStudent']>)['SessionContentStudents']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<(NonNullable<GetSessionContentStudentsQuery['OnlineContentQuery']>)['SessionContentStudent']>)['SessionContentStudents']>)['Result']>)[number]>;
  export type SessionContentParts = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<(NonNullable<GetSessionContentStudentsQuery['OnlineContentQuery']>)['SessionContentStudent']>)['SessionContentStudents']>)['Result']>)[number]>['SessionContentParts']>)[number]>;
}

export namespace Subjects {
  export type Variables = SubjectsQueryVariables;
  export type Query = SubjectsQuery;
  export type LookupQuery = (NonNullable<SubjectsQuery['LookupQuery']>);
  export type Subjects = (NonNullable<(NonNullable<SubjectsQuery['LookupQuery']>)['Subjects']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<SubjectsQuery['LookupQuery']>)['Subjects']>)['Result']>)[number]>;
}

export namespace TeacherAssistants {
  export type Variables = TeacherAssistantsQueryVariables;
  export type Query = TeacherAssistantsQuery;
  export type AssistantQuery = (NonNullable<TeacherAssistantsQuery['AssistantQuery']>);
  export type Assistants = (NonNullable<(NonNullable<TeacherAssistantsQuery['AssistantQuery']>)['Assistants']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<TeacherAssistantsQuery['AssistantQuery']>)['Assistants']>)['Result']>)[number]>;
}

export namespace TeacherCourseGroups {
  export type Variables = TeacherCourseGroupsQueryVariables;
  export type Query = TeacherCourseGroupsQuery;
  export type CourseGroupQuery = (NonNullable<TeacherCourseGroupsQuery['CourseGroupQuery']>);
  export type CourseGroups = (NonNullable<(NonNullable<TeacherCourseGroupsQuery['CourseGroupQuery']>)['CourseGroups']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<TeacherCourseGroupsQuery['CourseGroupQuery']>)['CourseGroups']>)['Result']>)[number]>;
  export type CourseGroupAssistants = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<TeacherCourseGroupsQuery['CourseGroupQuery']>)['CourseGroups']>)['Result']>)[number]>['CourseGroupAssistants']>)[number]>;
  export type AssistantRoles = NonNullable<(NonNullable<NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<TeacherCourseGroupsQuery['CourseGroupQuery']>)['CourseGroups']>)['Result']>)[number]>['CourseGroupAssistants']>)[number]>['AssistantRoles']>)[number]>;
  export type CourseGroupStudent = (NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<TeacherCourseGroupsQuery['CourseGroupQuery']>)['CourseGroups']>)['Result']>)[number]>['CourseGroupStudent']>);
  export type Teacher = (NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<TeacherCourseGroupsQuery['CourseGroupQuery']>)['CourseGroups']>)['Result']>)[number]>['Teacher']>);
  export type CourseGroupHours = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<TeacherCourseGroupsQuery['CourseGroupQuery']>)['CourseGroups']>)['Result']>)[number]>['CourseGroupHours']>)[number]>;
}

export namespace TeacherCourses {
  export type Variables = TeacherCoursesQueryVariables;
  export type Query = TeacherCoursesQuery;
  export type CourseQuery = (NonNullable<TeacherCoursesQuery['CourseQuery']>);
  export type Courses = (NonNullable<(NonNullable<TeacherCoursesQuery['CourseQuery']>)['Courses']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<TeacherCoursesQuery['CourseQuery']>)['Courses']>)['Result']>)[number]>;
  export type CourseGroups = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<TeacherCoursesQuery['CourseQuery']>)['Courses']>)['Result']>)[number]>['CourseGroups']>)[number]>;
}

export namespace TeacherQuestionsBank {
  export type Variables = TeacherQuestionsBankQueryVariables;
  export type Query = TeacherQuestionsBankQuery;
  export type TeacherQuery = (NonNullable<TeacherQuestionsBankQuery['TeacherQuery']>);
  export type QuestionBank = (NonNullable<(NonNullable<TeacherQuestionsBankQuery['TeacherQuery']>)['QuestionBank']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<TeacherQuestionsBankQuery['TeacherQuery']>)['QuestionBank']>)['Result']>)[number]>;
  export type QuestionAttachments = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<TeacherQuestionsBankQuery['TeacherQuery']>)['QuestionBank']>)['Result']>)[number]>['QuestionAttachments']>)[number]>;
  export type AnswerAttachments = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<TeacherQuestionsBankQuery['TeacherQuery']>)['QuestionBank']>)['Result']>)[number]>['AnswerAttachments']>)[number]>;
  export type Teacher = (NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<TeacherQuestionsBankQuery['TeacherQuery']>)['QuestionBank']>)['Result']>)[number]>['Teacher']>);
  export type Subject = (NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<TeacherQuestionsBankQuery['TeacherQuery']>)['QuestionBank']>)['Result']>)[number]>['Subject']>);
}

export namespace TeacherSubjects {
  export type Variables = TeacherSubjectsQueryVariables;
  export type Query = TeacherSubjectsQuery;
  export type TeacherQuery = (NonNullable<TeacherSubjectsQuery['TeacherQuery']>);
  export type TeacherSubjects = (NonNullable<(NonNullable<TeacherSubjectsQuery['TeacherQuery']>)['TeacherSubjects']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<TeacherSubjectsQuery['TeacherQuery']>)['TeacherSubjects']>)['Result']>)[number]>;
  export type Subject = (NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<TeacherSubjectsQuery['TeacherQuery']>)['TeacherSubjects']>)['Result']>)[number]>['Subject']>);
}

export namespace GetTeachers {
  export type Variables = GetTeachersQueryVariables;
  export type Query = GetTeachersQuery;
  export type TeacherQuery = (NonNullable<GetTeachersQuery['TeacherQuery']>);
  export type Teachers = (NonNullable<(NonNullable<GetTeachersQuery['TeacherQuery']>)['Teachers']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<GetTeachersQuery['TeacherQuery']>)['Teachers']>)['Result']>)[number]>;
}

export namespace UserActivities {
  export type Variables = UserActivitiesQueryVariables;
  export type Query = UserActivitiesQuery;
  export type ActivityQuery = (NonNullable<UserActivitiesQuery['ActivityQuery']>);
  export type UserActivities = (NonNullable<(NonNullable<UserActivitiesQuery['ActivityQuery']>)['UserActivities']>);
  export type Result = NonNullable<(NonNullable<(NonNullable<(NonNullable<UserActivitiesQuery['ActivityQuery']>)['UserActivities']>)['Result']>)[number]>;
  export type Attachments = NonNullable<(NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<UserActivitiesQuery['ActivityQuery']>)['UserActivities']>)['Result']>)[number]>['Attachments']>)[number]>;
}

export namespace UserProfile {
  export type Variables = UserProfileQueryVariables;
  export type Query = UserProfileQuery;
  export type AccountQuery = (NonNullable<UserProfileQuery['AccountQuery']>);
  export type UserProfile = (NonNullable<(NonNullable<UserProfileQuery['AccountQuery']>)['UserProfile']>);
  export type Result = (NonNullable<(NonNullable<(NonNullable<UserProfileQuery['AccountQuery']>)['UserProfile']>)['Result']>);
}

export type RequestForAbsentMutationVariables = Exact<{
  model: AbsentInput;
}>;


export type RequestForAbsentMutation = (
  { __typename?: 'RootMutation' }
  & { Request?: Maybe<(
    { __typename?: 'RequestMutations' }
    & { StudentRequests?: Maybe<(
      { __typename?: 'StudentRequestMutations' }
      & { Absent?: Maybe<(
        { __typename?: 'RequestTypeResponse' }
        & { Result?: Maybe<(
          { __typename?: 'Request' }
          & Pick<Request, 'Id'>
        )> }
      )> }
    )> }
  )> }
);

export type ApplyActivityOfflineMutationVariables = Exact<{
  model?: Maybe<ApplyActivityOfflineInput>;
}>;


export type ApplyActivityOfflineMutation = (
  { __typename?: 'RootMutation' }
  & { Activity?: Maybe<(
    { __typename?: 'ActivityMutations' }
    & { ApplyActivityOffline?: Maybe<(
      { __typename?: 'ApplyActivityOfflineTypeResponse' }
      & { Result?: Maybe<(
        { __typename?: 'ApplyActivityOffline' }
        & Pick<ApplyActivityOffline, 'ActivityId' | 'StudentId' | 'Attachments'>
      )> }
    )> }
  )> }
);

export type ApplyActivityOnlineMutationVariables = Exact<{
  model: ApplyActivityOnlineInput;
}>;


export type ApplyActivityOnlineMutation = (
  { __typename?: 'RootMutation' }
  & { Student?: Maybe<(
    { __typename?: 'StudentMutations' }
    & { ApplyActivityOnline?: Maybe<(
      { __typename?: 'UserActivityTypeResponse' }
      & { Result?: Maybe<(
        { __typename?: 'StudentActivity' }
        & Pick<StudentActivity, 'Id' | 'ActivityId' | 'StudentId' | 'IsMarked'>
      )> }
    )> }
  )> }
);

export type AskSessionQuestionMutationVariables = Exact<{
  model?: Maybe<SessionQuestionInput>;
}>;


export type AskSessionQuestionMutation = (
  { __typename?: 'RootMutation' }
  & { Session?: Maybe<(
    { __typename?: 'SessionMutations' }
    & { AskSessionQuestion?: Maybe<(
      { __typename?: 'SessionQuestionTypeResponse' }
      & Pick<SessionQuestionTypeResponse, 'TotalCount'>
      & { Result?: Maybe<(
        { __typename?: 'SessionQuestion' }
        & Pick<SessionQuestion, 'Id'>
      )> }
    )> }
  )> }
);

export type BlockStudentMutationVariables = Exact<{
  model?: Maybe<ManageStudentInput>;
}>;


export type BlockStudentMutation = (
  { __typename?: 'RootMutation' }
  & { UserBlock?: Maybe<(
    { __typename?: 'UserBlockMutation' }
    & { BlockStudent?: Maybe<(
      { __typename?: 'BooleanGraphTypeResponse' }
      & Pick<BooleanGraphTypeResponse, 'Result'>
    )> }
  )> }
);

export type CancelRequestMutationVariables = Exact<{
  model: RequestInput;
}>;


export type CancelRequestMutation = (
  { __typename?: 'RootMutation' }
  & { Request?: Maybe<(
    { __typename?: 'RequestMutations' }
    & { CancelRequest?: Maybe<(
      { __typename?: 'BooleanGraphTypeResponse' }
      & Pick<BooleanGraphTypeResponse, 'Result'>
    )> }
  )> }
);

export type ChangeCourseGroupPermanentMutationVariables = Exact<{
  model: ChangeCourseGroupInput;
}>;


export type ChangeCourseGroupPermanentMutation = (
  { __typename?: 'RootMutation' }
  & { Request?: Maybe<(
    { __typename?: 'RequestMutations' }
    & { StudentRequests?: Maybe<(
      { __typename?: 'StudentRequestMutations' }
      & { ChangeCourseGroup?: Maybe<(
        { __typename?: 'RequestTypeResponse' }
        & { Result?: Maybe<(
          { __typename?: 'Request' }
          & Pick<Request, 'CourseGroupId' | 'CourseGroupName' | 'CourseName' | 'Id'>
        )> }
      )> }
    )> }
  )> }
);

export type ChangePasswordMutationVariables = Exact<{
  model: ChangePasswordInput;
}>;


export type ChangePasswordMutation = (
  { __typename?: 'RootMutation' }
  & { Account?: Maybe<(
    { __typename?: 'AccountMutations' }
    & { ChangePassword?: Maybe<(
      { __typename?: 'ChangePasswordTypeResponse' }
      & Pick<ChangePasswordTypeResponse, 'TotalCount'>
      & { Result?: Maybe<(
        { __typename?: 'ChangePasswordOutput' }
        & Pick<ChangePasswordOutput, 'AssistantId' | 'ImagePath' | 'Name' | 'ParentId' | 'RefreshToken' | 'StudentId' | 'TeacherId' | 'Token' | 'TokenExpirationDate' | 'UserId' | 'UserName'>
      )> }
    )> }
  )> }
);

export type CheckEmailAndMobileNumberMutationVariables = Exact<{
  model: CheckEmailAndMobileNumberInput;
}>;


export type CheckEmailAndMobileNumberMutation = (
  { __typename?: 'RootMutation' }
  & { Account?: Maybe<(
    { __typename?: 'AccountMutations' }
    & { CheckEmailAndMobileNumber?: Maybe<(
      { __typename?: 'CheckEmailAndMobileNumberTypeResponse' }
      & Pick<CheckEmailAndMobileNumberTypeResponse, 'TotalCount'>
      & { Result?: Maybe<(
        { __typename?: 'CheckEmailAndMobileNumberOutput' }
        & Pick<CheckEmailAndMobileNumberOutput, 'IsValid'>
      )> }
    )> }
  )> }
);

export type ContactUsMutationVariables = Exact<{
  model?: Maybe<ContactUsInput>;
}>;


export type ContactUsMutation = (
  { __typename?: 'RootMutation' }
  & { Common?: Maybe<(
    { __typename?: 'CommonMutation' }
    & { ContactUs?: Maybe<(
      { __typename?: 'ContactUsTypeResponse' }
      & { Result?: Maybe<(
        { __typename?: 'ContactUs' }
        & Pick<ContactUs, 'Id' | 'Email' | 'Mobile' | 'Name'>
      )> }
    )> }
  )> }
);

export type CreateAssignmentMutationVariables = Exact<{
  model: AssignmentInput;
}>;


export type CreateAssignmentMutation = (
  { __typename?: 'RootMutation' }
  & { Activity?: Maybe<(
    { __typename?: 'ActivityMutations' }
    & { CreateAssignment?: Maybe<(
      { __typename?: 'ActivityTypeListResponse' }
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'Activity' }
        & Pick<Activity, 'Id'>
      )>>> }
    )> }
  )> }
);

export type CreateCourseGroupMutationVariables = Exact<{
  model: CourseGroupInput;
}>;


export type CreateCourseGroupMutation = (
  { __typename?: 'RootMutation' }
  & { CourseGroup?: Maybe<(
    { __typename?: 'CourseGroupMutations' }
    & { CreateCourseGroup?: Maybe<(
      { __typename?: 'CourseGroupTypeResponse' }
      & { Result?: Maybe<(
        { __typename?: 'CourseGroup' }
        & Pick<CourseGroup, 'Id'>
      )> }
    )> }
  )> }
);

export type CreateCourseMutationVariables = Exact<{
  model?: Maybe<CourseInput>;
}>;


export type CreateCourseMutation = (
  { __typename?: 'RootMutation' }
  & { Course?: Maybe<(
    { __typename?: 'CourseMutations' }
    & { CreateCourse?: Maybe<(
      { __typename?: 'CourseTypeResponse' }
      & { Result?: Maybe<(
        { __typename?: 'Course' }
        & Pick<Course, 'Id' | 'SubjectId' | 'TeacherId' | 'IsActive' | 'ImagePath'>
      )> }
    )> }
  )> }
);

export type CreatePostMutationVariables = Exact<{
  model: PostInput;
}>;


export type CreatePostMutation = (
  { __typename?: 'RootMutation' }
  & { Activity?: Maybe<(
    { __typename?: 'ActivityMutations' }
    & { CreatePost?: Maybe<(
      { __typename?: 'ActivityTypeListResponse' }
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'Activity' }
        & Pick<Activity, 'Id'>
      )>>> }
    )> }
  )> }
);

export type CreatePreviouExamMutationVariables = Exact<{
  model?: Maybe<AddPreviousExamInput>;
}>;


export type CreatePreviouExamMutation = (
  { __typename?: 'RootMutation' }
  & { Teacher?: Maybe<(
    { __typename?: 'TeacherMutations' }
    & { CreatePreviousExam?: Maybe<(
      { __typename?: 'PreviousExamTypeResponse' }
      & { Result?: Maybe<(
        { __typename?: 'PreviousExam' }
        & Pick<PreviousExam, 'TeacherId' | 'CourseId' | 'Title' | 'ExamYear' | 'AttachmentPath'>
      )> }
    )> }
  )> }
);

export type CreateQuizMutationVariables = Exact<{
  model: QuizInput;
}>;


export type CreateQuizMutation = (
  { __typename?: 'RootMutation' }
  & { Activity?: Maybe<(
    { __typename?: 'ActivityMutations' }
    & { CreateQuiz?: Maybe<(
      { __typename?: 'ActivityTypeListResponse' }
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'Activity' }
        & Pick<Activity, 'Id'>
      )>>> }
    )> }
  )> }
);

export type CreateRevisionMutationVariables = Exact<{
  model: RevisionInput;
}>;


export type CreateRevisionMutation = (
  { __typename?: 'RootMutation' }
  & { Activity?: Maybe<(
    { __typename?: 'ActivityMutations' }
    & { CreateRevision?: Maybe<(
      { __typename?: 'ActivityTypeListResponse' }
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'Activity' }
        & Pick<Activity, 'Id'>
      )>>> }
    )> }
  )> }
);

export type CreateSessionMutationVariables = Exact<{
  model?: Maybe<SessionInput>;
}>;


export type CreateSessionMutation = (
  { __typename?: 'RootMutation' }
  & { Session?: Maybe<(
    { __typename?: 'SessionMutations' }
    & { CreateSession?: Maybe<(
      { __typename?: 'SessionTypeResponse' }
      & { Result?: Maybe<(
        { __typename?: 'Session' }
        & Pick<Session, 'Id' | 'QRCodePath'>
      )> }
    )> }
  )> }
);

export type CreateTeacherQuestionBankMutationVariables = Exact<{
  model?: Maybe<TeacherQuestionInput>;
}>;


export type CreateTeacherQuestionBankMutation = (
  { __typename?: 'RootMutation' }
  & { Teacher?: Maybe<(
    { __typename?: 'TeacherMutations' }
    & { CreateTeacherQuestion?: Maybe<(
      { __typename?: 'TeacherQuestionTypeResponse' }
      & { Result?: Maybe<(
        { __typename?: 'TeacherQuestion' }
        & Pick<TeacherQuestion, 'Id' | 'TeacherId' | 'SubjectId'>
      )> }
    )> }
  )> }
);

export type DeleteCourseGroupMutationVariables = Exact<{
  model: DeleteCourseGroupInput;
}>;


export type DeleteCourseGroupMutation = (
  { __typename?: 'RootMutation' }
  & { CourseGroup?: Maybe<(
    { __typename?: 'CourseGroupMutations' }
    & { DeleteCourseGroup?: Maybe<(
      { __typename?: 'BooleanGraphTypeResponse' }
      & Pick<BooleanGraphTypeResponse, 'Result'>
    )> }
  )> }
);

export type FollowTeacherMutationVariables = Exact<{
  model?: Maybe<TeacherFollowerInput>;
}>;


export type FollowTeacherMutation = (
  { __typename?: 'RootMutation' }
  & { Teacher?: Maybe<(
    { __typename?: 'TeacherMutations' }
    & { FollowTeacher?: Maybe<(
      { __typename?: 'TeacherFollowerTypeResponse' }
      & { Result?: Maybe<(
        { __typename?: 'TeacherFollower' }
        & Pick<TeacherFollower, 'TeacherId' | 'StudentId' | 'ParentId'>
      )> }
    )> }
  )> }
);

export type ForgetPasswordMutationVariables = Exact<{
  model: ForgetPasswordInput;
}>;


export type ForgetPasswordMutation = (
  { __typename?: 'RootMutation' }
  & { Account?: Maybe<(
    { __typename?: 'AccountMutations' }
    & { ForgetPassword?: Maybe<(
      { __typename?: 'ForgetPasswordTypeResponse' }
      & Pick<ForgetPasswordTypeResponse, 'TotalCount'>
      & { Result?: Maybe<(
        { __typename?: 'ForgetPasswordOutput' }
        & Pick<ForgetPasswordOutput, 'Code' | 'UserId'>
      )> }
    )> }
  )> }
);

export type GenerateQrCodeMutationVariables = Exact<{
  sessionId?: Maybe<Scalars['Int']>;
}>;


export type GenerateQrCodeMutation = (
  { __typename?: 'RootMutation' }
  & { Session?: Maybe<(
    { __typename?: 'SessionMutations' }
    & { GenerateQRCode?: Maybe<(
      { __typename?: 'StringGraphTypeResponse' }
      & Pick<StringGraphTypeResponse, 'Result'>
    )> }
  )> }
);

export type JoinCourseGroupMutationVariables = Exact<{
  model: JoinCourseGroupInput;
}>;


export type JoinCourseGroupMutation = (
  { __typename?: 'RootMutation' }
  & { Request?: Maybe<(
    { __typename?: 'RequestMutations' }
    & { StudentRequests?: Maybe<(
      { __typename?: 'StudentRequestMutations' }
      & { JoinCourseGroup?: Maybe<(
        { __typename?: 'RequestTypeResponse' }
        & { Result?: Maybe<(
          { __typename?: 'Request' }
          & Pick<Request, 'Id'>
        )> }
      )> }
    )> }
  )> }
);

export type LoginMutationVariables = Exact<{
  model: LoginInput;
}>;


export type LoginMutation = (
  { __typename?: 'RootMutation' }
  & { Account?: Maybe<(
    { __typename?: 'AccountMutations' }
    & { Login?: Maybe<(
      { __typename?: 'LoginTypeResponse' }
      & Pick<LoginTypeResponse, 'TotalCount'>
      & { Result?: Maybe<(
        { __typename?: 'LoginOutput' }
        & Pick<LoginOutput, 'AssistantId' | 'EducationTypeId' | 'ImagePath' | 'Name' | 'ParentId' | 'RefreshToken' | 'StudentId' | 'TeacherId' | 'Token' | 'TokenExpirationDate' | 'UserId' | 'UserType' | 'UserName'>
      )> }
    )> }
  )> }
);

export type ManageAddSonRequestMutationVariables = Exact<{
  model: ManageRequestInput;
}>;


export type ManageAddSonRequestMutation = (
  { __typename?: 'RootMutation' }
  & { Request?: Maybe<(
    { __typename?: 'RequestMutations' }
    & { StudentRequests?: Maybe<(
      { __typename?: 'StudentRequestMutations' }
      & { ManageAddSonRequest?: Maybe<(
        { __typename?: 'BooleanGraphTypeResponse' }
        & Pick<BooleanGraphTypeResponse, 'Result'>
      )> }
    )> }
  )> }
);

export type ManageAddToCourseGroupRequestMutationVariables = Exact<{
  model: ManageRequestInput;
}>;


export type ManageAddToCourseGroupRequestMutation = (
  { __typename?: 'RootMutation' }
  & { Request?: Maybe<(
    { __typename?: 'RequestMutations' }
    & { StudentRequests?: Maybe<(
      { __typename?: 'StudentRequestMutations' }
      & { ManageAddToCourseGroupRequest?: Maybe<(
        { __typename?: 'BooleanGraphTypeResponse' }
        & Pick<BooleanGraphTypeResponse, 'Result'>
      )> }
    )> }
  )> }
);

export type RefreshTokenMutationVariables = Exact<{
  model: RefreshTokenInput;
}>;


export type RefreshTokenMutation = (
  { __typename?: 'RootMutation' }
  & { Account?: Maybe<(
    { __typename?: 'AccountMutations' }
    & { RereshToken?: Maybe<(
      { __typename?: 'TokenTypeResponse' }
      & Pick<TokenTypeResponse, 'TotalCount'>
      & { Result?: Maybe<(
        { __typename?: 'Token' }
        & Pick<Token, 'RefreshToken' | 'Token' | 'TokenExpirationDate'>
      )> }
    )> }
  )> }
);

export type RegisterMutationVariables = Exact<{
  model: RegisterInput;
}>;


export type RegisterMutation = (
  { __typename?: 'RootMutation' }
  & { Account?: Maybe<(
    { __typename?: 'AccountMutations' }
    & { Register?: Maybe<(
      { __typename?: 'RegisterTypeResponse' }
      & Pick<RegisterTypeResponse, 'TotalCount'>
      & { Result?: Maybe<(
        { __typename?: 'RegisterOutput' }
        & Pick<RegisterOutput, 'AssistantId' | 'Code' | 'ImagePath' | 'ParentId' | 'StudentId' | 'TeacherId' | 'UserId'>
      )> }
    )> }
  )> }
);

export type RemoveAssignmentMutationVariables = Exact<{
  activtiyId?: Maybe<Scalars['Int']>;
}>;


export type RemoveAssignmentMutation = (
  { __typename?: 'RootMutation' }
  & { Activity?: Maybe<(
    { __typename?: 'ActivityMutations' }
    & { DeleteActivtiy?: Maybe<(
      { __typename?: 'BooleanGraphTypeResponse' }
      & Pick<BooleanGraphTypeResponse, 'Result'>
    )> }
  )> }
);

export type RemovePostMutationVariables = Exact<{
  activtiyId?: Maybe<Scalars['Int']>;
}>;


export type RemovePostMutation = (
  { __typename?: 'RootMutation' }
  & { Activity?: Maybe<(
    { __typename?: 'ActivityMutations' }
    & { DeleteActivtiy?: Maybe<(
      { __typename?: 'BooleanGraphTypeResponse' }
      & Pick<BooleanGraphTypeResponse, 'Result'>
    )> }
  )> }
);

export type RemovePreviousExamMutationVariables = Exact<{
  previousExamId?: Maybe<Scalars['Int']>;
}>;


export type RemovePreviousExamMutation = (
  { __typename?: 'RootMutation' }
  & { Teacher?: Maybe<(
    { __typename?: 'TeacherMutations' }
    & { DeletePreviousExam?: Maybe<(
      { __typename?: 'BooleanGraphTypeResponse' }
      & Pick<BooleanGraphTypeResponse, 'Result'>
    )> }
  )> }
);

export type RemoveQuizMutationVariables = Exact<{
  activtiyId?: Maybe<Scalars['Int']>;
}>;


export type RemoveQuizMutation = (
  { __typename?: 'RootMutation' }
  & { Activity?: Maybe<(
    { __typename?: 'ActivityMutations' }
    & { DeleteActivtiy?: Maybe<(
      { __typename?: 'BooleanGraphTypeResponse' }
      & Pick<BooleanGraphTypeResponse, 'Result'>
    )> }
  )> }
);

export type RemoveRevisionMutationVariables = Exact<{
  activtiyId?: Maybe<Scalars['Int']>;
}>;


export type RemoveRevisionMutation = (
  { __typename?: 'RootMutation' }
  & { Activity?: Maybe<(
    { __typename?: 'ActivityMutations' }
    & { DeleteActivtiy?: Maybe<(
      { __typename?: 'BooleanGraphTypeResponse' }
      & Pick<BooleanGraphTypeResponse, 'Result'>
    )> }
  )> }
);

export type RemoveSessionMutationVariables = Exact<{
  SessionId?: Maybe<Scalars['Int']>;
}>;


export type RemoveSessionMutation = (
  { __typename?: 'RootMutation' }
  & { Session?: Maybe<(
    { __typename?: 'SessionMutations' }
    & { DeleteSession?: Maybe<(
      { __typename?: 'BooleanGraphTypeResponse' }
      & Pick<BooleanGraphTypeResponse, 'Result'>
    )> }
  )> }
);

export type RemoveStudentsMutationVariables = Exact<{
  model?: Maybe<ManageStudentInput>;
}>;


export type RemoveStudentsMutation = (
  { __typename?: 'RootMutation' }
  & { Teacher?: Maybe<(
    { __typename?: 'TeacherMutations' }
    & { DeleteStudent?: Maybe<(
      { __typename?: 'BooleanGraphTypeResponse' }
      & Pick<BooleanGraphTypeResponse, 'Result'>
    )> }
  )> }
);

export type RemoveTeacherQuestionbankMutationVariables = Exact<{
  questionId?: Maybe<Scalars['Int']>;
}>;


export type RemoveTeacherQuestionbankMutation = (
  { __typename?: 'RootMutation' }
  & { Teacher?: Maybe<(
    { __typename?: 'TeacherMutations' }
    & { DeleteTeacherQuestion?: Maybe<(
      { __typename?: 'BooleanGraphTypeResponse' }
      & Pick<BooleanGraphTypeResponse, 'Result'>
    )> }
  )> }
);

export type ResendVerificationCodeMutationVariables = Exact<{
  model: ResendVerificationCodeInput;
}>;


export type ResendVerificationCodeMutation = (
  { __typename?: 'RootMutation' }
  & { Account?: Maybe<(
    { __typename?: 'AccountMutations' }
    & { ResendVerificationCode?: Maybe<(
      { __typename?: 'ResendVerificationCodeTypeResponse' }
      & Pick<ResendVerificationCodeTypeResponse, 'TotalCount'>
      & { Result?: Maybe<(
        { __typename?: 'ResendVerificationCodeOutput' }
        & Pick<ResendVerificationCodeOutput, 'Code' | 'UserId'>
      )> }
    )> }
  )> }
);

export type ResetPasswordMutationVariables = Exact<{
  model: ResetPasswordInput;
}>;


export type ResetPasswordMutation = (
  { __typename?: 'RootMutation' }
  & { Account?: Maybe<(
    { __typename?: 'AccountMutations' }
    & { ResetPassword?: Maybe<(
      { __typename?: 'ResetPasswordTypeResponse' }
      & Pick<ResetPasswordTypeResponse, 'TotalCount'>
      & { Result?: Maybe<(
        { __typename?: 'ResetPasswordOutput' }
        & Pick<ResetPasswordOutput, 'AssistantId' | 'ImagePath' | 'Name' | 'ParentId' | 'StudentId' | 'TeacherId' | 'Token' | 'TokenExpirationDate' | 'UserId' | 'UserName'>
      )> }
    )> }
  )> }
);

export type SubmitCourseGroupRateMutationVariables = Exact<{
  model?: Maybe<CourseGroupRateInput>;
}>;


export type SubmitCourseGroupRateMutation = (
  { __typename?: 'RootMutation' }
  & { Student?: Maybe<(
    { __typename?: 'StudentMutations' }
    & { SubmitCourseGroupRate?: Maybe<(
      { __typename?: 'CourseGroupStudentsTypeResponse' }
      & Pick<CourseGroupStudentsTypeResponse, 'TotalCount'>
      & { Result?: Maybe<(
        { __typename?: 'CourseGroupStudents' }
        & Pick<CourseGroupStudents, 'Comment' | 'CourseGroupId' | 'CourseGroupName' | 'CourseId' | 'Id' | 'ImagePath' | 'IsSelected' | 'IsSubscribed' | 'IsTeacherPaid' | 'ParentId' | 'Rate' | 'Report' | 'SchoolYearName' | 'ScientificSubjectName' | 'StudentId' | 'StudentMobileNumber' | 'StudentName' | 'StudentStatus' | 'TeacherName'>
      )> }
    )> }
  )> }
);

export type SubmitCourseGroupReportMutationVariables = Exact<{
  model?: Maybe<CourseGroupReportInput>;
}>;


export type SubmitCourseGroupReportMutation = (
  { __typename?: 'RootMutation' }
  & { Student?: Maybe<(
    { __typename?: 'StudentMutations' }
    & { SubmitCourseGroupReport?: Maybe<(
      { __typename?: 'CourseGroupStudentsTypeResponse' }
      & Pick<CourseGroupStudentsTypeResponse, 'TotalCount'>
      & { Result?: Maybe<(
        { __typename?: 'CourseGroupStudents' }
        & Pick<CourseGroupStudents, 'Comment' | 'CourseGroupId' | 'CourseGroupName' | 'CourseId' | 'Id' | 'ImagePath' | 'IsSelected' | 'IsSubscribed' | 'IsTeacherPaid' | 'ParentId' | 'Rate' | 'Report' | 'SchoolYearName' | 'ScientificSubjectName' | 'StudentId' | 'StudentMobileNumber' | 'StudentName' | 'StudentStatus' | 'TeacherName'>
      )> }
    )> }
  )> }
);

export type SubmitSessionRateMutationVariables = Exact<{
  model?: Maybe<SessionRateInput>;
}>;


export type SubmitSessionRateMutation = (
  { __typename?: 'RootMutation' }
  & { Session?: Maybe<(
    { __typename?: 'SessionMutations' }
    & { SubmitSessionRate?: Maybe<(
      { __typename?: 'SessionRateTypeResponse' }
      & { Result?: Maybe<(
        { __typename?: 'SessionRate' }
        & Pick<SessionRate, 'SessionId' | 'StudentId' | 'Rate' | 'Comment'>
      )> }
    )> }
  )> }
);

export type SuggestQuestionMutationVariables = Exact<{
  model?: Maybe<SuggestQuestionInput>;
}>;


export type SuggestQuestionMutation = (
  { __typename?: 'RootMutation' }
  & { Student?: Maybe<(
    { __typename?: 'StudentMutations' }
    & { SuggestQuestion?: Maybe<(
      { __typename?: 'CourseQuestionTypeResponse' }
      & { Result?: Maybe<(
        { __typename?: 'CourseQuestion' }
        & Pick<CourseQuestion, 'Id' | 'StudentId' | 'CourseId' | 'QuestionText' | 'QuestionPath' | 'Answer' | 'AnswerPath'>
      )> }
    )> }
  )> }
);

export type SuspendStudentMutationVariables = Exact<{
  model?: Maybe<ManageStudentInput>;
}>;


export type SuspendStudentMutation = (
  { __typename?: 'RootMutation' }
  & { Teacher?: Maybe<(
    { __typename?: 'TeacherMutations' }
    & { SuspendAndActiveStudent?: Maybe<(
      { __typename?: 'BooleanGraphTypeResponse' }
      & Pick<BooleanGraphTypeResponse, 'Result'>
    )> }
  )> }
);

export type UnFollowTeacherMutationVariables = Exact<{
  model?: Maybe<TeacherFollowerInput>;
}>;


export type UnFollowTeacherMutation = (
  { __typename?: 'RootMutation' }
  & { Teacher?: Maybe<(
    { __typename?: 'TeacherMutations' }
    & { UnFollowTeacher?: Maybe<(
      { __typename?: 'TeacherFollowerTypeResponse' }
      & { Result?: Maybe<(
        { __typename?: 'TeacherFollower' }
        & Pick<TeacherFollower, 'TeacherId' | 'StudentId' | 'ParentId'>
      )> }
    )> }
  )> }
);

export type UpdateAssignmentMutationVariables = Exact<{
  model: AssignmentInput;
}>;


export type UpdateAssignmentMutation = (
  { __typename?: 'RootMutation' }
  & { Activity?: Maybe<(
    { __typename?: 'ActivityMutations' }
    & { UpdateAssignment?: Maybe<(
      { __typename?: 'ActivityTypeResponse' }
      & { Result?: Maybe<(
        { __typename?: 'Activity' }
        & Pick<Activity, 'Id'>
      )> }
    )> }
  )> }
);

export type UpdateCourseGroupMutationVariables = Exact<{
  model: CourseGroupInput;
}>;


export type UpdateCourseGroupMutation = (
  { __typename?: 'RootMutation' }
  & { CourseGroup?: Maybe<(
    { __typename?: 'CourseGroupMutations' }
    & { UpdateCourseGroup?: Maybe<(
      { __typename?: 'CourseGroupTypeResponse' }
      & { Result?: Maybe<(
        { __typename?: 'CourseGroup' }
        & Pick<CourseGroup, 'Id'>
      )> }
    )> }
  )> }
);

export type UpdatePostMutationVariables = Exact<{
  model: PostInput;
}>;


export type UpdatePostMutation = (
  { __typename?: 'RootMutation' }
  & { Activity?: Maybe<(
    { __typename?: 'ActivityMutations' }
    & { UpdatePost?: Maybe<(
      { __typename?: 'ActivityTypeResponse' }
      & { Result?: Maybe<(
        { __typename?: 'Activity' }
        & Pick<Activity, 'Id'>
      )> }
    )> }
  )> }
);

export type UpdatePreviouExamMutationVariables = Exact<{
  model?: Maybe<EditPreviousExamInput>;
}>;


export type UpdatePreviouExamMutation = (
  { __typename?: 'RootMutation' }
  & { Teacher?: Maybe<(
    { __typename?: 'TeacherMutations' }
    & { EditPreviousExam?: Maybe<(
      { __typename?: 'BooleanGraphTypeResponse' }
      & Pick<BooleanGraphTypeResponse, 'Result'>
    )> }
  )> }
);

export type UpdateRevisionMutationVariables = Exact<{
  model: RevisionInput;
}>;


export type UpdateRevisionMutation = (
  { __typename?: 'RootMutation' }
  & { Activity?: Maybe<(
    { __typename?: 'ActivityMutations' }
    & { UpdateRevision?: Maybe<(
      { __typename?: 'ActivityTypeResponse' }
      & { Result?: Maybe<(
        { __typename?: 'Activity' }
        & Pick<Activity, 'Id'>
      )> }
    )> }
  )> }
);

export type UpdateSessionMutationVariables = Exact<{
  model?: Maybe<SessionInput>;
}>;


export type UpdateSessionMutation = (
  { __typename?: 'RootMutation' }
  & { Session?: Maybe<(
    { __typename?: 'SessionMutations' }
    & { UpdateSession?: Maybe<(
      { __typename?: 'SessionTypeResponse' }
      & { Result?: Maybe<(
        { __typename?: 'Session' }
        & Pick<Session, 'Id'>
      )> }
    )> }
  )> }
);

export type UpdateTeacherQuestionbankMutationVariables = Exact<{
  model?: Maybe<TeacherQuestionInput>;
}>;


export type UpdateTeacherQuestionbankMutation = (
  { __typename?: 'RootMutation' }
  & { Teacher?: Maybe<(
    { __typename?: 'TeacherMutations' }
    & { EditTeacherQuestion?: Maybe<(
      { __typename?: 'TeacherQuestionTypeResponse' }
      & { Result?: Maybe<(
        { __typename?: 'TeacherQuestion' }
        & Pick<TeacherQuestion, 'Id' | 'TeacherId' | 'SubjectId'>
      )> }
    )> }
  )> }
);

export type UpdateUserProfileMutationVariables = Exact<{
  model?: Maybe<UpdateUserProfileInput>;
}>;


export type UpdateUserProfileMutation = (
  { __typename?: 'RootMutation' }
  & { Account?: Maybe<(
    { __typename?: 'AccountMutations' }
    & { UpdateUserProfile?: Maybe<(
      { __typename?: 'UserProfileTypeResponse' }
      & Pick<UserProfileTypeResponse, 'TotalCount'>
      & { Result?: Maybe<(
        { __typename?: 'UserProfile' }
        & Pick<UserProfile, 'AssistantId' | 'Bio' | 'EducationTypeId' | 'Email' | 'Gender' | 'GenderName' | 'Id' | 'UserType' | 'ImagePath' | 'IsConfirmed' | 'MobileNumber' | 'Name' | 'ParentId' | 'SchoolYearId' | 'Slogan' | 'Status' | 'StudentEducationTypeName' | 'StudentId' | 'StudentSchoolYearName' | 'TeacherId' | 'UserId' | 'VideoPath'>
      )> }
    )> }
  )> }
);

export type ValidateResetPasswordCodeMutationVariables = Exact<{
  model: ValidateResetPasswordCodeInput;
}>;


export type ValidateResetPasswordCodeMutation = (
  { __typename?: 'RootMutation' }
  & { Account?: Maybe<(
    { __typename?: 'AccountMutations' }
    & { ValidateResetPasswordCode?: Maybe<(
      { __typename?: 'ValidateResetPasswordCodeTypeResponse' }
      & Pick<ValidateResetPasswordCodeTypeResponse, 'TotalCount'>
      & { Result?: Maybe<(
        { __typename?: 'ValidateResetPasswordCodeOutput' }
        & Pick<ValidateResetPasswordCodeOutput, 'Code' | 'IsValid' | 'UserId'>
      )> }
    )> }
  )> }
);

export type VerifyMobileMutationVariables = Exact<{
  model: VerifyMobileInput;
}>;


export type VerifyMobileMutation = (
  { __typename?: 'RootMutation' }
  & { Account?: Maybe<(
    { __typename?: 'AccountMutations' }
    & { VerifyMobile?: Maybe<(
      { __typename?: 'VerifyMobileTypeResponse' }
      & Pick<VerifyMobileTypeResponse, 'TotalCount'>
      & { Result?: Maybe<(
        { __typename?: 'VerifyMobileOutput' }
        & Pick<VerifyMobileOutput, 'AssistantId' | 'ImagePath' | 'Name' | 'RefreshToken' | 'StudentId' | 'ParentId' | 'TeacherId' | 'Token' | 'TokenExpirationDate' | 'UserId' | 'UserName'>
      )> }
    )> }
  )> }
);

export type ViewRevisionMutationVariables = Exact<{
  model: ViewRevisionInput;
}>;


export type ViewRevisionMutation = (
  { __typename?: 'RootMutation' }
  & { Student?: Maybe<(
    { __typename?: 'StudentMutations' }
    & { ViewRevision?: Maybe<(
      { __typename?: 'ViewRevisionTypeResponse' }
      & { Result?: Maybe<(
        { __typename?: 'ViewRevision' }
        & Pick<ViewRevision, 'ActivityId' | 'StudentId'>
      )> }
    )> }
  )> }
);

export type ActivityQuestionsWithAnswersQueryVariables = Exact<{
  criteria?: Maybe<ActivityQuestionCriteria>;
  studentCriteria?: Maybe<StudentActivityQuestionAnswersCriteria>;
}>;


export type ActivityQuestionsWithAnswersQuery = (
  { __typename?: 'RootQuery' }
  & { ActivityQuery?: Maybe<(
    { __typename?: 'ActiviyQuery' }
    & { ActivityQuestions?: Maybe<(
      { __typename?: 'ActivityQuestionTypeListResponse' }
      & Pick<ActivityQuestionTypeListResponse, 'TotalCount'>
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'ActivityQuestion' }
        & Pick<ActivityQuestion, 'Id' | 'QuestionText' | 'ChoiceOne' | 'ChoiceTwo' | 'ChoiceThree' | 'ChoiceFour' | 'Answer' | 'QuestionType' | 'Grade'>
        & { QuestionAttachments?: Maybe<Array<Maybe<(
          { __typename?: 'Attachment' }
          & Pick<Attachment, 'AttachmentName' | 'AttachmentPath' | 'IsAnswer' | 'FileType'>
        )>>>, StudentAnswer?: Maybe<(
          { __typename?: 'StudentActivityQuestionAnswer' }
          & Pick<StudentActivityQuestionAnswer, 'Id' | 'Answer' | 'IsCorrectAnswer' | 'IsAnswer' | 'IsMarked' | 'Grade'>
        )>, Activity?: Maybe<(
          { __typename?: 'Activity' }
          & Pick<Activity, 'Id' | 'Name' | 'StartDate' | 'DeadLine' | 'Grade' | 'CourseGroupName' | 'ScientificSubjectName'>
        )> }
      )>>> }
    )> }
  )> }
);

export type ActivityQuestionsQueryVariables = Exact<{
  activityCriteria?: Maybe<ActivityCriteria>;
  withQuestion: Scalars['Boolean'];
}>;


export type ActivityQuestionsQuery = (
  { __typename?: 'RootQuery' }
  & { ActivityQuery?: Maybe<(
    { __typename?: 'ActiviyQuery' }
    & { Activities?: Maybe<(
      { __typename?: 'ActivityTypeListResponse' }
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'Activity' }
        & Pick<Activity, 'Id' | 'ActivityType' | 'Name' | 'Date' | 'AddedDate' | 'StartDate' | 'DeadLine' | 'StartTime' | 'EndTime' | 'Creator' | 'CreatorPic' | 'ScientificSubjectName' | 'Grade'>
        & { ActivityQuestions?: Maybe<Array<Maybe<(
          { __typename?: 'ActivityQuestion' }
          & Pick<ActivityQuestion, 'Id' | 'QuestionText' | 'QuestionType' | 'ChoiceOne' | 'ChoiceTwo' | 'ChoiceThree' | 'ChoiceFour'>
          & { QuestionAttachments?: Maybe<Array<Maybe<(
            { __typename?: 'Attachment' }
            & Pick<Attachment, 'AttachmentName' | 'AttachmentPath' | 'FileType'>
          )>>> }
        )>>> }
      )>>> }
    )> }
  )> }
);

export type AreasQueryVariables = Exact<{
  criteria?: Maybe<AreaCriteria>;
}>;


export type AreasQuery = (
  { __typename?: 'RootQuery' }
  & { LookupQuery?: Maybe<(
    { __typename?: 'LookupsQuery' }
    & { Areas?: Maybe<(
      { __typename?: 'AreaTypeListResponse' }
      & Pick<AreaTypeListResponse, 'TotalCount'>
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'Area' }
        & Pick<Area, 'Id' | 'Name'>
      )>>> }
    )> }
  )> }
);

export type AssignmentsQueryVariables = Exact<{
  criteria?: Maybe<ActivityCriteria>;
  userActivityCriteria?: Maybe<UserActivityCriteria>;
  studentCriteria?: Maybe<StudentActivityQuestionAnswersCriteria>;
}>;


export type AssignmentsQuery = (
  { __typename?: 'RootQuery' }
  & { ActivityQuery?: Maybe<(
    { __typename?: 'ActiviyQuery' }
    & { Assignments?: Maybe<(
      { __typename?: 'AssignmentTypeListResponse' }
      & Pick<AssignmentTypeListResponse, 'TotalCount'>
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'Assignment' }
        & Pick<Assignment, 'Id' | 'Name' | 'ScientificSubjectName' | 'SchoolYearName' | 'CourseGroupName' | 'SessionName' | 'SessionId' | 'StartDate' | 'DeadLine' | 'Content' | 'UserActivityCount' | 'StudentCount' | 'IsMarkedUserActivityCount' | 'IsMarked' | 'IsFinished' | 'IsDraft' | 'Grade' | 'IsOnline' | 'ServerDateTime' | 'IsScheduled'>
        & { CourseGroup?: Maybe<(
          { __typename?: 'CourseGroup' }
          & Pick<CourseGroup, 'Id' | 'CourseId'>
        )>, Session?: Maybe<(
          { __typename?: 'Session' }
          & { GroupedSessionGroups?: Maybe<Array<Maybe<(
            { __typename?: 'GroupedSessionGroup' }
            & Pick<GroupedSessionGroup, 'CourseGroupName' | 'CourseGroupId'>
          )>>> }
        )>, Attatchments?: Maybe<Array<Maybe<(
          { __typename?: 'Attachment' }
          & Pick<Attachment, 'AttachmentPath' | 'AttachmentName' | 'FileType'>
        )>>>, ActivityQuestions?: Maybe<Array<Maybe<(
          { __typename?: 'ActivityQuestion' }
          & Pick<ActivityQuestion, 'Id' | 'QuestionText' | 'ChoiceOne' | 'ChoiceTwo' | 'ChoiceThree' | 'ChoiceFour' | 'Answer' | 'QuestionType' | 'Grade'>
          & { QuestionAttachments?: Maybe<Array<Maybe<(
            { __typename?: 'Attachment' }
            & Pick<Attachment, 'AttachmentName' | 'AttachmentPath' | 'IsAnswer' | 'FileType'>
          )>>>, QuestionAnswerAttachments?: Maybe<Array<Maybe<(
            { __typename?: 'Attachment' }
            & Pick<Attachment, 'AttachmentName' | 'AttachmentPath' | 'IsAnswer' | 'FileType'>
          )>>>, StudentAnswer?: Maybe<(
            { __typename?: 'StudentActivityQuestionAnswer' }
            & Pick<StudentActivityQuestionAnswer, 'Id' | 'Answer' | 'IsCorrectAnswer' | 'IsAnswer' | 'IsMarked' | 'Grade'>
          )> }
        )>>>, UserActivities?: Maybe<Array<Maybe<(
          { __typename?: 'StudentActivity' }
          & Pick<StudentActivity, 'ActivityId' | 'ActivityGrad' | 'ActivityAddedDate' | 'ActivityDeadline' | 'ActivityIsOnline' | 'Comment' | 'Grade' | 'IsMarked'>
          & { Attachments?: Maybe<Array<Maybe<(
            { __typename?: 'Attachment' }
            & Pick<Attachment, 'AttachmentName' | 'AttachmentPath' | 'FileType'>
          )>>> }
        )>>> }
      )>>> }
    )> }
  )> }
);

export type AssistantsQueryVariables = Exact<{
  criteria?: Maybe<AssistantCriteria>;
}>;


export type AssistantsQuery = (
  { __typename?: 'RootQuery' }
  & { AssistantQuery?: Maybe<(
    { __typename?: 'AssistantQuery' }
    & { Assistants?: Maybe<(
      { __typename?: 'AssistantTypeListResponse' }
      & Pick<AssistantTypeListResponse, 'TotalCount'>
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'Assistant' }
        & Pick<Assistant, 'Id' | 'Name' | 'ImagePath' | 'MobileNumber' | 'Email' | 'About'>
        & { CourseGroups?: Maybe<Array<Maybe<(
          { __typename?: 'CourseGroup' }
          & Pick<CourseGroup, 'Id' | 'Name' | 'SchoolYearName' | 'ScientificSubjectName'>
        )>>> }
      )>>> }
    )> }
  )> }
);

export type AssistantRolesQueryVariables = Exact<{
  criteria?: Maybe<AssistantRoleCriteria>;
}>;


export type AssistantRolesQuery = (
  { __typename?: 'RootQuery' }
  & { AssistantQuery?: Maybe<(
    { __typename?: 'AssistantQuery' }
    & { AssistantRoles?: Maybe<(
      { __typename?: 'AssistantRoleTypeListResponse' }
      & Pick<AssistantRoleTypeListResponse, 'TotalCount'>
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'AssistantRoles' }
        & Pick<AssistantRoles, 'RoleId' | 'RoleName' | 'CourseGroupId' | 'CourseGroupName' | 'Permission'>
      )>>> }
    )> }
  )> }
);

export type AssignAssistantCourseGroupRolesMutationVariables = Exact<{
  model: AssignAssistantRolesInput;
}>;


export type AssignAssistantCourseGroupRolesMutation = (
  { __typename?: 'RootMutation' }
  & { Teacher?: Maybe<(
    { __typename?: 'TeacherMutations' }
    & { AssignAssistantCourseGroupRoles?: Maybe<(
      { __typename?: 'AssignAssistantRolesTypeResponse' }
      & { Result?: Maybe<(
        { __typename?: 'AssignAssistantRolesType' }
        & Pick<AssignAssistantRolesType, 'AssistantId' | 'CourseGroupId' | 'TeacherId'>
      )> }
    )> }
  )> }
);

export type AssignAssistantGeneralRolesMutationVariables = Exact<{
  model: AssignAssistantRolesInput;
}>;


export type AssignAssistantGeneralRolesMutation = (
  { __typename?: 'RootMutation' }
  & { Teacher?: Maybe<(
    { __typename?: 'TeacherMutations' }
    & { AssignAssistantPublicRoles?: Maybe<(
      { __typename?: 'AssignAssistantRolesTypeResponse' }
      & { Result?: Maybe<(
        { __typename?: 'AssignAssistantRolesType' }
        & Pick<AssignAssistantRolesType, 'AssistantId'>
      )> }
    )> }
  )> }
);

export type DeleteAssistantMutationVariables = Exact<{
  model: ManageAssistantInput;
}>;


export type DeleteAssistantMutation = (
  { __typename?: 'RootMutation' }
  & { Teacher?: Maybe<(
    { __typename?: 'TeacherMutations' }
    & { DeleteAssistant?: Maybe<(
      { __typename?: 'BooleanGraphTypeResponse' }
      & Pick<BooleanGraphTypeResponse, 'Result'>
    )> }
  )> }
);

export type CheckTeacherFollowerQueryVariables = Exact<{
  criteria?: Maybe<TeacherFollowerCriteria>;
}>;


export type CheckTeacherFollowerQuery = (
  { __typename?: 'RootQuery' }
  & { StudentQuery?: Maybe<(
    { __typename?: 'StudentQuery' }
    & { CheckTeacherFollower?: Maybe<(
      { __typename?: 'CheckTeacherFollowerTypeResponse' }
      & { Result?: Maybe<(
        { __typename?: 'CheckTeacherFollowerOutput' }
        & Pick<CheckTeacherFollowerOutput, 'IsFollow'>
      )> }
    )> }
  )> }
);

export type CitiesQueryVariables = Exact<{
  criteria?: Maybe<CityCriteria>;
}>;


export type CitiesQuery = (
  { __typename?: 'RootQuery' }
  & { LookupQuery?: Maybe<(
    { __typename?: 'LookupsQuery' }
    & { Cities?: Maybe<(
      { __typename?: 'CityTypeListResponse' }
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'City' }
        & Pick<City, 'Id' | 'Name'>
      )>>> }
    )> }
  )> }
);

export type CountriesQueryVariables = Exact<{
  criteria?: Maybe<CountryCriteria>;
}>;


export type CountriesQuery = (
  { __typename?: 'RootQuery' }
  & { LookupQuery?: Maybe<(
    { __typename?: 'LookupsQuery' }
    & { Countries?: Maybe<(
      { __typename?: 'CountryTypeListResponse' }
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'Country' }
        & Pick<Country, 'Id' | 'ImagePath' | 'Name'>
      )>>> }
    )> }
  )> }
);

export type CourseGroupStudentsQueryVariables = Exact<{
  criteria?: Maybe<CourseGroupStudentCriteria>;
}>;


export type CourseGroupStudentsQuery = (
  { __typename?: 'RootQuery' }
  & { CourseGroupQuery?: Maybe<(
    { __typename?: 'CourseGroupQuery' }
    & { CourseGroupStudents?: Maybe<(
      { __typename?: 'CourseGroupStudentsTypeListResponse' }
      & Pick<CourseGroupStudentsTypeListResponse, 'TotalCount'>
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'CourseGroupStudents' }
        & Pick<CourseGroupStudents, 'Comment' | 'CourseGroupId' | 'CourseGroupName' | 'CourseId' | 'ActivationDate' | 'AddedDate' | 'SuspensionDate' | 'Id' | 'ImagePath' | 'IsSelected' | 'IsSubscribed' | 'IsTeacherPaid' | 'ParentId' | 'Rate' | 'Report' | 'SchoolYearName' | 'ScientificSubjectName' | 'StudentId' | 'StudentMobileNumber' | 'StudentName' | 'StudentStatus' | 'TeacherName' | 'AssignLicense'>
        & { CourseGroup?: Maybe<(
          { __typename?: 'CourseGroup' }
          & Pick<CourseGroup, 'Name' | 'TeacherId'>
          & { CourseGroupHours?: Maybe<Array<Maybe<(
            { __typename?: 'CourseGroupHours' }
            & Pick<CourseGroupHours, 'Day' | 'StartTime' | 'EndTime'>
          )>>> }
        )> }
      )>>> }
    )> }
  )> }
);

export type CourseGroupsQueryVariables = Exact<{
  criteria?: Maybe<CourseGroupCriteria>;
  courseHours: Scalars['Boolean'];
}>;


export type CourseGroupsQuery = (
  { __typename?: 'RootQuery' }
  & { CourseGroupQuery?: Maybe<(
    { __typename?: 'CourseGroupQuery' }
    & { CourseGroups?: Maybe<(
      { __typename?: 'CourseGroupTypeListResponse' }
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'CourseGroup' }
        & Pick<CourseGroup, 'Id' | 'ScientificSubjectName' | 'TeacherName' | 'MainAssistantName' | 'Name' | 'NumberOfStudents' | 'SchoolYearName' | 'IsNeedAction' | 'AllowViewActivitiesForAssistant' | 'IsActive' | 'CourseId' | 'AllowAbsentRequest' | 'AllowChangeGroupRequest' | 'AllowNewRequests' | 'ActualNumOfStudents'>
        & { CourseGroupAssistants?: Maybe<Array<Maybe<(
          { __typename?: 'CourseGroupAssistant' }
          & Pick<CourseGroupAssistant, 'AssistantId' | 'AssistantName'>
          & { AssistantRoles?: Maybe<Array<Maybe<(
            { __typename?: 'AssistantRoles' }
            & Pick<AssistantRoles, 'RoleId' | 'RoleName'>
          )>>> }
        )>>>, CourseGroupHours?: Maybe<Array<Maybe<(
          { __typename?: 'CourseGroupHours' }
          & Pick<CourseGroupHours, 'Day' | 'EndTime' | 'StartTime'>
        )>>>, Course?: Maybe<(
          { __typename?: 'Course' }
          & Pick<Course, 'Id'>
        )>, CourseGroupStudent?: Maybe<(
          { __typename?: 'CourseGroupStudents' }
          & Pick<CourseGroupStudents, 'StudentStatus' | 'IsSubscribed'>
        )>, Teacher?: Maybe<(
          { __typename?: 'Teacher' }
          & Pick<Teacher, 'Id' | 'ImagePath'>
        )> }
      )>>> }
    )> }
  )> }
);

export type PostsQueryVariables = Exact<{
  criteria?: Maybe<ActivityCriteria>;
}>;


export type PostsQuery = (
  { __typename?: 'RootQuery' }
  & { ActivityQuery?: Maybe<(
    { __typename?: 'ActiviyQuery' }
    & { Posts?: Maybe<(
      { __typename?: 'PostTypeListResponse' }
      & Pick<PostTypeListResponse, 'TotalCount'>
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'Post' }
        & Pick<Post, 'Id' | 'ActualCreator' | 'ScientificSubjectName' | 'SchoolYearName' | 'Name' | 'HideFromRecording' | 'Content' | 'VideoPath' | 'VideoPathId' | 'VideoPicturePath' | 'IsScheduled' | 'StartDate' | 'Date' | 'ActualCreatorImage' | 'CourseGroupName' | 'CourseGroupId'>
        & { Attachments?: Maybe<Array<Maybe<(
          { __typename?: 'Attachment' }
          & Pick<Attachment, 'AttachmentPath' | 'AttachmentName' | 'FileType'>
        )>>>, CourseGroup?: Maybe<(
          { __typename?: 'CourseGroup' }
          & Pick<CourseGroup, 'CourseId'>
        )> }
      )>>> }
    )> }
  )> }
);

export type PreviousExamsQueryVariables = Exact<{
  criteria?: Maybe<PreviousExamCriteria>;
}>;


export type PreviousExamsQuery = (
  { __typename?: 'RootQuery' }
  & { TeacherQuery?: Maybe<(
    { __typename?: 'TeacherQuery' }
    & { PreviousExams?: Maybe<(
      { __typename?: 'PreviousExamTypeListResponse' }
      & Pick<PreviousExamTypeListResponse, 'TotalCount'>
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'PreviousExam' }
        & Pick<PreviousExam, 'Added' | 'AssistantId' | 'AttachmentPath' | 'CourseId' | 'ExamYear' | 'Id' | 'SchoolYearName' | 'ScientificSubjectName' | 'TeacherId' | 'Title'>
        & { Attachments?: Maybe<Array<Maybe<(
          { __typename?: 'Attachment' }
          & Pick<Attachment, 'AttachmentName' | 'AttachmentPath' | 'IsAnswer' | 'FileType'>
        )>>> }
      )>>> }
    )> }
  )> }
);

export type QuizzesQueryVariables = Exact<{
  criteria?: Maybe<ActivityCriteria>;
  withQuestion: Scalars['Boolean'];
  userActivityCriteria?: Maybe<UserActivityCriteria>;
  studentCriteria?: Maybe<StudentActivityQuestionAnswersCriteria>;
}>;


export type QuizzesQuery = (
  { __typename?: 'RootQuery' }
  & { ActivityQuery?: Maybe<(
    { __typename?: 'ActiviyQuery' }
    & { Quizzes?: Maybe<(
      { __typename?: 'QuizTypeListResponse' }
      & Pick<QuizTypeListResponse, 'TotalCount'>
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'Quiz' }
        & Pick<Quiz, 'Id' | 'Name' | 'ScientificSubjectName' | 'SchoolYearName' | 'CourseGroupName' | 'Date' | 'StartDate' | 'CourseId' | 'DeadLine' | 'IsOnline' | 'IsMarked' | 'Description' | 'Grade' | 'ActivityDuration' | 'ServerDateTime' | 'UserActivityCount' | 'CourseGroupId' | 'Content' | 'IsScheduled'>
        & { Attachments?: Maybe<Array<Maybe<(
          { __typename?: 'Attachment' }
          & Pick<Attachment, 'AttachmentName' | 'AttachmentPath' | 'FileType'>
        )>>>, QuizQuestions?: Maybe<Array<Maybe<(
          { __typename?: 'ActivityQuestion' }
          & Pick<ActivityQuestion, 'Id' | 'QuestionType' | 'QuestionText' | 'ChoiceOne' | 'ChoiceTwo' | 'ChoiceThree' | 'ChoiceFour' | 'Answer' | 'Grade'>
          & { StudentAnswer?: Maybe<(
            { __typename?: 'StudentActivityQuestionAnswer' }
            & Pick<StudentActivityQuestionAnswer, 'Answer' | 'Id' | 'Grade' | 'IsMarked'>
          )>, QuestionAttachments?: Maybe<Array<Maybe<(
            { __typename?: 'Attachment' }
            & Pick<Attachment, 'AttachmentName' | 'AttachmentPath' | 'FileType'>
          )>>>, QuestionAnswerAttachments?: Maybe<Array<Maybe<(
            { __typename?: 'Attachment' }
            & Pick<Attachment, 'AttachmentName' | 'AttachmentPath' | 'IsAnswer' | 'FileType'>
          )>>> }
        )>>>, UserActivities?: Maybe<Array<Maybe<(
          { __typename?: 'StudentActivity' }
          & Pick<StudentActivity, 'StudentName' | 'ActivityGrad' | 'ActivityId' | 'IsMarked' | 'DeliveredDate' | 'ActivityDeadline' | 'Grade' | 'CourseGroupName'>
          & { Attachments?: Maybe<Array<Maybe<(
            { __typename?: 'Attachment' }
            & Pick<Attachment, 'AttachmentName' | 'AttachmentPath' | 'FileType'>
          )>>> }
        )>>> }
      )>>> }
    )> }
  )> }
);

export type QuizzesForTableQueryVariables = Exact<{
  criteria?: Maybe<ActivityCriteria>;
}>;


export type QuizzesForTableQuery = (
  { __typename?: 'RootQuery' }
  & { ActivityQuery?: Maybe<(
    { __typename?: 'ActiviyQuery' }
    & { Quizzes?: Maybe<(
      { __typename?: 'QuizTypeListResponse' }
      & Pick<QuizTypeListResponse, 'TotalCount'>
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'Quiz' }
        & Pick<Quiz, 'Id' | 'Name' | 'ScientificSubjectName' | 'SchoolYearName' | 'CourseGroupName' | 'Date' | 'StartDate' | 'CourseId' | 'DeadLine' | 'IsOnline' | 'IsMarked' | 'Description' | 'Grade' | 'ActivityDuration' | 'ServerDateTime' | 'UserActivityCount' | 'CourseGroupId' | 'Content' | 'IsScheduled'>
        & { Attachments?: Maybe<Array<Maybe<(
          { __typename?: 'Attachment' }
          & Pick<Attachment, 'AttachmentName' | 'AttachmentPath' | 'FileType'>
        )>>> }
      )>>> }
    )> }
  )> }
);

export type RevisionsQueryVariables = Exact<{
  criteria?: Maybe<ActivityCriteria>;
  withComment: Scalars['Boolean'];
}>;


export type RevisionsQuery = (
  { __typename?: 'RootQuery' }
  & { ActivityQuery?: Maybe<(
    { __typename?: 'ActiviyQuery' }
    & { Revisions?: Maybe<(
      { __typename?: 'RevisionTypeListResponse' }
      & Pick<RevisionTypeListResponse, 'TotalCount'>
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'Revision' }
        & Pick<Revision, 'Id' | 'Name' | 'Description' | 'IsOnline' | 'CreatorImage' | 'Creator' | 'ScientificSubjectName' | 'SchoolYearName' | 'CourseGroupName' | 'Date' | 'StartDate' | 'StartTime' | 'Content' | 'EndTime' | 'IsDraft' | 'CourseGroupId' | 'ServerDateTime' | 'CurrentTime' | 'IsScheduled' | 'DeadLine' | 'UserActivityCount'>
        & { Attachments?: Maybe<Array<Maybe<(
          { __typename?: 'Attachment' }
          & Pick<Attachment, 'AttachmentPath' | 'AttachmentName' | 'FileType'>
        )>>>, Comments?: Maybe<Array<Maybe<(
          { __typename?: 'StudentActivity' }
          & Pick<StudentActivity, 'Id' | 'Comment' | 'UserName' | 'ImagePath' | 'DeliveredDate'>
          & { Replies?: Maybe<Array<Maybe<(
            { __typename?: 'StudentActivity' }
            & Pick<StudentActivity, 'Id' | 'Comment' | 'UserName' | 'ImagePath' | 'DeliveredDate'>
          )>>> }
        )>>> }
      )>>> }
    )> }
  )> }
);

export type GetBasicRolesQueryVariables = Exact<{
  criteria: BasicAssistantRoleCriteria;
}>;


export type GetBasicRolesQuery = (
  { __typename?: 'RootQuery' }
  & { LookupQuery?: Maybe<(
    { __typename?: 'LookupsQuery' }
    & { BasicRoles?: Maybe<(
      { __typename?: 'BasicAssistantRoleTypeListResponse' }
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'BasicAssistantRole' }
        & Pick<BasicAssistantRole, 'Id' | 'Name' | 'IsSelected' | 'IsExpanded'>
        & { Permissions?: Maybe<Array<Maybe<(
          { __typename?: 'Permission' }
          & Pick<Permission, 'Id' | 'Name'>
        )>>> }
      )>>> }
    )> }
  )> }
);

export type SchoolYearsQueryVariables = Exact<{
  criteria?: Maybe<SchoolYearCriteria>;
}>;


export type SchoolYearsQuery = (
  { __typename?: 'RootQuery' }
  & { LookupQuery?: Maybe<(
    { __typename?: 'LookupsQuery' }
    & { SchoolYears?: Maybe<(
      { __typename?: 'SchoolYearTypeListResponse' }
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'SchoolYear' }
        & Pick<SchoolYear, 'Id' | 'Name'>
        & { Subjects?: Maybe<Array<Maybe<(
          { __typename?: 'Subject' }
          & Pick<Subject, 'Id' | 'ScientificSubjectName'>
        )>>> }
      )>>> }
    )> }
  )> }
);

export type ScientificSubjectsQueryVariables = Exact<{
  criteria?: Maybe<ScientificSubjectCriteria>;
}>;


export type ScientificSubjectsQuery = (
  { __typename?: 'RootQuery' }
  & { LookupQuery?: Maybe<(
    { __typename?: 'LookupsQuery' }
    & { ScientificSubjects?: Maybe<(
      { __typename?: 'ScientificSubjectTypeListResponse' }
      & Pick<ScientificSubjectTypeListResponse, 'TotalCount'>
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'ScientificSubject' }
        & Pick<ScientificSubject, 'Id' | 'Name'>
      )>>> }
    )> }
  )> }
);

export type SessionQuestionsQueryVariables = Exact<{
  criteria?: Maybe<SessionQuestionCriteria>;
}>;


export type SessionQuestionsQuery = (
  { __typename?: 'RootQuery' }
  & { SessionQuery?: Maybe<(
    { __typename?: 'SessionQuery' }
    & { SessionsQuestions?: Maybe<(
      { __typename?: 'SessionQuestionTypeListResponse' }
      & Pick<SessionQuestionTypeListResponse, 'TotalCount'>
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'SessionQuestion' }
        & Pick<SessionQuestion, 'Id' | 'StudentName' | 'Question' | 'Answer' | 'IsVerified'>
        & { QuestionAttachments?: Maybe<Array<Maybe<(
          { __typename?: 'Attachment' }
          & Pick<Attachment, 'AttachmentPath' | 'AttachmentName' | 'FileType'>
        )>>>, AnswerAttachments?: Maybe<Array<Maybe<(
          { __typename?: 'Attachment' }
          & Pick<Attachment, 'AttachmentPath' | 'AttachmentName' | 'FileType'>
        )>>> }
      )>>> }
    )> }
  )> }
);

export type SessionsQueryVariables = Exact<{
  criteria?: Maybe<SessionCriteria>;
}>;


export type SessionsQuery = (
  { __typename?: 'RootQuery' }
  & { SessionQuery?: Maybe<(
    { __typename?: 'SessionQuery' }
    & { Sessions?: Maybe<(
      { __typename?: 'SessionTypeListResponse' }
      & Pick<SessionTypeListResponse, 'TotalCount'>
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'Session' }
        & Pick<Session, 'Id' | 'Title' | 'Date' | 'SchoolYearName' | 'CourseGroupName' | 'Description' | 'StartTime' | 'EndTime' | 'IsLocked' | 'AssignmentCount' | 'StudentCount' | 'ScientificSubjectName' | 'QRCodePath' | 'SessionType' | 'SessionAttendType' | 'CourseGroupId'>
        & { CourseGroup?: Maybe<(
          { __typename?: 'CourseGroup' }
          & Pick<CourseGroup, 'Name' | 'CourseId' | 'TeacherId'>
          & { CourseGroupHours?: Maybe<Array<Maybe<(
            { __typename?: 'CourseGroupHours' }
            & Pick<CourseGroupHours, 'Day' | 'StartTime' | 'EndTime'>
          )>>> }
        )>, ZoomMeetingSettings?: Maybe<(
          { __typename?: 'ZoomMeetingSettigs' }
          & Pick<ZoomMeetingSettigs, 'Topic' | 'Agenda' | 'AutoRecording'>
        )>, SessionsAttendance?: Maybe<Array<Maybe<(
          { __typename?: 'SessionAttendance' }
          & Pick<SessionAttendance, 'StudentName'>
        )>>>, StudentSessionAttendance?: Maybe<(
          { __typename?: 'SessionAttendance' }
          & Pick<SessionAttendance, 'StudentAttendanceCode' | 'IsAttended' | 'Day'>
        )>, Assignments?: Maybe<Array<Maybe<(
          { __typename?: 'Assignment' }
          & Pick<Assignment, 'IsFinished'>
        )>>>, Attachments?: Maybe<Array<Maybe<(
          { __typename?: 'Attachment' }
          & Pick<Attachment, 'AttachmentName' | 'AttachmentPath' | 'FileType'>
        )>>> }
      )>>> }
    )> }
  )> }
);

export type StudentRequestsQueryVariables = Exact<{
  criteria?: Maybe<RequestCriteria>;
}>;


export type StudentRequestsQuery = (
  { __typename?: 'RootQuery' }
  & { RequestQuery?: Maybe<(
    { __typename?: 'RequestQuery' }
    & { StudentRequests?: Maybe<(
      { __typename?: 'RequestTypeListResponse' }
      & Pick<RequestTypeListResponse, 'TotalCount'>
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'Request' }
        & Pick<Request, 'Id' | 'TeacherName' | 'CourseGroupName' | 'CourseName' | 'ParentName' | 'RequestType' | 'RequestTypeName' | 'RequestStatus' | 'RequestStatusName' | 'SendDate' | 'SendTo' | 'SendFrom'>
      )>>> }
    )> }
  )> }
);

export type GetStudentBalanceQueryVariables = Exact<{
  criteria?: Maybe<StudentBalanceCriteria>;
}>;


export type GetStudentBalanceQuery = (
  { __typename?: 'RootQuery' }
  & { PaymentQuery?: Maybe<(
    { __typename?: 'PaymentQuery' }
    & { StudentBalance?: Maybe<(
      { __typename?: 'StudentBalanceQuery' }
      & { GetStudentBalance?: Maybe<(
        { __typename?: 'StudentBalanceTypeResponse' }
        & { Result?: Maybe<(
          { __typename?: 'StudentBalance' }
          & Pick<StudentBalance, 'Balance'>
        )> }
      )> }
    )> }
  )> }
);

export type GetSessionContentStudentsQueryVariables = Exact<{
  criteria?: Maybe<SessionContentStudentCriteria>;
}>;


export type GetSessionContentStudentsQuery = (
  { __typename?: 'RootQuery' }
  & { OnlineContentQuery?: Maybe<(
    { __typename: 'OnlineContentQuery' }
    & { SessionContentStudent?: Maybe<(
      { __typename: 'SessionContentStudentQuery' }
      & { SessionContentStudents?: Maybe<(
        { __typename: 'SessionContentStudentTypeListResponse' }
        & Pick<SessionContentStudentTypeListResponse, 'TotalCount'>
        & { Result?: Maybe<Array<Maybe<(
          { __typename: 'SessionContentStudent' }
          & Pick<SessionContentStudent, 'Id' | 'StudentId' | 'StudentName' | 'SessionContentId' | 'SessionTitle' | 'Unit' | 'Chapter' | 'StudentStatus' | 'NumOfSessionParts' | 'Rate' | 'Price' | 'PaidDate' | 'IntroVideoPath'>
          & { SessionContentParts?: Maybe<Array<Maybe<(
            { __typename: 'SessionContentPart' }
            & Pick<SessionContentPart, 'Id' | 'VideoPath' | 'VideoPicturePath'>
          )>>> }
        )>>> }
      )> }
    )> }
  )> }
);

export type SubjectsQueryVariables = Exact<{
  criteria?: Maybe<SubjectCriteria>;
}>;


export type SubjectsQuery = (
  { __typename?: 'RootQuery' }
  & { LookupQuery?: Maybe<(
    { __typename?: 'LookupsQuery' }
    & { Subjects?: Maybe<(
      { __typename?: 'SubjectTypeListResponse' }
      & Pick<SubjectTypeListResponse, 'TotalCount'>
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'Subject' }
        & Pick<Subject, 'Id' | 'Name' | 'ScientificSubjectId' | 'ScientificSubjectName' | 'SchoolYearName' | 'EducationTypeName' | 'SemesterId'>
      )>>> }
    )> }
  )> }
);

export type TeacherAssistantsQueryVariables = Exact<{
  criteria?: Maybe<AssistantCriteria>;
}>;


export type TeacherAssistantsQuery = (
  { __typename?: 'RootQuery' }
  & { AssistantQuery?: Maybe<(
    { __typename?: 'AssistantQuery' }
    & { Assistants?: Maybe<(
      { __typename?: 'AssistantTypeListResponse' }
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'Assistant' }
        & Pick<Assistant, 'Id' | 'Name' | 'ImagePath' | 'MobileNumber' | 'Email' | 'About'>
      )>>> }
    )> }
  )> }
);

export type TeacherCourseGroupsQueryVariables = Exact<{
  criteria?: Maybe<CourseGroupCriteria>;
  courseHours: Scalars['Boolean'];
}>;


export type TeacherCourseGroupsQuery = (
  { __typename?: 'RootQuery' }
  & { CourseGroupQuery?: Maybe<(
    { __typename?: 'CourseGroupQuery' }
    & { CourseGroups?: Maybe<(
      { __typename?: 'CourseGroupTypeListResponse' }
      & Pick<CourseGroupTypeListResponse, 'TotalCount'>
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'CourseGroup' }
        & Pick<CourseGroup, 'Id' | 'ScientificSubjectName' | 'TeacherName' | 'MainAssistantName' | 'Name' | 'NumberOfStudents' | 'SchoolYearName' | 'IsNeedAction' | 'AllowViewActivitiesForAssistant' | 'IsActive' | 'CourseId' | 'AllowAbsentRequest' | 'AllowChangeGroupRequest' | 'AllowNewRequests' | 'ActualNumOfStudents' | 'ImagePath'>
        & { CourseGroupAssistants?: Maybe<Array<Maybe<(
          { __typename?: 'CourseGroupAssistant' }
          & Pick<CourseGroupAssistant, 'AssistantId' | 'AssistantName'>
          & { AssistantRoles?: Maybe<Array<Maybe<(
            { __typename?: 'AssistantRoles' }
            & Pick<AssistantRoles, 'RoleId' | 'RoleName'>
          )>>> }
        )>>>, CourseGroupStudent?: Maybe<(
          { __typename?: 'CourseGroupStudents' }
          & Pick<CourseGroupStudents, 'StudentStatus' | 'IsSubscribed'>
        )>, Teacher?: Maybe<(
          { __typename?: 'Teacher' }
          & Pick<Teacher, 'Id' | 'ImagePath'>
        )>, CourseGroupHours?: Maybe<Array<Maybe<(
          { __typename?: 'CourseGroupHours' }
          & Pick<CourseGroupHours, 'Day' | 'StartTime' | 'EndTime'>
        )>>> }
      )>>> }
    )> }
  )> }
);

export type TeacherCoursesQueryVariables = Exact<{
  criteria?: Maybe<CourseCriteria>;
}>;


export type TeacherCoursesQuery = (
  { __typename?: 'RootQuery' }
  & { CourseQuery?: Maybe<(
    { __typename?: 'CourseQuery' }
    & { Courses?: Maybe<(
      { __typename?: 'CourseTypeListResponse' }
      & Pick<CourseTypeListResponse, 'TotalCount'>
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'Course' }
        & Pick<Course, 'Id' | 'ImagePath' | 'ActualNumOfCourseGroups' | 'ActualNumOfStudents' | 'IsActive' | 'SchoolYearName' | 'NumOfStudents' | 'ScientificSubjectName'>
        & { CourseGroups?: Maybe<Array<Maybe<(
          { __typename?: 'CourseGroup' }
          & Pick<CourseGroup, 'Id'>
        )>>> }
      )>>> }
    )> }
  )> }
);

export type TeacherQuestionsBankQueryVariables = Exact<{
  criteria?: Maybe<TeacherQuestionCriteria>;
}>;


export type TeacherQuestionsBankQuery = (
  { __typename?: 'RootQuery' }
  & { TeacherQuery?: Maybe<(
    { __typename?: 'TeacherQuery' }
    & { QuestionBank?: Maybe<(
      { __typename?: 'TeacherQuestionTypeListResponse' }
      & Pick<TeacherQuestionTypeListResponse, 'TotalCount'>
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'TeacherQuestion' }
        & Pick<TeacherQuestion, 'Id' | 'Tags' | 'SubjectId' | 'QuestionText' | 'Answer' | 'ChoiceOne' | 'ChoiceTwo' | 'ChoiceThree' | 'ChoiceFour' | 'QuestionType' | 'SubjectName' | 'SchoolYearName' | 'AddedDate' | 'Level'>
        & { QuestionAttachments?: Maybe<Array<Maybe<(
          { __typename?: 'Attachment' }
          & Pick<Attachment, 'AttachmentName' | 'AttachmentPath' | 'FileType'>
        )>>>, AnswerAttachments?: Maybe<Array<Maybe<(
          { __typename?: 'Attachment' }
          & Pick<Attachment, 'AttachmentName' | 'AttachmentPath' | 'FileType'>
        )>>>, Teacher?: Maybe<(
          { __typename?: 'Teacher' }
          & Pick<Teacher, 'Name'>
        )>, Subject?: Maybe<(
          { __typename?: 'Subject' }
          & Pick<Subject, 'SchoolYearId' | 'EducationTypeId'>
        )> }
      )>>> }
    )> }
  )> }
);

export type TeacherSubjectsQueryVariables = Exact<{
  criteria?: Maybe<TeacherSubjectCriteria>;
}>;


export type TeacherSubjectsQuery = (
  { __typename?: 'RootQuery' }
  & { TeacherQuery?: Maybe<(
    { __typename?: 'TeacherQuery' }
    & { TeacherSubjects?: Maybe<(
      { __typename?: 'TeacherSubjectTypeListResponse' }
      & Pick<TeacherSubjectTypeListResponse, 'TotalCount'>
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'TeacherSubject' }
        & Pick<TeacherSubject, 'ScientificSubjectName' | 'SchoolYearName'>
        & { Subject?: Maybe<(
          { __typename?: 'Subject' }
          & Pick<Subject, 'Name' | 'EducationTypeName'>
        )> }
      )>>> }
    )> }
  )> }
);

export type GetTeachersQueryVariables = Exact<{
  criteria?: Maybe<TeacherCriteria>;
}>;


export type GetTeachersQuery = (
  { __typename?: 'RootQuery' }
  & { TeacherQuery?: Maybe<(
    { __typename?: 'TeacherQuery' }
    & { Teachers?: Maybe<(
      { __typename?: 'TeacherTypeListResponse' }
      & Pick<TeacherTypeListResponse, 'TotalCount'>
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'Teacher' }
        & Pick<Teacher, 'Id' | 'Name' | 'MobileNumber' | 'Slogan' | 'NumberOfFollowers' | 'RankScore' | 'ImagePath' | 'Gender' | 'Email' | 'Bio' | 'VideoPath' | 'VideoPicturePath' | 'About'>
      )>>> }
    )> }
  )> }
);

export type UserActivitiesQueryVariables = Exact<{
  criteria?: Maybe<UserActivityCriteria>;
}>;


export type UserActivitiesQuery = (
  { __typename?: 'RootQuery' }
  & { ActivityQuery?: Maybe<(
    { __typename?: 'ActiviyQuery' }
    & { UserActivities?: Maybe<(
      { __typename?: 'UserActivityTypeListResponse' }
      & Pick<UserActivityTypeListResponse, 'TotalCount'>
      & { Result?: Maybe<Array<Maybe<(
        { __typename?: 'StudentActivity' }
        & Pick<StudentActivity, 'ActivityAddedDate' | 'ActivityDeadline' | 'ActivityDescription' | 'ActivityGrad' | 'ActivityId' | 'ActivityIsFinished' | 'ActivityIsOnline' | 'ActivityName' | 'ActivityTypeName' | 'AssistantName' | 'AttachmentPath' | 'AttachmentPathFileType' | 'Comment' | 'CourseGroupName' | 'DeliveredDate' | 'Grade' | 'Id' | 'ImagePath' | 'IsMarked' | 'IsView' | 'IsWinner' | 'ParentUserActivityId' | 'SchoolYearName' | 'ScientificSubjectName' | 'ScoreOrder' | 'SessionName' | 'StudentId' | 'StudentName' | 'TeacherName' | 'UserName'>
        & { Attachments?: Maybe<Array<Maybe<(
          { __typename?: 'Attachment' }
          & Pick<Attachment, 'AttachmentName' | 'AttachmentPath' | 'FileType'>
        )>>> }
      )>>> }
    )> }
  )> }
);

export type UserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type UserProfileQuery = (
  { __typename?: 'RootQuery' }
  & { AccountQuery?: Maybe<(
    { __typename?: 'AccountQuery' }
    & { UserProfile?: Maybe<(
      { __typename?: 'UserProfileTypeResponse' }
      & { Result?: Maybe<(
        { __typename?: 'UserProfile' }
        & Pick<UserProfile, 'Bio' | 'Email' | 'Gender' | 'GenderName' | 'MobileNumber' | 'EducationTypeId' | 'SchoolYearId' | 'ImagePath' | 'IsConfirmed' | 'VideoPath' | 'VideoPathId' | 'VideoPicturePath' | 'Name' | 'Slogan' | 'Status' | 'StudentEducationTypeName' | 'StudentSchoolYearName' | 'UserId' | 'UserType' | 'StudentId' | 'TeacherId' | 'ParentId' | 'AssistantId'>
      )> }
    )> }
  )> }
);
