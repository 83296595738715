import { gql } from '@apollo/client/core';

export const UPDATE_TEACHER_QUESTION_BANK = gql`
  mutation UpdateTeacherQuestionbank($model: TeacherQuestionInput) {
    Teacher {
      EditTeacherQuestion(model: $model) {
        Result {
          Id
          TeacherId
          SubjectId
        }
      }
    }
  }
`;
