import { gql } from '@apollo/client/core';

export const REGISTER = gql`
  mutation Register($model: RegisterInput!) {
    Account {
      Register(model: $model) {
        Result {
          AssistantId
          Code
          ImagePath
          ParentId
          StudentId
          TeacherId
          UserId
        }
        TotalCount
      }
    }
  }
`;
