import { gql } from '@apollo/client/core';

export const UPDATE_TEACHER_PROFILE_ACTIVITIES = gql`
  mutation UpdateUserProfile($model: UpdateTeacherActivitiesInputType) {
    Teacher {
      UpdateTeacherActivities(model: $model) {
        Result
      }
    }
  }
`;
