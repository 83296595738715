import { Injectable } from '@angular/core';
import {
  AssignAssistantCourseGroupRolesMutation,
  AssignAssistantCourseGroupRolesMutationVariables,
  AssignAssistantGeneralRolesMutation,
  AssignAssistantGeneralRolesMutationVariables,
  AssignAssistantRolesInput,
  AssignAssistantRolesTypeResponse,
  AssistantCriteria,
  AssistantQueryAssistantRolesArgs,
  AssistantRoleCriteria,
  AssistantRolesQuery,
  AssistantRolesQueryVariables,
  AssistantRoleTypeListResponse,
  AssistantsQuery,
  AssistantsQueryVariables,
  AssistantTypeListResponse,
  BooleanGraphTypeResponse,
  DeleteAssistantMutation,
  DeleteAssistantMutationVariables,
  ManageAssistantInput,
} from '@generated-types';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import { ASSIGN_ASSISTANT_COURSEGROUP_ROLES, ASSIGN_ASSISTANT_GENERAL_ROLES, DELETE_ASSISTANT, GET_ASSISTANTS, GET_ASSISTANT_ROLES } from '@queries';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AssistantsService {
  public constructor(private readonly dataService: DataService) {}

  public getAssistants(
    criteria: AssistantCriteria
  ): Observable<AssistantTypeListResponse> {
    return this.dataService
      .query<AssistantsQuery, AssistantsQueryVariables>(GET_ASSISTANTS, {
        criteria,
      })
      .pipe(map((response) => response.data.AssistantQuery.Assistants));
  }
  
  public getAssistantRoles(
    criteria: AssistantRoleCriteria
  ): Observable<AssistantRoleTypeListResponse> {
    return this.dataService
      .query<AssistantRolesQuery, AssistantRolesQueryVariables>(GET_ASSISTANT_ROLES, {
        criteria,
      })
      .pipe(map((response) => response.data.AssistantQuery.AssistantRoles));
  }

  public getAssistantGroupPermissions(
    criteria: AssistantRoleCriteria
  ): Observable<AssistantRoleTypeListResponse> {
    return this.dataService
      .query<AssistantRolesQuery, AssistantRolesQueryVariables>(GET_ASSISTANT_ROLES, {
        criteria,
      })
      .pipe(map((response) => response.data.AssistantQuery.AssistantRoles));
  }


  public assignAssistantCourseGroupRoles(
    model: AssignAssistantRolesInput
  ): Observable<AssignAssistantRolesTypeResponse> {
    return this.dataService
      .mutate<AssignAssistantCourseGroupRolesMutation, AssignAssistantCourseGroupRolesMutationVariables>(
        ASSIGN_ASSISTANT_COURSEGROUP_ROLES,
        {
          model,
        }
      )
      .pipe(map((response) => response.data.Teacher.AssignAssistantCourseGroupRoles));
  }

  public assignAssistantGeneralRoles(
    model: AssignAssistantRolesInput
  ): Observable<AssignAssistantRolesTypeResponse> {
    return this.dataService
      .mutate<AssignAssistantGeneralRolesMutation, AssignAssistantGeneralRolesMutationVariables>(
        ASSIGN_ASSISTANT_GENERAL_ROLES,
        {
          model,
        }
      )
      .pipe(map((response) => response.data.Teacher.AssignAssistantPublicRoles));
  }


  public deleteAssistant(
    model: ManageAssistantInput
  ): Observable<BooleanGraphTypeResponse> {
    return this.dataService
      .mutate<
      DeleteAssistantMutation,
      DeleteAssistantMutationVariables
      >(
        DELETE_ASSISTANT,
        {
          model
        }
      )
      .pipe(map((response) => response.data.Teacher.DeleteAssistant));
  }

}
