import { gql } from '@apollo/client/core';

export const GET_COURSE_GROUP_STUDENTS = gql`
  query CourseGroupStudents($criteria: CourseGroupStudentCriteria) {
    CourseGroupQuery {
      CourseGroupStudents(criteria: $criteria) {
        Result {
          Comment
          CourseGroupId
          CourseGroupName
          CourseId
          ActivationDate
          AddedDate
          SuspensionDate
          CourseGroup {
            Name
            TeacherId
            CourseGroupHours {
              Day
              StartTime
              EndTime
            }
          }
          Id
          ImagePath
          IsSelected
          IsSubscribed
          IsTeacherPaid
          ParentId
          Rate
          Report
          SchoolYearName
          ScientificSubjectName
          StudentId
          StudentMobileNumber
          StudentName
          StudentStatus
          TeacherName
          AssignLicense
        }
        TotalCount
      }
    }
  }
`;
