import { gql } from '@apollo/client/core';

export const REMOVE_SESSION = gql`
  mutation RemoveSession($SessionId: Int) {
    Session {
      DeleteSession(sessionId: $SessionId) {
        Result
      }
    }
  }
`;
