import { Apollo } from 'apollo-angular';
import {
  ApolloQueryResult,
  FetchPolicy,
  FetchResult,
} from '@apollo/client/core';
import { Injectable } from '@angular/core';

import { DocumentNode } from 'graphql';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(private readonly apollo: Apollo) {}

  public query<T = any, V = any>(
    query: DocumentNode,
    variables?: V,
    fetchPolicy?: FetchPolicy
  ): Observable<ApolloQueryResult<T>> {
    return this.apollo.watchQuery<T, V>({
      query,
      variables,
      errorPolicy: 'all',
      fetchPolicy: fetchPolicy || 'cache-and-network',
    }).valueChanges;
  }

  public mutate<T = any, V = any>(
    mutation: DocumentNode,
    variables?: V
  ): Observable<FetchResult<T>> {
    return this.apollo.mutate<T, V>({
      mutation,
      variables,
      errorPolicy: 'all',
    });
  }

  public mutateWithRefetch<T = any, V = any, R = any, F = any>(
    mutation: DocumentNode,
    mutationVariables: V,
    refetchQuery: DocumentNode,
    refetchQueryVariables?: F
  ): Observable<FetchResult<T>> {
    return this.apollo.mutate<T, V>({
      mutation,
      variables: mutationVariables,
      errorPolicy: 'all',
      refetchQueries: [
        {
          query: refetchQuery,
          variables: refetchQueryVariables,
        },
      ],
    });
  }
}
