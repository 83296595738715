<section>
  <div class="attachments" *ngIf="!vedioOnly">
    <h4>{{ title }}</h4>
    <ng-container
      *ngFor="let attachment of attachmentsForPreview; let i = index"
    >
      <div class="attachment">
        <ng-container [ngSwitch]="attachment?.FileType">
          <img
            *ngSwitchCase="FILE_TYPE.IMAGE"
            [src]="attachment?.AttachmentPath"
            width="100"
            height="100"
            (click)="openLightbox(i)"
          />
          <a [href]=""></a>
          <img
            *ngSwitchCase="FILE_TYPE.FILE"
            src="assets/images/PDF.png"
            width="100"
            height="100"
            (click)="pdfInput.click()"
          />
          <a
            style="display: none"
            [href]="attachment.AttachmentPath"
            #pdfInput
            download
          ></a>
        </ng-container>

        <p *ngIf="!previewOnly">{{ attachment?.AttachmentName }}</p>
        <mat-icon
          *ngIf="!previewOnly"
          color="error"
          matTooltip="Remove"
          maTooltipPosition="above"
          (click)="removeAttachment(i)"
          >clear</mat-icon
        >
      </div>
    </ng-container>
  </div>

  <div *ngIf="vedioAttachement?.VideoPicturePath">
    <h4 style="margin-top: 50px">Vedio Attachemnts</h4>
    <div *ngIf="post && vedioAttachement?.VideoPicturePath !== ''">
      <img
        [src]="vedioAttachement?.VideoPicturePath"
        width="150"
        height="150"
        (click)="openVideoModal(vedioAttachement)"
      />
      <mat-icon
        style="cursor: pointer"
        color="error"
        matTooltip="Remove"
        maTooltipPosition="above"
        (click)="removeVedioAttachment()"
        >clear</mat-icon
      >
    </div>
    <div *ngIf="!post || vedioAttachement.VideoPicturePath === ''">
      <a [href]="vedioAttachement.VideoPath" target="_blank">
        <img src="assets/images/vedio.png" width="150" height="150" />
      </a>

      <!-- <mat-icon
        style="cursor: pointer"
        color="error"
        matTooltip="Remove"
        maTooltipPosition="above"
        (click)="removeVedioAttachment()"
        >clear</mat-icon
      > -->
    </div>
  </div>

  <div class="upload-buttons" *ngIf="!previewOnly">
    <button
      *ngIf="!vedioOnly"
      mat-mini-fab
      matTooltip="Upload PDF"
      matTooltipPosition="above"
      type="button"
      color="primary"
      (click)="fileInput.click()"
      [loading]="uploadPDFLoading"
    >
      <mat-icon>attach_file</mat-icon>
    </button>
    <button
      *ngIf="!vedioOnly"
      mat-mini-fab
      matTooltip="Upload Photo"
      matTooltipPosition="above"
      type="button"
      color="primary"
      (click)="imageInput.click()"
      [loading]="uploadImageLoading"
    >
      <mat-icon>insert_photo</mat-icon>
    </button>
    <button
      *ngIf="showVedio"
      mat-mini-fab
      matTooltip="Upload Vedio"
      matTooltipPosition="above"
      type="button"
      color="primary"
      (click)="vedioInput.click()"
      [loading]="uploadVedioLoading"
    >
      <mat-icon>shop</mat-icon>
    </button>

    <input
      type="file"
      accept="application/pdf"
      #fileInput
      style="display: none"
      (change)="uploadAttachment($event, 'PDF')"
    />
    <input
      type="file"
      accept="image/x-png,image/gif,image/jpeg"
      #imageInput
      style="display: none"
      (change)="uploadAttachment($event, 'IMAGE')"
    />
    <input
      type="file"
      accept="video/mp4,video/x-m4v,video/*"
      #vedioInput
      style="display: none"
      (change)="uploadAttachmentVedio($event, 'VEDIO')"
    />
  </div>
</section>
