import { gql } from '@apollo/client/core';

export const GET_ONLINE_SESSION_INFO = gql`
  mutation StartOnlineSession($model: StartOnlineSessionInput) {
    Session {
      StartOnlineSession(model: $model) {
        Result {
          MeetingId
          MeetingPassword
          UserId
          ZAK
          token
          Signature
          APIKey
        }
      }
    }
  }
`;
