import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnInit,
} from '@angular/core';
import { CourseGroupStudents, UserProfile } from '@generated-types';
import { AuthService } from '@services';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-courses-carousel',
  templateUrl: './courses-carousel.component.html',
  styleUrls: ['./courses-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoursesCarouselComponent implements OnInit, OnChanges {
  @Output() public courseGroupSelected = new EventEmitter<number>();
  @Input() public courseGroups: CourseGroupStudents[];
  @Input() public carouselType: string;
  public userProfileType: string;

  constructor(public readonly authService: AuthService) {}

  public readonly carouselOptions: OwlOptions = {
    loop: true,
    dots: true,
    dotsData: true,
    navSpeed: 700,
    autoplay: true,
    autoHeight: true,
    autoWidth: true,
    animateIn: 'animate__animated  animate__fadeIn animate__slower',
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 3,
      },
      740: {
        items: 5,
      },
      940: {
        items: 6,
      },
      1500: {
        items: 7,
      },
    },
  };

  public ngOnInit(): void {
    this.authService.getUserProfile().subscribe((res) => {
      console.log(res);
      this.userProfileType = res.UserType;
    });
  }

  public onSelectCourseGroup(courseGroupId?: number): void {
    this.courseGroupSelected.emit(courseGroupId);
  }

  ngOnChanges(): void {
    console.log(this.courseGroups);
  }
}
