import { gql } from '@apollo/client/core';

export const GET_EDUCATION_STAGES = gql`
  query EducationStages($criteria: EducationStageCriteria) {
    LookupQuery {
      EducationStages(criteria: $criteria) {
        Result {
          Id
          Name
        }
      }
    }
  }
`;
