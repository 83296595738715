import { gql } from '@apollo/client/core';

export const LOGIN = gql`
  mutation Login($model: LoginInput!) {
    Account {
      Login(model: $model) {
        Result {
          AssistantId
          EducationTypeId
          ImagePath
          Name
          ParentId
          RefreshToken
          StudentId
          TeacherId
          Token
          TokenExpirationDate
          UserId
          UserType
          UserName
        }
        TotalCount
      }
    }
  }
`;
