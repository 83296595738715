import { gql } from '@apollo/client/core';

export const UPDATE_PREVIOUS_EXAM = gql`
  mutation UpdatePreviouExam($model: EditPreviousExamInput) {
    Teacher {
      EditPreviousExam(model: $model) {
        Result
      }
    }
  }
`;
