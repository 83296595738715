import { gql } from '@apollo/client/core';

export const GET_CITIES = gql`
  query Cities($criteria: CityCriteria) {
    LookupQuery {
      Cities(criteria: $criteria) {
        Result {
          Id
          Name
        }
      }
    }
  }
`;
