import { gql } from '@apollo/client/core';

export const DELETE_COURSE_GROUP = gql`
  mutation DeleteCourseGroup($model: DeleteCourseGroupInput!) {
    CourseGroup {
      DeleteCourseGroup(model: $model) {
        Result
      }
    }
  }
`;
