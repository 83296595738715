import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent {
  @Input() public readonly label: string;
  @Input() public defaultOption: string | {};
  @Input() public readonly multiple: boolean;
  @Input() public readonly options: string | {}[];
  @Input() public readonly valueKey: string;
  @Input() public readonly viewKey: string;
  @Output() public readonly optionSelected = new EventEmitter<string | {}[]>();
  @HostBinding('style.flex-basis') public flexBasis = `30%`;
  @Input() public set width(value: number) {
    this.flexBasis = `${value}%`;
  }

  public selectOption({ value }: MatSelectChange): void {
    this.optionSelected.emit(value);
  }
}
