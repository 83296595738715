import { gql } from '@apollo/client/core';

export const RESET_PASSWORD = gql`
  mutation ResetPassword($model: ResetPasswordInput!) {
    Account {
      ResetPassword(model: $model) {
        Result {
          AssistantId
          ImagePath
          Name
          ParentId
          StudentId
          TeacherId
          Token
          TokenExpirationDate
          UserId
          UserName
        }
        TotalCount
      }
    }
  }
`;
