import { Observable, Subject } from 'rxjs';
import { StateService } from '../services/state.service';

export class BaseListComponent<L> {
  public isLoading$: Observable<boolean>;
  public totalCount = 0;
  public list: L[] = [];
  protected readonly destroy$ = new Subject<void>();

  public constructor(protected readonly stateService: StateService) {}

  public onInit(): void {
    this.stateService.setState('loading', true);
    this.isLoading$ = this.stateService.select((state) => state.loading);
  }

  public onDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
