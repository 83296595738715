import { HttpHeaders } from '@angular/common/http';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '@services';

export function apolloFactory(
  httpLink: HttpLink,
  localStorageService: LocalStorageService
): {} {
  const http = httpLink.create({ uri: environment.GRAPHQL_ENDPOINT });

  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: new HttpHeaders({
        Authorization: `Bearer ${localStorageService.get('authToken')}`,
        Language:
          localStorageService.get('uiLanguageCode') === 'ar' ? 'ar' : 'en',
        AcademyCode:
          environment.AcademyCode
      }),
    });

    return forward(operation);
  });

  const link = authMiddleware.concat(http);

  return {
    link,
    cache: new InMemoryCache({
      typePolicies: {
        Mutation: {
          fields: {
            Account: {
              merge: (existing, incoming) => incoming,
            },
            Student: {
              merge: (existing, incoming) => incoming,
            },
            Session: {
              merge: (existing, incoming) => incoming,
            },
            Teacher: {
              merge: (existing, incoming) => incoming,
            },
            Activity: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        Query: {
          fields: {
            AccountQuery: {
              merge: (existing, incoming) => incoming,
            },
            ActivityQuery: {
              merge: (existing, incoming) => incoming,
            },
            CourseGroupQuery: {
              merge: (existing, incoming) => incoming,
            },
            CourseQuery: {
              merge: (existing, incoming) => incoming,
            },
            LookupQuery: {
              merge: (existing, incoming) => incoming,
            },
            RequestQuery: {
              merge: (existing, incoming) => incoming,
            },
            TeacherQuery: {
              merge: (existing, incoming) => incoming,
            },
            SessionQuery: {
              merge: (existing, incoming) => incoming,
            },
            StudentQuery: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
      },
    }),
  };
}
