import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import HLS from 'hls.js';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoModalComponent implements OnInit, AfterViewInit {
  @ViewChild('videoPlayer') videoPlayer: ElementRef<HTMLVideoElement>;
  hls: HLS;
  videoPath: string;

  public constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: { videoPath: string }
  ) {}

  public ngOnInit(): void {
    this.videoPath = this.data.videoPath;
  }

  public ngAfterViewInit(): void {
    this.establishStream();
  }

  private establishStream(): void {
    if (this.hls) {
      this.hls.destroy();
    }

    this.hls = new HLS({
      startLevel: 2,
      capLevelToPlayerSize: true,
    });

    if (HLS.isSupported()) {
      this.hls.attachMedia(this.videoPlayer.nativeElement);
      this.hls.loadSource(this.data.videoPath);
    }
  }
}
