import { gql } from '@apollo/client/core';

export const GET_PREVIOUS_EXAMS = gql`
  query PreviousExams($criteria: PreviousExamCriteria) {
    TeacherQuery {
      PreviousExams(criteria: $criteria) {
        Result {
          Added
          AssistantId
          AttachmentPath
          Attachments {
            AttachmentName
            AttachmentPath
            IsAnswer
            FileType
          }
          CourseId
          ExamYear
          Id
          SchoolYearName
          ScientificSubjectName
          TeacherId
          Title
        }
        TotalCount
      }
    }
  }
`;
