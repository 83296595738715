<section>
  <mat-icon class="heading_star">star_outline</mat-icon>
  <h1>Rate {{ data?.type }}</h1>
  <h2 mat-dialog-title>{{ data?.title }}</h2>
  <mat-dialog-content>
    <button
      mat-icon-button
      [color]="color"
      *ngFor="let ratingId of ratingArr; index as i"
      [id]="'star_' + 1"
      (click)="onClickRating(i + 1)"
    >
      <mat-icon>{{ showIcon(i) }}</mat-icon>
    </button>
    <form [formGroup]="ratingForm">
      <mat-form-field appearance="outline">
        <mat-label>Leave a comment</mat-label>
        <textarea matInput rows="6" formControlName="Comment"></textarea>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button
      mat-raised-button
      color="accent"
      [disabled]="!rating"
      (click)="onSubmitRating()"
    >
      Submit
    </button>
  </mat-dialog-actions>
</section>
