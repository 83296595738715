import { gql } from '@apollo/client/core';

export const CREATE_ASSIGNMENT = gql`
  mutation CreateAssignment($model: AssignmentInput!) {
    Activity {
      CreateAssignment(model: $model) {
        Result {
          Id
        }
      }
    }
  }
`;
