import { gql } from '@apollo/client/core';

export const GET_USER_ACTIVITIES = gql`
  query GetUserActivities($criteria: UserActivityCriteria) {
    ActivityQuery {
      UserActivities(criteria: $criteria) {
        Result {
          Id
          ActivityId
          StudentId
          StudentName
          ScientificSubjectName
          SchoolYearName
          CourseGroupName
          Grade
          ActivityGrad
          IsMarked
          DeliveredDate
          ScoreOrder
          ActivityGrad
          AttachmentPath
          ActivityIsOnline
          Attachments {
            AttachmentName
            AttachmentPath
            filetype: FileType
          }
        }
        TotalCount
      }
    }
  }
`;
