import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Quiz } from '@generated-types';
import { LocalStorageService } from '@services';
import {
  countDownTimerConfigModel,
  CountdownTimerService,
  timerTexts,
} from 'ngx-timer';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss'],
})
export class TimerComponent implements OnInit, OnDestroy {
  @Input() public readonly quiz: Quiz;
  @Output() public timeFinished = new EventEmitter<void>();

  private quizDate: Date;
  public readonly timerConfig = new countDownTimerConfigModel();
  private readonly destroy$ = new Subject<void>();

  public constructor(
    private readonly timerService: CountdownTimerService,
    private readonly localStorageService: LocalStorageService
  ) {
    this.timerConfig.timerClass = 'timer';
    this.timerConfig.timerTexts = new timerTexts();
    this.timerConfig.timerTexts.hourText = ' : ';
    this.timerConfig.timerTexts.minuteText = ' : ';
    this.timerConfig.timerTexts.secondsText = ' ';
  }

  public ngOnInit(): void {
    const savedQuizz = JSON.parse(this.localStorageService.get('savedQuizz'));
    const runningDate = new Date();

    if (savedQuizz && savedQuizz.Id === this.quiz.Id) {
      const leavingDate = new Date(savedQuizz.leavingDate);

      runningDate.setHours(leavingDate.getHours());
      runningDate.setMinutes(leavingDate.getMinutes());
      runningDate.setSeconds(leavingDate.getSeconds());
    } else {
      const hours = Math.floor(this.quiz?.ActivityDuration / 60 / 60);
      const minutes = Math.floor(this.quiz?.ActivityDuration / 60) - hours * 60;
      const seconds = Math.floor(this.quiz?.ActivityDuration % 60);

      runningDate.setHours(runningDate.getHours() + hours);
      runningDate.setMinutes(runningDate.getMinutes() + minutes);
      runningDate.setSeconds(runningDate.getSeconds() + seconds);
    }

    this.quizDate = runningDate;

    this.timerService.startTimer(runningDate);

    this.timerService.onTimerStatusChange
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        if (status === 'STOP') {
          this.timeFinished.emit();
        }
      });
  }

  public ngOnDestroy(): void {
    const leavingDate = new Date();
    if (leavingDate < this.quizDate) {
      this.localStorageService.set(
        'savedQuizz',
        JSON.stringify({
          Id: this.quiz.Id,
          leavingDate: this.quizDate,
        })
      );
    }

    this.destroy$.next();
    this.destroy$.complete();
  }
}
