import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BaseComponent } from '@base';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('style.flex-basis') public flexBasis = `30%`;
  @Input() public readonly disabled: boolean;
  @Input() public set width(value: number) {
    this.flexBasis = `${value}%`;
  }
  @ViewChild('searchInput') private readonly searchInput: ElementRef;
  @Output() public readonly searchTerm = new EventEmitter<string>();

  public constructor() {
    super();
  }

  public ngOnInit(): void {}

  public ngAfterViewInit(): void {
    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(debounceTime(200), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe({
        next: () => this.searchTerm.emit(this.searchInput.nativeElement.value),
      });
  }

  public ngOnDestroy(): void {
    this.onDestroy();
  }
}
