import { gql } from '@apollo/client/core';

export const ASK_SESSION_QUESTION = gql`
  mutation AskSessionQuestion($model: SessionQuestionInput) {
    Session {
      AskSessionQuestion(model: $model) {
        Result {
          Id
        }
        TotalCount
      }
    }
  }
`;
