import { Injectable } from '@angular/core';
import {
  BooleanGraphTypeResponse,
  ChangeCourseGroupInput,
  CourseCriteria,
  CourseGroup,
  CourseGroupCriteria,
  CourseGroupInput,
  CourseGroupRateInput,
  CourseGroupReportInput,
  CourseGroupStudentCriteria,
  CourseGroupStudentsQuery,
  CourseGroupStudentsQueryVariables,
  CourseGroupStudentsTypeListResponse,
  CourseGroupStudentsTypeResponse,
  CourseGroupTypeListResponse,
  CourseGroupTypeResponse,
  CourseInput,
  CourseTypeListResponse,
  CourseTypeResponse,
  CreateCourseGroupMutation,
  CreateCourseGroupMutationVariables,
  CreateCourseMutation,
  CreateCourseMutationVariables,
  DeleteCourseGroupInput,
  DeleteCourseGroupMutation,
  DeleteCourseGroupMutationVariables,
  RequestTypeResponse,
  SubmitCourseGroupRateMutation,
  SubmitCourseGroupRateMutationVariables,
  SubmitCourseGroupReportMutation,
  SubmitCourseGroupReportMutationVariables,
  TeacherCourseGroupsQuery,
  TeacherCourseGroupsQueryVariables,
  TeacherCoursesQuery,
  TeacherCoursesQueryVariables,
  UpdateCourseGroupMutation,
  UpdateCourseGroupMutationVariables,
} from '@generated-types';
import {
  GET_COURSE_GROUP_STUDENTS,
  GET_TEACHER_COURSES,
  GET_TEACHER_COURSE_GROUPS,
} from '@queries';
import { first, map } from 'rxjs/operators';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import {
  CHANGE_COURSE_GROUP,
  CREATE_COURSE,
  CREATE_COURSE_GROUP,
  DELETE_COURSE_GROUP,
  SUBMIT_COURSE_GROUP_RATE,
  SUBMIT_COURSE_GROUP_REPORT,
  UPDATE_COURSE_GROUP,
} from '@mutations';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CoursesService implements Resolve<Observable<CourseGroup>> {
  public constructor(private readonly dataService: DataService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<CourseGroup> {
    return this.dataService
      .query<TeacherCourseGroupsQuery, TeacherCourseGroupsQueryVariables>(
        GET_TEACHER_COURSE_GROUPS,
        {
          criteria: {
            Id: +route.params.courseGroupId,
          },
          courseHours: true,
        }
      )
      .pipe(
        map(
          (response) => response.data.CourseGroupQuery.CourseGroups.Result[0]
        ),
        first()
      );
  }

  public getStudentCourseGroups(
    criteria: CourseGroupStudentCriteria
  ): Observable<CourseGroupStudentsTypeListResponse> {
    return this.dataService
      .query<CourseGroupStudentsQuery, CourseGroupStudentsQueryVariables>(
        GET_COURSE_GROUP_STUDENTS,
        {
          criteria,
        }
      )
      .pipe(
        map((response) => response.data.CourseGroupQuery.CourseGroupStudents)
      );
  }

  public getTeacherCourses(
    criteria: CourseCriteria
  ): Observable<CourseTypeListResponse> {
    return this.dataService
      .query<TeacherCoursesQuery, TeacherCoursesQueryVariables>(
        GET_TEACHER_COURSES,
        {
          criteria,
        }
      )
      .pipe(map((response) => response.data.CourseQuery.Courses));
  }

  public getTeacherCourseGroups(
    criteria: CourseGroupCriteria
  ): Observable<CourseGroupTypeListResponse> {
    return this.dataService
      .query<TeacherCourseGroupsQuery, TeacherCourseGroupsQueryVariables>(
        GET_TEACHER_COURSE_GROUPS,
        {
          criteria,
          courseHours: true,
        }
      )
      .pipe(map((response) => response.data.CourseGroupQuery.CourseGroups));
  }

  public createCourse(model: CourseInput): Observable<CourseTypeResponse> {
    return this.dataService
      .mutate<CreateCourseMutation, CreateCourseMutationVariables>(
        CREATE_COURSE,
        {
          model,
        }
      )
      .pipe(map((response) => response.data.Course.CreateCourse));
  }

  public createCourseGroup(
    model: CourseGroupInput
  ): Observable<CourseGroupTypeResponse> {
    return this.dataService
      .mutate<CreateCourseGroupMutation, CreateCourseGroupMutationVariables>(
        CREATE_COURSE_GROUP,
        {
          model,
        }
      )
      .pipe(map((response) => response.data.CourseGroup.CreateCourseGroup));
  }

  public updateCourseGroup(
    model: CourseGroupInput
  ): Observable<CourseGroupTypeResponse> {
    return this.dataService
      .mutate<UpdateCourseGroupMutation, UpdateCourseGroupMutationVariables>(
        UPDATE_COURSE_GROUP,
        {
          model,
        }
      )
      .pipe(map((response) => response.data.CourseGroup.UpdateCourseGroup));
  }

  public deleteCourseGroup(
    model: DeleteCourseGroupInput,
    criteria: CourseGroupCriteria
  ): Observable<BooleanGraphTypeResponse> {
    return this.dataService
      .mutateWithRefetch<
        DeleteCourseGroupMutation,
        DeleteCourseGroupMutationVariables,
        TeacherCourseGroupsQuery,
        TeacherCourseGroupsQueryVariables
      >(
        DELETE_COURSE_GROUP,
        {
          model,
        },
        GET_TEACHER_COURSE_GROUPS,
        {
          courseHours: true,
          criteria,
        }
      )
      .pipe(map((response) => response.data.CourseGroup.DeleteCourseGroup));
  }

  public rateCourseGroup(
    model: CourseGroupRateInput,
    criteria: CourseGroupStudentCriteria
  ): Observable<CourseGroupStudentsTypeResponse> {
    return this.dataService
      .mutateWithRefetch<
        SubmitCourseGroupRateMutation,
        SubmitCourseGroupRateMutationVariables,
        CourseGroupStudentsQuery,
        CourseGroupStudentsQueryVariables
      >(
        SUBMIT_COURSE_GROUP_RATE,
        {
          model,
        },
        GET_COURSE_GROUP_STUDENTS,
        { criteria }
      )
      .pipe(map((response) => response.data.Student.SubmitCourseGroupRate));
  }

  public reportCourseGroup(
    model: CourseGroupReportInput
  ): Observable<CourseGroupStudentsTypeResponse> {
    return this.dataService
      .mutate<
        SubmitCourseGroupReportMutation,
        SubmitCourseGroupReportMutationVariables
      >(SUBMIT_COURSE_GROUP_REPORT, {
        model,
      })
      .pipe(map((response) => response.data.Student.SubmitCourseGroupReport));
  }

  public changeCourseGroup(
    model: ChangeCourseGroupInput
  ): Observable<RequestTypeResponse> {
    return this.dataService
      .mutate(CHANGE_COURSE_GROUP, {
        model,
      })
      .pipe(
        map(
          (response) => response.data.Request.StudentRequests.ChangeCourseGroup
        )
      );
  }

  public convertTime12to24(time12h): string {
    console.log(time12h);
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  }

  public convertTime24to12(time): string {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }
}
