import { gql } from '@apollo/client/core';

export const BLOCK_STUDENT = gql`
  mutation BlockStudent($model: ManageStudentInput) {
    UserBlock {
      BlockStudent(model: $model) {
        Result
      }
    }
  }
`;
