import { gql } from '@apollo/client/core';

export const MANAGE_ADD_TO_COURSE_GROUP_REQUEST = gql`
  mutation ManageAddToCourseGroupRequest($model: ManageRequestInput!) {
    Request {
      StudentRequests {
        ManageAddToCourseGroupRequest(model: $model) {
          Result
        }
      }
    }
  }
`;
