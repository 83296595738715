import { gql } from '@apollo/client/core';

export const VIEW_REVISION = gql`
  mutation ViewRevision($model: ViewRevisionInput!) {
    Student {
      ViewRevision(model: $model) {
        Result {
          ActivityId
          StudentId
        }
      }
    }
  }
`;
