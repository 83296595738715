<section>
  <mat-icon>hourglass_disabled</mat-icon>
  <h1>Time is up</h1>
  <h2 mat-dialog-title>
    Sorry, your answers have been submitted automatically at
    {{ data?.leavingDate | date: "long" }} due to your inactiveness during the
    specified duration.
  </h2>
  <mat-dialog-actions align="center">
    <button
      type="button"
      mat-raised-button
      mat-dialog-close
      color="accent"
      (click)="submit()"
    >
      Okay
    </button>
  </mat-dialog-actions>
</section>
