import { gql } from '@apollo/client/core';

export const CANCEL_REQUEST = gql`
  mutation CancelRequest($model: RequestInput!) {
    Request {
      CancelRequest(model: $model) {
        Result
      }
    }
  }
`;
