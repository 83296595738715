import { gql } from '@apollo/client/core';

export const GET_TEACHERS = gql`
  query GetTeachers($criteria: TeacherCriteria) {
    TeacherQuery {
      Teachers(criteria: $criteria) {
        Result {
          Id
          Name
          MobileNumber
          Slogan
          NumberOfFollowers
          RankScore
          ImagePath
          Gender
          Email
          Bio
          VideoPath
          VideoPicturePath
          About
        }
        TotalCount
      }
    }
  }
`;
