import { gql } from '@apollo/client/core';

export const SUBMIT_SESSION_RATE = gql`
  mutation SubmitSessionRate($model: SessionRateInput) {
    Session {
      SubmitSessionRate(model: $model) {
        Result {
          SessionId
          StudentId
          Rate
          Comment
        }
      }
    }
  }
`;
