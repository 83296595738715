<section class="" *ngIf="courseGroups?.length < 5; else cardsCarousel">
  <div class="normal-card-container">
    <div
      *ngIf="userProfileType === 'TEACHER'"
      class="carousel-card"
      (click)="onSelectCourseGroup()"
      [routerLink]="[]"
      [queryParams]="{ cg: 'all' }"
      routerLinkActive="active-carousel-card"
    >
      <h2>All Courses</h2>
    </div>
    <ng-container *ngFor="let courseGroup of courseGroups">
      <div
        class="carousel-card"
        (click)="onSelectCourseGroup(courseGroup?.CourseGroupId)"
        [routerLink]="[]"
        [queryParams]="{ cg: courseGroup.CourseGroupId }"
        routerLinkActive="active-carousel-card"
      >
        <h2>{{ courseGroup?.CourseGroupName }}</h2>
        <h3>{{ courseGroup?.TeacherName }}</h3>
      </div>
    </ng-container>
  </div>
</section>

<ng-template #cardsCarousel>
  <section>
    <owl-carousel-o [options]="carouselOptions">
      <ng-template carouselSlide *ngIf="carouselType !== 'studentQuestionBank'">
        <div
          class="carousel-card"
          (click)="onSelectCourseGroup()"
          [routerLink]="[]"
          [queryParams]="{ cg: 'all' }"
          routerLinkActive="active-carousel-card"
        >
          <h2>All Courses</h2>
        </div>
      </ng-template>
      <ng-template *ngFor="let courseGroup of courseGroups" carouselSlide>
        <div
          class="carousel-card"
          (click)="onSelectCourseGroup(courseGroup?.CourseGroupId)"
          [routerLink]="[]"
          [queryParams]="{ cg: courseGroup.Id }"
          routerLinkActive="active-carousel-card"
        >
          <h2>{{ courseGroup?.CourseGroupName }}</h2>
          <h3>{{ courseGroup?.TeacherName }}</h3>
        </div>
      </ng-template>
    </owl-carousel-o>
  </section>
</ng-template>
