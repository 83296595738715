import { Injectable } from '@angular/core';
import {
  ActivityCriteria,
  ActivityTypeListResponse,
  ActivityTypeResponse,
  BooleanGraphTypeResponse,
  CreateRevisionMutation,
  CreateRevisionMutationVariables,
  RemoveQuizMutation,
  RemoveQuizMutationVariables,
  Revision,
  RevisionInput,
  RevisionsQuery,
  RevisionsQueryVariables,
  RevisionTypeListResponse,
  UpdateRevisionMutation,
  UpdateRevisionMutationVariables,
  UserActivitiesQuery,
  UserActivitiesQueryVariables,
  UserActivityCriteria,
  UserActivityTypeListResponse,
  ViewRevisionInput,
  ViewRevisionMutation,
  ViewRevisionMutationVariables,
  ViewRevisionTypeResponse,
} from '@generated-types';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import { GET_REVISIONS } from '@queries';
import { first, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { AuthService } from './auth.service';
import {
  CREATE_REVISION,
  REMOVE_REVISION,
  UPDATE_REVISION,
  VIEW_REVISION,
} from '@mutations';
import { GET_REVISION_USER_ACIVITIES } from 'src/app/common/graphql/query/get-revision-user-activities.graphql';

@Injectable({
  providedIn: 'root',
})
export class RevisionsService implements Resolve<Observable<Revision>> {
  public constructor(
    private readonly dataService: DataService,
    private readonly authService: AuthService
  ) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Revision> {
    if (this.authService.getUserState().StudentId) {
      return this.getRevisions({
        Id: +route.params.id,
        IsDraft: false,
        StudentIdCouserGroup: this.authService.getUserState().StudentId,
      }).pipe(
        map((response) => response.Result[0]),
        first()
      );
    } else {
      return this.getRevisions({
        Id: +route.params.id,
        IsDraft: route.params.isDraft,
        TeacherId: this.authService.getUserState().TeacherId,
      }).pipe(
        map((response) => response.Result[0]),
        first()
      );
    }
  }

  public getRevisions(
    criteria: ActivityCriteria,
    withComment: boolean = false
  ): Observable<RevisionTypeListResponse> {
    return this.dataService
      .query<RevisionsQuery, RevisionsQueryVariables>(GET_REVISIONS, {
        criteria,
        withComment,
      })
      .pipe(map((response) => response.data.ActivityQuery.Revisions));
  }

  public createRevision(
    model: RevisionInput
  ): Observable<ActivityTypeListResponse> {
    return this.dataService
      .mutate<CreateRevisionMutation, CreateRevisionMutationVariables>(
        CREATE_REVISION,
        {
          model,
        }
      )
      .pipe(map((response) => response.data.Activity.CreateRevision));
  }

  public updateRevision(
    model: RevisionInput
  ): Observable<ActivityTypeResponse> {
    return this.dataService
      .mutate<UpdateRevisionMutation, UpdateRevisionMutationVariables>(
        UPDATE_REVISION,
        {
          model,
        }
      )
      .pipe(map((response) => response.data.Activity.UpdateRevision));
  }

  public removeRevision(
    activtiyId: number,
    criteria: ActivityCriteria
  ): Observable<BooleanGraphTypeResponse> {
    return this.dataService
      .mutateWithRefetch<
        RemoveQuizMutation,
        RemoveQuizMutationVariables,
        RevisionsQuery,
        RevisionsQueryVariables
      >(
        REMOVE_REVISION,
        {
          activtiyId,
        },
        GET_REVISIONS,
        {
          criteria,
          withComment: false,
        }
      )
      .pipe(map((response) => response.data.Activity.DeleteActivtiy));
  }

  public viewRevision(
    model: ViewRevisionInput
  ): Observable<ViewRevisionTypeResponse> {
    return this.dataService
      .mutate<ViewRevisionMutation, ViewRevisionMutationVariables>(
        VIEW_REVISION,
        {
          model,
        }
      )
      .pipe(map((response) => response.data.Student.ViewRevision));
  }

  public getRevisionUserActivities(
    criteria: UserActivityCriteria
  ): Observable<UserActivityTypeListResponse> {
    return this.dataService
      .query<UserActivitiesQuery, UserActivitiesQueryVariables>(
        GET_REVISION_USER_ACIVITIES,
        {
          criteria,
        }
      )
      .pipe(map((response) => response.data.ActivityQuery.UserActivities));
  }
}
