import { gql } from '@apollo/client/core';

export const SUBMIT_COURSE_GROUP_REPORT = gql`
  mutation SubmitCourseGroupReport($model: CourseGroupReportInput) {
    Student {
      SubmitCourseGroupReport(model: $model) {
        Result {
          Comment
          CourseGroupId
          CourseGroupName
          CourseId
          Id
          ImagePath
          IsSelected
          IsSubscribed
          IsTeacherPaid
          ParentId
          Rate
          Report
          SchoolYearName
          ScientificSubjectName
          StudentId
          StudentMobileNumber
          StudentName
          StudentStatus
          TeacherName
        }
        TotalCount
      }
    }
  }
`;
