import { gql } from '@apollo/client/core';

export const GET_SUBJECTS = gql`
  query Subjects($criteria: SubjectCriteria) {
    LookupQuery {
      Subjects(criteria: $criteria) {
        Result {
          Id
          Name
          ScientificSubjectId
          ScientificSubjectName
          SchoolYearName
          EducationTypeName
          SemesterId
        }
        TotalCount
      }
    }
  }
`;
