import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
})
export class RatingComponent implements OnInit {
  public ratingForm: FormGroup;
  public rating: number;
  public readonly starCount = 5;
  public readonly color = 'primary';
  public ratingArr = [];

  public constructor(
    private readonly fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public readonly data: { title: string; type: string },
    private readonly dialogRef: MatDialogRef<RatingComponent>
  ) {}

  public ngOnInit(): void {
    this.ratingForm = this.fb.group({
      Comment: '',
      Rate: this.rating,
    });

    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
  }

  public showIcon(index: number): string {
    if (this.rating >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }

  public onClickRating(rating: number): boolean {
    this.rating = rating;
    this.ratingForm.controls.Rate.setValue(rating);
    return false;
  }

  public onSubmitRating(): void {
    this.dialogRef.close({
      Comment: this.ratingForm.value.Comment,
      Rate: +this.ratingForm.value.Rate,
    });
  }
}
