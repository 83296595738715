import { Injectable } from '@angular/core';
import {
  BlockStudentMutation,
  BlockStudentMutationVariables,
  BooleanGraphTypeResponse,
  CourseGroupStudentCriteria,
  CourseGroupStudentsQuery,
  CourseGroupStudentsQueryVariables,
  CourseGroupStudentsTypeListResponse,
  ManageStudentInput,
  RemoveStudentsMutation,
  RemoveStudentsMutationVariables,
  SuspendStudentMutation,
  SuspendStudentMutationVariables,
} from '@generated-types';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import { GET_COURSE_GROUP_STUDENTS } from '@queries';
import { map } from 'rxjs/operators';
import { REMOVE_STUDENT, SUSPEND_STUDENT, BLOCK_STUDENT } from '@mutations';

@Injectable({
  providedIn: 'root',
})
export class StudentsService {
  public constructor(private readonly dataService: DataService) {}

  public getTeacherStudents(
    criteria: CourseGroupStudentCriteria
  ): Observable<CourseGroupStudentsTypeListResponse> {
    return this.dataService
      .query<CourseGroupStudentsQuery, CourseGroupStudentsQueryVariables>(
        GET_COURSE_GROUP_STUDENTS,
        {
          criteria,
        }
      )
      .pipe(
        map((response) => response.data.CourseGroupQuery.CourseGroupStudents)
      );
  }

  public suspendAndActiveStudent(
    model: ManageStudentInput,
    criteria: CourseGroupStudentCriteria
  ): Observable<BooleanGraphTypeResponse> {
    return this.dataService
      .mutateWithRefetch<
        SuspendStudentMutation,
        SuspendStudentMutationVariables,
        CourseGroupStudentsQuery,
        CourseGroupStudentsQueryVariables
      >(
        SUSPEND_STUDENT,
        {
          model,
        },
        GET_COURSE_GROUP_STUDENTS,
        {
          criteria,
        }
      )
      .pipe(map((response) => response.data.Teacher.SuspendAndActiveStudent));
  }

  public removeStudent(
    model: ManageStudentInput,
    criteria: CourseGroupStudentCriteria
  ): Observable<BooleanGraphTypeResponse> {
    return this.dataService
      .mutateWithRefetch<
        RemoveStudentsMutation,
        RemoveStudentsMutationVariables,
        CourseGroupStudentsQuery,
        CourseGroupStudentsQueryVariables
      >(
        REMOVE_STUDENT,
        {
          model,
        },
        GET_COURSE_GROUP_STUDENTS,
        {
          criteria,
        }
      )
      .pipe(map((response) => response.data.Teacher.DeleteStudent));
  }

  public blockStudent(
    model: ManageStudentInput,
    criteria: CourseGroupStudentCriteria
  ): Observable<BooleanGraphTypeResponse> {
    return this.dataService
      .mutateWithRefetch<
        BlockStudentMutation,
        BlockStudentMutationVariables,
        CourseGroupStudentsQuery,
        CourseGroupStudentsQueryVariables
      >(
        BLOCK_STUDENT,
        {
          model,
        },
        GET_COURSE_GROUP_STUDENTS,
        {
          criteria,
        }
      )
      .pipe(map((response) => response.data.UserBlock.BlockStudent));
  }
}
