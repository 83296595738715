import { gql } from '@apollo/client/core';

export const REMOVE_TEACHER_QUESTION_BANK = gql`
  mutation RemoveTeacherQuestionbank($questionId: Int) {
    Teacher {
      DeleteTeacherQuestion(questionId: $questionId) {
        Result
      }
    }
  }
`;
