import { gql } from '@apollo/client/core';

export const GET_TEACHER_SUBJECTS = gql`
  query TeacherSubjects($criteria: TeacherSubjectCriteria) {
    TeacherQuery {
      TeacherSubjects(criteria: $criteria) {
        Result {
          ScientificSubjectName
          SchoolYearName
          Subject {
            Name
            EducationTypeName
          }
        }
        TotalCount
      }
    }
  }
`;
