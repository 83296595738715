import { gql } from '@apollo/client/core';

export const SUGGEST_QUESTION = gql`
  mutation SuggestQuestion($model: SuggestQuestionInput) {
    Student {
      SuggestQuestion(model: $model) {
        Result {
          Id
          StudentId
          CourseId
          QuestionText
          QuestionPath
          Answer
          AnswerPath
        }
      }
    }
  }
`;
