import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { AuthService } from '@services';

@Directive({
  selector: '[appUserHasType]',
})
export class RoleBasedDirective implements OnInit {
  @Input() public appUserHasType: string[];
  private isVisible = false;

  public constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<any>,
    private readonly authService: AuthService
  ) {}

  public ngOnInit(): void {
    const user = this.authService.getUserState();

    if (user && this.appUserHasType.includes(user.UserType)) {
      if (!this.isVisible) {
        this.isVisible = true;
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      }
    } else {
      this.viewContainerRef.clear();
    }
  }
}
