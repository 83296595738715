import { gql } from '@apollo/client/core';

export const CHECK_TEACHER_FOLLOW = gql`
  query CheckTeacherFollower($criteria: TeacherFollowerCriteria) {
    StudentQuery {
      CheckTeacherFollower(criteria: $criteria) {
        Result {
          IsFollow
        }
      }
    }
  }
`;
