import { gql } from '@apollo/client/core';

export const VALIDATE_RESET_PASSWORD_CODE = gql`
  mutation ValidateResetPasswordCode($model: ValidateResetPasswordCodeInput!) {
    Account {
      ValidateResetPasswordCode(model: $model) {
        Result {
          Code
          IsValid
          UserId
        }
        TotalCount
      }
    }
  }
`;
