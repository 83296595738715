import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { forkJoin, Observable, of } from 'rxjs';
import { first, map, mergeMap } from 'rxjs/operators';
import { merge } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  private readonly UPLOAD_ENDPOINT = environment.UPLOAD_ENDPOINT;
  public readonly VIMO_TOKEN: string = '69c930c245439fcc51a7396dec3a4bca';
  public readonly USER_ID: string = '205148212';
  public readonly BASE_VIVMO_URL: string = 'https://api.vimeo.com/users';

  public header = new HttpHeaders();
  public binaryFile: any;

  public constructor(private readonly http: HttpClient) {}

  public upload(file: File | File[]): Observable<string[]> {
    if (!file) {
      return of(['']);
    }
    const formData = new FormData();
    if (Array.isArray(file)) {
      file.forEach((f, index) => formData.append(`${index}`, f));
    } else {
      formData.append('file', file);
    }
    return this.http.post<string[]>(this.UPLOAD_ENDPOINT, formData, {
      observe: 'body',
    });
  }

  //GET_UPLOAD_LINK_FROM_VIMEO
  public getVimoUploadLink(file: File): any {
    const header = new HttpHeaders({
      Authorization: 'Bearer ' + this.VIMO_TOKEN,
      'Content-Type': 'application/json',
      Accept: 'application/vnd.vimeo.*+json;version=3.4',
    });
    const data = {
      name: file.name,
      upload: {
        approach: 'tus',
        size: file.size,
      },
    };
    return this.http.post(
      this.BASE_VIVMO_URL + '/' + this.USER_ID + '/videos',
      data,
      {
        headers: header,
      }
    );
  }

  public uploadVedioOnServerVimo(uploadLink: string, file: File): any {
    const headers = new HttpHeaders({
      'Tus-Resumable': '1.0.0',
      'Upload-Offset': '0',
      'Content-Type': 'application/offset+octet-stream',
    });

    return this.http.patch(uploadLink, file, {
      headers: headers,
    });
  }

  public checkViemoProgressUploadVedio(url: string): any {
    const header = new HttpHeaders({
      Authorization: 'Bearer ' + this.VIMO_TOKEN,
      'Content-Type': 'application/json',
      Accept: 'application/vnd.vimeo.*+json;version=3.4',
    });
    return this.http.get(
      'https://api.vimeo.com' +
        url +
        '?fileds=uri,upload.status,transcode.status',
      { headers: header }
    );
  }

  public uploadVedio(file: File): Observable<any> {
    return this.getVimoUploadLink(file).pipe(
      map((response: any) => {
        return response;
      }),
      mergeMap((response: any) => {
        const uploaded = this.uploadVedioOnServerVimo(
          response.upload.upload_link,
          file
        );
        const progress = this.checkViemoProgressUploadVedio(response.uri);
        return forkJoin([uploaded, progress]);
      })
    );
  }
}
