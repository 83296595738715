import { BREAKPOINT } from '@angular/flex-layout';

export interface EduraMediaBreakpoint {
  name: string;
  width: number;
}

export const CUSTOM_BREAKPOINTS = [
  {
    alias: 'medium',
    suffix: 'Medium',
    mediaQuery: 'screen and (max-width: 800px)',
    overlapping: false,
    priority: 1001,
  },
];
