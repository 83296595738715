import { gql } from '@apollo/client/core';

export const GET_INFO_ONLINE_SESSION = gql`
  query GetOnlineSessionInfo($sessionId: Int) {
    SessionQuery {
      OnlineSessionInfo(sessionId: $sessionId) {
        Result {
          MeetingId
          MeetingPassword
          APIKey
          Signature
        }
      }
    }
  }
`;
