import { gql } from '@apollo/client/core';

export const RESEND_VERIFICATION_CODE = gql`
  mutation ResendVerificationCode($model: ResendVerificationCodeInput!) {
    Account {
      ResendVerificationCode(model: $model) {
        Result {
          Code
          UserId
        }
        TotalCount
      }
    }
  }
`;
