import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import {
  UserProfile,
  CheckEmailAndMobileNumber,
  UpdateUserProfileInput,
  UserProfileTypeResponse,
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables,
  Teacher,
  GetTeachers,
  TeacherCriteria,
  ChangePasswordInput,
  ChangePasswordTypeResponse,
  ChangePasswordMutation,
  ChangePasswordMutationVariables,
  UpdateTeacherActivitiesInputType,
  BooleanGraphTypeResponse,
  UpdateTeacherQuestionbankMutation,
  TeacherMutations,
} from '@generated-types';
import { GET_USER_PROFILE } from '@queries';
import {
  CHANGE_PASSWORD,
  CHECK_EMAIL_AND_MOBILE_NUMBER,
  UPDATE_USER_PROFILE,
} from '@mutations';
import { FetchResult } from '@apollo/client/core';
import { map } from 'rxjs/operators';
import { DataService } from './data.service';
import { StateService } from './state.service';
import { GET_TEACHER_PROFILE } from 'src/app/common/graphql/query/get-teacher-profile.graphql';
import { UPDATE_TEACHER_PROFILE_ACTIVITIES } from 'src/app/common/graphql/mutation/udate-teacher-profile-activities.graghql';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly dataService: DataService,
    private readonly stateService: StateService,
    private readonly apollo: Apollo
  ) {}

  public getUserProfile(): Observable<UserProfile.Result | null> {
     if (this.localStorageService.get('authToken')) {
      return this.dataService
        .query<UserProfile.Query>(GET_USER_PROFILE, {}, 'network-only')
        .pipe(map((response) => response.data.AccountQuery.UserProfile.Result));
    } else {
      return of(null);
    }
  }

  public getUserState(): UserProfile.Result {
    return this.apollo.client.readQuery<UserProfile.Query>({
      query: GET_USER_PROFILE,
    })?.AccountQuery?.UserProfile?.Result;
  }

  public getTeacherProfile(
    criteria: TeacherCriteria
  ): Observable<Teacher | null> {
    if (this.localStorageService.get('authToken')) {
      return this.dataService
        .query<GetTeachers.Query>(GET_TEACHER_PROFILE, { criteria })
        .pipe(
          map((response) => response.data.TeacherQuery.Teachers?.Result[0])
        );
    } else {
      return of(null);
    }
  }

  public logout(): Observable<boolean> {
    this.localStorageService.remove('authToken');
    this.stateService.setState('signedIn', false);

    return of(true);
  }

  public checkEmailAndMobile(
    variables: CheckEmailAndMobileNumber.Variables
  ): Observable<FetchResult<CheckEmailAndMobileNumber.Mutation>> {
    return this.dataService.mutate<
      CheckEmailAndMobileNumber.Mutation,
      CheckEmailAndMobileNumber.Variables
    >(CHECK_EMAIL_AND_MOBILE_NUMBER, variables);
  }

  public updateUserProfile(
    model: UpdateUserProfileInput
  ): Observable<any> {
    console.log(model);
    return this.dataService
      .mutate<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(
        UPDATE_USER_PROFILE,
        {
          model,
        }
      )
      .pipe(map((response) => response.data.Account.UpdateUserProfile));
  }
  
  public updateTeacherActivities(
    model: any
  ): Observable<BooleanGraphTypeResponse> {
    console.log(model);
    return this.dataService
      .mutate(
        UPDATE_TEACHER_PROFILE_ACTIVITIES,
        {
          model,
        }
      )
      .pipe(map((response) => response.data.UpdateTeacherActivities));
  }

  public updateUserState(result: UserProfile.Result): void {
    this.apollo.client.writeQuery<UserProfile.Query>({
      query: GET_USER_PROFILE,
      data: {
        AccountQuery: {
          UserProfile: {
            Result: {
              ...result,
            },
          },
        },
      },
    });
  }

  public changePassword(
    model: ChangePasswordInput
  ): Observable<ChangePasswordTypeResponse> {
    return this.dataService
      .mutate<ChangePasswordMutation, ChangePasswordMutationVariables>(
        CHANGE_PASSWORD,
        { model }
      )
      .pipe(map((response) => response.data.Account.ChangePassword));
  }
}
