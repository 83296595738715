import { gql } from '@apollo/client/core';

export const REMOVE_ASSIGNMENT = gql`
  mutation RemoveAssignment($activtiyId: Int) {
    Activity {
      DeleteActivtiy(activtiyId: $activtiyId) {
        Result
      }
    }
  }
`;
