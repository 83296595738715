import { gql } from '@apollo/client/core';

export const UPDATE_ASSIGNMENT = gql`
  mutation UpdateAssignment($model: AssignmentInput!) {
    Activity {
      UpdateAssignment(model: $model) {
        Result {
          Id
        }
      }
    }
  }
`;
