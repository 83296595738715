import { gql } from '@apollo/client/core';

export const GET_TEACHER_ASSISTANTS = gql`
  query TeacherAssistants($criteria: AssistantCriteria) {
    AssistantQuery {
      Assistants(criteria: $criteria) {
        Result {
          Id
          Name
          ImagePath
          MobileNumber
          Email
          About
        }
      }
    }
  }
`;
