import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  Optional,
  PLATFORM_ID,
  SkipSelf,
} from '@angular/core';
import {
  BrowserModule,
  BrowserTransferStateModule,
  TransferState,
} from '@angular/platform-browser';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '../shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FlexLayoutModule, BREAKPOINT } from '@angular/flex-layout';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SplashScreenService } from './services/splash-screen.service';
import { EDURA_DOCUMENT, EDURA_WINDOW } from './services/constants';
import { translateBrowserLoaderFactory } from './translate-loaders/browser.loader';
import { documentFactory, windowFactory } from './factories';
import { apolloFactory } from './factories/apollo.factory';
import { LocalStorageService, CUSTOM_BREAKPOINTS } from '@services';

import { HttpLink } from 'apollo-angular/http';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { MainComponent } from './main.component';
import { DefaultInterceptor } from '@interceptors';
import { BreadcrumbsModule } from '@exalif/ngx-breadcrumbs';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [MainComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'edura' }),
    BrowserAnimationsModule,
    BrowserTransferStateModule,
    HttpClientModule,
    SharedModule,
    FontAwesomeModule,
    FlexLayoutModule,
    NgxSpinnerModule,
    BreadcrumbsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState],
      },
    }),
  ],
  providers: [
    {
      provide: EDURA_DOCUMENT,
      useFactory: documentFactory,
      deps: [PLATFORM_ID],
    },
    {
      provide: EDURA_WINDOW,
      useFactory: windowFactory,
      deps: [PLATFORM_ID],
    },
    {
      provide: BREAKPOINT,
      useValue: CUSTOM_BREAKPOINTS,
      multi: true,
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: apolloFactory,
      deps: [HttpLink, LocalStorageService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultInterceptor,
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {
  constructor(
    private readonly splashScreenService: SplashScreenService,
    @Optional() @SkipSelf() core: CoreModule
  ) {
    if (core) {
      throw new Error(
        'You should import core module only once in the root module'
      );
    }
  }
}
