import { gql } from '@apollo/client/core';

export const UPDATE_POST = gql`
  mutation UpdatePost($model: PostInput!) {
    Activity {
      UpdatePost(model: $model) {
        Result {
          Id
        }
      }
    }
  }
`;
