import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-select-strings-input',
  templateUrl: './select-strings-input.component.html',
  styleUrls: ['./select-strings-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectStringsInputComponent implements OnInit {
  public defaultSelectedOption: string;
  @Input() label: string;
  @Input() options: string[];
  @Input() defaultOption: string;
  @Output() optionSelected = new EventEmitter<string>();

  public ngOnInit(): void {
    if (this.defaultOption) {
      this.defaultSelectedOption = this.defaultOption;
    }
  }

  public onSelectOption(selection: MatSelectChange): void {
    this.optionSelected.emit(selection.value);
  }
}
