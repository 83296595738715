import { Injectable } from '@angular/core';
import {
  Activity,
  ActivityCriteria,
  ActivityMutations,
  ActivityMutationsUpdateQuizArgs,
  ActivityQuestionCriteria,
  ActivityQuestionsQuery,
  ActivityQuestionsWithAnswersQuery,
  ActivityQuestionsWithAnswersQueryVariables,
  ActivityQuestionTypeListResponse,
  ActivityTypeListResponse,
  ActivityTypeResponse,
  ApplicationUserType,
  BooleanGraphTypeResponse,
  CreateQuizMutation,
  CreateQuizMutationVariables,
  MarkActivityInput,
  MarkStudentAnswerOnlineInput,
  Quiz,
  QuizInput,
  QuizTypeListResponse,
  QuizzesForTableQuery,
  QuizzesForTableQueryVariables,
  QuizzesQuery,
  QuizzesQueryVariables,
  RemoveQuizMutation,
  RemoveQuizMutationVariables,
  StudentActivity,
  StudentActivityQuestionAnswersCriteria,
  TeacherMutations,
  TeacherMutationsMarkActivityOfflineArgs,
  TeacherMutationsMarkStudentAnswerOnlineArgs,
  UserActivitiesQuery,
  UserActivitiesQueryVariables,
  UserActivityCriteria,
  UserActivityTypeListResponse,
} from '@generated-types';
import {
  GET_ACTIVITY_QUESTIONS_WITH_ANSWERS,
  GET_QUIZZES,
  GET_QUIZZES_FOR_TABLE,
  GET_USER_ACTIVITIES,
} from '@queries';
import { first, map } from 'rxjs/operators';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { AuthService } from './auth.service';
import { CREATE_QUIZ, REMOVE_QUIZ } from '@mutations';
import { GET_QUIZES_COURSES } from 'src/app/common/graphql/query/getQuizesCourses.graphql';
import { UPDATE_QUIZ } from 'src/app/common/graphql/mutation/uodate-quiz.graphql';
import { MARK_STUDENT_ANSWER_ONLINE } from 'src/app/common/graphql/mutation/mark-student-answer-online.graphql';
import { MARK_ACTIVITY_OFFLINE } from 'src/app/common/graphql/mutation/mark-activity-offline.graphql';

@Injectable({
  providedIn: 'root',
})
export class QuizzesService implements Resolve<Observable<Quiz>> {
  public constructor(
    private readonly dataService: DataService,
    private readonly authService: AuthService
  ) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Quiz> {
    if (
      this.authService.getUserState().UserType === ApplicationUserType.STUDENT
    ) {
      return this.getQuizzes(
        {
          Id: +route.params.id,
        },
        true,
        {
          ActivityId: +route.params.id,
          TeacherId: this.authService.getUserState().TeacherId || null,
        },
        {
          StudentId: this.authService.getUserState().StudentId || null,
        }
      ).pipe(
        map((response) => response.Result[0]),
        first()
      );
    } else {
      return this.getQuizzes(
        {
          Id: +route.params.id,
        },
        true,
        {
          ActivityId: +route.params.id,
          TeacherId: this.authService.getUserState().TeacherId || null,
        },
        {
          StudentId: route.queryParams.studentId || null,
        }
      ).pipe(
        map((response) => response.Result[0]),
        first()
      );
    }
  }

  public getQuizzes(
    criteria: ActivityCriteria,
    withQuestion: boolean,
    userActivityCriteria?: UserActivityCriteria,
    studentCriteria?: StudentActivityQuestionAnswersCriteria
  ): Observable<QuizTypeListResponse> {
    return this.dataService
      .query<QuizzesQuery, QuizzesQueryVariables>(GET_QUIZZES, {
        criteria,
        withQuestion,
        userActivityCriteria,
        studentCriteria,
      })
      .pipe(map((response) => response.data.ActivityQuery.Quizzes));
  }

  public getQuizzesForTable(
    criteria: ActivityCriteria
  ): Observable<QuizTypeListResponse> {
    return this.dataService
      .query<QuizzesForTableQuery, QuizzesForTableQueryVariables>(GET_QUIZZES_FOR_TABLE, {
        criteria
      })
      .pipe(map((response) => response.data.ActivityQuery.Quizzes));
  }

  public GetCourseQuizzes(
    criteria: ActivityCriteria
  ): Observable<QuizTypeListResponse> {
    return this.dataService
      .query(GET_QUIZES_COURSES, {
        criteria,
      })
      .pipe(map((response) => response.data.ActivityQuery.Quizzes));
  }

  public createQuiz(model: QuizInput): Observable<ActivityTypeListResponse> {
    return this.dataService
      .mutate<CreateQuizMutation, CreateQuizMutationVariables>(CREATE_QUIZ, {
        model,
      })
      .pipe(map((response) => response.data.Activity.CreateQuiz));
  }

  public updateQuiz(model: QuizInput): Observable<ActivityTypeResponse> {
    return this.dataService
      .mutate<ActivityTypeResponse, ActivityMutationsUpdateQuizArgs>(
        UPDATE_QUIZ,
        {
          model,
        }
      )
      .pipe(map((response) => response.data));
  }

  public removeQuiz(
    activtiyId: number,
    criteria: ActivityCriteria
  ): Observable<BooleanGraphTypeResponse> {
    return this.dataService
      .mutateWithRefetch<
        RemoveQuizMutation,
        RemoveQuizMutationVariables,
        QuizzesQuery,
        QuizzesQueryVariables
      >(
        REMOVE_QUIZ,
        {
          activtiyId,
        },
        GET_QUIZZES,
        {
          criteria,
          withQuestion: false,
        }
      )
      .pipe(map((response) => response.data.Activity.DeleteActivtiy));
  }

  public getUserActivities(
    criteria: UserActivityCriteria
  ): Observable<UserActivityTypeListResponse> {
    return this.dataService
      .query<UserActivitiesQuery, UserActivitiesQueryVariables>(
        GET_USER_ACTIVITIES,
        { criteria }
      )
      .pipe(map((response) => response.data.ActivityQuery.UserActivities));
  }

  public getStudentAnswer(
    criteria: ActivityQuestionCriteria,
    studentCriteria: StudentActivityQuestionAnswersCriteria
  ): Observable<ActivityQuestionTypeListResponse> {
    return this.dataService
      .query<
        ActivityQuestionsWithAnswersQuery,
        ActivityQuestionsWithAnswersQueryVariables
      >(GET_ACTIVITY_QUESTIONS_WITH_ANSWERS, {
        criteria,
        studentCriteria,
      })
      .pipe(map((response) => response.data.ActivityQuery.ActivityQuestions));
  }

  public markStudentAnswerOnline(
    model: MarkStudentAnswerOnlineInput
  ): Observable<BooleanGraphTypeResponse> {
    return this.dataService
      .mutate<TeacherMutations, TeacherMutationsMarkStudentAnswerOnlineArgs>(
        MARK_STUDENT_ANSWER_ONLINE,
        { model }
      )
      .pipe(map((response) => response.data.MarkStudentAnswerOnline));
  }

  public markStudentAnswerOffline(
    model: MarkActivityInput
  ): Observable<BooleanGraphTypeResponse> {
    return this.dataService
      .mutate<TeacherMutations, TeacherMutationsMarkActivityOfflineArgs>(
        MARK_ACTIVITY_OFFLINE,
        { model }
      )
      .pipe(map((response) => response.data.MarkStudentAnswerOnline));
  }
}
