import { gql } from '@apollo/client/core';

export const UPDATE_QUIZ = gql`
  mutation UpdateQuiz($model: QuizInput!) {
    Activity {
      UpdateQuiz(model: $model) {
        Result {
          Id
        }
      }
    }
  }
`;
