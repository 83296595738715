import { gql } from '@apollo/client/core';

export const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile($model: UpdateUserProfileInput) {
    Account {
      UpdateUserProfile(model: $model) {
        Result {
          AssistantId
          Bio
          EducationTypeId
          Email
          Gender
          GenderName
          Id
          UserType
          ImagePath
          IsConfirmed
          MobileNumber
          Name
          ParentId
          SchoolYearId
          Slogan
          Status
          StudentEducationTypeName
          StudentId
          StudentSchoolYearName
          TeacherId
          UserId
          VideoPath
        }
        TotalCount
      }
    }
  }
`;
