import { gql } from '@apollo/client/core';

export const GET_SESSIONS_ASSIGMENTS = gql`
  query GetSessionAssignments($criteria: ActivityCriteria) {
    ActivityQuery {
      Assignments(criteria: $criteria) {
        Result {
          Id
          Name
          ScientificSubjectName
          SchoolYearName
          CourseGroupId
          CourseGroupName
          SessionName
          StartDate
          DeadLine
          ActivityQuestions {
            QuestionAttachments {
              AttachmentName
              AttachmentPath
              IsAnswer
              FileType
            }
            QuestionAnswerAttachments {
              AttachmentName
              AttachmentPath
              IsAnswer

              FileType
            }
          }

          Attatchments {
            AttachmentPath
            FileType
          }
          UserActivityCount
          StudentCount
          IsMarked
          IsFinished
        }
        TotalCount
      }
    }
  }
`;
