export * from './user-profile.graphql';
export * from './course-group-students.graphql';
export * from './countries.graphql';
export * from './cities.graphql';
export * from './areas.graphql';
export * from './student-requests.graphql';
export * from './previous-exams.graphql';
export * from './teacher-questions-bank.graphql';
export * from './teachers.graphql';
export * from './posts.graphql';
export * from './sessions.graphql';
export * from './scientific-subjects.graphql';
export * from './check-teacher-follow.graphql';
export * from './teacher-course-groups.graphql';
export * from './teacher-subjects.graphql';
export * from './assignments.graphql';
export * from './activity-questions.graphql';
export * from './activity-questions-with-answers.graphql';
export * from './session-questions.graphql';
export * from './course-groups.graphql';
export * from './quizzes.graphql';
export * from './revisions.graphql';
export * from './teacher-courses.graphql';
export * from './eudcation-types.graphql';
export * from './school-years.graphql';
export * from './subjects.graphql';
export * from './teacher-assistants.graphql';
export * from './assistants.graphql';
export * from './user-activities.graphql';
