import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-time-is-up-modal',
  templateUrl: './time-is-up-modal.component.html',
  styleUrls: ['./time-is-up-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeIsUpModalComponent {
  public constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: { leavingDate: Date },
    private readonly dialogRef: MatDialogRef<TimeIsUpModalComponent>
  ) {}

  public submit(): void {
    this.dialogRef.close();
  }
}
