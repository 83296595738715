import { gql } from '@apollo/client/core';

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($model: RefreshTokenInput!) {
    Account {
      RereshToken(model: $model) {
        Result {
          RefreshToken
          Token
          TokenExpirationDate
        }
        TotalCount
      }
    }
  }
`;
