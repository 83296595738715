import { Injectable } from '@angular/core';
import {
  AddPreviousExamInput,
  ApplicationUserType,
  BooleanGraphTypeResponse,
  CreatePreviouExamMutation,
  CreatePreviouExamMutationVariables,
  EditPreviousExamInput,
  PreviousExam,
  PreviousExamCriteria,
  PreviousExamsQuery,
  PreviousExamsQueryVariables,
  PreviousExamTypeListResponse,
  PreviousExamTypeResponse,
  RemovePreviousExamMutation,
  RemovePreviousExamMutationVariables,
  UpdatePreviouExamMutation,
  UpdatePreviouExamMutationVariables,
} from '@generated-types';
import { GET_PREVIOUS_EXAMS } from '@queries';
import { AuthService } from './auth.service';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import {
  CREATE_PREVIOUS_EXAM,
  REMOVE_PREVIOUS_EXAM,
  UPDATE_PREVIOUS_EXAM,
} from '@mutations';

@Injectable({
  providedIn: 'root',
})
export class PreviousExamsService implements Resolve<Observable<PreviousExam>> {
  public constructor(
    private readonly dataService: DataService,
    private readonly authService: AuthService
  ) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<PreviousExam> {
    return this.getPreviousExams({
      Id: +route.params.id,
      StudentId: this.authService.getUserState().StudentId || null,
      TeacherId: this.authService.getUserState().TeacherId || null,
    }).pipe(
      map(({ Result }) => Result[0]),
      first()
    );
  }

  public getPreviousExams(
    criteria: PreviousExamCriteria
  ): Observable<PreviousExamTypeListResponse> {
    return this.dataService
      .query<PreviousExamsQuery, PreviousExamsQueryVariables>(
        GET_PREVIOUS_EXAMS,
        {
          criteria,
        }
      )
      .pipe(map((response) => response.data.TeacherQuery.PreviousExams));
  }

  public createPreviousExam(
    model: AddPreviousExamInput
  ): Observable<PreviousExamTypeResponse> {
    return this.dataService
      .mutate<CreatePreviouExamMutation, CreatePreviouExamMutationVariables>(
        CREATE_PREVIOUS_EXAM,
        {
          model,
        }
      )
      .pipe(map((response) => response.data.Teacher.CreatePreviousExam));
  }

  public updatePreviousExam(
    model: EditPreviousExamInput
  ): Observable<BooleanGraphTypeResponse> {
    return this.dataService
      .mutate<UpdatePreviouExamMutation, UpdatePreviouExamMutationVariables>(
        UPDATE_PREVIOUS_EXAM,
        {
          model,
        }
      )
      .pipe(map((response) => response.data.Teacher.EditPreviousExam));
  }

  public removePreviousExam(
    previousExamId: number,
    criteria: PreviousExamCriteria
  ): Observable<BooleanGraphTypeResponse> {
    return this.dataService
      .mutateWithRefetch<
        RemovePreviousExamMutation,
        RemovePreviousExamMutationVariables,
        PreviousExamsQuery,
        PreviousExamsQueryVariables
      >(
        REMOVE_PREVIOUS_EXAM,
        {
          previousExamId,
        },
        GET_PREVIOUS_EXAMS,
        {
          criteria,
        }
      )
      .pipe(map((response) => response.data.Teacher.DeletePreviousExam));
  }
}
