import { gql } from '@apollo/client/core';
export const CONFIRM_TEACHER_STUDENT_ATTENDANCE = gql`
  mutation ConfirmStudentAttendance($model: ConfirmSessionAttendanceInput) {
    Teacher {
      ConfirmStudentAttendance(model: $model) {
        Result {
          Id
          SessionId
          StudentId
          IsAttended
        }
      }
    }
  }
`;
