import { gql } from '@apollo/client/core';

export const REMOVE_POST = gql`
  mutation RemovePost($activtiyId: Int) {
    Activity {
      DeleteActivtiy(activtiyId: $activtiyId) {
        Result
      }
    }
  }
`;
