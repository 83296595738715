import { gql } from '@apollo/client/core';

export const GET_POSTS = gql`
  query Posts($criteria: ActivityCriteria) {
    ActivityQuery {
      Posts(criteria: $criteria) {
        Result {
          Id
          ActualCreator
          ScientificSubjectName
          SchoolYearName
          Name
          HideFromRecording
          Content
          VideoPath
          VideoPathId
          VideoPicturePath
          IsScheduled
          StartDate
          Date
          Attachments {
            AttachmentPath
            AttachmentName
            FileType
          }
          ActualCreatorImage
          CourseGroupName
          CourseGroupId
          CourseGroup {
            CourseId
          }
        }
        TotalCount
      }
    }
  }
`;
