import { gql } from '@apollo/client/core';

export const MARK_ACTIVITY_OFFLINE = gql`
  mutation MarkActivityOffline($model: MarkActivityInput) {
    Teacher {
      MarkActivityOffline(model: $model) {
        Result
      }
    }
  }
`;
