import { gql } from '@apollo/client/core';

export const UPDATE_SESSION = gql`
  mutation UpdateSession($model: SessionInput) {
    Session {
      UpdateSession(model: $model) {
        Result {
          Id
        }
      }
    }
  }
`;
