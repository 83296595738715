import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { I18NService, Language } from './services/i18n.service';

@Component({
  template: '<router-outlet></router-outlet>',
})
export class MainComponent implements OnInit {
  constructor(
    private readonly translate: TranslateService,
    private readonly i18NService: I18NService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly title: Title
  ) {}

  ngOnInit(): void {
    this.i18NService.init(
      environment.i18n.defaultLanguage as Language,
      environment.i18n.availableLanguages as Language[]
    );

    const onNavigationEnd$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );

    merge(this.translate.onLangChange, onNavigationEnd$)
      .pipe(
        map(() => {
          let currentRoute = this.route;
          while (currentRoute.firstChild) {
            currentRoute = currentRoute.firstChild;
          }
          return currentRoute;
        }),
        filter((route) => route.outlet === 'primary'),
        switchMap((route) => route.data),
        tap((data) => {
          if (data.title) {
            this.title.setTitle(this.translate.instant(data.title));
          }
        })
      )
      .subscribe();
  }
}
