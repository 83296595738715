import { Injectable } from '@angular/core';
import {
  AbsentInput,
  RequestForAbsentMutation,
  RequestForAbsentMutationVariables,
  RequestTypeResponse,
} from '@generated-types';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import { ABSENT_REQUEST } from '@mutations';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RequestsService {
  public constructor(private readonly dataService: DataService) {}

  public absent(model: AbsentInput): Observable<RequestTypeResponse> {
    return this.dataService
      .mutate<RequestForAbsentMutation, RequestForAbsentMutationVariables>(
        ABSENT_REQUEST,
        {
          model,
        }
      )
      .pipe(map((response) => response.data.Request.StudentRequests.Absent));
  }
}
