import { gql } from '@apollo/client/core';

export const CHECK_EMAIL_AND_MOBILE_NUMBER = gql`
  mutation CheckEmailAndMobileNumber($model: CheckEmailAndMobileNumberInput!) {
    Account {
      CheckEmailAndMobileNumber(model: $model) {
        Result {
          IsValid
        }
        TotalCount
      }
    }
  }
`;
