import { gql } from '@apollo/client/core';

export const CREATE_COURSE_GROUP = gql`
  mutation CreateCourseGroup($model: CourseGroupInput!) {
    CourseGroup {
      CreateCourseGroup(model: $model) {
        Result {
          Id
        }
      }
    }
  }
`;
