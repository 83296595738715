import { gql } from '@apollo/client/core';

export const GET_QUIZZES = gql`
  query Quizzes(
    $criteria: ActivityCriteria
    $withQuestion: Boolean!
    $userActivityCriteria: UserActivityCriteria
    $studentCriteria: StudentActivityQuestionAnswersCriteria
  ) {
    ActivityQuery {
      Quizzes(criteria: $criteria) {
        Result {
          Id
          Name
          Attachments {
            AttachmentName
            AttachmentPath
            FileType
          }
          ScientificSubjectName
          SchoolYearName
          CourseGroupName
          Date
          StartDate
          CourseId
          DeadLine
          IsOnline
          IsMarked
          Description
          Grade
          ActivityDuration
          ServerDateTime
          UserActivityCount
          CourseGroupId
          Content
          IsScheduled
          QuizQuestions @include(if: $withQuestion) {
            Id
            QuestionType
            QuestionText
            ChoiceOne
            ChoiceTwo
            ChoiceThree
            ChoiceFour
            Answer
            Grade
            StudentAnswer(criteria: $studentCriteria) {
              Answer
              Id
              Grade
              IsMarked
            }
            QuestionAttachments {
              AttachmentName
              AttachmentPath
              FileType
            }
            QuestionAnswerAttachments {
              AttachmentName
              AttachmentPath
              IsAnswer
              FileType
            }
          }
          UserActivities(criteria: $userActivityCriteria) {
            StudentName
            ActivityGrad
            ActivityId
            IsMarked
            DeliveredDate
            ActivityDeadline
            Grade
            CourseGroupName
            Attachments {
              AttachmentName
              AttachmentPath
              FileType
            }
          }
        }
        TotalCount
      }
    }
  }
`;


export const GET_QUIZZES_FOR_TABLE = gql`
  query QuizzesForTable(
    $criteria: ActivityCriteria
  ) {
    ActivityQuery {
      Quizzes(criteria: $criteria) {
        Result {
          Id
          Name
          Attachments {
            AttachmentName
            AttachmentPath
            FileType
          }
          ScientificSubjectName
          SchoolYearName
          CourseGroupName
          Date
          StartDate
          CourseId
          DeadLine
          IsOnline
          IsMarked
          Description
          Grade
          ActivityDuration
          ServerDateTime
          UserActivityCount
          CourseGroupId
          Content
          IsScheduled
        }
        TotalCount
      }
    }
  }
`;